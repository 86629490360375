import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  resetSite,
  resetCrane,
  updateSiteDetails,
} from '../../context/siteplan';
import { setRadiusModal, setResetModal } from '../../context/liftpage';
import Modal from '../../reusables/Modal/Modal';
import { resetOverlay } from './sitePlanFunctions';
import Button from '../../reusables/Button';

export default function ResetModal({
  footprint,
  setFootprint,
  obstacleOverlays,
  setObstacleOverlays,
  setCurrentPins,
  craneOverlay,
  setCraneOverlay,
  notesOverlays,
  setNotesOverlays,
  setInitialSetup,
}) {
  const { resetModal } = useSelector((state) => state.page);

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleResetButton = () => {
    reset();
    dispatch(resetSite());
    dispatch(resetCrane());
    dispatch(setResetModal(false));
    dispatch(setRadiusModal(true));
    setInitialSetup(false);
  };

  const reset = () => {
    if (craneOverlay) {
      if (craneOverlay.crane) craneOverlay.crane.setMap(null);
      if (craneOverlay.set) craneOverlay.set.setMap(null);
      if (craneOverlay.pick) craneOverlay.pick.setMap(null);
      if (craneOverlay.setLine) {
        craneOverlay.setLine.line.setMap(null);
        craneOverlay.setLine.label.marker.setMap(null);
      }
      if (craneOverlay.pickLine) {
        craneOverlay.pickLine.line.setMap(null);
        craneOverlay.pickLine.label.marker.setMap(null);
      }
      if (craneOverlay.tailswing) {
        craneOverlay.tailswing.setMap(null);
      }
      setCraneOverlay({
        crane: null,
        set: null,
        pick: null,
        setLine: null,
        pickLine: null,
        tailswing: null,
      });
    }
    if (footprint) {
      if (footprint.setCircle) footprint.setCircle.setMap(null);
      if (footprint.pickCircle) footprint.pickCircle.setMap(null);
      footprint.setMap(null);
      footprint.rigObstacle.setMap(null);
      for (let i = 0; i < 4; i += 1) {
        footprint.padObstacleArray[i].setMap(null);
      }
      setFootprint(null);
    }
    if (obstacleOverlays.length) {
      setCurrentPins([]);
      resetOverlay(obstacleOverlays);
      setObstacleOverlays([]);
      dispatch(updateSiteDetails({ packagedObstacles: [] }));
    }
    if (notesOverlays.length) {
      resetOverlay(notesOverlays);
      setNotesOverlays([]);
      dispatch(updateSiteDetails({ packagedNotes: [] }));
    }
  };

  return (
    <Modal
      show={resetModal}
      modalStyles={theme.modals.resetJobWalk}
      handleClose={() => dispatch(setResetModal(false))}
      title="Confirmation"
    >
      <p>The job walk will be reset</p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => handleResetButton()}
          theme={theme.buttons.resetJobWalk}
        >
          Confirm
        </Button>
        <Button
          onClick={() => dispatch(setResetModal(false))}
          theme={theme.buttons.editJobWalk}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
