import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled, { useTheme } from 'styled-components/macro';
import Modal from '../../reusables/Modal/Modal';
import Button from '../../reusables/Button';
import Input from '../../reusables/Input';
import { saveData } from '../../helpers/saveData';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../context/liftpage';
import { setAdditionalNoteModal } from '../../context/liftpage';
import { updateHazards, setHazards } from '../../context/siteplan';
import FilledPlus from '../../assets/images/FilledPlus.svg';

const HazardContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
  @media (min-width: 768px) {
    padding-left: 53px;
    padding-right: 53px;
  }
`;

const HazardsCard = styled.div`
  width: 246px;
  min-height: 243px;

  border: 1px solid #cfd4db;
  border-radius: 8px;
  margin: 0px 6px 10px 6px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    font-family: Roboto;
  }
  p {
    margin-top: 10px;
    color: gray;
  }
`;

const HazardsCardContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  font-size: 32px;
  flex-grow: 1;
  line-height: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px;
`;

export default function Hazards() {
  const { hazards } = useSelector((state) => state.site);
  const [hazardName, setHazardName] = useState('');
  const [mitigationPlanDesc, setMigtigationPlanDesc] = useState('');
  const [selectedId, setSelectedId] = useState();
  const { additionalNoteModal } = useSelector((state) => state.page);
  const theme = useTheme();
  const dispatch = useDispatch();
  const onEdit = (id) => {
    const filteredHazard = hazards?.find((d) => d.id === id);
    if (filteredHazard) {
      setHazardName(filteredHazard.hazardName);
      setMigtigationPlanDesc(filteredHazard.mitigationPlanDesc);
      dispatch(setAdditionalNoteModal(true));
      setSelectedId(filteredHazard.id);
    }
  };
  const onDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete?');
    if (confirmDelete) {
      let updatedHazards = hazards?.slice() || [];
      updatedHazards = updatedHazards.filter((d) => d.id !== id);
      dispatch(setHazards(updatedHazards));
      dispatch(saveData());
      dispatch(
        setNotification({
          text: 'Hazard deleted successfully!',
          type: 'success',
        })
      );
    }
  };

  return (
    <HazardContainer>
      <TitleContainer>
        <Title>Additional Considerations</Title>
        <Button
          onClick={() => dispatch(setAdditionalNoteModal(true))}
          theme={theme.buttons.editJobWalk}
          style={{ width: 'auto' }}
        >
          <img src={FilledPlus} /> &nbsp; Add Note
        </Button>
      </TitleContainer>
      <HazardsCardContainer>
        {Array.isArray(hazards) &&
          hazards?.map((d) => (
            <HazardsCard key={d.id}>
              <h3 style={{ marginTop: '20px' }} title={d.hazardName}>
                {d.hazardName}
              </h3>
              <p
                style={{
                  minHeight: '80px',
                  overflowWrap: 'break-word',
                  fontSize: '13px',
                }}
                title={d.mitigationPlanDesc}
              >
                {d.mitigationPlanDesc.length >= 150
                  ? d.mitigationPlanDesc.substring(0, 150) + '...'
                  : d.mitigationPlanDesc}
              </p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  marginBottom: '24px',
                  marginTop: '24px',
                }}
                className="hazard-card-icons-container"
              >
                <Button
                  theme={theme.buttons.deleteAdditionalConsiderations}
                  onClick={() => onDelete(d.id)}
                >
                  Delete
                </Button>
                <Button
                  theme={theme.buttons.resumeAdditionalConsiderations}
                  onClick={() => onEdit(d.id)}
                >
                  Edit
                </Button>
              </div>
            </HazardsCard>
          ))}
      </HazardsCardContainer>
      <Modal
        show={additionalNoteModal}
        modalStyles={{
          padding: '20px',
          height: '100%',
        }}
        title={'Add Note'}
        handleClose={() => {
          dispatch(setAdditionalNoteModal(false));
          setHazardName('');
          setMigtigationPlanDesc('');
          setSelectedId(undefined);
        }}
      >
        <div>
          <Input>
            <p>Name</p>
            <input
              placeholder="Hazard, special requirements"
              type="text"
              value={hazardName}
              onChange={(e) => setHazardName(e.target.value)}
            />
          </Input>
          <Input>
            <p>Details/Mitigation</p>
            <textarea
              rows="5"
              style={{ resize: 'none' }}
              placeholder="Specifics, extra equipment required, etc."
              type="text"
              value={mitigationPlanDesc}
              onChange={(e) => setMigtigationPlanDesc(e.target.value)}
            />
          </Input>
          <Button
            id="modal-save-button"
            theme={theme.buttons.default}
            onClick={() => {
              if (hazardName.length === 0 || mitigationPlanDesc.length === 0) {
                dispatch(
                  setNotification({
                    text: 'Hazard fields cannot be empty',
                    type: 'error',
                  })
                );
                return;
              }

              if (selectedId) {
                if (hazardName.length > 0 && mitigationPlanDesc.length > 0) {
                  if (Array.isArray(hazards)) {
                    let updatedHazards = [...hazards];
                    for (let i = 0; i < updatedHazards.length; i++) {
                      if (updatedHazards[i].id === selectedId) {
                        updatedHazards[i] = {
                          id: updatedHazards[i].id,
                          hazardName: hazardName,
                          mitigationPlanDesc: mitigationPlanDesc,
                        };
                      }
                    }
                    dispatch(setHazards(updatedHazards));
                    dispatch(saveData());
                  }
                }
              } else if (
                hazardName.length > 0 &&
                mitigationPlanDesc.length > 0
              ) {
                const newhaz = {
                  id: uuidv4(),
                  hazardName,
                  mitigationPlanDesc,
                };
                dispatch(updateHazards(newhaz));
                dispatch(saveData());
              }
              setHazardName('');
              setMigtigationPlanDesc('');
              dispatch(setAdditionalNoteModal(false));
              setSelectedId(undefined);
              dispatch(
                setNotification({
                  text: `Hazard ${
                    selectedId ? 'updated' : 'added'
                  } successfully!`,
                  type: 'success',
                })
              );
            }}
          >
            {selectedId ? 'Update' : 'Save'}
          </Button>
        </div>
      </Modal>
    </HazardContainer>
  );
}
