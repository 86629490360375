import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { useStytch } from '@stytch/react';
import Button from '../../reusables/Button';
import { validateEmail } from '../../helpers/validate';
import Loader from '../../reusables/Loader/Loader';
import Input from '../../reusables/InputBar';
import Password from '../../assets/images/password.svg';
import Switch from '../../reusables/Switch';
// import { useDispatch } from 'react-redux';
// import { setNotification } from '../../context/liftpage';

const Title = styled.h1`
  font-family: 'Roboto-Medium';
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 36px;
`;

const SwitchContainer = styled.div`
  width: 100%;
  p {
    margin-top: 44px;
    margin-bottom: 15px;
    font-size: 16px;
  }
`;

const ForgotPassword = styled.div`
  font-size: 16px;
  color: #f5a302;
  width: 100%;
  cursor: pointer;
  @media (min-width: 768px) {
    width: auto;
  }
`;

const LoginFormContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 64px);
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 100px;
    padding-right: 100px;
    overflow: auto;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  p {
    margin-bottom: 8px;
    font-size: 16px;
    align-self: start;
  }
  button {
    position: absolute;
    top: 40px;
    right: 10px;
    border: none;
    background: none;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function LoginForm({ history, notification }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [ready, setReady] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const theme = useTheme();
  const stytch = useStytch();

  const callLoginService = async () => {
    if (validateEmail(email) && password.length > 0) {
      setIsLoading(true);
      stytch.passwords
        .authenticate({
          email,
          password,
          session_duration_minutes: rememberMe ? 1440 : 60,
        })
        .then((res) => {
          history.push('/');
        })
        .catch((err) => {
          if (err.message.includes('[401]')) {
            notification('Error while logging in! Email or password invalid');
          }
        });
      setIsLoading(false);
    } else {
      notification(
        'Please double check that you typed your email and password correctly'
      );
    }
  };

  useEffect(() => {
    if (email.length && password.length) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [email, password]);

  return (
    <LoginFormContainer>
      <Title>Login</Title>
      <InputContainer>
        <p>Email address</p>
        <Input
          placeholder="Enter email id"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              callLoginService();
            }
          }}
        />
      </InputContainer>
      <InputContainer>
        <p>Password</p>
        <Input
          placeholder="Enter password"
          type={isPasswordVisible ? 'text' : 'password'}
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              callLoginService();
            }
          }}
        />
        <button
          id="togglePassword"
          type="button"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <img alt="eyes" src={Password} />
        </button>
      </InputContainer>
      <ForgotPassword>
        <Link
          style={{ color: '#0458B2', textDecoration: 'none' }}
          to="/passwordless"
        >
          Forgot Password
        </Link>
      </ForgotPassword>
      <SwitchContainer>
        <p>Remember Me</p>
        <Switch callback={setRememberMe} toggled={rememberMe} />
      </SwitchContainer>
      <FlexColumn>
        <Button
          theme={ready ? theme.buttons.loginNotReady : theme.buttons.login}
          style={{ marginTop: '62px', marginBottom: '20px' }}
          disabled={isLoading}
          onClick={() => callLoginService()}
        >
          {isLoading ? <Loader /> : 'Login'}
        </Button>
      </FlexColumn>
    </LoginFormContainer>
  );
}
