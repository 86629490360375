import React from 'react';
import { ReactComponent as MapSVG } from '../../assets/images/mapComponent.svg';
import { ReactComponent as DiagramSVG } from '../../assets/images/diagramComponent.svg';
import { DiagramContainer } from './siteplan_components';
import DoubleButton from './DoubleButton';
import { useTheme } from 'styled-components';

export default function DiagramToggle({
  diagramModal,
  selectedBoomLength,
  setDiagramModal,
}) {
  const theme = useTheme();

  return (
    <DiagramContainer>
      <DoubleButton theme={theme.buttons.editJobWalk}>
        <button
          className={diagramModal ? 'first' : 'first selected'}
          onClick={() => setDiagramModal(false)}
        >
          <MapSVG
            fill={diagramModal ? 'black' : '#0458b2'}
            style={{ height: '18px', width: '18px' }}
          />
          Map
        </button>
        {selectedBoomLength === null || selectedBoomLength === 'default' ? (
          <button className={'last disabled'}>
            <DiagramSVG
              fill={'#3B4046'}
              style={{ height: '18px', width: '18px' }}
            />
            Diagram
          </button>
        ) : (
          <button
            className={diagramModal ? 'last selected' : 'last'}
            onClick={() => setDiagramModal(true)}
          >
            <DiagramSVG
              fill={diagramModal ? '#0458b2' : 'black'}
              style={{ height: '18px', width: '18px' }}
            />
            Diagram
          </button>
        )}
      </DoubleButton>
    </DiagramContainer>
  );
}
