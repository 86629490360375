import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { setDeleteModal } from '../../context/liftpage';
import Trash from '../../assets/images/Trash.svg';

const TheBubble = styled.div`
  width: 111px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.29);
  position: absolute;
  background-color: white;
  right: 40px;
  cursor: pointer;
  p {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.red.base};
  }
  top: -8px;
  &::after {
    content: '';
    position: absolute;
    top: 20px;
    right: -8px;
    height: 20px;
    width: 20px;
    background: inherit;
    transform: rotate(45deg);
    border-radius: 2px;
  }
`;

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function Popup({ content, setShowBubble }) {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShowBubble);

  const handleOnClick = () => {
    dispatch(setDeleteModal({ show: true, content: content }));
  };

  return (
    <TheBubble ref={wrapperRef} onClick={() => handleOnClick()}>
      <img src={Trash} alt="Trashcan" />
      <p>Delete</p>
    </TheBubble>
  );
}
