import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

export default function Routes() {
  const publicRoutes = [
    '/login',
    '/passwordless',
    '/magiclink',
    '/signup',
    '/reset',
    '/review/liftplan/:id',
    '*',
  ];
  const privateRoutes = [
    '/',
    '/home',
    '/admin',
    '/help',
    '/jobwalk/:id',
    '/liftplan/:id',
    '/mycranes',
    '/myteam',
  ];
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path={privateRoutes} />
        <PublicRoute path={publicRoutes} />
      </Switch>
    </Router>
  );
}
