import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { useStytch } from '@stytch/react';
import { API_URL } from '../../services/api';
import Button from '../../reusables/Button';
import { validateEmail } from '../../helpers/validate';
import Loader from '../../reusables/Loader/Loader';
import Input from '../../reusables/InputBar';
import Password from '../../assets/images/password.svg';

const Title = styled.h1`
  font-family: 'Roboto-Medium';
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 36px;
`;

const LoginFormContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 64px);
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 100px;
    padding-right: 100px;
    overflow: auto;
  }
`;

const InputContainer = styled.div`
margin-bottom: 30px;
position: relative;
width: 100%;
p {
  margin-bottom: 8px;
  font-size: 12px;
  align-self: start;
}
button {
  position: absolute;
  top: 40px;
  right: 10px;
  border: none;
  background: none;
},
`;

// authenticate via magic link
export default function MagicLinkAuth({ history }) {
  const stytchClient = useStytch();

  useEffect(() => {
    try {
      const token = new URLSearchParams(window.location.search).get('token');
      axios
        .post(`${API_URL}/user/magiclink`, {
          token,
        })
        .then(async (res) => {
          if (res.status === 200) {
            document.cookie = `stytch_session=${res.data.msg.session_token}`;
            document.cookie = `stytch_session_jwt=${res.data.msg.session_jwt}`;
            await stytchClient.session.authenticate(
              res.data.msg.session_token,
              { session_duration_minutes: 60 }
            );
            window.location.replace('/');
          }
        });
    } catch (err) {
      console.log('Error occured while authenticating via magic link ', err);
    }
  }, []);

  console.log('four');

  return <div>authenticating...</div>;
}
