const liftplanState = {
  basicInfo: {
    createdBy: {
      type: 'String',
      required: false,
      label: 'Created By',
    },
    jobWalkDate: {
      type: 'String',
      required: false,
      label: 'Job Walk Date',
    },
    jobStartDate: {
      type: 'String',
      required: false,
      label: 'Job Start Date',
    },
    date: {
      type: 'String',
      required: false,
      label: 'Report Created Date',
    },
    projectName: {
      type: 'String',
      required: true,
      label: 'Project Name',
    },
    customerName: {
      type: 'String',
      required: false,
      label: 'Customer Name',
    },
    customerPOC: {
      type: 'String',
      required: false,
      label: 'Customer Point of Contact',
    },
    customerPhone: {
      type: 'String',
      required: false,
      label: 'Point of Contact Phone Number',
    },
    customerEmail: {
      type: 'String',
      required: false,
      label: 'Point of Contact Email',
    },
    location: {
      type: 'String',
      required: true,
      label: 'Location',
    },
    description: {
      type: 'String',
      required: false,
      label: 'Description',
    },
    duration: {
      type: 'Number',
      required: false,
      label: 'Duration',
    },
    durationType: {
      type: 'String',
      required: false,
      label: 'Duration Type',
    },
    arrivalTime: {
      type: 'String',
      required: false,
      label: 'Arrival Time',
    },
  },
  loadDetails: {
    emailVerification: {
      type: 'Mixed',
      required: false,
      label: 'Email verification',
    },
    loadWeight: {
      type: 'Number',
      required: true,
      label: 'Load weight',
    },
    loadDescription: {
      type: 'String',
      required: true,
      label: 'Load description',
    },
    loadHeight: {
      type: 'Number',
      required: true,
      label: 'Load height',
    },
    loadWidth: {
      type: 'Number',
      required: true,
      label: 'Load width',
    },
    loadDiameter: {
      type: 'Number',
      required: true,
      label: 'Load Diamter',
    },
    loadLength: {
      type: 'Number',
      required: true,
      label: 'Load length',
    },
    selectedLoad: {
      type: 'String',
      required: true,
      label: 'Selected load',
    },
    weightDeterminedBy: {
      type: 'String',
      required: false,
      label: 'Weight determined',
    },
  },
  craneSelectionDetails: {
    selectedCrane: {
      type: 'String',
      required: true,
      label: 'Please select a crane from the list',
    },
    selectedAttachment: {
      type: 'String',
      required: true,
      label: 'Please select attachment',
    },
    selectedCounterweight: {
      type: 'String',
      required: true,
      label: 'Please select a counterweight from the list',
    },
    rotation: {
      type: 'Mixed',
      required: false,
      label: 'Rotation',
      default: '360',
    },
    selectedLoadChart: {
      type: 'Object',
      required: false,
      label: 'Load Chart',
    },
    selectedHookblock: {
      type: 'Object',
      required: false,
      label: 'Please select a hookblock from the list',
    },
    selectedBoomLength: {
      type: 'String',
      required: true,
      label: 'Please select a boom length from the list',
    },
    maxOperatingRadius: {
      type: 'Number',
      required: true,
    },
    grossCapacity: {
      type: 'Number',
      required: true,
    },
    additionalDeductions: {
      type: 'Number',
      required: false,
      label: 'Additional Deductions',
      default: 0,
    },
    totalDeductions: {
      type: 'Number',
      required: false,
      label: 'Total Deductions',
      default: 0,
    },
    netCapacity: {
      type: 'Number',
      required: true,
      label: 'Net Capacity',
    },
    percentOfCap: {
      type: 'String',
      required: true,
      label: 'Please make sure crane configuration is fully selected',
    },
  },
  operatorSelectionDetails: {
    selectedOperator: {
      type: 'Object',
      required: true,
      label: 'Please select an operator for the plan',
    },
  },
  sitePlanInfo: {
    overlays: {
      type: 'Object',
      required: true,
      label: 'Please place the crane',
    },
  },
};

export default liftplanState;
