/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import authHeader from '../services/auth-header';
import { API_URL } from '../services/api';

const initialState = {
  basicInfo: {
    location: '',
    durationType: 'Hours',
  },
  loadDetails: {
    selectedLoad: '',
    loadWeight: 0,
  },
  craneDetails: {
    customObstacles: [],
    selectedOffset: 0,
    allowableOffsets: [],
    min: 0,
    selectedLoadChart: null,
    boomPinHeight: 3,
    selectedCrane: 'default',
    selectedAttachment: 'default',
    auxLineDeduction: null,
    mainLineDeduction: null,
    selectedCounterWeight: null,
    boomsAndCapacities: {},
    maxOperatingRadius: null,
    stowedDeduction: 0,
    selectedBoomLength: 'default',
    totalDeductions: 0,
    rotation: '360',
    grossCap: 0,
    hookBlocks: [],
    netCapacity: 0,
    percentOfCap: null,
    footprintDimensions: {
      height: 13424,
      width: 2880,
      fullWidth: 7200,
      centerPin: 9219,
      frontRig: 3914,
      backRig: 12574,
      pickRadius: 0,
      setRadius: 0,
      padWidth: 4,
      padLength: 6,
    },
    attachments: [],
    counterweights: [],
    units: 'Imperial',
    additionalDeductions: null,
    selectedHookblock: 'default',
    auxselectedHookblock: 'default',
    attachmentStatus: 'absent',
    attachmentExtension: 'default',
    hangingHeight: 0,
    sheaves: 'default',
    partsOfLine: 'default',
    auxsheaves: 'default',
    auxpartsOfLine: 'default',
    riggingDeductions: 0,
    otherDeductions: 0,
    auxLinePresent: 0,
    mainLinePresent: 0,
    lowPercent: 0,
    highPercent: 75,
    setRadius: null,
    pickRadius: null,
    setAngle: null,
    pickAngle: null,
  },
  hazards: [],
  siteOverlays: {},
  siteDetails: {
    liftplanId: null,
    scalingPins: [],
    ready: false,
    distances: [],
    diagramPins: [],
    loadWeight: null,
    angle: null,
    reset: null,
    selectedObstacle: null,
    craneLocation: null,
    setLocation: null,
    pickLocation: null,
    pinType: null,
    packagedObstacles: [],
    packagedNotes: [],
    footprintPath: [],
    rigprintPath: [],
    padPathArray: [],
  },
  overlays: {
    uploadModal: false,
    isShown: false,
    imageLink: '',
    height: 0,
    width: 0,
    tempPoint: { x: 0, y: 0 },
    point1: { x: 0, y: 0 },
    point2: { x: 0, y: 0 },
    mapPoint1: null,
    mapPoint2: null,
    deg: 0,
    rotationPoint: null,
    overlayBounds: null,
  },
  riggingPlan: {
    bridlePositions: [
      { x: -1, y: 1 },
      { x: 1, y: 1 },
      { x: 1, y: -1 },
      { x: -1, y: -1 },
      { x: 0, y: 0 },
    ],
    lines: 0,
    type: null,
    xdist: 1,
    cylRotateX: Math.PI / 2,
    config: null,
    lineHeight: 0,
    slingWeights: [],
    currentShackle: { size: 'default' },
    currentSling: {
      manufacturer: 'Lift-All',
      category: 'Endless Polyester Roundsling',
      length: 20,
      width: null,
      color: 'Blue',
      ply: null,
      weight: 22,
      capacities: {
        vertical: 21200,
        choker: 17000,
        basket: 42400,
      },
      linkedCompany: 'cranes',
      files: [
        'https://nesscampbell.com/wp-content/uploads/the-science-behind-rigging-nesscampbell-2.jpg',
      ],
    },
  },
  craneList: [],
  mode: 'select',
};

const handleSaveData = (state, liftplanId) => {
  axios
    .post(
      `${API_URL}/liftplan/create/draft`,
      {
        liftplanId,
        liftplanState: state,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      console.log('response', response);
    })
    .catch((err) => {
      console.log('error', err);
    });
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    resetRedux: (state) => initialState,
    updateBasicInfo: (state, action) => {
      state.basicInfo = { ...state.basicInfo, ...action.payload };
    },
    updateCraneDetails: (state, action) => {
      state.craneDetails = { ...state.craneDetails, ...action.payload };
    },
    updateLoadDetails: (state, action) => {
      state.loadDetails = { ...state.loadDetails, ...action.payload };
    },
    updateRiggingPlan: (state, action) => {
      state.riggingPlan = { ...state.riggingPlan, ...action.payload };
    },
    updateHazards: (state, action) => {
      state.hazards = [...state.hazards, action.payload];
    },
    setHazards: (state, action) => {
      state.hazards = action.payload;
    },
    updateMode: (state, action) => {
      state.mode = action.payload;
    },
    updateSiteOverlays: (state, action) => {
      state.siteOverlays = { ...state.siteOverlays, ...action.payload };
    },
    updateSiteDetails: (state, action) => {
      state.siteDetails = { ...state.siteDetails, ...action.payload };
    },
    saveData: (state, action) => {
      const newSiteDetails = {
        ...state.siteDetails,
        ...action.payload.savedData,
      };
      const newBasicInfo = { ...state.basicInfo };
      const newLoadDetails = { ...state.loadDetails };
      const newCraneDetails = { ...state.craneDetails };
      const newOverlays = { ...state.overlays };
      const newRigging = { ...state.riggingPlan };
      handleSaveData(
        {
          basicInfo: newBasicInfo,
          loadDetails: newLoadDetails,
          craneDetails: newCraneDetails,
          overlays: newOverlays,
          siteDetails: newSiteDetails,
          riggingPlan: newRigging,
        },
        action.payload.liftplanId
      );
      state.siteDetails = newSiteDetails;
    },
    setSiteplanData: (state, action) => {
      state.basicInfo = { ...state.basicInfo, ...action.payload.basicInfo };
      state.craneDetails = {
        ...state.craneDetails,
        ...action.payload.craneDetails,
      };
      state.siteDetails = { ...action.payload.siteDetails };
      state.loadDetails = {
        ...state.loadDetails,
        ...action.payload.loadDetails,
      };
      state.overlays = { ...action.payload.overlays };
      state.riggingPlan = {
        ...state.riggingPlan,
        ...action.payload.riggingPlan,
      };
      state.hazards = action.payload.hazards || [];
    },
    updateSiteplanData: (state, action) => {
      state.basicInfo = { ...state.basicInfo, ...action.payload.basicInfo };
      state.craneDetails = {
        ...state.craneDetails,
        ...action.payload.craneDetails,
      };
      state.loadDetails = {
        ...state.loadDetails,
        ...action.payload.loadDetails,
      };
      state.hazards = action.payload.hazards;
    },
    setReady: (state) => {
      state.siteDetails.ready = true;
    },
    siteplanValue: (state, action) => ({ ...state, ...action.payload }),
    craneLift: (state, action) => ({
      ...initialState,
      craneLift: action.payload,
      ready: false,
    }),
    resetSite: (state) => {
      const { craneList, loadWeight } = state;
      return {
        ...initialState,
        craneList,
        mode: 'select',
        ready: true,
        loadWeight,
      };
    },
    resetRigging: (state) => {
      state.riggingPlan = {
        ...initialState.riggingPlan,
        currentSling: { ...state.riggingPlan.currentSling },
      };
    },
    craneList: (state, action) => {
      state.craneList = action.payload;
    },
    setCanvas: (state, action) => {
      state.overlays.isShown = action.payload;
    },
    setImage: (state, action) => {
      state.overlays.imageLink = action.payload.imageLink;
    },
    setPoints: (state, action) => {
      if (action.payload.point === 1) {
        state.overlays.point1 = state.overlays.tempPoint;
      }
      if (action.payload.point === 2) {
        state.overlays.point2 = state.overlays.tempPoint;
      }
    },
    setMapPoints: (state, action) => {
      state.overlays.mapPoint1 = action.payload[0];
      state.overlays.mapPoint2 = action.payload[1];
    },
    setTemp: (state, action) => {
      state.overlays.tempPoint = action.payload.tempPoint;
    },
    setOverlayData: (state, action) => {
      state.overlays.overlayBounds = action.payload.overlayBounds;
      state.overlays.rotationPoint = action.payload.rotationPoint;
      state.overlays.deg = action.payload.deg;
    },
    setUploadModal: (state, action) => {
      state.overlays.uploadModal = action.payload;
    },
    setDimensions: (state, action) => {
      state.overlays.height = action.payload.height;
      state.overlays.width = action.payload.width;
    },
    setConfiguration: (state, action) => {
      state.overlays.imageLink = action.payload.imageLink;
      state.overlays.height = action.payload.height;
      state.overlays.width = action.payload.width;
      state.overlays.point1 = action.payload.point1;
      state.overlays.point2 = action.payload.point2;
      state.overlays.mapPoint1 = action.payload.mapPoint1;
      state.overlays.mapPoint2 = action.payload.mapPoint2;
    },
    setBridlePoints: (state, action) => {
      const n = [...state.riggingPlan.bridlePositions];
      n[action.payload.index] = action.payload.point;
      state.riggingPlan.bridlePositions = [...n];
    },
    resetCanvas: (state) => ({
      ...state,
      overlays: {
        ...state.overlays,
        imageLink: initialState.overlays.imageLink,
        tempPoint: initialState.overlays.tempPoint,
        point1: initialState.overlays.point1,
        point2: initialState.overlays.point2,
      },
    }),
    setOverlayDefault: (state) => {
      state.overlays = initialState.overlays;
    },
    mapLoaded: (state, action) => {
      state.craneDetails = {
        ...state.craneDetails,
        ...action.payload.craneDetails,
      };
    },
    resetCrane: (state) => {
      state.craneDetails = {
        ...initialState.craneDetails,
        setRadius: null,
        pickRadius: null,
      };
    },
    newCrane: (state) => {
      state.craneDetails.percentOfCap = null;
      state.craneDetails.selectedAttachment = 'default';
      state.craneDetails.selectedCounterWeight = 'default';
      state.craneDetails.boomsAndCapacities = {};
      state.craneDetails.selectedBoomLength = 'default';
      state.craneDetails.pickRadius = null;
      state.craneDetails.setRadius = null;
    },
  },
});

export const {
  resetRedux,
  siteplanValue,
  craneList,
  setReady,
  resetSite,
  setSiteplanData,
  updateBasicInfo,
  updateLoadDetails,
  updateCraneDetails,
  updateSiteOverlays,
  updateSiteDetails,
  updateHazards,
  setCanvas,
  setImage,
  setPoints,
  setTemp,
  setDimensions,
  setUploadModal,
  setConfiguration,
  setMapPoints,
  setOverlayData,
  resetCanvas,
  setOverlayDefault,
  mapLoaded,
  saveData,
  resetCrane,
  updateMode,
  setHazards,
  setBridlePoints,
  resetRigging,
  updateRiggingPlan,
} = siteSlice.actions;

export default siteSlice.reducer;
