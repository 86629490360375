import React from 'react';
import LittleButton from './LittleButton';
import { ReactComponent as CaretRight } from '../assets/images/CaretRight.svg';
import { ReactComponent as CaretLeft } from '../assets/images/CaretLeft.svg';
import styled from 'styled-components/macro';

const PaginationContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-self: stretch;
`;

const BigNum = styled.p`
  color: #3b4046;
  font-size: 24px;
`;

function Pagination({
  current,
  handleRight,
  handleLeft,
  handlePage,
  numPages,
  disabled,
}) {
  const createButtons = (num) => {
    const bs = [];
    for (let i = 0; i < num; i++) {
      let backgroundStyle = {};
      let colorStyle = {};
      if (i + 1 === current) {
        backgroundStyle = { background: '#0458B2' };
        colorStyle = { color: 'white' };
      }
      if (disabled.includes(i + 1)) {
        backgroundStyle = {
          background: '#CFD4DB',
          justifyContent: 'flex-start',
        };
        colorStyle = { color: '#656D76', fontStyle: 'italic' };
      }
      bs.push(
        <LittleButton
          onClick={() => {
            handlePage(i + 1);
          }}
          style={{ ...backgroundStyle }}
        >
          <BigNum style={{ ...colorStyle }}>{i + 1}</BigNum>
        </LittleButton>
      );
    }
    return bs;
  };

  return (
    <PaginationContainer>
      <LittleButton
        onClick={() => {
          handleLeft();
        }}
        style={disabled.includes(current - 1) ? { background: '#CFD4DB' } : {}}
      >
        <CaretLeft
          fill={disabled.includes(current - 1) ? '#656D76' : '#3B4046'}
        />
      </LittleButton>
      {createButtons(numPages)}
      <LittleButton
        onClick={() => {
          handleRight();
        }}
        style={disabled.includes(current + 1) ? { background: '#CFD4DB' } : {}}
      >
        <CaretRight
          fill={disabled.includes(current + 1) ? '#656D76' : '#3B4046'}
        />
      </LittleButton>
    </PaginationContainer>
  );
}

export default Pagination;
