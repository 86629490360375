import styled from "styled-components/macro";

const DoubleButton = styled.div`
  display: flex;
  &::focus {
    outline-width: 0;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .first {
    display: flex;
    gap: 8px;
    border-radius: 8px 0 0 8px;
    background: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
    font-size: ${({ theme }) => (theme.fontSize ? theme.fontSize : "16px")};
    border: ${({ theme }) => (theme.border ? theme.border : "none")};
    height: 48px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ theme }) => theme.width};
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamily};
    padding: ${({ theme }) => (theme.padding ? theme.padding : "0")};
    font-style: ${({ theme }) =>
      theme.fontStyle ? theme.fontStyle : "normal"};
  }
  .last {
    display: flex;
    gap: 8px;
    border-radius: 0 8px 8px 0;
    background: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.color};
    font-size: ${({ theme }) => (theme.fontSize ? theme.fontSize : "16px")};
    border: ${({ theme }) => (theme.border ? theme.border : "none")};
    height: 48px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ theme }) => theme.width};
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamily};
    padding: ${({ theme }) => (theme.padding ? theme.padding : "0")};
    font-style: ${({ theme }) =>
      theme.fontStyle ? theme.fontStyle : "normal"};
  }
  .selected {
    font-weight: 600;
    color: #0458b2;
    border: 1px solid #0458b3;
  }
  .disabled {
    font-style: italic;
    font-weight: 400;
    color: #3b4046;
    background: #cfd4db;
  }
`;

export default DoubleButton;
