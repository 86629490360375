import React from 'react';
import styled, { withTheme } from 'styled-components/macro';
import LoginCraneBanner from '../../reusables/LoginCraneBannner/LoginCraneBanner';
import LoginForm from './LoginForm';
import { useState } from 'react';

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  height: calc(100vh - 64px);
  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const Banner = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    width: 45%;
    background-color: dark gray;
  }
`;

const FormDiv = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    background-color: theme.colors.body.primary;
  }
  @media (min-width: 768px) {
    width: 55%;
    background-color: theme.colors.body.primary;
    display: flex;
    align-items: center;
  }
`;

const NotificationBanner = styled.div`
  width: 100%;
  position: absolute;
  height: 67px;
  text-align: center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.5s;
  color: #b50614;
  background-color: #f2d5d7;
`;

export default function Login(props) {
  const [isDisplayed, setIsDisplayed] = useState(1);
  const [notifText, setNotifText] = useState('');

  const notification = (text) => {
    setNotifText(text);
    setIsDisplayed(0);
    setTimeout(() => {
      setIsDisplayed(1);
    }, 5000);
  };

  return (
    <Container>
      <NotificationBanner style={{ top: isDisplayed * -67 }}>
        {notifText}
        <i
          className="fas fa-times"
          onClick={() => setIsDisplayed(1)}
          style={{
            cursor: 'pointer',
            fontSize: 25,
            color: 'black',
            position: 'absolute',
            right: 30,
          }}
        />
      </NotificationBanner>
      <Banner>
        <LoginCraneBanner />
      </Banner>
      <FormDiv>
        <LoginForm {...props} notification={notification} />
      </FormDiv>
    </Container>
  );
}
