import React from 'react';
import styled, { withTheme } from 'styled-components/macro';
import LoginCraneBanner from '../../reusables/LoginCraneBannner/LoginCraneBanner';
import PasswordlessForm from './PasswordlessForm';

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  height: calc(100vh - 64px);
  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const Banner = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    width: 45%;
    background-color: dark gray;
  }
`;

const FormDiv = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    background-color: theme.colors.body.primary;
  }
  @media (min-width: 768px) {
    width: 55%;
    background-color: theme.colors.body.primary;
    display: flex;
    align-items: center;
  }
`;

function Login(props) {
  return (
    <Container>
      <Banner>
        <LoginCraneBanner />
      </Banner>
      <FormDiv>
        <PasswordlessForm {...props} />
      </FormDiv>
    </Container>
  );
}

export default withTheme(Login);
