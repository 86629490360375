export default {
  deleteJobWalk: {
    width: '460px',
    position: 'fixed',
    padding: '20px',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  },
  elevation: {
    borderRadius: '5px',
    padding: '20px',
    width: '327px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  radius: {
    borderRadius: '5px',
    padding: '20px',
    width: '327px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  info: {
    padding: '20px',
    width: '570px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
  },
  resetJobWalk: {
    padding: '20px',
    width: '400px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
  },
  diagram: {
    padding: '20px',
    position: 'fixed',
    width: 'auto',
    height: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  notes: {
    borderRadius: '5px',
    padding: '20px',
    width: '327px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  craneSelection: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  rigging: {
    padding: '20px',
    width: '570px',
    height: '600px',
    overflowY: 'scroll',
    position: 'fixed',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
  },
  pick: {
    padding: '20px',
    width: '90%',
    height: '90%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
  },
};
