import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/macro';
import NoteSVG from '../../assets/images/Toolbar/notes.svg';
import PathSVG from '../../assets/images/Toolbar/paths.svg';
import CraneSVG from '../../assets/images/Toolbar/crane.svg';
import ObstacleSVG from '../../assets/images/Toolbar/obstacles.svg';
import MapSVG from '../../assets/images/Toolbar/map.svg';
import Slider from '../../reusables/Slider';
import {
  updateSiteDetails,
  resetCanvas,
  setPoints,
  setCanvas,
  setUploadModal,
  updateMode,
} from '../../context/siteplan';
import {
  setNoteModal,
  setRadiusModal,
  setShowOverlay,
} from '../../context/liftpage';
import Button from '../../reusables/Button';
import { saveData } from '../../helpers/saveData';

const PlacePinDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding-left: 24px;
`;

const TheButton = styled.button`
  width: 80px;
  height: 80px;
  background: #3b4046;
  border: 2px solid #2a2f35;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 16px;
  img {
    margin-bottom: 5px;
  }
  align-items: center;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 420px;
  gap: 8px;
`;

const SliderContainer = styled.div`
  border-radius: 8px;
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
  border: 1px solid #cfd4db;
  width: 151px;
  font-size: 16px;
  padding: 12px 16px;
  text-align: center;
`;

export default function SitePlanControls({
  handlePlacePin,
  currentPins,
  confirmObstacle,
  sendImageData,
  setRadius,
  pickRadius,
  angle,
  done,
  setlocation,
  mode,
  pinType,
  radiusModal,
}) {
  const dispatch = useDispatch();
  const [pinNum, setPinNum] = useState(1);
  const theme = useTheme();

  let touch = null;
  if (
    'ontouchstart' in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  ) {
    touch = true;
  } else {
    touch = false;
  }

  useEffect(() => {
    if (mode === 'pins2') dispatch(updateSiteDetails({ pinType: null }));
  }, [mode]);

  const Select = (
    <Toolbar>
      <TheButton onClick={() => dispatch(setNoteModal(true))}>
        <img src={NoteSVG} alt="Note" />
        Add Note
      </TheButton>
      <TheButton
        onClick={() => {
          dispatch(updateSiteDetails({ pinType: 'Route' }));
          dispatch(updateMode('arrow'));
        }}
      >
        <img src={PathSVG} alt="Path" />
        Route
      </TheButton>
      <TheButton
        onClick={() => {
          dispatch(updateSiteDetails({ distances: [] }));
          dispatch(updateMode('pins2'));
        }}
      >
        <img src={CraneSVG} alt="Crane" />
        Crane
      </TheButton>
      <TheButton
        onClick={() => {
          dispatch(updateSiteDetails({ pinType: 'Obstacle' }));
          dispatch(updateMode('obstacle'));
        }}
      >
        <img src={ObstacleSVG} alt="Obstacle" />
        Obstacle
      </TheButton>
      <TheButton onClick={() => dispatch(setUploadModal(true))}>
        <img src={MapSVG} alt="Upload" />
        Overlay
      </TheButton>
    </Toolbar>
  );

  const Obstacle = (
    <PlacePinDiv>
      <Button
        theme={theme.buttons.siteplanControl}
        onClick={() => dispatch(updateMode('polygon'))}
      >
        Polygon
      </Button>
      <Button
        theme={theme.buttons.siteplanControl}
        onClick={() => dispatch(updateMode('line'))}
      >
        Line
      </Button>
      {mode !== 'select' ? (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => dispatch(updateMode('select'))}
          style={{ color: '#B50614' }}
        >
          Cancel
        </Button>
      ) : null}
    </PlacePinDiv>
  );

  const PlacePin = (
    <PlacePinDiv>
      <Button
        theme={theme.buttons.siteplanControl}
        onClick={() => handlePlacePin(pinType)}
        style={{ color: '#0458B2' }}
      >
        {pinType === 'Set' || pinType === 'Pick' ? 'Confirm' : 'Place'}{' '}
        {pinType}
      </Button>
      {mode === 'pins' && pinType === 'Pick' && !done && (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => {
            if (setlocation) {
              dispatch(updateSiteDetails({ pinType: null }));
              dispatch(updateMode('select'));
            } else {
              dispatch(updateSiteDetails({ pinType: 'Set' }));
              dispatch(updateMode('pins'));
            }
          }}
        >
          Skip Pick Point
        </Button>
      )}
      {mode === 'pins' && pinType === 'Set' && !done && (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => {
            dispatch(updateSiteDetails({ pinType: null }));
            dispatch(updateMode('select'));
          }}
        >
          Skip Set Point
        </Button>
      )}
      {currentPins.length > 1 && mode !== 'circle' ? (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => confirmObstacle()}
        >
          Confirm
        </Button>
      ) : null}
      {pinType === 'Crane' ? (
        <SliderContainer>
          Rotate Crane
          <Slider
            min={0}
            max={360}
            step={1}
            handleSlider={(e) => {
              dispatch(updateSiteDetails({ angle: parseInt(e.target.value) }));
              dispatch(saveData());
            }}
            sliderValue={angle}
          />
        </SliderContainer>
      ) : null}
      <Button
        theme={theme.buttons.siteplanControl}
        onClick={() => dispatch(updateMode('select'))}
        style={{ color: '#B50614' }}
      >
        Cancel
      </Button>
    </PlacePinDiv>
  );

  const SelectPin = (
    <PlacePinDiv>
      <Button
        theme={theme.buttons.siteplanControl}
        onClick={() => dispatch(setRadiusModal(!radiusModal))}
        style={{ color: '#0458B2' }}
      >
        Set Radius
      </Button>
      {setRadius && pickRadius ? (
        <>
          <Button
            theme={theme.buttons.siteplanControl}
            onClick={() => {
              dispatch(updateSiteDetails({ pinType: 'Crane' }));
              dispatch(updateMode('crane'));
            }}
            style={{ color: '#0458B2' }}
          >
            Place Crane Footprint
          </Button>
          <Button
            theme={theme.buttons.siteplanControl}
            onClick={() => {
              dispatch(updateSiteDetails({ pinType: 'Set' }));
              dispatch(updateMode('pins'));
            }}
            style={{ color: '#0458B2' }}
          >
            Place Set Point
          </Button>
          <Button
            theme={theme.buttons.siteplanControl}
            style={{ color: '#0458B2' }}
            onClick={() => {
              dispatch(updateSiteDetails({ pinType: 'Pick' }));
              dispatch(updateMode('pins'));
            }}
          >
            Place Pick Point
          </Button>
        </>
      ) : null}
      {mode !== 'select' ? (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => dispatch(updateMode('select'))}
          style={{ color: '#B50614' }}
        >
          Cancel
        </Button>
      ) : null}
    </PlacePinDiv>
  );

  const Photo = (
    <PlacePinDiv>
      {pinNum < 3 ? (
        <Button
          theme={theme.buttons.siteplanControl}
          style={{ color: '#0458B2' }}
          onClick={() => {
            dispatch(setPoints({ point: pinNum }));
            setPinNum(pinNum + 1);
          }}
        >
          Place Pin {pinNum}
        </Button>
      ) : (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => {
            sendImageData();
            dispatch(setCanvas(false));
            dispatch(updateSiteDetails({ pinType: 'Overlay' }));
            dispatch(updateMode('measurements'));
            dispatch(setShowOverlay(false));
            setPinNum(1);
          }}
        >
          Confirm
        </Button>
      )}
      {mode !== 'select' ? (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => {
            dispatch(setCanvas(false));
            dispatch(updateMode('select'));
            dispatch(resetCanvas());
            setPinNum(1);
          }}
          style={{ color: '#B50614' }}
        >
          Cancel
        </Button>
      ) : null}
    </PlacePinDiv>
  );

  const Measurements = (
    <PlacePinDiv>
      {currentPins.length <= 1 ? (
        <Button
          theme={theme.buttons.siteplanControl}
          style={{ color: '#0458B2' }}
          onClick={() => {
            handlePlacePin();
            setPinNum(pinNum + 1);
          }}
        >
          Place Pin {pinNum}
        </Button>
      ) : (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => {
            confirmObstacle();
            setPinNum(1);
          }}
        >
          Confirm
        </Button>
      )}
      {mode !== 'select' ? (
        <Button
          theme={theme.buttons.siteplanControl}
          onClick={() => {
            dispatch(setCanvas(false));
            dispatch(updateMode('select'));
            setPinNum(1);
          }}
          style={{ color: '#B50614' }}
        >
          Cancel
        </Button>
      ) : null}
    </PlacePinDiv>
  );

  const Loading = (
    <Button theme={theme.buttons.siteplanControl}>Loading...</Button>
  );

  if (mode === 'select') {
    return Select;
  }
  if (mode === 'obstacle') {
    return Obstacle;
  }
  if (mode === 'photo') {
    return Photo;
  }
  if (mode === 'loading') {
    return Loading;
  }
  if (mode === 'measurements') {
    return Measurements;
  }
  if (pinType === null) {
    return SelectPin;
  }
  return PlacePin;
}
