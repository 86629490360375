import React from 'react';
import styled from 'styled-components/macro';

const SliderContainer = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    z-index: 3;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background-color: #000;
    border-radius: 0.5rem;
    height: 2px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -8px;

    background-color: #656d76;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    z-index: 3;
  }

  input[type='range']::-moz-range-track {
    background-color: #000;
    border-radius: 0.5rem;
    height: 2px;
  }

  input[type='range']::-moz-range-thumb {
    border: none;

    background-color: #656d76;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    z-index: 3;
  }
`;

export default function Slider({
  handleSlider,
  min = 0,
  max,
  sliderValue,
  step,
}) {
  return (
    <SliderContainer>
      <input
        type="range"
        name="slider"
        id="slider"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={(e) => handleSlider(e)}
      />
    </SliderContainer>
  );
}
