import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../services/api';
import LiftPlanPreview from '../LiftPlanPreview/LiftPlanPreview';

export default function ReviewLiftPlan(props) {
  const [planState, setPlanState] = useState({});
  useEffect(() => {
    const planId = props.match.params.id || props.location?.state?.liftplanId;
    if (props.location?.state?.liftplanId) {
      setPlanState(props.location?.state?.planMetaData);
    } else {
      axios.get(`${API_URL}/liftplan/review/${planId}`).then((response) => {
        const { draftInfo } = response.data.data.liftplan;
        setPlanState(draftInfo);
      });
    }
  }, []);

  return (
    <div className="lift-plan-container">
      <div className="lift-plan-content-container">
        <LiftPlanPreview liftplanState={planState} isEditable={false} />
      </div>
    </div>
  );
}
