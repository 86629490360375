import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { hideNotification } from '../context/liftpage';

const Banner = styled.div`
  width: 100%;
  position: absolute;
  height: 67px;
  text-align: center;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.5s;
`;

const Link = styled.a`
  color: #0458b2;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`;

export default function Notification() {
  const dispatch = useDispatch();
  const [typeColor, setTypeColor] = useState('');
  const [typeBackColor, setTypeBackColor] = useState('');
  const { displayNotification, type, text, bold } = useSelector(
    (state) => state.page
  );

  useEffect(() => {
    if (type === 'error') {
      setTypeBackColor('#F2D5D7');
      setTypeColor('#B50614');
    } else if (type === 'success') {
      setTypeBackColor('#D1EDDC');
      setTypeColor('#0F7637');
    } else {
      setTypeBackColor('#FFF5DB');
      setTypeColor('#2A2F35');
    }
    if (displayNotification === 0) {
      setTimeout(() => {
        dispatch(hideNotification());
      }, 5000);
    }
  }, [displayNotification]);

  return (
    <div>
      <Banner
        style={{
          top: displayNotification * -67,
          backgroundColor: typeBackColor,
          color: typeColor,
        }}
      >
        <span style={{ fontWeight: 700 }}>{bold}</span>&nbsp;{text}&nbsp;
        {/* {refresh && (
          <Link onClick={() => window.location.reload()}>
            refresh to see changes
          </Link>
        )} */}
        <i
          className="fas fa-times"
          onClick={() => dispatch(hideNotification())}
          style={{
            cursor: 'pointer',
            fontSize: 25,
            color: 'black',
            position: 'absolute',
            right: 30,
          }}
        />
      </Banner>
    </div>
  );
}
