import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Passwordless from '../pages/Passwordless/Passwordless';
import MagicLinkAuth from '../pages/Passwordless/MagicLinkAuth';
import ResetPassword from '../pages/Passwordless/ResetPassword';
import Signup from '../pages/SignUp/SignUp';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import ReviewLiftPlan from '../pages/ReviewLiftPlan/ReviewLiftPlan';

function PublicRoute({ path, ...rest }) {
  // routes for which userToken should be checked
  // const tokenRoutes = ['/login', '/signup'];
  // if (tokenRoutes.indexOf(path) !== -1 && AuthService.getToken()) {
  //   return <Redirect to="/" />;
  // }
  return (
    <Switch>
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route
        path="/passwordless"
        render={(props) => <Passwordless {...props} />}
      />
      <Route
        path="/magiclink"
        render={(props) => <MagicLinkAuth {...props} />}
      />
      <Route path="/signup" render={(props) => <Signup {...props} />} />
      <Route path="/reset" render={(props) => <ResetPassword {...props} />} />
      <Route
        exact
        path="/review/liftplan/:id"
        render={(props) => <ReviewLiftPlan {...props} />}
      />
      <Route path="*" render={(props) => <PageNotFound {...props} />} />
    </Switch>
  );
}

export default PublicRoute;
