import styled from "styled-components/macro";

const LittleButton = styled.button`
  border: 1px solid #cfd4db;
  border-radius: 8px;
  height: 42px;
  width: 42px;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
`;

export default LittleButton;
