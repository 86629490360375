import styled from 'styled-components/macro';

const Button = styled.button`
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.color};
  font-size: ${({ theme }) => (theme.fontSize ? theme.fontSize : '16px')};
  border: ${({ theme }) => (theme.border ? theme.border : 'none')};
  height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.width};
  border-radius: 8px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily};
  padding: ${({ theme }) => (theme.padding ? theme.padding : '0')};
  margin: ${({ theme }) => (theme.margin ? theme.margin : '0')};
  font-style: ${({ theme }) => (theme.fontStyle ? theme.fontStyle : 'normal')};
  &::focus {
    outline-width: 0;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export default Button;
