import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import { useStytch } from '@stytch/react';
import { API_URL } from '../../services/api';
import Button from '../../reusables/Button';
import { validateEmail } from '../../helpers/validate';
import Loader from '../../reusables/Loader/Loader';
import Input from '../../reusables/InputBar';
import Password from '../../assets/images/password.svg';

const Title = styled.h1`
  font-family: 'Roboto-Medium';
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 36px;
`;

const LoginFormContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 64px);
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 100px;
    padding-right: 100px;
    overflow: auto;
  }
`;

const InputContainer = styled.div`
margin-bottom: 30px;
position: relative;
width: 100%;
p {
  margin-bottom: 8px;
  font-size: 12px;
  align-self: start;
}
button {
  position: absolute;
  top: 40px;
  right: 10px;
  border: none;
  background: none;
},
`;

export default function PasswordlessForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const theme = useTheme();

  // hit the backend with request to reset password
  const resetPasswordService = async () => {
    setIsLoading(true);
    try {
      axios.post(`${API_URL}/user/resetPassword`, {
        email,
      });
    } catch (err) {
      console.log('Error occured while sending magic link ', err);
    }
    setIsLoading(false);
  };

  return (
    <LoginFormContainer>
      <Title>Login</Title>
      <InputContainer>
        <p>
          Enter your email address, we will send you a link to log in and reset
          your password
        </p>
        <Input
          placeholder="Enter email id"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputContainer>
      <Button
        theme={theme.buttons.login}
        style={{ marginTop: '32px', marginBottom: '20px' }}
        disabled={isLoading}
        onClick={() => resetPasswordService()}
      >
        {isLoading ? <Loader /> : 'Reset my Password'}
      </Button>
    </LoginFormContainer>
  );
}
