import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import Button from '../../reusables/Button';
import InfoSVG from '../../assets/images/Icon.svg';

export default function PercentOfChart({
  percentOfCap,
  lowPercent,
  highPercent,
}) {
  const theme = useTheme();
  const [color, setColor] = useState('');

  useEffect(() => {
    const float = parseFloat(percentOfCap);
    if (float >= 100 || float < 0) {
      setColor('#C43A45');
    } else if (float >= lowPercent && float <= highPercent) {
      setColor('#549D70');
    } else {
      setColor('#F5A302');
    }
  }, [percentOfCap, lowPercent, highPercent]);

  return (
    <Button
      theme={theme.buttons.percentOfChart}
      style={{
        gap: '8px',
      }}
    >
      <img src={InfoSVG} />
      <p style={{ color: color, fontWeight: 600 }}>{percentOfCap} % of Chart</p>
    </Button>
  );
}
