import React from 'react';
import axios from 'axios';
import authHeader from '../services/auth-header';
import ErrorPage from './ErrorPage';
import { API_URL, DEVELOPMENT } from '../services/api';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (DEVELOPMENT) {
      return;
    }
    axios
      .post(
        `${API_URL}/user/error`,
        { error: error.message + errorInfo.componentStack },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log('res', res);
      })
      .catch((err) => {
        console.log('this is error', err);
      });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
