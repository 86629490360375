import React, { useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import SideBar from '../SideBar/SideBar';
import { login } from '../../context/user';
import authHeader from '../../services/auth-header';
import { API_URL } from '../../services/api';
import Notification from '../../reusables/Notification';
import { setScreen } from '../../context/liftpage';

const MainWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100dvh;
  width: 100%;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export default function MainWrapper({ children }) {
  const dispatch = useDispatch();
  const { companyName } = useSelector((state) => state.user);

  useLayoutEffect(() => {
    function updateSize() {
      dispatch(
        setScreen({
          width: window.innerWidth + 1,
          height: window.innerHeight - 1,
        })
      );
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (companyName) return;
    axios
      .get(`${API_URL}/user/me`, {
        headers: authHeader(),
      })
      .then((response) => {
        const mobile = window.innerWidth <= 768;
        // console.log('Session Active', response);
        dispatch(
          login({
            role: response.data.user.role,
            verified: response.data.user.verified,
            username: response.data.user.username,
            companyName: response.data.companyName.name,
            email: response.data.user.email,
            mobile,
          })
        );
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'lpv78bwv',
          name: response.data.user.username,
          email: response.data.user.email,
          user_hash: response.data.genHash,
        });
      })
      .catch((err) => {
        console.log('Error fetching user data');
      });
  }, [companyName]);

  return (
    <MainWrapperContainer>
      <PageContent>
        <Notification />
        <SideBar />
        {children}
      </PageContent>
    </MainWrapperContainer>
  );
}
