import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import Button from '../../reusables/Button';
import { useDispatch } from 'react-redux';
import { setDeductionModal, setInfoModal } from '../../context/liftpage';
import Modal from '../../reusables/Modal/Modal';

const CapacityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeductionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 12px;
`;

const DataContainer = styled.div`
  background: #f0f2f5;
  border-radius: 8px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  flex-grow: 1;
  color: #2a2f35;
`;

const TitleData = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #2a2f35;
`;

const BoldText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #3b4046;
  flex-grow: 1;
`;

const BoldData = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #3b4046;
`;

const RowText = styled.p`
  font-size: 16px;
  color: #3b4046;
  flex-grow: 1;
`;

const Data = styled.p`
  font-size: 16px;
  color: #3b4046;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
`;

const DashedDivider = styled.div`
  border-bottom: 1px dashed #cfd4db;
  margin: 0px 12px;
  flex-grow: 1;
`;

const SolidDivider = styled.div`
  border-bottom: 1px solid #cfd4db;
  margin: 0px 12px;
  flex-grow: 1;
`;

const Percent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 12px 0px 12px;
`;

const Report = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #0458b2;
  flex-grow: 1;
`;

export default function InfoModal() {
  const [color, setColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const {
    grossCap,
    stowedDeduction,
    selectedHookblock,
    mainLinePresent,
    mainLineDeduction,
    percentOfCap,
    sheaves,
    auxsheaves,
    auxselectedHookblock,
    auxLineDeduction,
    totalDeductions,
    auxLinePresent,
    hookBlocks,
    riggingDeductions,
    otherDeductions,
    netCapacity,
    lowPercent,
    highPercent,
    pickRadius,
    setRadius,
    linePullLimit,
    partsOfLine,
  } = useSelector((state) => state.site.craneDetails);
  const { loadWeight, estimatedDeductions } = useSelector(
    (state) => state.site.loadDetails
  );
  const { infoModal } = useSelector((state) => state.page);
  const theme = useTheme();
  const dispatch = useDispatch();

  const truncateNumber = (value) => {
    if (value === null) {
      return null;
    }
    value = parseFloat(value);
    return parseFloat(value.toFixed(2));
  };

  useEffect(() => {
    const float = parseFloat(percentOfCap);
    if (float >= 100 || float < 0) {
      setColor('#B50614');
      setBackgroundColor('#F2D5D7');
    } else if (float >= lowPercent && float <= highPercent) {
      setColor('#0F7637');
      setBackgroundColor('#D1EDDC');
    } else {
      setColor('#774F01');
      setBackgroundColor('#FFF5DB');
    }
  }, [percentOfCap]);

  // becareful when showing ballDeduction
  return (
    <Modal
      show={infoModal}
      modalStyles={theme.modals.info}
      handleClose={() => dispatch(setInfoModal(false))}
      title="Capacity Calculation Details"
    >
      <CapacityContainer>
        <Row>
          <Title>
            Gross Capacity At {truncateNumber(Math.max(pickRadius, setRadius))}
            ft:
          </Title>
          <TitleData>{truncateNumber(grossCap)} (lbs)</TitleData>
        </Row>
        {linePullLimit > 0 && partsOfLine !== 'default' && (
          <Row>
            <Title>
              Max Line Pull with{' '}
              {partsOfLine === 1 ? '1-part' : `${partsOfLine}-parts`}
            </Title>
            <TitleData>{truncateNumber(linePullLimit)} (lbs)</TitleData>
          </Row>
        )}
        <DeductionsContainer>
          <Row>
            <BoldText>Deductions</BoldText>
          </Row>
          <DataContainer>
            <Row>
              <RowText>Loaded Line:</RowText>
              {truncateNumber(mainLineDeduction) ? (
                <Data>{truncateNumber(mainLineDeduction)} (lbs)</Data>
              ) : (
                <Data>-</Data>
              )}
            </Row>
            <Row>
              <RowText>Loaded {sheaves > 0 ? 'Block' : 'Ball'}:</RowText>
              {sheaves > 0 ? (
                <Data>
                  {truncateNumber(selectedHookblock?.weight) || 0} (lbs)
                </Data>
              ) : (
                <Data>{truncateNumber(hookBlocks[0]?.weight) || 0} (lbs)</Data>
              )}
            </Row>
            <Row>
              <RowText>Extra Line (Unloaded):</RowText>
              {truncateNumber(auxLineDeduction) ? (
                <Data>{truncateNumber(auxLineDeduction)} (lbs)</Data>
              ) : (
                <Data>-</Data>
              )}
            </Row>
            {auxLinePresent ? (
              <Row>
                <RowText>
                  Extra {`${auxsheaves > 0 ? 'Block' : 'Ball'}`} (Unloaded):
                </RowText>
                {auxsheaves > 0 ? (
                  <Data>
                    {truncateNumber(auxselectedHookblock?.weight) || 0} (lbs)
                  </Data>
                ) : (
                  <Data>
                    {truncateNumber(hookBlocks[0]?.weight) || 0} (lbs)
                  </Data>
                )}
              </Row>
            ) : (
              <Row>
                <RowText>Extra Ball (Unloaded):</RowText>
                <Data>-</Data>
              </Row>
            )}
            <Row>
              <RowText>Jib/Extension:</RowText>
              {truncateNumber(stowedDeduction) > 0 ? (
                <Data>{truncateNumber(stowedDeduction)} (lbs)</Data>
              ) : (
                <Data>-</Data>
              )}
            </Row>
            <Row>
              <RowText>Rigging:</RowText>
              {truncateNumber(riggingDeductions) ? (
                <Data>{truncateNumber(riggingDeductions)} (lbs)</Data>
              ) : (
                <Data>-</Data>
              )}
            </Row>
            <Row>
              <RowText>Other:</RowText>
              {truncateNumber(otherDeductions) ? (
                <Data>{truncateNumber(otherDeductions)} (lbs)</Data>
              ) : (
                <Data>-</Data>
              )}
            </Row>
          </DataContainer>
          <Row>
            <BoldText>
              {estimatedDeductions > 0 ? 'Estimated' : 'Total'} Deductions:
            </BoldText>
            <BoldData style={{ color: '#B50614' }}>
              {truncateNumber(totalDeductions) || 0} (lbs)
            </BoldData>
          </Row>
          <DashedDivider />
          <Row>
            <BoldText>
              Net Capacity ({linePullLimit < grossCap ? 'Line ' : 'Chart '}
              Limited):
            </BoldText>
            <BoldData>{truncateNumber(netCapacity) || 0} (lbs)</BoldData>
          </Row>
        </DeductionsContainer>
        <SolidDivider />
        <Row>
          <BoldText>Load Weight:</BoldText>
          <BoldData>{truncateNumber(loadWeight)} (lbs)</BoldData>
        </Row>
        <DashedDivider />
        <Row>
          <BoldText>Percent of Chart:</BoldText>
          <Percent style={{ backgroundColor: backgroundColor, color: color }}>
            {truncateNumber(percentOfCap)}%
          </Percent>
        </Row>
        <ButtonContainer>
          <Report>{/* Report Calculation Errors */}</Report>
          <Button
            theme={theme.buttons.editJobWalk}
            style={{ width: 'auto' }}
            onClick={() => {
              dispatch(setInfoModal(false));
              dispatch(setDeductionModal(true));
            }}
          >
            Add/Edit Deductions
          </Button>
        </ButtonContainer>
      </CapacityContainer>
    </Modal>
  );
}
