import React from 'react';
import { PercentContainer } from './siteplan_components';
import PercentOfChart from './PercentOfChart';

export default function CapacityToggle({
  calculateDeductions,
  setInfoModal,
  percentOfCap,
  lowPercent,
  highPercent,
}) {
  return (
    <PercentContainer
      onClick={() => {
        calculateDeductions();
        setInfoModal(true);
      }}
    >
      <PercentOfChart
        percentOfCap={percentOfCap}
        lowPercent={lowPercent}
        highPercent={highPercent}
      />
    </PercentContainer>
  );
}
