import * as THREE from 'three';
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import styled, { useTheme } from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import InputContainer from '../../reusables/Input';
import Button from '../../reusables/Button';
import Down from '../../assets/images/arrow-ios-downward.svg';
import {
  resetRigging,
  updateLoadDetails,
  updateRiggingPlan,
} from '../../context/siteplan';
import Edit from '../../assets/images/Edit.svg';
import FilledPlus from '../../assets/images/FilledPlus.svg';
import LittleButton from '../../reusables/LittleButton';
import CaretRight from '../../assets/images/CaretRight.svg';
import CaretLeft from '../../assets/images/CaretLeft.svg';
import {
  setPointsModal,
  setRiggingModal,
  setRiggingInfoModal,
} from '../../context/liftpage';
import RiggingModal from './modal_riggingSearch';
import PointsModal from './modal_pickPoints';
import { saveData } from '../../helpers/saveData';
import InfoSVG from '../../assets/images/Icon.svg';
import RiggingInfoModal from './modal_capacity';

const RiggingContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-left: 27px;
  width: 100%;
  align-items: center;
  padding-right: 27px;
  @media (min-width: 768px) {
    padding-left: 53px;
    padding-right: 53px;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 3;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  @media (min-width: 768px) {
    select {
      height: 56px;
    }
  }
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`;

const SlingSelector = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const CanvasContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  max-height: 700px;
  border: 1px solid black;
`;

const Title = styled.h1`
  font-size: 32px;
  flex-grow: 1;
  line-height: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 12px;
`;

function LineSystem({ start, end, color }) {
  return (
    <>
      <Dot pos={start} color={color} />
      <Line start={start} end={end} color={color} />
      <Dot pos={end} color={color} />
    </>
  );
}

function Line({ start, end, color }) {
  const mesh = useRef();
  const geo = useRef();

  return (
    <mesh ref={mesh} position={[0, 0, 0]} rotation={[0, 0, 0]}>
      <tubeGeometry
        ref={geo}
        args={[
          new THREE.LineCurve3(
            new THREE.Vector3(start[0], start[1], start[2]),
            new THREE.Vector3(end[0], end[1], end[2])
          ),
          1,
          0.05,
          6,
        ]}
      />
      <meshPhongMaterial color={color} />
    </mesh>
  );
}

function HalfChoker({ diameter, xdist, rotation, color }) {
  const mesh = useRef();
  const geo = useRef();

  return (
    <mesh ref={mesh} position={[0, -diameter / 2, xdist]} rotation={rotation}>
      <tubeGeometry
        ref={geo}
        args={[
          new THREE.CubicBezierCurve3(
            new THREE.Vector3(-(diameter / 2), 0, 0),
            new THREE.Vector3(
              -(diameter / 2),
              0,
              (1.32 + (Math.PI / 2 - Math.abs(rotation[0]))) * (diameter / 2)
            ),
            new THREE.Vector3(
              diameter / 2,
              0,
              (1.32 + (Math.PI / 2 - Math.abs(rotation[0]))) * (diameter / 2)
            ),
            new THREE.Vector3(diameter / 2, 0, 0)
          ),
          100,
          0.05,
          6,
        ]}
      />
      <meshPhongMaterial color={color} />
    </mesh>
  );
}

function HalfC({ diameter, xdist, rotation, color }) {
  const mesh = useRef();
  const geo = useRef();

  return (
    <mesh ref={mesh} position={[0, -diameter / 2, 0]} rotation={rotation}>
      <tubeGeometry
        ref={geo}
        args={[
          new THREE.CubicBezierCurve3(
            new THREE.Vector3(-(diameter / 2), xdist, 0),
            new THREE.Vector3(-(diameter / 2), xdist, 1.32 * (diameter / 2)),
            new THREE.Vector3(diameter / 2, xdist, 1.32 * (diameter / 2)),
            new THREE.Vector3(diameter / 2, xdist, 0)
          ),
          100,
          0.05,
          6,
        ]}
      />
      <meshPhongMaterial color={color} />
    </mesh>
  );
}

function HookCube({ height, posx, posy }) {
  return (
    <mesh position={[0 + posx, height + 0.5, 0 + posy]} rotation={[0, 0, 0]}>
      <boxGeometry args={[0.5, 0.5, 0.5]} />
      <meshPhongMaterial color="black" />
    </mesh>
  );
}

function Hook({ height, posx, posy }) {
  return (
    <mesh position={[0 + posx, height + 0.25, 0 + posy]} rotation={[0, 0, 0]}>
      <tubeGeometry
        args={[
          new THREE.CatmullRomCurve3([
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(0, -0.1, 0),
            new THREE.Vector3(0.1, -0.2, 0),
            new THREE.Vector3(0.2, -0.3, 0),
            new THREE.Vector3(0.2, -0.4, 0),
            new THREE.Vector3(0.1, -0.5, 0),
            new THREE.Vector3(0, -0.5, 0),
            new THREE.Vector3(-0.15, -0.35, 0),
          ]),
          100,
          0.05,
          6,
        ]}
      />

      <meshPhongMaterial color="black" />
    </mesh>
  );
}

function HookSystem({ height, posx, posy }) {
  return (
    <>
      <HookCube height={height} posx={posx} posy={posy} />
      <Hook height={height} posx={posx} posy={posy} />
      <Dot pos={[-0.15 + posx, height - 0.1, 0 + posy]} color={'black'} />
    </>
  );
}

function Dot({ pos, color }) {
  const ref = useRef();

  useLayoutEffect(() => {
    ref.current.position.x = pos[0];
    ref.current.position.y = pos[1];
    ref.current.position.z = pos[2];
  }, [pos]);

  return (
    <mesh ref={ref}>
      <sphereGeometry args={[0.05, 5, 5]} />
      <meshPhongMaterial color={color} />
    </mesh>
  );
}

function Rect({ height, width, length }) {
  const myMesh = useRef();

  useLayoutEffect(() => {
    myMesh.current.position.y = -width / 2;
  }, [height, width, length]);

  return (
    <mesh ref={myMesh}>
      <boxGeometry args={[height, width, length]} />
      <meshPhongMaterial color="grey" />
    </mesh>
  );
}

function Cyl({ diameter, length, rotateX }) {
  const myMesh = useRef();

  useLayoutEffect(() => {
    if (rotateX === 0) {
      myMesh.current.position.y = -length / 2;
    } else {
      myMesh.current.position.y = -diameter / 2;
    }
    myMesh.current.rotation.x = rotateX;
  }, [rotateX]);

  return (
    <mesh ref={myMesh}>
      <cylinderGeometry args={[diameter / 2, diameter / 2, length, 32]} />
      <meshPhongMaterial color="grey" />
    </mesh>
  );
}

export default function NewRigging({ getRigging }) {
  const { loadDetails } = useSelector((state) => state.site);
  const {
    bridlePositions,
    lines,
    xdist,
    currentSling,
    cylRotateX,
    type,
    config,
    lineHeight,
    slingWeights,
    currentShackle,
  } = useSelector((state) => state.site.riggingPlan);
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState(0);
  const [angle, setAngle] = useState(0);
  const [color, setColor] = useState('');
  const [percent, setPercent] = useState(0);
  const [rigging, setRigging] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();

  const EXAMPLE_DATA = [
    {
      manufacturer: 'Lift-All',
      size: '3/16',
      weight: 0.06,
      capacity: 660,
    },
    {
      manufacturer: 'Lift-All',
      size: '1/4',
      weight: 0.12,
      capacity: 1000,
    },
    {
      manufacturer: 'Lift-All',
      size: '5/16',
      weight: 0.2,
      capacity: 1500,
    },
    {
      manufacturer: 'Lift-All',
      size: '3/8',
      weight: 0.3,
      capacity: 2000,
    },
    {
      manufacturer: 'Lift-All',
      size: '7/16',
      weight: 0.5,
      capacity: 3000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1/2',
      weight: 0.75,
      capacity: 4000,
    },
    {
      manufacturer: 'Lift-All',
      size: '5/8',
      weight: 1.3,
      capacity: 6500,
    },
    {
      manufacturer: 'Lift-All',
      size: '3/4',
      weight: 2.3,
      capacity: 9500,
    },
    {
      manufacturer: 'Lift-All',
      size: '7/8',
      weight: 3.5,
      capacity: 13000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1',
      weight: 5,
      capacity: 17000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1-1/8',
      weight: 7,
      capacity: 19000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1-1/4',
      weight: 9.5,
      capacity: 24000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1-3/8',
      weight: 12.5,
      capacity: 27000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1-1/2',
      weight: 17.2,
      capacity: 34000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1-5/8',
      weight: 23.5,
      capacity: 40000,
    },
    {
      manufacturer: 'Lift-All',
      size: '1-3/4',
      weight: 27.7,
      capacity: 50000,
    },
    {
      manufacturer: 'Lift-All',
      size: '2',
      weight: 39,
      capacity: 70000,
    },
  ];

  const rectBasketHeight2 = (
    slingLength,
    objHeight,
    objWidth,
    xdist,
    ydist
  ) => {
    let smallLength = (slingLength - objWidth) / 2;
    const templ = Math.sqrt(smallLength * smallLength - xdist * xdist);
    const ratio = objHeight / templ;
    smallLength = smallLength - smallLength * ratio;
    xdist *= ratio;
    if (smallLength < 0) return null;
    const diag = Math.sqrt(ydist * ydist + xdist * xdist);
    const lineHeight = Math.sqrt(smallLength * smallLength - diag * diag);
    const W = (lineHeight / smallLength) * currentSling.capacities.basket;
    setAngle(Math.asin(lineHeight / smallLength));
    dispatch(updateRiggingPlan({ slingWeights: [W, W, W, W] }));
    return lineHeight;
  };

  const rectChokerHeight = (slingLength, objHeight, objWidth, dist) => {
    const smallLength = slingLength - objHeight * 2 - objWidth * 2;
    if (smallLength < 0) return null;
    const lineHeight = Math.sqrt(smallLength * smallLength - dist * dist);
    const W = (lineHeight / smallLength) * currentSling.capacities.choker;
    setAngle(Math.asin(lineHeight / smallLength));
    dispatch(updateRiggingPlan({ slingWeights: [W, W, W, W] }));
    return lineHeight;
  };

  const cylBasketHeight = (slingLength, diameter, xdist, ydist) => {
    const smallLength = (slingLength - (diameter * Math.PI) / 2) / 2;
    if (smallLength < 0) return null;
    const diag = Math.sqrt(ydist * ydist + xdist * xdist);
    const lineHeight = Math.sqrt(smallLength * smallLength - diag * diag);
    const W = (lineHeight / smallLength) * currentSling.capacities.basket;
    setAngle(Math.asin(lineHeight / smallLength));
    dispatch(updateRiggingPlan({ slingWeights: [W, W, W, W] }));
    return lineHeight;
  };

  const cylChokerHeight = (slingLength, diameter, dist) => {
    const smallLength = slingLength - diameter * Math.PI;
    if (smallLength < 0) return null;
    const lineHeight = Math.sqrt(smallLength * smallLength - dist * dist);
    const W = (lineHeight / smallLength) * currentSling.capacities.choker;
    setAngle(Math.asin(lineHeight / smallLength));
    dispatch(updateRiggingPlan({ slingWeights: [W, W, W, W] }));
    return lineHeight;
  };

  const BridleHeight4 = (slingLength, xdist, ydist) => {
    const diag = Math.sqrt(ydist * ydist + xdist * xdist);
    return Math.sqrt(slingLength * slingLength - diag * diag);
  };

  const updateSize = (x, SL, cylinder, rotX, lines) => {
    if (cylinder) {
      if (rotX === 0) {
        x = clamp(x, parseFloat(loadDetails.loadDiameter));
      } else {
        if (lines === 4) {
          x = clamp(x, parseFloat(loadDetails.loadLength / 3));
        }
        x = clamp(x, parseFloat(loadDetails.loadLength));
      }
    } else {
      x = clamp(x, parseFloat(loadDetails.loadLength));
      if (lines === 3) {
        x = clamp(x, parseFloat(loadDetails.loadWidth));
      }
      if (lines === 4) {
        x = clamp(x, parseFloat(loadDetails.loadLength / 3));
      }
    }
    x = x / 2;
    let line = 0;
    let totalweights = [];
    if (type === 'Bridle') {
      if (lines === 1) {
        line = SL;
        totalweights = [currentSling.capacities.vertical];
        setAngle(Math.PI / 2);
      } else {
        line = 0;
        let weights = [];
        for (let i = 0; i < lines; i++) {
          const h = BridleHeight4(
            SL,
            Math.abs(bridlePositions[i].x - bridlePositions[4].x),
            Math.abs(bridlePositions[i].y - bridlePositions[4].y)
          );
          weights[i] = (h / SL) * currentSling.capacities.vertical;
          setAngle(Math.asin(h / SL));
          line += h;
        }
        line /= lines;
        totalweights = weights;
      }
      dispatch(updateRiggingPlan({ slingWeights: totalweights }));
    }
    if (type === 'Basket') {
      if (cylinder) {
        line = cylBasketHeight(
          SL,
          parseFloat(loadDetails.loadDiameter),
          x,
          parseFloat(loadDetails.loadDiameter) / 2
        );
      } else {
        line = rectBasketHeight2(
          SL,
          parseFloat(loadDetails.loadHeight),
          parseFloat(loadDetails.loadWidth),
          x,
          parseFloat(loadDetails.loadWidth) / 2
        );
      }
    }
    if (type === 'Choker') {
      if (cylinder) {
        line = cylChokerHeight(SL, parseFloat(loadDetails.loadDiameter), x);
      } else {
        line = rectChokerHeight(
          SL,
          parseFloat(loadDetails.loadHeight),
          parseFloat(loadDetails.loadWidth),
          x
        );
      }
    }
    dispatch(
      updateRiggingPlan({
        xdist: Math.round(x * 200) / 100,
        lineHeight: line,
      })
    );
    dispatch(saveData());
  };

  useEffect(() => {
    updateSize(
      xdist,
      currentSling.length,
      loadDetails.selectedLoad === 'Cylinder',
      cylRotateX,
      lines
    );
  }, [
    loadDetails.loadWidth,
    cylRotateX,
    type,
    lines,
    bridlePositions,
    currentSling,
  ]);

  useEffect(() => {
    getRigging((s) => {
      setRigging(s);
    });
  }, []);

  const clamp = (num, limit) => {
    if (num < 0) {
      return 0;
    }
    if (num > limit) {
      return limit;
    }
    return num;
  };

  const renderLines = (numLines) => {
    if (numLines === 1) {
      return (
        <LineSystem
          start={[bridlePositions[4].y, 0, -bridlePositions[4].x]}
          end={[
            bridlePositions[4].y,
            currentSling.length,
            -bridlePositions[4].x,
          ]}
          color={isEditing && selected === 0 ? 'orange' : 'blue'}
        />
      );
    }
    if (numLines === 2) {
      if (type === 'Basket') {
        if (loadDetails.selectedLoad !== 'Cylinder') {
          return (
            <>
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
            </>
          );
        } else {
          const angle = Math.atan(
            (lineHeight + parseFloat(loadDetails.loadDiameter) / 2) /
              (xdist / 2)
          );
          const b =
            Math.tan(Math.PI / 2 - angle) *
            (parseFloat(loadDetails.loadDiameter) / 2);
          return (
            <>
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  xdist / 2 - b,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  xdist / 2 - b,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -(xdist / 2 - b),
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -(xdist / 2 - b),
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <HalfChoker
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2 - b}
                rotation={[angle + angle * 0.1, 0, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <HalfChoker
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-(xdist / 2 - b)}
                rotation={[-angle - angle * 0.1, Math.PI, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
            </>
          );
        }
      }
      if (type === 'Bridle') {
        return (
          <>
            <LineSystem
              start={[bridlePositions[0].y, 0, -bridlePositions[0].x]}
              end={[bridlePositions[4].y, lineHeight, -bridlePositions[4].x]}
              color={isEditing && selected === 1 ? 'orange' : 'blue'}
            />
            <LineSystem
              start={[bridlePositions[1].y, 0, -bridlePositions[1].x]}
              end={[bridlePositions[4].y, lineHeight, -bridlePositions[4].x]}
              color={isEditing && selected === 0 ? 'orange' : 'blue'}
            />
          </>
        );
      }
      if (type === 'Choker') {
        if (loadDetails.selectedLoad === 'Cylinder') {
          return (
            <>
              <LineSystem
                start={[0, 0, xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, -xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2}
                rotation={[Math.PI / 2, 0, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-xdist / 2}
                rotation={[Math.PI / 2, 0, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2}
                rotation={[Math.PI / 2, Math.PI, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-xdist / 2}
                rotation={[Math.PI / 2, Math.PI, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
            </>
          );
        } else {
          return (
            <>
              <LineSystem
                start={[0, 0, -xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[-parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[-parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                end={[-parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                end={[-parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
            </>
          );
        }
      }
    }
    if (numLines === 4) {
      if (type === 'Bridle') {
        return (
          <>
            <LineSystem
              start={[bridlePositions[0].y, 0, -bridlePositions[0].x]}
              end={[bridlePositions[4].y, lineHeight, -bridlePositions[4].x]}
              color={isEditing && selected === 0 ? 'orange' : 'blue'}
            />
            <LineSystem
              start={[bridlePositions[1].y, 0, -bridlePositions[1].x]}
              end={[bridlePositions[4].y, lineHeight, -bridlePositions[4].x]}
              color={isEditing && selected === 1 ? 'orange' : 'blue'}
            />
            <LineSystem
              start={[bridlePositions[2].y, 0, -bridlePositions[2].x]}
              end={[bridlePositions[4].y, lineHeight, -bridlePositions[4].x]}
              color={isEditing && selected === 2 ? 'orange' : 'blue'}
            />
            <LineSystem
              start={[bridlePositions[3].y, 0, -bridlePositions[3].x]}
              end={[bridlePositions[4].y, lineHeight, -bridlePositions[4].x]}
              color={isEditing && selected === 3 ? 'orange' : 'blue'}
            />
          </>
        );
      }
      if (type === 'Basket') {
        if (loadDetails.selectedLoad !== 'Cylinder') {
          return (
            <>
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -parseFloat(loadDetails.loadHeight) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    xdist / 2 +
                    xdist,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -parseFloat(loadDetails.loadHeight) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    xdist / 2 +
                    xdist,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    -xdist / 2 -
                    xdist,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    -xdist / 2 -
                    xdist,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (-xdist / 2)) +
                    xdist / 2,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2)) +
                    -xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -parseFloat(loadDetails.loadHeight) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    xdist / 2 +
                    xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -parseFloat(loadDetails.loadHeight) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    xdist / 2 +
                    xdist,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    -xdist / 2 -
                    xdist,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -(-parseFloat(loadDetails.loadHeight)) /
                    ((lineHeight + parseFloat(loadDetails.loadHeight)) /
                      (xdist / 2 + xdist)) +
                    -xdist / 2 -
                    xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
            </>
          );
        } else {
          const angle1 = Math.atan(
            (lineHeight + parseFloat(loadDetails.loadDiameter) / 2) /
              (xdist / 2)
          );
          const b1 =
            Math.tan(Math.PI / 2 - angle1) *
            (parseFloat(loadDetails.loadDiameter) / 2);
          const angle2 = Math.atan(
            (lineHeight + parseFloat(loadDetails.loadDiameter) / 2) /
              (xdist / 2 + xdist)
          );
          const b2 =
            Math.tan(Math.PI / 2 - angle2) *
            (parseFloat(loadDetails.loadDiameter) / 2);
          return (
            <>
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  xdist / 2 - b1,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  xdist / 2 - b1,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -(xdist / 2 - b1),
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -(xdist / 2 - b1),
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  xdist / 2 + xdist - b2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  xdist / 2 + xdist - b2,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -(xdist / 2 - b2) - xdist,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadDiameter) / 2,
                  -parseFloat(loadDetails.loadDiameter) / 2,
                  -(xdist / 2 - b2) - xdist,
                ]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <HalfChoker
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2 - b1}
                rotation={[angle1 + angle1 * 0.1, 0, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <HalfChoker
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-(xdist / 2 - b1)}
                rotation={[-angle1 - angle1 * 0.1, Math.PI, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <HalfChoker
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2 + xdist - b2}
                rotation={[angle2 + angle2 * 0.1, 0, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <HalfChoker
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-(xdist / 2 - b2) - xdist}
                rotation={[-angle2 - angle2 * 0.1, Math.PI, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
            </>
          );
        }
      }
      if (type === 'Choker') {
        if (loadDetails.selectedLoad === 'Cylinder') {
          return (
            <>
              <LineSystem
                start={[0, 0, xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, -xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, xdist / 2 + xdist]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, -xdist / 2 - xdist]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2}
                rotation={[Math.PI / 2, 0, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-xdist / 2}
                rotation={[Math.PI / 2, 0, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2}
                rotation={[Math.PI / 2, Math.PI, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-xdist / 2}
                rotation={[Math.PI / 2, Math.PI, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />

              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2 + xdist}
                rotation={[Math.PI / 2, 0, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-xdist / 2 - xdist}
                rotation={[Math.PI / 2, 0, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={xdist / 2 + xdist}
                rotation={[Math.PI / 2, Math.PI, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <HalfC
                diameter={parseFloat(loadDetails.loadDiameter)}
                xdist={-xdist / 2 - xdist}
                rotation={[Math.PI / 2, Math.PI, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
            </>
          );
        } else {
          return (
            <>
              <LineSystem
                start={[0, 0, -xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, xdist / 2]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[0, 0, -xdist / 2 - xdist]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[0, 0, xdist / 2 + xdist]}
                end={[0, lineHeight, 0]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[-parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[-parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  xdist / 2 + xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  xdist / 2 + xdist,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -xdist / 2 - xdist,
                ]}
                end={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -xdist / 2 - xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2,
                ]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2,
                ]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  -parseFloat(loadDetails.loadHeight),
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                end={[-parseFloat(loadDetails.loadWidth) / 2, 0, -xdist / 2]}
                color={isEditing && selected === 1 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                end={[-parseFloat(loadDetails.loadWidth) / 2, 0, xdist / 2]}
                color={isEditing && selected === 0 ? 'orange' : 'blue'}
              />

              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -xdist / 2 - xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  -xdist / 2 - xdist,
                ]}
                color={isEditing && selected === 3 ? 'orange' : 'blue'}
              />
              <LineSystem
                start={[
                  parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  xdist / 2 + xdist,
                ]}
                end={[
                  -parseFloat(loadDetails.loadWidth) / 2,
                  0,
                  xdist / 2 + xdist,
                ]}
                color={isEditing && selected === 2 ? 'orange' : 'blue'}
              />
            </>
          );
        }
      }
    }
  };

  return (
    <RiggingContainer>
      <RiggingModal setIsEditing={setIsEditing} rigging={rigging} />
      <RiggingInfoModal
        config={config}
        currentSling={currentSling}
        type={type}
        loadWeight={loadDetails.loadWeight}
        lines={lines}
        slingWeights={slingWeights}
        angle={angle}
        setColor2={setColor}
        percent={percent}
        setPercent={setPercent}
        currentShackle={currentShackle}
      />
      <PointsModal
        width={loadDetails.loadWidth}
        length={loadDetails.loadLength}
        lines={lines}
        bridlePositions={bridlePositions}
      />
      <TitleContainer>
        <Title>Rigging Design</Title>
      </TitleContainer>
      <MainContainer>
        <Column style={{ width: '29%' }}>
          {!isEditing ? (
            <>
              <InputContainer>
                <p>Rigging Configuration</p>
                <SelectContainer>
                  <img src={Down} alt="down" />
                  <select
                    id="config"
                    onChange={(e) => {
                      dispatch(resetRigging());
                      const split = e.target.value.split('-');
                      const split2 = split[1].split(' ');
                      dispatch(
                        updateRiggingPlan({
                          lines: parseInt(split[0]),
                          type: split2[1],
                          config: e.target.value,
                        })
                      );
                    }}
                    value={config || 'default'}
                  >
                    <option disabled value="default">
                      Select Rigging Type
                    </option>
                    <option
                      disabled={
                        cylRotateX === 0 ||
                        currentSling.capacities.choker === null
                      }
                      value="2-Part Choker"
                    >
                      2-Part Choker
                    </option>
                    <option
                      disabled={
                        cylRotateX === 0 ||
                        currentSling.capacities.choker === null
                      }
                      value="4-Part Choker"
                    >
                      4-Part Choker
                    </option>
                    <option
                      disabled={
                        cylRotateX === 0 ||
                        currentSling.capacities.basket === null
                      }
                      value="2-Part Basket"
                    >
                      2-Part Basket
                    </option>
                    <option
                      disabled={
                        cylRotateX === 0 ||
                        currentSling.capacities.basket === null
                      }
                      value="4-Part Basket"
                    >
                      4-Part Basket
                    </option>
                    <option
                      disabled={currentSling.capacities.vertical === null}
                      value="1-Part Bridle"
                    >
                      1-Part Bridle
                    </option>
                    <option
                      disabled={currentSling.capacities.vertical === null}
                      value="2-Part Bridle"
                    >
                      2-Part Bridle
                    </option>
                    <option
                      disabled={currentSling.capacities.vertical === null}
                      value="4-Part Bridle"
                    >
                      4-Part Bridle
                    </option>
                  </select>
                </SelectContainer>
              </InputContainer>
              {lines !== null && type !== 'Bridle' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '30px',
                  }}
                >
                  <p style={{ flexGrow: 1, width: '100%' }}>Spacing (ft)</p>
                  <InputContainer style={{ marginBottom: 0 }}>
                    <input
                      id="xdist"
                      placeholder="x"
                      type="number"
                      value={xdist || ''}
                      onChange={(e) => {
                        const x = parseFloat(e.target.value) || 0;
                        updateSize(
                          x,
                          currentSling.length,
                          loadDetails.selectedLoad === 'Cylinder',
                          cylRotateX,
                          lines
                        );
                      }}
                    />
                  </InputContainer>
                </div>
              )}
              {type === 'Bridle' && loadDetails.selectedLoad !== 'Cylinder' && (
                <Button
                  theme={theme.buttons.editJobWalk}
                  style={{ width: '100%', marginBottom: '30px' }}
                  onClick={() => {
                    dispatch(setPointsModal(true));
                  }}
                >
                  Edit Lift Points &nbsp; <img src={Edit} />
                </Button>
              )}
              {type !== 'Basket' && (
                <InputContainer>
                  <p>Shackle Configuration</p>
                  <SelectContainer>
                    <img src={Down} alt="down" />
                    <select
                      id="shackle"
                      onChange={(e) => {
                        dispatch(
                          updateRiggingPlan({
                            currentShackle: EXAMPLE_DATA.filter(
                              (data) => data.size === e.target.value
                            )[0],
                          })
                        );
                      }}
                      value={currentShackle.size}
                    >
                      <option disabled value="default">
                        Select Shackles
                      </option>
                      {EXAMPLE_DATA.filter(
                        (data) => data.capacity > loadDetails.loadWeight / 2
                      ).map((data) => (
                        <option
                          value={data.size}
                          key={data.size}
                        >{`${data.manufacturer} ${data.size} (in)`}</option>
                      ))}
                    </select>
                  </SelectContainer>
                </InputContainer>
              )}
            </>
          ) : (
            <EditContainer>
              <h3>{config}</h3>
              <SlingSelector>
                <LittleButton
                  onClick={() => setSelected((selected + lines - 1) % lines)}
                >
                  <img src={CaretLeft} />
                </LittleButton>
                <Button
                  theme={theme.buttons.editJobWalk}
                  style={{
                    flexGrow: 1,
                    width: 'auto',
                    height: '42px',
                    minHeight: '42px',
                  }}
                >
                  Sling # {selected + 1}
                </Button>
                <LittleButton
                  onClick={() => setSelected((selected + 1) % lines)}
                >
                  <img src={CaretRight} />
                </LittleButton>
              </SlingSelector>

              <Button
                theme={theme.buttons.editJobWalk}
                style={{ width: '100%' }}
                onClick={() => dispatch(setRiggingModal(true))}
              >
                {currentSling.length}ft & {currentSling.category}
              </Button>
            </EditContainer>
          )}
          {type !== null && (
            <Button
              theme={theme.buttons.editJobWalk}
              style={{ width: '100%' }}
              onClick={() => {
                setIsEditing(!isEditing);
                setSelected(0);
              }}
            >
              {isEditing ? (
                <>
                  Change Rigging &nbsp; <img src={FilledPlus} />
                </>
              ) : (
                <>
                  Edit Components &nbsp; <img src={Edit} />
                </>
              )}
            </Button>
          )}
        </Column>
        <Column style={{ width: '69%', position: 'relative' }}>
          <Button
            theme={theme.buttons.percentOfChart}
            style={{
              gap: '8px',
              position: 'absolute',
              top: '8px',
              left: '8px',
              zIndex: 5,
            }}
            onClick={() => {
              dispatch(setRiggingInfoModal(true));
            }}
          >
            <img src={InfoSVG} />
            <p style={{ color: color, fontWeight: 600 }}>
              {percent || 0} % Capacity
            </p>
          </Button>
          <CanvasContainer>
            <Canvas>
              <HookSystem
                height={lineHeight + 0.2}
                posx={type === 'Bridle' ? bridlePositions[4].y : 0}
                posy={type === 'Bridle' ? -bridlePositions[4].x : 0}
              />
              {renderLines(lines)}
              {loadDetails.selectedLoad === 'Cylinder' ? (
                <Cyl
                  diameter={parseFloat(loadDetails.loadDiameter)}
                  length={parseFloat(loadDetails.loadLength)}
                  rotateX={cylRotateX}
                />
              ) : (
                <Rect
                  height={parseFloat(loadDetails.loadWidth)}
                  width={parseFloat(loadDetails.loadHeight)}
                  length={parseFloat(loadDetails.loadLength)}
                />
              )}
              <ambientLight intensity={0.2} />
              <directionalLight />
              <OrbitControls />
            </Canvas>
          </CanvasContainer>
          <ButtonContainer>
            {loadDetails.selectedLoad !== 'Cylinder' && (
              <Button
                theme={theme.buttons.editJobWalk}
                style={{ width: 'auto' }}
                onClick={() => {
                  let w = loadDetails.loadWidth;
                  let l = loadDetails.loadLength;
                  dispatch(updateLoadDetails({ loadWidth: l, loadLength: w }));
                }}
              >
                Rotate Horizontally
              </Button>
            )}
            <Button
              theme={theme.buttons.editJobWalk}
              style={{ width: 'auto' }}
              onClick={() => {
                if (loadDetails.selectedLoad === 'Cylinder') {
                  setIsEditing(false);
                  dispatch(
                    updateRiggingPlan({
                      lines: null,
                      config: null,
                      cylRotateX: (cylRotateX + Math.PI / 2) % Math.PI,
                    })
                  );
                } else {
                  let w = loadDetails.loadWidth;
                  let h = loadDetails.loadHeight;
                  dispatch(updateLoadDetails({ loadWidth: h, loadHeight: w }));
                }
              }}
            >
              Rotate Vertically
            </Button>
          </ButtonContainer>
        </Column>
      </MainContainer>
    </RiggingContainer>
  );
}
