import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { updateSiteDetails } from '../../context/siteplan';
import { setNoteModal } from '../../context/liftpage';
import Modal from '../../reusables/Modal/Modal';
import { saveData } from '../../helpers/saveData';
import { PictureLink, GrayBackground, ThePicture } from './siteplan_components';
import { generateNote, uploadFile, packNotes } from './sitePlanFunctions';
import styled from 'styled-components';
import photoIcon from '../../assets/images/photoIcon.svg';
import Button from '../../reusables/Button';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UploadButton = styled.label`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  width: 160px;
  border: 1px solid #cfd4db;
  cursor: pointer;
  background: linear-gradient(180deg, #fff 0%, #f8fafc 100%);
  input {
    display: none;
  }
`;

const Textarea = styled.textarea`
  height: 200px;
  border-radius: 8px;
  border: 1px solid #cfd4db;
  background: linear-gradient(180deg, #fff 0%, #f8fafc 100%);
  font-family: 'Roboto';
  font-size: 16px;
  padding: 10px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-family: 'Roboto';
  }
`;

export default function AddNote({
  mapRef,
  selectedNote,
  setSelectedNote,
  notesOverlays,
  setNotesOverlays,
  note,
  setNote,
}) {
  const [picture, setPicture] = useState(null);

  const { noteModal } = useSelector((state) => state.page);

  const dispatch = useDispatch();
  const { modals, buttons } = useTheme();

  const handlePlaceNote = (url, center) => {
    if (selectedNote) {
      selectedNote.note = note || null;
      if (url) {
        selectedNote.url = url;
      }
      dispatch(setNoteModal(false));
      const newNotes = packNotes(notesOverlays);
      dispatch(updateSiteDetails({ packagedNotes: newNotes }));
      setSelectedNote(null);
      setNote('');
      dispatch(saveData());
      return;
    }
    const theNote = generateNote(mapRef, center, notesOverlays.length);
    theNote.note = note || null;
    theNote.url = url || null;
    let timer = null;
    google.maps.event.addListener(theNote, 'mousedown', () => {
      timer = setTimeout(() => {
        timer = null;
        clearTimeout(timer);
        setNote(theNote.note);
        setSelectedNote(theNote);
        dispatch(setNoteModal(true));
      }, 700);
    });
    setNotesOverlays([...notesOverlays, theNote]);
    const packagedNotes = packNotes([...notesOverlays, theNote]);
    dispatch(updateSiteDetails({ packagedNotes }));
    dispatch(setNoteModal(false));
    setNote('');
    dispatch(saveData());
  };

  const handleCloseNote = () => {
    dispatch(setNoteModal(false));
    setNote('');
    setSelectedNote(null);
  };

  const deleteNote = () => {
    selectedNote.setMap(null);
    const remainingNotes = notesOverlays.filter((note) =>
      note.map !== null ? note : null
    );
    setNotesOverlays(remainingNotes);
    const packagedNotes = packNotes(remainingNotes);
    dispatch(updateSiteDetails({ packagedNotes }));
    dispatch(saveData());
    setSelectedNote(null);
    dispatch(setNoteModal(false));
  };

  return (
    <Modal
      show={noteModal}
      modalStyles={modals.info}
      handleClose={() => handleCloseNote()}
      title="Add Note"
    >
      <Textarea
        value={note || ''}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Add note..."
      />
      <FlexRow>
        <Button theme={buttons.resetJobWalk} onClick={() => deleteNote()}>
          Delete
        </Button>
        {selectedNote?.url ? (
          <Button
            style={{ width: '160px', gap: '8px' }}
            theme={buttons.editJobWalk}
            onClick={() => setPicture(selectedNote.url)}
          >
            <img src={photoIcon} />
            View Photo
          </Button>
        ) : (
          <UploadButton>
            <img src={photoIcon} />
            Add Photo
            <input
              type="file"
              onChange={(e) =>
                uploadFile(
                  e.target.files[0],
                  handlePlaceNote,
                  () => dispatch(setNoteModal(false)),
                  mapRef.getCenter()
                )
              }
              accept="image/*"
            />
          </UploadButton>
        )}
        <Button
          theme={buttons.overlay}
          onClick={() => handlePlaceNote('', mapRef.getCenter())}
        >
          Save
        </Button>
      </FlexRow>

      {picture ? (
        <GrayBackground onClick={() => setPicture(null)}>
          <ThePicture src={selectedNote.url} />
        </GrayBackground>
      ) : null}
    </Modal>
  );
}
