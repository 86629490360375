import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

const SwitchContainer = styled.div`
  width: 84px;
  border-radius: 90px;
  height: 46px;
  border: 1px solid #656d76;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: ${({ theme }) => (theme.toggled ? '40px' : '3px')};
  background-color: ${({ theme }) => (theme.toggled ? '#D1EDDC' : '')};
  transition: padding 0.36s ease-in-out;
`;

const SwitchToggle = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 30px;
  background-color: ${({ theme }) => (theme.toggled ? '#0F7637' : '#656D76')};
  transition: background-color 0.2s;
`;

export default function Switch({ callback, toggled }) {
  const handleToggle = () => {
    callback(!toggled);
  };

  return (
    <SwitchContainer theme={{ toggled }} onClick={() => handleToggle()}>
      <SwitchToggle theme={{ toggled }} />
    </SwitchContainer>
  );
}
