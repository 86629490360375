// for checking valid number Ex: 123, 123.12
function removeLeadingZeroes(number) {
  return number.replace(/^0+/, '');
}

function removeSpaces(string) {
  return string.replace(/\s/g, '');
}

function convertToMMDDYY(dateObj) {
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  return `${month + 1}/${date}/${year}`;
}

function isValidObj(refObj = {}, currState = null, fn = () => {}) {
  return Object.keys(refObj).every((key) => {
    const val = currState[key];
    if (key === 'overlays') {
      if (
        !val ||
        (!val.packagedCrane?.pickRadius && !val.packagedCrane?.setRadius)
      ) {
        fn({
          text: 'Measurements are not updated as expected. Please update once again!',
          type: 'error',
        });
        return false;
      }
    }
    if (refObj[key].type === 'String') {
      if ((!val || val.length === 0) && refObj[key].required === true) {
        fn({
          text: 'cannot be empty!',
          type: 'error',
          bold: refObj[key].label,
        });
        return false;
      }
      if (key === 'customerPhone') {
        if (currState[key] === undefined || currState[key] === '') return true;
        const regex = new RegExp(
          '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
        ); // https://ihateregex.io/expr/phone/ feel free to change is this doesnt work in the future
        if (!regex.test(currState[key])) {
          fn({ text: 'is not valid!', type: 'error', bold: refObj[key].label });
          return false;
        }
      }
      if (key === 'customerEmail') {
        if (currState[key] === undefined || currState[key] === '') return true;
        const regex = new RegExp(
          "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
        );
        if (!regex.test(currState[key])) {
          fn({ text: 'is not valid!', type: 'error', bold: refObj[key].label });
          return false;
        }
      }
    } else if (refObj[key].type === 'Number') {
      if (Number.isNaN(val)) {
        fn({ text: 'Enter a valid number!', type: 'error' });
        return false;
      }
      if (Number(val) < 0) {
        fn({
          text: 'cannot be less than 0',
          type: 'error',
          bold: refObj[key].label,
        });
        return false;
      }
    } else if (refObj[key].type === 'Array') {
      if (val && val.filter((v) => v).length < refObj[key].length) {
        fn({ type: 'error', bold: refObj[key].label });
        return false;
      }
    } else if (refObj[key].type === 'Object' && refObj.required) {
      if (!(val instanceof Object)) {
        fn({ type: 'error', bold: refObj[key].label });
        return false;
      }
    }
    return true;
  });
}

export { removeLeadingZeroes, removeSpaces, convertToMMDDYY, isValidObj };
