import React, { useState } from 'react';
import axios from 'axios';
import styled, { useTheme } from 'styled-components/macro';
import { withTheme } from 'styled-components';
import { API_URL } from '../../services/api';
import Button from '../../reusables/Button';
import Loader from '../../reusables/Loader/Loader';
import Input from '../../reusables/InputBar';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const Title = styled.h1`
  font-family: 'Roboto-Medium';
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 36px;
`;

const ResetPasswordContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 64px);
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 100px;
    padding-right: 100px;
    overflow: auto;
  }
`;

const InputContainer = styled.div`
margin-bottom: 30px;
position: relative;
width: 100%;
p {
  margin-bottom: 8px;
  font-size: 12px;
  align-self: start;
}
button {
  position: absolute;
  top: 40px;
  right: 10px;
  border: none;
  background: none;
},
`;

const Banner = styled.div`
  width: 100%;
  position: absolute;
  height: 67px;
  text-align: center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.5s;
`;

function ResetPassword({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const theme = useTheme();
  const [isDisplayed, setIsDisplayed] = useState(1);
  const [notifText, setNotifText] = useState('');
  const [typeColor, setTypeColor] = useState('');
  const [typeBackColor, setTypeBackColor] = useState('');

  const notification = (text, type) => {
    setNotifText(text);
    if (type === 'error') {
      setTypeBackColor('#F2D5D7');
      setTypeColor('#B50614');
    } else if (type === 'success') {
      setTypeBackColor('#D1EDDC');
      setTypeColor('#0F7637');
    } else {
      setTypeBackColor('#FFF5DB');
      setTypeColor('#2A2F35');
    }
    setIsDisplayed(0);
    setTimeout(() => {
      setIsDisplayed(1);
    }, 5000);
  };

  const callPasswordReset = () => {
    setIsLoading(true);
    if (confirmPassword !== password) {
      notification('Passwords do not match', 'error');
      setIsLoading(false);
      return;
    }
    try {
      const token = new URLSearchParams(window.location.search).get('token');
      axios
        .post(`${API_URL}/user/password`, {
          token,
          password,
        })
        .then((res) => {
          console.log(res);
          notification('Password Updated Successfully', 'success');
          history.push('/');
        });
    } catch (err) {
      console.log('Error occured while updating password: ', err);
    }
    setIsLoading(false);
  };

  return (
    <ResetPasswordContainer>
      <Banner
        style={{
          top: isDisplayed * -67,
          backgroundColor: typeBackColor,
          color: typeColor,
        }}
      >
        {notifText}
        <i
          className="fas fa-times"
          onClick={() => setIsDisplayed(1)}
          style={{
            cursor: 'pointer',
            fontSize: 25,
            color: 'black',
            position: 'absolute',
            right: 30,
          }}
        />
      </Banner>
      <Title>Reset Your Password</Title>
      <InputContainer>
        <p>New Password</p>
        <Input
          placeholder="Set Password"
          type={isPasswordVisible ? 'text' : 'password'}
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <p>Confirm New Password</p>
        <Input
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </InputContainer>
      <Button
        theme={theme.buttons.login}
        style={{ marginTop: '32px', marginBottom: '20px' }}
        disabled={isLoading}
        onClick={() => callPasswordReset()}
      >
        {isLoading ? <Loader /> : 'Reset and Return to Login'}
      </Button>
    </ResetPasswordContainer>
  );
}

export default withTheme(ResetPassword);
