import React from 'react';
import styled from 'styled-components/macro';
import ErrorImage from '../assets/images/error.svg';
import { Link } from 'react-router-dom';

const ErrorPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  img {
    width: 400px;
  }
  h3 {
    margin-top: 30px;
  }
  p {
    color: #656d76;
    font-size: 14px;
    line-height: 18.75px;
  }
`;

const RefreshButton = styled.button`
  width: 281px;
  height: 72px;
  border: none;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  font-size: 24px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  a {
    color: #0458b2;
    text-decoration: none;
  }
`;

export default function ErrorPage() {
  return (
    <ErrorPageContainer>
      <img src={ErrorImage} alt="Error Crane" />
      <h3>OOPS!</h3>
      <p>Something went wrong on our end.</p>
      <p>Please refresh the page</p>
      <RefreshButton>
        <Link to="/home">Click Here to Refresh</Link>
      </RefreshButton>
    </ErrorPageContainer>
  );
}
