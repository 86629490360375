import styled from 'styled-components';

const Bubbled = styled.div`
    max-width: 200px;
    background-color: #2A2F35;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 4px 4px;
    position: absolute;
    font-size: 14px;
    bottom: 100%;
    overflow-wrap: break-word;
    margin-bottom:10px;
    line-height: 22px;
    z-index: 999;
    transition: 0.3s;

    &:after {
        content: "";
        z-index: 999;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #2A2F35 transparent transparent transparent;
    }
`

export default function Bubble(props) {

    return (
        <Bubbled>
            {props.text}
        </Bubbled>
    );
}