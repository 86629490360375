import React from 'react';
import styled, { keyframes } from 'styled-components';

const wspin = keyframes`
0% {
    -webkit-transform: rotate(0deg);
}

100% {
    -webkit-transform: rotate(360deg);
}
  
`;
const spin = keyframes`
0% {
    transform: rotate(0deg);
}

100% {
    transform: rotate(360deg);
}
  
`;

const TheLoader = styled.div`
  border: 4px solid #6f7072;
  border-radius: 50%;
  border-top: 4px solid #3b4046;
  width: 30px;
  height: 30px;
  -webkit-animation: ${wspin} 1s linear infinite;
  animation: ${spin} 1s linear infinite;
`;

export default function Loader() {
  return <TheLoader />;
}
