import styled from 'styled-components/macro';
import { useState, useRef } from 'react';
import LittleButton from './LittleButton';
import CaretUp from '../assets/images/CaretUp.svg';
import CaretDown from '../assets/images/CaretDown.svg';
import Edit from '../assets/images/Edit.svg';

const CollapseContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #e3e6ea;
  width: 100%;
`;

const Header = styled.div`
  border-radius: 8px;
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
  padding: 8px 12px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const MainSection = styled.div`
  padding: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
`;

const Message = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #656d76;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #2a2f35;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`;

function Collapsible(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const contentRef = useRef(null);

  return (
    <CollapseContainer id={props.id ? props.id : null}>
      <Header
        style={
          isCollapsed
            ? { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }
            : { borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }
        }
      >
        <Title>{props.title}</Title>
        {props.completed ? (
          <Status style={{ backgroundColor: '#D1EDDC', color: '#0F7637' }}>
            Completed
          </Status>
        ) : (
          <Status style={{ backgroundColor: '#FFF5DB', color: '#774F01' }}>
            Incomplete
          </Status>
        )}
        {props.message && <Message>{props.message}</Message>}
        <div style={{ flexGrow: 1 }} />
        {props.isEditable && (
          <LittleButton onClick={() => props.editFunction(props.sectionKey)}>
            <img src={Edit} />
          </LittleButton>
        )}
        <LittleButton onClick={() => setIsCollapsed(!isCollapsed)}>
          <img src={isCollapsed ? CaretDown : CaretUp} />
        </LittleButton>
      </Header>
      <MainSection
        ref={contentRef}
        style={
          isCollapsed
            ? {
                height: '0px',
                borderTop: 'none',
              }
            : {
                height: `${contentRef.current?.scrollHeight}px`,
                borderTop: '1px solid #e3e6ea',
              }
        }
      >
        <div>{props.children}</div>
      </MainSection>
    </CollapseContainer>
  );
}

export default Collapsible;
