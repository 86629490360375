import React from 'react';
import { OverlayView } from '@react-google-maps/api';

export default function MapOverlay({ bounds, deg, image, z = 1 }) {
  return (
    <OverlayView mapPaneName={OverlayView.OVERLAY_LAYER} bounds={bounds}>
      <img
        src={image}
        alt="Test"
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          transform: `rotate(${deg}deg)`,
          zIndex: `${z}`,
        }}
      />
    </OverlayView>
  );
}
