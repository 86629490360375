export default {
  yellow: {
    base: '#F5A302',
    medium: '#F4B301',
    light: '#FED154',
    xlight: '#FFE8A8',
    dark: '#A97101',
  },
  blue: {
    base: '#0458B2',
    medium: '#1865B8',
    light: '#3679C1',
    xlight: '#C0D5EB',
    dark: '#023367',
  },
  purple: {
    base: '#4309A3',
    medium: '#521AAA',
    light: '#602FB1',
    xlight: '#D1C3E8',
    dark: '#25055A',
  },
  green: {
    base: '#0F7637',
    medium: '#2C864F',
    light: '#2C864F',
    xlight: '#D1EDDC',
    dark: '#063217',
  },
  red: {
    base: '#B50614',
    medium: '#C43A45',
    light: '#C0535D',
    xlight: '#F2D5D7',
    dark: '#6B040C',
  },
  black: '#000000',
  grey: {
    xdark: 'rgba(42, 47, 53, 1)',
    dark: 'rgba(59, 64, 70, 1)',
    base: 'rgba(101, 109, 118, 1)',
    light: 'rgba(207, 212, 219, 1)',
    xlight: 'rgba(227, 230, 234, 1)',
    xxlight: 'rgba(240, 242, 245, 1)',
    xxxlight: 'rgba(248, 250, 252, 1)',
  },
  white: 'rgba(255, 255, 255, 1)',
  link: {
    color: '#0458B2',
  },
};
