import React from 'react';
import styled from 'styled-components/macro';
import AngleArrow from '../../assets/images/Arrow 1.svg';

const CraneContainer = styled.div`
  height: 300px;
  width: 600px;
  position: relative;
  /* border: 1px dashed black; */
`;

const Body = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  background: #f4b301;
  width: 60px;
  top: 55%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
`;

const Center = styled.div`
  position: absolute;
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  background: #d9d9d9;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
`;

const Pointer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;
  img {
    margin-top: 8px;
    margin-left: 20px;
  }
`;

const AngleBox = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;
  div {
    margin-top: 20px;
    margin-left: 20px;
  }
`;

const Pick = styled.div`
  position: relative;
  left: -1px;
  height: 20px;
  width: 2px;
  background: #1865b8;
`;

const Set = styled.div`
  position: relative;
  left: -1px;
  height: 20px;
  width: 2px;
  background: #b50614;
`;

const Rigger = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const Outrigger = styled.div`
  background: #656d76;
  height: 16px;
`;

const Extension = styled.p`
  color: #000;
  font-size: 14px;
  font-weight: 600;
`;

const Pressure = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;
  gap: 4px;
`;

const Max = styled.div`
  border-radius: 5px;
  border: 1px solid #b50614;
  color: #b50614;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  padding: 2px;
`;

const Current = styled.div`
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  padding: 2px;
`;

const Pad = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  padding: 3px;
  height: 100px;
  gap: 12px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fed154;
`;

const Arrow = styled.div`
  height: 0px;
  width: 0px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 13px solid #0458b2;
`;

const Front = styled.p`
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

const Slew = styled.p`
  color: #000;
  font-size: 18px;
  text-align: center;
`;

export default function CraneDiagram({
  angle,
  loadWeight,
  craneDetails,
  craneAngle,
}) {
  const dimensions = craneDetails.selectedCrane.dimensions;
  const grossWeight = craneDetails.selectedCrane.grossWeight;
  const tailswing = dimensions.tailSwing;
  const boomWeight = 7500;
  const counterweight = parseInt(
    craneDetails.selectedCounterWeight.replace(',', '')
  );
  const V =
    grossWeight +
    counterweight +
    parseFloat(loadWeight) +
    parseFloat(craneDetails.totalDeductions);
  const radius =
    Math.max(craneDetails.pickRadius, craneDetails.setRadius) * 304.8;
  const outrigDifference =
    dimensions.frontToRearOutrig - dimensions.frontToFrontOutrig;
  const outrigCenter =
    (dimensions.frontToRearOutrig + dimensions.frontToFrontOutrig) / 2;
  const superstructureCG =
    tailswing * counterweight +
    -radius * parseFloat(loadWeight) +
    -radius * boomWeight;
  const rotationOffset = outrigCenter - dimensions.frontToCenterpin;
  const carrierCG = grossWeight * 0.8 * rotationOffset;
  const outrigExtension = dimensions.fullWidth;

  const convertAngle = (angle, offset) => {
    offset = offset || 0;
    return (Math.abs(angle - 180) + offset) % 360;
  };

  const calcGBP = (angle) => {
    const LCG =
      (carrierCG +
        grossWeight * 0.2 +
        superstructureCG * Math.cos(((angle + 180) * Math.PI) / 180)) /
        V -
      rotationOffset;
    const TCG =
      (grossWeight * 0.2 +
        superstructureCG * Math.sin(((angle + 180) * Math.PI) / 180)) /
      V;
    return {
      frontRight:
        V / 4 -
        (V * LCG) / (2 * outrigDifference) +
        (V * TCG) / (2 * outrigExtension),
      frontLeft:
        V / 4 -
        (V * LCG) / (2 * outrigDifference) -
        (V * TCG) / (2 * outrigExtension),
      rearRight:
        V / 4 +
        (V * LCG) / (2 * outrigDifference) +
        (V * TCG) / (2 * outrigExtension),
      rearLeft:
        V / 4 +
        (V * LCG) / (2 * outrigDifference) -
        (V * TCG) / (2 * outrigExtension),
    };
  };

  const getMax = () => {
    let max = calcGBP(0);
    for (let i = 1; i < 360; i++) {
      let temp = calcGBP(i);
      if (temp.frontRight > max.frontRight) {
        max.frontRight = temp.frontRight;
      }
      if (temp.frontLeft > max.frontLeft) {
        max.frontLeft = temp.frontLeft;
      }
      if (temp.rearRight > max.rearRight) {
        max.rearRight = temp.rearRight;
      }
      if (temp.rearLeft > max.rearLeft) {
        max.rearLeft = temp.rearLeft;
      }
    }
    return max;
  };

  const max = getMax();

  const pickAngle = convertAngle(craneDetails.pickAngle, craneAngle);
  const setAngle = convertAngle(craneDetails.setAngle, craneAngle);
  const center = dimensions.frontToCenterpin;
  const length = dimensions.length;
  const width = dimensions.bodyWidth;
  const toFront = dimensions.frontToFrontOutrig;
  const toRear = dimensions.frontToRearOutrig;
  const rigLength = dimensions.fullWidth;

  const weights = calcGBP(angle);

  const bodyHeight = Math.min(250, (length / width) * 60);
  const frontDiff = (toFront / length) * bodyHeight - 8;
  const rearDiff = (toRear / length) * bodyHeight - 8;
  const riggerExtension = ((rigLength - width) / 2 / width) * 60;

  return (
    <CraneContainer>
      <Slew>Slew angle: {angle}°</Slew>
      <Body style={{ height: `${bodyHeight}px` }}>
        <Arrow />
        <Front>Front</Front>
        <Center style={{ top: `${(center / length) * bodyHeight}px` }}>
          <Circle />
          <Pointer
            style={{ transform: `rotate(${-parseFloat(angle) + 90}deg)` }}
          >
            <img src={AngleArrow} />
          </Pointer>
          <AngleBox
            style={{ transform: `rotate(${-parseFloat(pickAngle)}deg)` }}
          >
            <Pick />
          </AngleBox>
          <AngleBox
            style={{ transform: `rotate(${-parseFloat(setAngle)}deg)` }}
          >
            <Set />
          </AngleBox>
        </Center>
        <Rigger style={{ top: frontDiff, left: `-${riggerExtension}px` }}>
          <Outrigger style={{ width: `${riggerExtension}px` }} />
          <Extension>100%</Extension>
          <Pressure
            style={{ left: `-${riggerExtension / 2 + 55}px`, top: '-10px' }}
          >
            <Max>{Math.ceil(max.frontLeft)} lbs</Max>
            <Current>{Math.ceil(weights.frontLeft)} lbs</Current>
          </Pressure>
        </Rigger>
        <Rigger style={{ top: frontDiff, right: `-${riggerExtension}px` }}>
          <Outrigger style={{ width: `${riggerExtension}px` }} />
          <Extension>100%</Extension>
          <Pressure
            style={{ right: `-${riggerExtension / 2 + 55}px`, top: '-10px' }}
          >
            <Max>{Math.ceil(max.frontRight)} lbs</Max>
            <Current>{Math.ceil(weights.frontRight)} lbs</Current>
          </Pressure>
        </Rigger>
        <Rigger style={{ top: rearDiff, left: `-${riggerExtension}px` }}>
          <Outrigger style={{ width: `${riggerExtension}px` }} />
          <Extension>100%</Extension>
          <Pressure
            style={{ left: `-${riggerExtension / 2 + 55}px`, top: '-10px' }}
          >
            <Max>{Math.ceil(max.rearLeft)} lbs</Max>
            <Current>{Math.ceil(weights.rearLeft)} lbs</Current>
          </Pressure>
        </Rigger>
        <Rigger style={{ top: rearDiff, right: `-${riggerExtension}px` }}>
          <Outrigger style={{ width: `${riggerExtension}px` }} />
          <Extension>100%</Extension>
          <Pressure
            style={{ right: `-${riggerExtension / 2 + 55}px`, top: '-10px' }}
          >
            <Max>{Math.ceil(max.rearRight)} lbs</Max>
            <Current>{Math.ceil(weights.rearRight)} lbs</Current>
          </Pressure>
        </Rigger>
        <Pad style={{ left: `-${riggerExtension + 180}px` }}>
          <Pressure style={{ position: 'relative' }}>
            <Max>{Math.ceil(max.frontLeft / 24)} psf</Max>
            <Current>{Math.ceil(weights.frontLeft / 24)} psf</Current>
          </Pressure>
          <Extension>4' x 6' Pad</Extension>
        </Pad>
        <Pad style={{ right: `-${riggerExtension + 180}px` }}>
          <Pressure style={{ position: 'relative' }}>
            <Max>{Math.ceil(max.frontRight / 24)} psf</Max>
            <Current>{Math.ceil(weights.frontRight / 24)} psf</Current>
          </Pressure>
          <Extension>4' x 6' Pad</Extension>
        </Pad>
        <Pad style={{ right: `-${riggerExtension + 180}px`, top: '150px' }}>
          <Pressure style={{ position: 'relative' }}>
            <Max>{Math.ceil(max.rearRight / 24)} psf</Max>
            <Current>{Math.ceil(weights.rearRight / 24)} psf</Current>
          </Pressure>
          <Extension>4' x 6' Pad</Extension>
        </Pad>
        <Pad style={{ left: `-${riggerExtension + 180}px`, top: '150px' }}>
          <Pressure style={{ position: 'relative' }}>
            <Max>{Math.ceil(max.rearLeft / 24)} psf</Max>
            <Current>{Math.ceil(weights.rearLeft / 24)} psf</Current>
          </Pressure>
          <Extension>4' x 6' Pad</Extension>
        </Pad>
      </Body>
    </CraneContainer>
  );
}
