import axios from 'axios';
import { API_URL } from '../services/api';
import { updateBasicInfo } from '../context/siteplan';
import authHeader from '../services/auth-header';
import { convertToMMDDYY } from './utils';

export function saveData(callback) {
  return function saving(dispatch, getState) {
    if (callback) callback(true);
    const state = getState();
    const { socketId } = state.user;
    const liftplanState = {
      basicInfo: { ...state.site.basicInfo },
      craneDetails: { ...state.site.craneDetails },
      loadDetails: { ...state.site.loadDetails },
      hazards: state.site.hazards,
      overlays: { ...state.site.overlays },
      siteDetails: { ...state.site.siteDetails },
      riggingPlan: { ...state.site.riggingPlan },
    };
    if (!liftplanState?.basicInfo?.createdBy) {
      liftplanState.basicInfo.createdBy = state.user.username;
      if (!liftplanState.basicInfo.jobWalkDate) {
        liftplanState.basicInfo.jobWalkDate = `${convertToMMDDYY(new Date())}`;
      }
      if (!liftplanState.basicInfo.jobStartDate) {
        liftplanState.basicInfo.jobStartDate = `${convertToMMDDYY(new Date())}`;
      }
      dispatch(
        updateBasicInfo({
          createdBy: liftplanState.basicInfo.createdBy,
          jobWalkDate: liftplanState.basicInfo.jobWalkDate,
          jobStartDate: liftplanState.basicInfo.jobStartDate,
        })
      );
    }
    axios
      .post(
        `${API_URL}/liftplan/create/draft`,
        {
          liftplanId: liftplanState.basicInfo.liftplanId,
          liftplanState,
          socketId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (callback) callback(false);
      })
      .catch((err) => {
        console.log('Error during save: ', err);
        if (callback) callback(false);
      });
  };
}
