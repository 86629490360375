/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import Image from './circle-outline.svg';
import Orange from '../../assets/images/orange.png';

const cloudName = 'db2d7nhto';
const shapes = {
  polygon: {
    strokeWeight: 2,
    strokeOpacity: 0.5,
    strokeColor: 'red',
    fillColor: 'red',
    fillOpacity: 0.3,
    zIndex: 3,
    clickable: true,
  },
  swingRadius: {
    strokeWeight: 2,
    strokeOpacity: 0.7,
    strokeColor: 'red',
    fillOpacity: 0.5,
    fillColor: 'red',
    zIndex: 3,
    clickable: false,
  },
  setCircle: {
    strokeWeight: 2,
    strokeOpacity: 0.5,
    strokeColor: 'red',
    fillOpacity: 0,
    zIndex: 3,
    clickable: false,
  },
  pickCircle: {
    strokeWeight: 2,
    strokeOpacity: 0.5,
    fillOpacity: 0,
    strokeColor: 'blue',
    zIndex: 3,
    clickable: false,
  },
  circle: {
    strokeWeight: 2,
    strokeOpacity: 0.5,
    strokeColor: 'red',
    zIndex: 3,
    clickable: true,
  },
  polyline: {
    strokeWeight: 2,
    strokeOpacity: 0.8,
    strokeColor: 'red',
    clickable: true,
    zIndex: 3,
  },
  arrow: {
    strokeWeight: 3,
    strokeOpacity: 1,
    strokeColor: 'lightgreen',
    clickable: true,
    zIndex: 3,
  },
};

const polylineOptions = {
  strokeWeight: 5,
  strokeOpacity: 0.8,
  clickable: false,
  zIndex: 3,
};

const computeDistanceBetweenPoints = (point1, point2) => {
  const length = window.google.maps.geometry.spherical.computeDistanceBetween(
    point1,
    point2
  );
  return (length * 3.28084).toFixed(2);
};

const attachPolygonInfoWindow = (polygon, mapRef) => {
  const path = polygon.getPath();
  const points = path.getArray();
  const bounds = new window.google.maps.LatLngBounds();

  for (let i = 0; i < points.length; i += 1) {
    bounds.extend(points[i]);
  }

  if (path.getLength() < 2) return null;
  let lengthInFoot = null;
  const start = polygon.getPath().getAt(0);
  const end = polygon.getPath().getAt(1);
  const sideLength =
    window.google.maps.geometry.spherical.computeDistanceBetween(start, end);
  lengthInFoot = (sideLength * 3.28084).toFixed(2);
  const sideCenter = window.google.maps.geometry.spherical.interpolate(
    start,
    end,
    0.5
  );
  const marker = new google.maps.Marker({
    label: {
      text: `${lengthInFoot} Feet`,
      className: 'lengthMarker',
      color: 'white',
    },
    icon: {
      scaledSize: { width: 0, height: 0 },
      url: Image,
    },
  });

  marker.setPosition(sideCenter);
  marker.setMap(mapRef);
  polygon.marker = marker;
  polygon.lengthInFoot = lengthInFoot;
  return polygon;
};

const generateNote = (map, position, length) => {
  const markerProperties = {
    label: {
      text: `${length + 1}`,
      color: 'black',
    },
    icon: {
      url: Orange,
      scaledSize: { width: 20, height: 20 },
      anchor: { x: 10, y: 10 },
    },
  };

  const marker = new google.maps.Marker(markerProperties);
  marker.setPosition(position);
  marker.setMap(map);
  return marker;
};

// Places a pin, what kind of pin depends on mode and submode
const placePin = (map, location, text) => {
  if (!location.lat) {
    return null;
  }
  let markerProperties = null;
  if (text) {
    markerProperties = {
      label: {
        text,
        className: 'marker-position',
        color: 'white',
      },
      icon: {
        url: Image,
        scaledSize: { width: 20, height: 20 },
        anchor: { x: 10, y: 10 },
      },
    };
  } else {
    markerProperties = {
      icon: {
        url: Image,
        scaledSize: { width: 20, height: 20 },
        anchor: { x: 10, y: 10 },
      },
    };
  }

  const marker = new google.maps.Marker(markerProperties);
  marker.setPosition(location);
  marker.setMap(map);
  return marker;
};

const generateLine = (point1, point2, map) => {
  const line = new google.maps.Polyline(polylineOptions);
  line.setPath([point1, point2]);
  line.setMap(map);
  const label = attachPolygonInfoWindow(line, map);
  return {
    label,
    line,
  };
};

const generateObstacle = (type, pathArray, print) => {
  let newObstacle = null;
  if (type === 'polygon') {
    if (print === 'swing') {
      newObstacle = new google.maps.Polygon(shapes.swingRadius);
    } else {
      newObstacle = new google.maps.Polygon(shapes.polygon);
    }
    newObstacle.setPath(pathArray);
    if (print === 'footprint') {
      const rigObstacle = new google.maps.Polygon(shapes.polygon);
      const padObstacleArray = [];
      for (let i = 0; i < 4; i += 1) {
        const padObstacle = new google.maps.Polygon(shapes.polygon);
        padObstacle.setPath(pathArray.padArray[i]);
        padObstacleArray.push(padObstacle);
      }
      rigObstacle.setPath(pathArray.rigPath);
      newObstacle.rigObstacle = rigObstacle;
      newObstacle.padObstacleArray = padObstacleArray;
      newObstacle.setOptions({
        geodesic: true,
        fillColor: 'yellow',
        strokeColor: 'black',
        strokeWeight: 1,
        strokeOpacity: 0.8,
        fillOpacity: 0.5,
      });
      rigObstacle.setOptions({
        geodesic: true,
        fillColor: 'black',
        strokeColor: 'black',
        strokeWeight: 1,
        strokeOpacity: 0.8,
        fillOpacity: 0.4,
      });
      for (let i = 0; i < 4; i += 1) {
        padObstacleArray[i].setOptions({
          geodesic: true,
          fillColor: 'red',
          strokeColor: 'black',
          strokeWeight: 1,
          strokeOpacity: 0.8,
          fillOpacity: 0.8,
        });
      }
      if (pathArray.pickRadius) {
        const pickCircle = new google.maps.Circle(shapes.pickCircle);
        pickCircle.setRadius(pathArray.pickRadius * 0.3048);
        pickCircle.path = pathArray;
        newObstacle.pickCircle = pickCircle;
      }
      if (pathArray.setRadius) {
        const setCircle = new google.maps.Circle(shapes.setCircle);
        setCircle.setRadius(pathArray.setRadius * 0.3048);
        setCircle.path = pathArray;
        newObstacle.setCircle = setCircle;
      }
    }
  }
  if (type === 'line') {
    newObstacle = new google.maps.Polyline(shapes.polyline);
    newObstacle.setPath(pathArray);
  }
  if (type === 'arrow') {
    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };
    newObstacle = new google.maps.Polyline({
      path: pathArray,
      icons: [
        {
          icon: lineSymbol,
          offset: '100%',
        },
      ],
      ...shapes.arrow,
    });
    newObstacle.setPath(pathArray);
  }
  if (!newObstacle) {
    return null;
  }
  newObstacle.type = type;
  return newObstacle;
};

const attachHandlers = (obstacle, callback) => {
  let timer = null;
  google.maps.event.addListener(obstacle, 'mousedown', () => {
    timer = setTimeout(() => {
      timer = null;
      callback(obstacle);
      clearTimeout(timer);
    }, 2000);
  });
  google.maps.event.addListener(obstacle, 'mouseup', () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  });
  return obstacle;
};

const generateElevation = (obstacle, theElevation, map) => {
  if (obstacle.marker) {
    obstacle.marker.setMap(null);
  }
  const marker = new google.maps.Marker({
    label: {
      text: theElevation,
      className: 'elevation-label',
    },
    icon: {
      url: Image,
      scaledSize: { width: 0, height: 0 },
    },
  });
  marker.setMap(map);
  const getCenter = (points) => {
    let sumX = 0;
    let sumY = 0;
    for (let i = 0; i < points.length; i += 1) {
      const point = points[i];
      const lat = point.lat();
      const lng = point.lng();
      sumX += lat;
      sumY += lng;
    }
    return { lat: sumX / points.length, lng: sumY / points.length };
  };

  if (obstacle.center) {
    marker.setPosition(obstacle.center);
  } else {
    const paths = obstacle.getPath().getArray();
    const nextPaths = paths;
    const theCenter = getCenter(nextPaths);
    marker.setPosition(theCenter);
  }
  obstacle.marker = marker;
  return obstacle;
};

const packObstacles = (obstacleOverlays) =>
  obstacleOverlays
    .map((overlay) => {
      if (!overlay.getMap()) {
        return null;
      }
      if (overlay.getPath) {
        let thePath = overlay.getPath().getArray();
        thePath = thePath.map((path) => ({ lat: path.lat(), lng: path.lng() }));
        return {
          path: thePath,
          type: overlay.type,
          label: overlay.marker ? overlay.marker.label.text : null,
        };
      }
      return {
        center: overlay.getCenter(),
        radius: overlay.getRadius(),
        path: overlay.path,
        type: overlay.type,
        label: overlay.marker ? overlay.marker.label.text : null,
      };
    })
    .filter((item) => item !== null);

const packNotes = (notesOverlay) =>
  notesOverlay
    .map((overlay) => {
      if (!overlay.getMap()) {
        return null;
      }
      return {
        position: overlay.position.toJSON(),
        note: overlay.note,
        url: overlay.url,
      };
    })
    .filter((item) => item !== null);

const returnMeasurements = (map) => {
  const bounds = map.getBounds();
  const topLat = bounds.getNorthEast().lat();
  const botLat = bounds.getSouthWest().lat();
  const topLong = bounds.getNorthEast().lng();
  const botLong = bounds.getSouthWest().lng();
  const boundsArray = [
    // top left
    { lat: topLat, lng: botLong },
    // top right
    { lat: topLat, lng: topLong },
    // bottom right
    { lat: botLat, lng: topLong },
    // bottom left
    { lat: botLat, lng: botLong },
  ];
  const boundsWidth =
    window.google.maps.geometry.spherical.computeDistanceBetween(
      boundsArray[0],
      boundsArray[1]
    );
  const boundsHeight =
    window.google.maps.geometry.spherical.computeDistanceBetween(
      boundsArray[0],
      boundsArray[3]
    );
  const widthDiff = boundsArray[1].lng - boundsArray[0].lng;
  const heightDiff = boundsArray[3].lat - boundsArray[0].lat;
  const oneMeterInDegWidth = widthDiff / boundsWidth; // one meter in lng
  const oneMeterInDegHeight = heightDiff / boundsHeight; // one meter in lat
  return {
    oneMeterInDegHeight,
    oneMeterInDegWidth,
  };
};

const createFootprintPath = (
  map,
  {
    height,
    width,
    centerPin,
    frontRig,
    backRig,
    fullWidth,
    padWidth,
    padLength,
    setRadius,
    pickRadius,
  },
  location
) => {
  const { oneMeterInDegWidth, oneMeterInDegHeight } = returnMeasurements(map);
  const center = location || map.getCenter();
  const newWidth = (oneMeterInDegWidth * (width / 1000)) / 2;
  const newHeight = (oneMeterInDegHeight * (height / 1000)) / 2;

  const newPadWidth = oneMeterInDegWidth * (padWidth * 0.3048);
  const newPadHeight = oneMeterInDegHeight * (padLength * 0.3048);

  // Meters to shift crane for center pin
  const difference = (centerPin - height / 2) / 1000;

  let lat = null;
  let lng = null;
  if (typeof center.lat === 'function') {
    lat = center.lat() - difference * oneMeterInDegHeight;
    lng = center.lng();
  } else {
    lat = center.lat - difference * oneMeterInDegHeight;
    lng = center.lng;
  }

  const cranePath = [
    { lat: lat + newHeight, lng: lng - newWidth },
    { lat: lat + newHeight, lng: lng + newWidth },
    { lat: lat - newHeight, lng: lng + newWidth },
    { lat: lat - newHeight, lng: lng - newWidth },
  ];

  const frontRigDifference = (frontRig / 1000) * oneMeterInDegHeight;
  const backRigDifference = ((height - backRig) / 1000) * oneMeterInDegHeight;
  const rigWidth = (oneMeterInDegWidth * (fullWidth / 1000)) / 2;

  const rigPath = [
    {
      lat: cranePath[0].lat - backRigDifference,
      lng: cranePath[0].lng - rigWidth,
    },
    {
      lat: cranePath[1].lat - backRigDifference,
      lng: cranePath[1].lng + rigWidth,
    },
    {
      lat: cranePath[2].lat + frontRigDifference,
      lng: cranePath[2].lng + rigWidth,
    },
    {
      lat: cranePath[3].lat + frontRigDifference,
      lng: cranePath[3].lng - rigWidth,
    },
  ];

  const padArray = [];
  for (let i = 0; i < 4; i += 1) {
    padArray.push([
      { lat: rigPath[i].lat - newPadHeight, lng: rigPath[i].lng - newPadWidth },
      { lat: rigPath[i].lat - newPadHeight, lng: rigPath[i].lng + newPadWidth },
      { lat: rigPath[i].lat + newPadHeight, lng: rigPath[i].lng + newPadWidth },
      { lat: rigPath[i].lat + newPadHeight, lng: rigPath[i].lng - newPadWidth },
    ]);
  }

  cranePath.rigPath = rigPath;
  cranePath.padArray = padArray;
  cranePath.setRadius = setRadius;
  cranePath.pickRadius = pickRadius;
  return cranePath;
};

function rotatePoint(point, origin, angle) {
  const angleRad = (angle * Math.PI) / 180.0;
  return {
    x:
      Math.cos(angleRad) * (point.x - origin.x) -
      Math.sin(angleRad) * (point.y - origin.y) +
      origin.x,
    y:
      Math.sin(angleRad) * (point.x - origin.x) +
      Math.cos(angleRad) * (point.y - origin.y) +
      origin.y,
  };
}

const rotatePolygon = (polygon, angle, potentialCenter) => {
  const map = polygon.getMap();
  const center = potentialCenter || map.getCenter();
  const prj = map.getProjection();
  const origin = prj.fromLatLngToPoint(center); // rotate around first point

  const coords = polygon
    .getPath()
    .getArray()
    .map((latLng) => {
      const point = prj.fromLatLngToPoint(latLng);
      const rotatedLatLng = prj.fromPointToLatLng(
        rotatePoint(point, origin, angle)
      );
      return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
    });
  polygon.setPath(coords);
  polygon.angle = angle;
};

const generateDegreesAndBoundaries = (mapRef, canvasState) => {
  const imageHeight = canvasState.height;
  const imageWidth = canvasState.width;
  const imagePointOne = { x: canvasState.point1.x, y: canvasState.point1.y };
  const imagePointTwo = { x: canvasState.point2.x, y: canvasState.point2.y };
  const mapPointOne = {
    lat: canvasState.mapPoint1.lat,
    lng: canvasState.mapPoint1.lng,
  };
  const mapPointTwo = {
    lat: canvasState.mapPoint2.lat,
    lng: canvasState.mapPoint2.lng,
  };
  const { oneMeterInDegWidth, oneMeterInDegHeight } =
    returnMeasurements(mapRef);

  const lineLengthInMeters = parseFloat(
    window.google.maps.geometry.spherical.computeDistanceBetween(
      { lat: mapPointOne.lat, lng: mapPointOne.lng },
      { lat: mapPointTwo.lat, lng: mapPointTwo.lng }
    )
  );
  const dx = imagePointOne.x - imagePointTwo.x;
  const dy = imagePointOne.y - imagePointTwo.y;
  const lineLengthInPixels = Math.sqrt(dx * dx + dy * dy);

  // theRatio x Pixels = meters
  const theRatio = lineLengthInMeters / lineLengthInPixels;

  const imageHeightInMeters = theRatio * imageHeight;
  const imageWidthInMeters = theRatio * imageWidth;

  const center = { lat: mapPointOne.lat, lng: mapPointOne.lng };

  const newWidth = (oneMeterInDegWidth * imageWidthInMeters) / 2;
  const newHeight = (oneMeterInDegHeight * imageHeightInMeters) / 2;
  // keeping in case or edge cases that I am not aware or yet
  // const mapPointingNorth =
  //   canvasState.mapPoint1.lng - canvasState.mapPoint2.lng < 0 ? true : false;
  // const mapPointingWest =
  //   canvasState.mapPoint1.lat - canvasState.mapPoint2.lat > 0 ? true : false;
  // const imagePointingNorth =
  //   canvasState.point1.y - canvasState.point2.y > 0 ? true : false;
  const imagePointingWest = canvasState.point1.x - canvasState.point2.x > 0;
  const imageCenter = { y: imageHeight / 2, x: imageWidth / 2 };
  let lat = null;
  let lng = null;
  let latDifferenceInMeters = null;
  let lngDifferenceInMeters = null;
  function findAngle(p0, p1, p2) {
    const a = (p1.x - p0.x) ** 2 + (p1.y - p0.y) ** 2;
    const b = (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2;
    const c = (p2.x - p0.x) ** 2 + (p2.y - p0.y) ** 2;
    return Math.acos((a + b - c) / Math.sqrt(4 * a * b));
  }
  let imageAngle =
    findAngle({ x: imagePointOne.x, y: 0 }, imagePointOne, imagePointTwo) *
    (180 / Math.PI);
  const mapAngle = window.google.maps.geometry.spherical.computeHeading(
    mapPointOne,
    mapPointTwo
  );
  if (imagePointingWest) {
    imageAngle = 180 - imageAngle + 180;
  }

  // Issue is here how we do shift the image properly
  const degreeDifference =
    mapAngle - (imagePointingWest ? imageAngle - 180 + 180 : imageAngle);
  const rotatedPointOne = rotatePoint(
    imagePointOne,
    imageCenter,
    mapAngle - imageAngle
  );

  latDifferenceInMeters = (imageHeight / 2 - rotatedPointOne.y) * theRatio;
  lngDifferenceInMeters = (imageWidth / 2 - rotatedPointOne.x) * theRatio;
  lat = center.lat + latDifferenceInMeters * oneMeterInDegHeight;
  lng = center.lng + lngDifferenceInMeters * oneMeterInDegWidth;

  const cranePath = [
    { lat: lat + newHeight, lng: lng - newWidth },
    { lat: lat + newHeight, lng: lng + newWidth },
    { lat: lat - newHeight, lng: lng + newWidth },
    { lat: lat - newHeight, lng: lng - newWidth },
  ];

  const boundaries = new google.maps.LatLngBounds(
    new google.maps.LatLng(cranePath[0].lat, cranePath[0].lng),
    new google.maps.LatLng(cranePath[2].lat, cranePath[2].lng)
  );
  return { boundaries, degreeDifference };
};

const rotateCrane = (obstacle, angle, potentialCenter) => {
  rotatePolygon(obstacle, angle, potentialCenter);
  rotatePolygon(obstacle.rigObstacle, angle, potentialCenter);
  for (let i = 0; i < 4; i += 1) {
    rotatePolygon(obstacle.padObstacleArray[i], angle, potentialCenter);
  }
};

const moveObstacle = (obstacle, map, angle, footprintDimensions, location) => {
  const newPath = createFootprintPath(map, footprintDimensions, location);
  obstacle.setPath(newPath);
  obstacle.setMap(map);
  obstacle.rigObstacle.setPath(newPath.rigPath);
  obstacle.rigObstacle.setMap(map);
  if (obstacle.setCircle) {
    obstacle.setCircle.setMap(map);
    obstacle.setCircle.setCenter(map.getCenter());
  }
  if (obstacle.pickCircle) {
    obstacle.pickCircle.setMap(map);
    obstacle.pickCircle.setCenter(map.getCenter());
  }
  for (let i = 0; i < 4; i += 1) {
    obstacle.padObstacleArray[i].setPath(newPath.padArray[i]);
    obstacle.padObstacleArray[i].setMap(map);
  }
  if (angle) {
    rotateCrane(obstacle, angle, location);
  }
};

const distanceInPx = (position1, position2, map) => {
  const p1 = map.getProjection().fromLatLngToPoint(position1);
  const p2 = map.getProjection().fromLatLngToPoint(position2);

  const pixelSize = 2 ** -map.getZoom();

  const d =
    Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) /
    pixelSize;

  return d;
};

const uploadFile = (file, handlePlaceNote, callback, center) => {
  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  callback();

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // File uploaded successfully
      const response = JSON.parse(xhr.responseText);
      const urler = response.secure_url;
      const tokens = urler.split('/');
      tokens.splice(-2, 0, 'w_150,c_scale');
      handlePlaceNote(response.url, center);
    }
  };

  fd.append('upload_preset', 'iphcqyhp');
  fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
  fd.append('file', file);
  fd.append('folder', 'test');
  xhr.send(fd);
};

const resetOverlay = (overlayArray) => {
  overlayArray.forEach((overlayItem) => {
    const overlay = overlayItem;
    if (overlay?.marker) {
      overlay.marker.setMap(null);
      overlay.marker = null;
    }
    overlayItem?.setMap(null);
  });
};

// START OF CODE COPIED FROM THE INTERNET
// https://stackoverflow.com/questions/24956616/draw-circles-arc-on-google-maps
const EarthRadiusMeters = 6378137.0; // meters

google.maps.LatLng.prototype.DestinationPoint = function (brng, dist) {
  const R = EarthRadiusMeters; // earth's mean radius in meters
  var brng = brng.toRad();
  const lat1 = this.lat().toRad();
  const lon1 = this.lng().toRad();
  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(dist / R) +
      Math.cos(lat1) * Math.sin(dist / R) * Math.cos(brng)
  );
  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(dist / R) * Math.cos(lat1),
      Math.cos(dist / R) - Math.sin(lat1) * Math.sin(lat2)
    );

  return new google.maps.LatLng(lat2.toDeg(), lon2.toDeg());
};

// === A function which returns the bearing between two LatLng in radians ===
// === If v1 is null, it returns the bearing between the first and last vertex ===
// === If v1 is present but v2 is null, returns the bearing from v1 to the next vertex ===
// === If either vertex is out of range, returns void ===
google.maps.LatLng.prototype.Bearing = function (otherLatLng) {
  const from = this;
  const to = otherLatLng;
  if (from.equals(to)) {
    return 0;
  }
  const lat1 = from.latRadians();
  const lon1 = from.lngRadians();
  const lat2 = to.latRadians();
  const lon2 = to.lngRadians();
  let angle = -Math.atan2(
    Math.sin(lon1 - lon2) * Math.cos(lat2),
    Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
  );
  if (angle < 0.0) angle += Math.PI * 2.0;
  if (angle > Math.PI) angle -= Math.PI * 2.0;
  return parseFloat(angle.toDeg());
};

Number.prototype.toRad = function () {
  return (this * Math.PI) / 180;
};

Number.prototype.toDeg = function () {
  return (this * 180) / Math.PI;
};

Number.prototype.toBrng = function () {
  return (this.toDeg() + 360) % 360;
};

const drawArc = (center, initialBearing, finalBearing, radius) => {
  center = new google.maps.LatLng(center);

  const points = 32;
  const extp = new Array();
  if (initialBearing > finalBearing) {
    [initialBearing, finalBearing] = [finalBearing, initialBearing];
  }
  let deltaBearing = finalBearing - initialBearing;
  deltaBearing /= points;
  extp.push(center);
  if (finalBearing - initialBearing > 180) {
    deltaBearing = (finalBearing - 180 - (initialBearing + 180)) / points;
    for (var i = 0; i < points + 1; i++) {
      extp.push(
        center.DestinationPoint(
          (finalBearing + i * -deltaBearing) % 360,
          radius
        )
      );
    }
  } else {
    for (var i = 0; i < points + 1; i++) {
      extp.push(
        center.DestinationPoint(initialBearing + i * deltaBearing, radius)
      );
    }
  }
  extp.push(center);
  return extp;
};
// END OF COPIED CODE

const placeCraneOverlay = (
  mapRef,
  craneLocation,
  setLocation,
  pickLocation,
  craneDetails
) => {
  let cranePin = null;
  let setPin = null;
  let pickPin = null;
  let pickLine = null;
  let setLine = null;
  let tailswing = null;

  if (craneLocation) {
    cranePin = placePin(mapRef, craneLocation, 'Crane');
  }
  if (setLocation) {
    setPin = placePin(mapRef, setLocation, 'Set');
    setLine = generateLine(craneLocation, setLocation, mapRef);
  }
  if (pickLocation) {
    pickPin = placePin(mapRef, pickLocation, 'Pick');
    pickLine = generateLine(craneLocation, pickLocation, mapRef);
  }
  if (pickLocation && setLocation && craneDetails.selectedCrane.dimensions) {
    tailswing = generateObstacle(
      'polygon',
      drawArc(
        craneLocation,
        google.maps.geometry.spherical.computeHeading(
          craneLocation,
          pickLocation
        ) + 180,
        google.maps.geometry.spherical.computeHeading(
          craneLocation,
          setLocation
        ) + 180,
        craneDetails.selectedCrane.dimensions.tailSwing / 1000
      ),
      'swing'
    );
    tailswing.setMap(mapRef);
  }
  return {
    crane: cranePin,
    pickLine,
    set: setPin,
    pick: pickPin,
    setLine,
    tailswing,
  };
};

const placeObstacles = (mapRef, obstacles, attach, dispatch, setSelected) => {
  const obstacleArray = obstacles.map((obstacleInfo) => {
    let newObstacle = null;
    if (attach) {
      newObstacle = attachHandlers(
        generateObstacle(obstacleInfo.type, obstacleInfo.path),
        (obj) => {
          setSelected(obj);
          dispatch();
        }
      );
    } else {
      newObstacle = generateObstacle(obstacleInfo.type, obstacleInfo.path);
    }

    newObstacle.setMap(mapRef);
    if (obstacleInfo.label) {
      generateElevation(newObstacle, obstacleInfo.label, mapRef);
    }
    return newObstacle;
  });
  return obstacleArray;
};

const placeNotes = (
  mapRef,
  notes,
  editable,
  setNote,
  setSelected,
  dispatch
) => {
  let count = 0;
  const notesArray = notes.map((notesInfo) => {
    const newNote = generateNote(mapRef, notesInfo.position, count);
    count += 1;
    newNote.note = notesInfo.note;
    newNote.url = notesInfo.url;
    if (editable) {
      let timer = null;
      google.maps.event.addListener(newNote, 'mousedown', () => {
        timer = setTimeout(() => {
          timer = null;
          clearTimeout(timer);
          setNote(newNote.note);
          setSelected(newNote);
          dispatch();
        }, 1000);
      });
    }
    return newNote;
  });
  return notesArray;
};

const findPlace = (
  mapRef,
  basicInfo,
  possibleLocation,
  setCenter,
  notification
) => {
  const placeService = new google.maps.places.PlacesService(mapRef);

  placeService.findPlaceFromQuery(
    { fields: ['ALL'], query: basicInfo.location },
    (res) => {
      if (!res) {
        if (notification) {
          notification({
            type: 'error',
            bold: 'Bad Address',
            text: 'Using device location instead',
          });
        }
        navigator.geolocation.watchPosition(
          // Success callback function
          function (position) {
            // Get the user's latitude and longitude coordinates
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            // Update the map with the user's new location
            console.log(`Latitude: ${lat}, longitude: ${lng}`);
            const theCenter = {
              lat: lat,
              lng: lng,
            };
            setCenter(possibleLocation || theCenter);
          },
          // Error callback function
          function (error) {
            // Handle errors, e.g. user denied location sharing permissions
            console.error('Error getting user location:', error);
          }
        );
      } else {
        const theCenter = {
          lat: res[0].geometry.location.lat(),
          lng: res[0].geometry.location.lng(),
        };
        setCenter(possibleLocation || theCenter);
      }
    }
  );
};

const handleOnTilesLoaded = (
  mapRef,
  loaded,
  center,
  setLoaded,
  setFootprint,
  siteDetails,
  craneDetails,
  dispatch,
  setShowOverlay
) => {
  if (loaded) return;
  if (center) {
    if (siteDetails.footprintPath) {
      const tempFootprintPath = [...siteDetails.footprintPath];
      tempFootprintPath.rigPath = siteDetails.rigprintPath;
      tempFootprintPath.padArray = siteDetails.padPathArray;
      tempFootprintPath.setRadius = craneDetails.setRadius;
      tempFootprintPath.pickRadius = craneDetails.pickRadius;
      const newObstacle = generateObstacle(
        'polygon',
        tempFootprintPath,
        'footprint'
      );
      newObstacle.setMap(mapRef);
      if (newObstacle.pickCircle) {
        newObstacle.pickCircle.setMap(mapRef);
        newObstacle.pickCircle.setCenter(mapRef.getCenter());
      }
      if (newObstacle.setCircle) {
        newObstacle.setCircle.setMap(mapRef);
        newObstacle.setCircle.setCenter(mapRef.getCenter());
      }
      for (let i = 0; i < 4; i += 1) {
        newObstacle.padObstacleArray[i].setMap(mapRef);
      }
      newObstacle.rigObstacle.setMap(mapRef);
      if (mapRef.getBounds()) {
        moveObstacle(
          newObstacle,
          mapRef,
          siteDetails.angle,
          craneDetails.footprintDimensions,
          center
        );
      }
      if (setFootprint !== null) {
        setFootprint(newObstacle);
      }
    }
  }
  if (siteDetails?.imageLink !== '') {
    dispatch(setShowOverlay(true));
  }
  dispatch(setLoaded(true));
};

export {
  placePin,
  generateObstacle,
  generateElevation,
  attachHandlers,
  packObstacles,
  createFootprintPath,
  returnMeasurements,
  moveObstacle,
  distanceInPx,
  rotatePolygon,
  attachPolygonInfoWindow,
  generateNote,
  packNotes,
  uploadFile,
  resetOverlay,
  computeDistanceBetweenPoints,
  rotateCrane,
  generateDegreesAndBoundaries,
  generateLine,
  drawArc,
  findPlace,
  placeNotes,
  placeObstacles,
  placeCraneOverlay,
  handleOnTilesLoaded,
};
