import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../reusables/Modal/Modal';
import Button from '../../reusables/Button';
import { setRadiusModal } from '../../context/liftpage';
import { updateCraneDetails, updateMode } from '../../context/siteplan';
import { updateSiteDetails } from '../../context/siteplan';

const RadiusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 0px 24px;
`;

const Label = styled.p`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 124px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
  border: 1px solid #3b4046;
  border-radius: 8px;
  font-size: 16px;
  width: 152px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

export default function Radius({ done }) {
  const dispatch = useDispatch();
  const { radiusModal } = useSelector((state) => state.page);
  const { pickRadius, setRadius } = useSelector(
    (state) => state.site.craneDetails
  );
  const { modals, buttons } = useTheme();
  const [modifiedPickRadius, setModifiedPickRadius] = useState(pickRadius);
  const [modifiedSetRadius, setModifiedSetRadius] = useState(setRadius);
  return (
    <Modal
      show={radiusModal}
      modalStyles={modals.info}
      handleClose={() => dispatch(setRadiusModal(false))}
      title="Enter Radius"
    >
      <RadiusContainer>
        <Row>
          <Label>Pick Radius (ft)</Label>
          <Input
            value={modifiedPickRadius || ''}
            type="number"
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setModifiedPickRadius(isNaN(value) ? 0 : value);
            }}
          />
        </Row>
        <Row>
          <Label>Set Radius (ft)</Label>
          <Input
            value={modifiedSetRadius || ''}
            type="number"
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setModifiedSetRadius(isNaN(value) ? 0 : value);
            }}
          />
        </Row>
        <ButtonContainer>
          <Button
            onClick={() => dispatch(setRadiusModal(false))}
            theme={buttons.editJobWalk}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(
                updateCraneDetails({
                  setRadius: parseInt(
                    modifiedSetRadius >= 5 ? modifiedSetRadius : 5
                  ),
                  pickRadius: parseInt(
                    modifiedPickRadius >= 5 ? modifiedPickRadius : 5
                  ),
                  selectedAttachment: 'default',
                  selectedCounterWeight: 'default',
                  selectedBoomLength: 'default',
                  craneSavePending: true,
                })
              );
              dispatch(setRadiusModal(false));
              if (!done) {
                dispatch(updateSiteDetails({ pinType: 'Crane' }));
                dispatch(updateMode('crane'));
              }
            }}
            theme={buttons.overlay}
          >
            Confirm
          </Button>
        </ButtonContainer>
      </RadiusContainer>
    </Modal>
  );
}
