import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import { useTheme } from 'styled-components';
import { API_URL } from '../../services/api';
import authHeader from '../../services/auth-header';
import Input from '../../reusables/Input';
import Button from '../../reusables/Button';
import Loader from '../../reusables/Loader/Loader';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  input {
    height: 28px;
    width: 28px;
    border-radius: 8px;
  }
  p {
    font-size: 16px;
  }
`;

const DropdownItem = styled.div`
  width: 100%;
  font-size: 16px;
  border: 1px solid lightgray;
  padding: 10px;
  background-color: white;
  cursor: pointer;
`;

function EmailModal({ show, theId, setEmailModal }) {
  const [sendToEmail, setSendToEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [isPDF, setIsPDF] = useState(1);
  const theme = useTheme();
  const [recentEmails, setRecentEmails] = useState([]);
  const [matchedEmails, setMatchedEmails] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { companyName, email } = useSelector((state) => state.user);
  const { customerName, projectName, createdBy } = useSelector(
    (state) => state.site.basicInfo
  );
  const { size } = useSelector(
    (state) => state.site.craneDetails.selectedCrane
  );

  useEffect(() => {
    setIsFocused(false);
    axios
      .get(`${API_URL}/company/emails/get/`, {
        params: {
          companyName,
        },
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.data.emails) {
          setRecentEmails(response.data.data.emails);
          setSendToEmail(response.data.data.emails[0]);
        }
      })
      .catch(() => {});
  }, [show]);

  if (!show) {
    return null;
  }

  const handleSendEmail = async () => {
    setLoader(true);
    setIsFocused(false);
    let pdf = null;
    if (isPDF) {
      const basicInfoRef = document.getElementById('basicInfo');
      const loadDetailsRef = document.getElementById('loadDetails');
      const craneSelectionRef = document.getElementById('craneSelection');
      const rangeDiagramRef = document.getElementById('rangeDiagram');
      const siteLayout = document.getElementById('siteLayout');
      const additionalInformation = document.getElementById(
        'additionalInformation'
      );
      const informationRefArray = [
        basicInfoRef,
        loadDetailsRef,
        craneSelectionRef,
      ];
      const siteLayoutRefArray = [siteLayout];
      const pageWidth = informationRefArray[0].scrollWidth;
      const pageHeight = informationRefArray[0].scrollWidth * 1.333;

      const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [pageWidth, pageHeight],
      });

      const generateCanvasArray = (refArray) => {
        const arrayToBeReturned = [];
        for (let i = 0; i < refArray.length; i += 1) {
          const reference = refArray[i];
          if (!reference) continue;
          if (reference.scrollHeight > pageHeight) {
            let j = 0;
            const tempArray = [];
            const howManyPages = Math.ceil(reference.scrollHeight / pageHeight);
            let totalElementHeight = 0;

            while (j < howManyPages) {
              j += 1;
              const canvasSlice = html2canvas(reference, {
                scale: 1,
                useCORS: true,
                ignoreElements: (node) => node.nodeName === 'IFRAME',
                height: pageHeight,
                scrollX: reference.scrollWidth,
                y: totalElementHeight,
              });
              tempArray.push(canvasSlice);
              totalElementHeight += pageHeight;
            }
            arrayToBeReturned.push(...tempArray);
          } else {
            arrayToBeReturned.push(
              html2canvas(reference, {
                scale: 1,
                useCORS: true,
                ignoreElements: (node) => node.nodeName === 'IFRAME',
                height: reference.scrollHeight,
                scrollX: reference.scrollWidth,
                scrollY: reference.scrollHeight,
              })
            );
          }
        }
        return arrayToBeReturned;
      };

      let informationCanvasArray = generateCanvasArray(informationRefArray);
      let siteLayoutCanvasArray = generateCanvasArray(siteLayoutRefArray);
      let rangeDiagramCanvasArray = generateCanvasArray([rangeDiagramRef]);
      let additionalInformationCanvasArray = generateCanvasArray([
        additionalInformation,
      ]);

      informationCanvasArray = await Promise.all(informationCanvasArray);
      siteLayoutCanvasArray = await Promise.all(siteLayoutCanvasArray);
      rangeDiagramCanvasArray = await Promise.all(rangeDiagramCanvasArray);
      additionalInformationCanvasArray = await Promise.all(
        additionalInformationCanvasArray
      );

      let informationHeightAdded = 0;
      let siteLayoutHeightAdded = 0;
      informationCanvasArray.forEach((canvasRef) => {
        if (!canvasRef) return;
        if (
          informationHeightAdded + parseInt(canvasRef.style.height) >
          pageHeight
        ) {
          informationHeightAdded = 0;
          doc.addPage([pageWidth, pageHeight]);
        }
        doc.addImage(
          canvasRef,
          'JPEG',
          0,
          informationHeightAdded,
          doc.internal.pageSize.getWidth(),
          parseInt(canvasRef.style.height)
        );
        informationHeightAdded += parseInt(canvasRef.style.height);
      });
      doc.addPage([pageWidth, pageHeight]);

      siteLayoutCanvasArray.forEach((canvasRef) => {
        if (!canvasRef) return;
        if (
          siteLayoutHeightAdded + parseInt(canvasRef.style.height) >
          pageHeight
        ) {
          siteLayoutHeightAdded = 0;
          doc.addPage([pageWidth, pageHeight]);
        }
        doc.addImage(
          canvasRef,
          'JPEG',
          0,
          siteLayoutHeightAdded,
          doc.internal.pageSize.getWidth(),
          parseInt(canvasRef.style.height)
        );
        siteLayoutHeightAdded += parseInt(canvasRef.style.height);
      });

      rangeDiagramCanvasArray.forEach((canvasRef) => {
        if (!canvasRef) return;
        if (
          siteLayoutHeightAdded + parseInt(canvasRef.style.height) >
          pageHeight
        ) {
          siteLayoutHeightAdded = 0;
          doc.addPage([pageWidth, pageHeight]);
        }
        doc.addImage(
          canvasRef,
          'JPEG',
          0,
          siteLayoutHeightAdded,
          doc.internal.pageSize.getWidth(),
          parseInt(canvasRef.style.height)
        );
        siteLayoutHeightAdded += parseInt(canvasRef.style.height);
      });

      doc.addPage();
      siteLayoutHeightAdded = 0;
      additionalInformationCanvasArray.forEach((canvasRef) => {
        if (!canvasRef) return;
        if (
          siteLayoutHeightAdded + parseInt(canvasRef.style.height) >
          pageHeight
        ) {
          siteLayoutHeightAdded = 0;
          doc.addPage([pageWidth, pageHeight]);
        }
        doc.addImage(
          canvasRef,
          'JPEG',
          0,
          siteLayoutHeightAdded,
          doc.internal.pageSize.getWidth(),
          parseInt(canvasRef.style.height)
        );
        siteLayoutHeightAdded += parseInt(canvasRef.style.height);
      });
      pdf = doc.output('datauristring');

      const emails = sendToEmail.split(',').map((email) => email.trim());
      const id = theId;
      if (id) {
        const url = `${window.location.origin}/review/liftplan/${id}`;
        axios
          .post(
            `${API_URL}/liftplan/send`,
            {
              emails,
              cc: email,
              url,
              pdf,
              filename: `${customerName}_${projectName}_${createdBy}`,
              subject: `${customerName} - ${size} - ${projectName}`,
            },
            {
              headers: authHeader(),
            }
          )
          .then(() => {
            setLoader(false);
            setEmailModal(false);
          })
          .catch((err) => {
            console.log('this is error: ', err);
          });

        axios
          .post(
            `${API_URL}/company/emails/add`,
            {
              emails,
              name: companyName,
            },
            {
              headers: authHeader(),
            }
          )
          .then((response) => {
            console.log('this is response: ', response);
          })
          .catch((err) => {
            console.log('this is error: ', err);
          });
      }
    }
  };

  const handleEmailChange = (value) => {
    setSendToEmail(value);
    setIsFocused(true);
    setMatchedEmails(
      recentEmails
        .filter(
          (email) =>
            email.toLowerCase().includes(value.toLowerCase()) &&
            email.toLowerCase() !== value.toLowerCase()
        )
        .slice(0, 5)
    );
  };

  return (
    <div>
      <Input style={{ marginTop: '15px' }}>
        <p>Email Address</p>
        <input
          placeholder="Email Address"
          type="text"
          value={sendToEmail}
          onFocus={() => handleEmailChange('')}
          onBlur={() =>
            setTimeout(() => {
              setIsFocused(false);
            }, 200)
          }
          onChange={(e) => handleEmailChange(e.target.value)}
        />
        <div>
          <div style={{ position: 'absolute', width: '100%' }}>
            {isFocused &&
              matchedEmails.map((email) => (
                <DropdownItem
                  key={email}
                  onClick={() => {
                    setSendToEmail(email);
                    setIsFocused(false);
                  }}
                >
                  {email}
                </DropdownItem>
              ))}
          </div>
        </div>
      </Input>
      <CheckboxContainer>
        <input
          type="checkbox"
          id="verify"
          value="Requested"
          checked={!!isPDF}
          onChange={() => {
            setIsPDF(isPDF ? 0 : 1);
          }}
        />
        <p>Send as PDF?</p>
      </CheckboxContainer>
      <div className="send-invite-button">
        <Button onClick={() => handleSendEmail()} theme={theme.buttons.default}>
          {loader ? <Loader /> : 'Send Invite'}
        </Button>
      </div>
    </div>
  );
}

export default EmailModal;
