export default function calculateDeductions(
  grossCap,
  theBoomLength,
  send,
  updateCraneDetails,
  saveData,
  craneDetails,
  loadDetails,
  currentCrane
) {
  let totalDeductionValue = parseFloat(loadDetails.estimatedDeductions) || 0;
  let auxLineDeduction = 0;
  let mainLineDeduction = 0;
  let stowedDeduction = 0;
  // Check if using Main Boom only or not
  // add dropdown selection but it does nothing in caclulations
  if (craneDetails.attachmentStatus === 'stowed') {
    let temp = null;
    let index = null;
    stowedDeduction = craneDetails.attachmentExtension.stowedDeduction;
    if (stowedDeduction === -1) {
      const theArrays = [
        ...craneDetails.attachmentExtension.stowedDeductionsArr,
      ];
      for (let i = theArrays.length - 1; i >= 0; i -= 1) {
        const chartDistance = parseFloat(Object.keys(theArrays[i])[0]);
        if (
          chartDistance >= theBoomLength &&
          (chartDistance < temp || temp === null)
        ) {
          temp = chartDistance;
          index = i;
        }
      }
      if (!index) {
        stowedDeduction = craneDetails.selectedAttachment.stowedDeduction;
      } else {
        stowedDeduction =
          craneDetails.attachmentExtension.stowedDeductionsArr[index][temp];
      }
    } else {
      stowedDeduction = craneDetails.selectedAttachment.stowedDeduction;
    }
    totalDeductionValue += stowedDeduction;
  }

  // Check if mainline of auxline is being used or not. if being used

  // Hookblock weight calculations
  totalDeductionValue += parseInt(craneDetails.selectedHookblock.weight) || 0;
  // Determine the excess parts of line and it's weight on the crane
  if (craneDetails.partsOfLine > 1) {
    const numberOfExcessLines =
      craneDetails.partsOfLine -
      Math.ceil(loadDetails.loadWeight / currentCrane.maxSingleLinePull);
    const tipHeight = Math.sqrt(
      theBoomLength ** 2 -
        Math.max(craneDetails.pickRadius, craneDetails.setRadius) ** 2 +
        3
    );
    mainLineDeduction =
      numberOfExcessLines * currentCrane.lineWeightPerFoot * tipHeight;
    totalDeductionValue += mainLineDeduction;
  }

  if (craneDetails.auxLinePresent) {
    // hookblock with zero sheaves
    totalDeductionValue +=
      parseInt(craneDetails.auxselectedHookblock.weight) || 0;
    if (craneDetails.auxpartsOfLine > 1) {
      const numberOfExcessLines =
        craneDetails.auxpartsOfLine -
        Math.ceil(loadDetails.loadWeight / currentCrane.maxSingleLinePull);
      auxLineDeduction =
        numberOfExcessLines *
        currentCrane.lineWeightPerFoot *
        craneDetails.hangingHeight;
    } else {
      auxLineDeduction =
        craneDetails.hangingHeight * currentCrane.lineWeightPerFoot;
    }
    totalDeductionValue += auxLineDeduction;
  }

  totalDeductionValue +=
    parseInt(craneDetails.otherDeductions) +
    parseInt(craneDetails.riggingDeductions);
  const linePullLimit =
    currentCrane.maxSingleLinePull * craneDetails.partsOfLine;
  const result = linePullLimit < grossCap ? linePullLimit : grossCap;
  const newResult = result - totalDeductionValue;
  const percCap = ((loadDetails.loadWeight / newResult) * 100)
    .toFixed(2)
    .toString();
  if (send) {
    updateCraneDetails({
      auxLineDeduction,
      mainLineDeduction,
      linePullLimit,
      totalDeductions: totalDeductionValue,
      netCapacity: newResult,
      percentOfCap: percCap,
      stowedDeduction,
    });

    saveData();
  }
  return percCap;
}
