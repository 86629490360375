import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setSteps } from '../../context/user';
import { setDeleteModal, setNotification } from '../../context/liftpage';
import Button from '../../reusables/Button';
import { API_URL } from '../../services/api';
import authHeader from '../../services/auth-header';
import Report from '../../assets/images/Report.svg';
import Card from './Card';
import Modal from '../../reusables/Modal/Modal';
import Info from '../../assets/images/Info.svg';
import { resetRedux } from '../../context/siteplan';
import Switch from '../../reusables/Switch';
import InputContainer from '../../reusables/Input';
import SearchSVG from '../../assets/images/search.svg';
import Loader from '../../reusables/Loader/Loader';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 360px;
  max-width: 360px;
  align-items: center;
`;

const InputIconLabel = styled.label`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 16px;
    top: 48%;
    bottom: 0;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    z-index: 999;
  }
  input {
    padding: 10px 30px;
  }
`;
const SectionText = styled.p`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.grey.base};
  img {
    margin-right: 10px;
  }
  span {
    font-weight: 600;
    margin-left: 4px;
  }
  .info {
    font-weight: 600;
    color: black;
    font-style: italic;
  }
`;

const InfoSectionText = styled.p`
  margin-top: 12px;
  color: black;
  font-style: italic;
  img {
    margin-right: 10px;
  }
  span {
    font-weight: 600;
  }
`;

const ScrollSectionText = styled.p`
  margin-top: 12px;
  max-height: 200px;
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.grey.base};
  img {
    margin-right: 10px;
  }
  span {
    font-weight: 600;
    margin-left: 4px;
  }
`;

const HomeContainer = styled.div`
  width: 100%;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${({ theme }) => theme.colors.grey.xxxlight};
`;

const StartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  h1 {
    font-size: 32px;
    font-weight: 400;
    margin-left: 12px;
  }
`;
const ReportContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  div:last-child {
    margin-right: auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: space-between;
`;

const SwitchLabel = styled.p`
  color: #3b4046;
  font-family: Roboto;
  font-size: 20px;
  flex-grow: 1;
`;

function formatDate(value) {
  const date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${day} ${month}, ${year}`;
}

export default function Home({ history }) {
  const dispatch = useDispatch();
  const [drafts, setDrafts] = useState([]);
  const [onlyMe, setOnlyMe] = useState(true);
  const [search, setSearch] = useState('');
  const theme = useTheme();
  const { companyName, username, socketId } = useSelector(
    (state) => state.user
  );
  const { deleteModal, content } = useSelector((state) => state.page);

  useEffect(() => {
    dispatch(setSteps(null));
  }, []);

  const getDrafts = () => {
    if (!companyName) return;
    axios
      .get(`${API_URL}/liftplan/draft/all`, {
        headers: authHeader(),
        params: {
          linkedCompany: companyName,
          socketId,
        },
      })
      .then((response) => {
        // console.log('this is drafts', response.data.data);
        setDrafts(response.data.data.drafts);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('error', err);
      });
  };

  const archiveLiftplan = (plan) => {
    axios
      .post(
        `${API_URL}/liftplan/delete/draft`,
        {
          liftplanId: plan?.liftplanId,

          archived: true,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        dispatch(setDeleteModal({ show: false, content: null }));
        getDrafts();
        dispatch(
          setNotification({
            type: 'success',
            text: 'has successfully been deleted!',
            bold: plan?.draftInfo.basicInfo.projectName,
          })
        );
      });
  };

  const handleViewReport = (id, type, page) => {
    dispatch(setSteps(page));
    history.push(type === 'liftplan' ? `/liftplan/${id}` : `/jobwalk/${id}`);
  };

  const generateReports = () => {
    let draftsArray = [];
    if (drafts.length > 0) {
      draftsArray = drafts
        .sort(
          (a, b) =>
            new Date(b.draftInfo.basicInfo.jobWalkDate) -
            new Date(a.draftInfo.basicInfo.jobWalkDate)
        )
        .filter((draft) => {
          let me = true;
          let s = true;
          if (onlyMe) {
            me = draft.draftInfo.basicInfo.createdBy === username;
          }
          if (search !== '') {
            s = false;
            if (draft.draftInfo.basicInfo.customerName) {
              s = draft.draftInfo.basicInfo.customerName
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          }
          return me && s;
        })
        .map((draft, i) => (
          <Card
            draft={draft}
            handleViewReport={handleViewReport}
            // eslint-disable-next-line react/no-array-index-key
            key={`inprogress-card-${i}-draft`}
          />
        ));
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            width: '100%',
          }}
        >
          <div style={{ marginRight: '0px' }}>
            <Loader />
          </div>
        </div>
      );
    }
    return [...draftsArray];
  };

  useEffect(() => {
    if (companyName) {
      getDrafts();
    }
  }, [companyName]);

  const createLiftPlan = () => {
    dispatch(resetRedux());
    dispatch({
      type: 'draft',
    });
    const newId = uuidv4();
    history.push(`/jobwalk/${newId}`);
  };

  return (
    <HomeContainer>
      <Modal
        show={deleteModal}
        handleClose={() =>
          dispatch(setDeleteModal({ show: false, content: null }))
        }
        modalStyles={theme.modals.deleteJobWalk}
        title={
          <p style={{ fontWeight: 'normal', textAlign: 'left' }}>
            Delete{' '}
            <span
              style={{ fontWeight: 700 }}
            >{`${content?.draftInfo.basicInfo.projectName}`}</span>
            ?
          </p>
        }
      >
        <div>
          <h3 style={{ fontWeight: 'normal' }}>Job Details</h3>
          <ScrollSectionText>
            {content?.draftInfo.basicInfo.description}
          </ScrollSectionText>
        </div>
        <div>
          <SectionText>
            Job Walk Date:
            <span>
              {formatDate(
                content?.draftInfo?.basicInfo.jobWalkDate ||
                  content?.draftInfo?.basicInfo.date
              )}
            </span>
          </SectionText>
          <SectionText>
            Customer:
            <span>{content?.draftInfo?.basicInfo.customerName}</span>
          </SectionText>
        </div>
        <InfoSectionText>
          <img
            src={Info}
            alt="Info"
            style={{ position: 'relative', top: '2px' }}
          />
          Deleting a job walk can <span>not</span> be undone!
        </InfoSectionText>
        <ButtonContainer>
          <Button
            theme={theme.buttons.editJobWalk}
            onClick={() =>
              dispatch(setDeleteModal({ show: false, content: null }))
            }
          >
            Cancel
          </Button>
          <Button
            theme={theme.buttons.deleteJobWalk}
            onClick={() => archiveLiftplan(content)}
          >
            Delete
          </Button>
        </ButtonContainer>
      </Modal>
      <Title>
        <img src={Report} alt="report" />
        <h1>My Job Walk Reports</h1>
      </Title>
      <StartButtonContainer>
        <SearchContainer>
          <InputContainer style={{ marginBottom: '0px' }}>
            <InputIconLabel image={SearchSVG}>
              <input
                placeholder="Filter Reports by Customer"
                value={search}
                style={{ fontSize: '16px' }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputIconLabel>
          </InputContainer>
        </SearchContainer>
        <Button theme={theme.buttons.create} onClick={() => createLiftPlan()}>
          New Job Walk
        </Button>
      </StartButtonContainer>
      <StartButtonContainer>
        <Switch callback={setOnlyMe} toggled={onlyMe} />
        <SwitchLabel>Only show reports created by me</SwitchLabel>
      </StartButtonContainer>
      <ReportContainer>{generateReports()}</ReportContainer>
    </HomeContainer>
  );
}
