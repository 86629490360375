import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useTheme } from 'styled-components';
import { craneList, resetRedux, setSiteplanData } from '../../context/siteplan';
import { saveData } from '../../helpers/saveData';
import Button from '../../reusables/Button';
import BasicInfo from '../../pages/BasicInfo/BasicInfo';
import Hazards from '../../pages/Hazards/Hazards';
import LoadDetails from '../../pages/LoadDetails/LoadDetails';
import SitePlan from '../../pages/Siteplan/SitePlan';
import authHeader from '../../services/auth-header';
import { API_URL } from '../../services/api';
import LiftPlanPreview from '../../pages/LiftPlanPreview/LiftPlanPreview';
import liftplanObj from '../../constants/liftplanState';
import Loader from '../../reusables/Loader/Loader';
import ErrorBoundary from '../../reusables/ErrorBoundary';
import Bubble from '../../reusables/Bubble';
import { setSteps } from '../../context/user';
import { isValidObj } from '../../helpers/utils';
import { setDiagramModal } from '../../context/liftpage';
import GroundPressure from '../../pages/GroundPressure/GroundPressure';
import NewRigging from '../../pages/RiggingPlan/NewRigging';
import Pagination from '../../reusables/Pagination';

const LiftPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  padding-left: 13px;
  padding-right: 13px;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const BubbleContainer = styled.div`
  position: relative;
  width: 100%;
  top: -40px;
  height: 0;
  display: flex;
  justify-content: center;
`;

const Status = styled.div`
  position: relative;
  color: #b50614;
  top: -15px;
  width: 100%;
  font-weight: 600;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function FormWrapper({ history, match, upgraded }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currStep, setCurrStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(upgraded ? 3 : 5);
  const [isFormComplete, setFormComplete] = useState(false);
  const [bubbleMessage, setBubbleMessage] = useState('');
  const [isBubbleShow, setBubbleShow] = useState(false);
  const [draft, setDraft] = useState({});
  const canvasState = useSelector((state) => state.site.overlays);
  const liftplanState = useSelector((state) => state.site);
  const { socketId } = useSelector((state) => state.user);
  const liftplanId = match.params.id;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { companyName, height, step } = useSelector((state) => state.user);
  const { refresh } = useSelector((state) => state.page);
  const [disabledPages, setDisabledPages] = useState([
    0,
    2,
    3,
    4,
    5,
    totalSteps + 1,
  ]);
  const [completedPages, setCompletedPages] = useState([1]);
  const [steps, setStepss] = useState([
    {
      key: 'basicInfo',
    },
    {
      key: 'loadDetails',
    },
    {
      key: 'siteDetails',
    },
    {
      key: 'hazards',
    },
    {
      key: 'review',
    },
  ]);

  useEffect(() => {
    dispatch(resetRedux());
    setCompletedPages([1]);
    setIsLoading(true);
    axios
      .get(`${API_URL}/liftplan/draft/${liftplanId}`, {
        headers: authHeader(),
        params: {
          socketId,
        },
      })
      .then((response) => {
        setIsLoading(false);
        let liftplan = { basicInfo: {} };
        if (response.data.data.liftplan) {
          const { draftInfo } = response.data.data.liftplan;
          liftplan = draftInfo;
        }
        setDraft(response.data.data.liftplan);
        liftplan.basicInfo.liftplanId = liftplanId;
        dispatch(setSiteplanData(liftplan));
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // start here!!!!
    if (companyName) {
      axios
        .get(`${API_URL}/crane/craneDetails`, {
          params: {
            linkedCompany: companyName,
          },
          headers: authHeader(),
        })
        .then((res) => {
          dispatch(craneList(res.data.craneList));
        })
        .catch((e) => {
          console.log('Error: ', e);
        });
    }
  }, [companyName]);

  useEffect(() => {
    if (step) {
      setCurrStep(step);
    }
  }, []);

  useEffect(() => {
    dispatch(setSteps(currStep));
  }, [currStep]);

  const sendImageData = () => {
    axios
      .post(
        `${API_URL}/liftplan/overlay/upload`,
        {
          liftplanId,
          imageLink: canvasState.imageLink,
          imageData: {
            imageLink: canvasState.imageLink,
            height: canvasState.height,
            width: canvasState.width,
            point1: canvasState.point1,
            point2: canvasState.point2,
            mapPoint1: canvasState.mapPoint1,
            mapPoint2: canvasState.mapPoint2,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {})
      .catch((err) => {
        console.log('caught', err);
      });
  };

  const createLiftPlan = (callback) => {
    axios
      .post(
        `${API_URL}/liftplan/createLiftplan`,
        {
          liftplanId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        callback();
      })
      .catch((err) => {
        console.log('caught', err);
      });
  };

  const getCustomers = (callback) => {
    axios
      .get(`${API_URL}/company/customers/get/`, {
        params: {
          companyName,
        },
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.data.customers) {
          callback(response.data.data.customers);
        }
      })
      .catch(() => {});
  };

  const addCustomer = (customers) => {
    axios
      .post(
        `${API_URL}/company/customers/add`,
        {
          customers,
          name: companyName,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log('this is response: ', response);
      })
      .catch((err) => {
        console.log('this is error: ', err);
      });
  };

  const getOverlays = (callback) => {
    axios
      .get(`${API_URL}/liftplan/overlay/get/${liftplanId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.data.overlays) {
          callback(response.data.data.overlays);
        }
      })
      .catch(() => {});
  };

  const getRigging = async (callback) => {
    axios
      .get(`${API_URL}/rigging/company/list`, {
        params: {
          linkedCompanyName: companyName,
        },
        headers: authHeader(),
      })
      .then((response) => {
        callback(response.data.data.rigging);
      });
  };

  const isValidStep = () => {
    if (upgraded) {
      setDisabledPages([0, 4]);
      return true;
    }
    const temp = [1];
    let dis = disabledPages;
    for (let i = 1; i < 3; i++) {
      const { key } = steps[i - 1];
      const planState = liftplanObj[key];
      const currState = liftplanState[key] || {};
      const complete = isValidObj(planState, currState, (obj) => {
        if (i === currStep) setBubbleMessage(`${obj.bold} ${obj.text}`);
      });
      if (i === currStep) {
        setFormComplete(complete);
      }
      if (complete) {
        if (i === 2) {
          if (temp.includes(2)) {
            temp.push(3, 4, 5);
          } else {
            dis = [...dis, 3, 4, 5];
          }
        } else {
          temp.push(i + 1);
        }

        setCompletedPages([
          ...temp.filter((item, index) => temp.indexOf(item) === index),
        ]);
      } else {
        if (i === 2) {
          dis = [...dis, 3, 4, 5];
        }
        dis = [...dis, i + 1];
      }
    }
    const dd = dis.filter((item, index) => dis.indexOf(item) === index);
    setDisabledPages(dd.filter((n) => !temp.includes(n)));
    return temp.includes(currStep);
  };

  useEffect(() => {
    isValidStep();
  }, [liftplanState, currStep]);

  useEffect(() => {
    // if (upgraded && draft.draftInfo) {
    //   if (!draft.linkedLiftplan) {
    //     history.push('/');
    //   }
    //   setCurrStep(1);
    // }
    if (upgraded) {
      setCurrStep(1); // uncomment to be redirected to home screen regardless of status
      history.push('/');
    }
    setTotalSteps(upgraded ? 3 : 5);
  }, [upgraded, draft]);

  const updateStep = (type, page) => {
    if (
      page < 1 ||
      page > totalSteps ||
      (!upgraded && disabledPages.includes(page))
    ) {
      setBubbleShow(true);
      setTimeout(() => {
        setBubbleShow(false);
      }, 3000);
      return;
    }
    dispatch(setDiagramModal(false));
    if (type === 'back') {
      setCurrStep(page);
    } else {
      setBubbleShow(true);
      setTimeout(() => {
        setBubbleShow(false);
      }, 3000);
      if (isValidStep()) {
        if (
          page === 2 &&
          !upgraded &&
          liftplanState.basicInfo.customerName !== null &&
          liftplanState.basicInfo.customerName !== ''
        ) {
          addCustomer([liftplanState.basicInfo.customerName]);
        }
        dispatch(saveData());
        setCurrStep(page);
      }
    }
  };

  const jobWalk = {
    1: <BasicInfo getCustomers={getCustomers} />,
    2: <LoadDetails />,
    3: <SitePlan sendImageData={sendImageData} getOverlays={getOverlays} />,
    4: <Hazards />,
    5: (
      <LiftPlanPreview
        updateStep={updateStep}
        liftplanState={liftplanState}
        isEditable
        match={match}
        history={history}
        createLiftPlan={createLiftPlan}
        upgraded={upgraded}
      />
    ),
  };

  const liftPlan = {
    1: <GroundPressure />,
    2: <NewRigging getRigging={getRigging} />,
    3: (
      <LiftPlanPreview
        updateStep={updateStep}
        liftplanState={liftplanState}
        isEditable
        match={match}
        upgraded={upgraded}
      />
    ),
  };

  return (
    <LiftPlanContainer style={{ height }}>
      <ScrollWrapper>
        <ErrorBoundary key={currStep}>
          {upgraded ? liftPlan[currStep] : jobWalk[currStep]}
        </ErrorBoundary>
      </ScrollWrapper>
      <Footer>
        {refresh && (
          <Status>
            <span
              style={{ fontSize: '40px', position: 'relative', top: '-2px' }}
            >
              •
            </span>
            <div>Someone is currently editing this lift plan</div>
          </Status>
        )}
        <Pagination
          current={currStep}
          numPages={totalSteps}
          handlePage={(n) => {
            if (n < currStep) {
              updateStep('back', n);
            } else {
              updateStep('next', n);
            }
          }}
          handleLeft={() => {
            updateStep('back', currStep - 1);
          }}
          handleRight={() => {
            updateStep('next', currStep + 1);
          }}
          disabled={disabledPages}
        />
        {!isFormComplete && isBubbleShow && (
          <BubbleContainer>
            <Bubble
              text={bubbleMessage}
              style={{ display: isFormComplete && 'none' }}
            />
          </BubbleContainer>
        )}
      </Footer>
    </LiftPlanContainer>
  );
}
