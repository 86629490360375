import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveData } from '../../helpers/saveData';
import Radius from './modal_radius';
import Pin1 from '../../assets/images/Pin1.png';
import Pin2 from '../../assets/images/Pin2.png';
import Loader from '../../reusables/Loader/Loader';
import {
  updateCraneDetails,
  updateSiteDetails,
  resetSite,
  setOverlayDefault,
  setOverlayData,
  setImage,
  updateMode,
  setMapPoints,
} from '../../context/siteplan';
import {
  setResetModal,
  setInfoModal,
  setDiagramModal,
  setElevationModal,
  setShowOverlay,
} from '../../context/liftpage';
import SitePlanControls from './SitePlan_Controls';
import Diagram from './siteplan_diagram';
import DeductionsModal from './modal_deductions';
import InfoModal from './modal_info';
import {
  SitePlanContainer,
  ControlsContainer,
  Crosshairdiv,
  TheReset,
  LoaderBackground,
} from './siteplan_components';
import {
  packNotes,
  placePin,
  generateLine,
  generateObstacle,
  attachHandlers,
  packObstacles,
  createFootprintPath,
  moveObstacle,
  resetOverlay,
  rotateCrane,
  generateDegreesAndBoundaries,
  drawArc,
} from './sitePlanFunctions';
import calculateDeductions from '../../reusables/DeductionCalc';

import ResetSVG from '../../assets/images/Reset.svg';
import FilterModal from './modal_filter';
import Overlay from './modal_overlay';
import DiagramToggle from './diagram_toggle';
import AddNote from './modal_addNote';
import ResetModal from './modal_reset';
import Elevation from './modal_elevation';
import CapacityToggle from './capacity_toggle';
import SiteplanMap from './siteplan_map';

const newPolylineOptions = {
  strokeWeight: 2,
  strokeOpacity: 0.8,
  strokeColor: 'black',
  zIndex: 1,
};

export default function MapContainer({ sendImageData, getOverlays, match }) {
  const {
    craneDetails,
    loadDetails,
    siteOverlays,
    siteDetails,
    basicInfo,
    craneList,
    mode,
  } = useSelector((state) => state.site);
  const canvasState = useSelector((state) => state.site.overlays);
  const { diagramModal, radiusModal, loaded } = useSelector(
    (state) => state.page
  );
  const [overlays, setOverlays] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const [footprint, setFootprint] = useState(null);
  const [obstacleOverlays, setObstacleOverlays] = useState([]);
  const [selectedObstacle, setSelectedObstacle] = useState(null);
  const [currentPins, setCurrentPins] = useState([]);
  const [currentPolylines, setCurrentPolylines] = useState([]);
  const [craneOverlay, setCraneOverlay] = useState({
    crane: null,
    set: null,
    pick: null,
    setLine: null,
    pickLine: null,
    tailswing: null,
  });
  const [blank, setBlank] = useState(false);
  const [initialSetup, setInitialSetup] = useState(false);
  const [notesOverlays, setNotesOverlays] = useState([]);
  const [livePolyline, setLivePolyline] = useState(null);
  const [livePreview, setLivePreview] = useState(null);
  const [note, setNote] = useState('');
  const [selectedNote, setSelectedNote] = useState(null);

  let defaultDimensions = null;
  if (craneList.length !== 0) {
    defaultDimensions = {
      height: craneList[0].dimensions.length,
      width: craneList[0].dimensions.bodyWidth,
      fullWidth: craneList[0].dimensions.fullWidth,
      centerPin: craneList[0].dimensions.frontToCenterpin,
      frontRig: craneList[0].dimensions.frontToFrontOutrig,
      backRig: craneList[0].dimensions.frontToRearOutrig,
      parent: craneList[0].dimensions.parent,
      padWidth: craneList[0].padWidth,
      padLength: craneList[0].padLength,
    };
  }
  const {
    selectedCrane,
    grossCap,
    selectedBoomLength,
    percentOfCap,
    lowPercent,
    highPercent,
  } = craneDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    getOverlays((data) => setOverlays(data));
  }, [canvasState.isShown, canvasState.uploadModal]);

  useEffect(() => {
    if (siteDetails.reset) {
      dispatch(resetSite());
      dispatch(
        updateSiteDetails({
          reset: null,
        })
      );
    }
  }, [siteDetails.reset]);

  const clearLivePolyline = () => {
    if (livePolyline.marker) {
      livePolyline.marker.setMap(null);
      livePolyline.marker = null;
    }
    livePolyline.setMap(null);
    setLivePolyline(null);
  };

  useEffect(() => {
    if (!mapRef) return;
    if (mode === 'crane') {
      if (!footprint) return;
      moveObstacle(
        footprint,
        mapRef,
        siteDetails.angle || 0,
        craneDetails?.footprintDimensions?.height
          ? craneDetails.footprintDimensions
          : defaultDimensions
      );
    }
  }, [siteDetails.angle]);

  // When swapping between modes, cleanup unfinished pins
  useEffect(() => {
    if (mode === 'arrow') {
      return;
    }
    if (livePolyline) {
      clearLivePolyline();
    }
    if (livePreview) {
      livePreview.setMap(null);
      setLivePreview(null);
    }
    resetOverlay(currentPins);
    if (currentPolylines.length > 0) {
      currentPolylines.forEach((polyline) => polyline.line.setMap(null));
      setCurrentPolylines([]);
    }
    setCurrentPins([]);
  }, [mode]);

  const confirmObstacle = () => {
    if (mode === 'measurements') {
      sendImageData();
      setOverlay({
        imageLink: canvasState.imageLink,
        height: canvasState.height,
        width: canvasState.width,
        point1: canvasState.point1,
        point2: canvasState.point2,
        mapPoint1: canvasState.mapPoint1,
        mapPoint2: canvasState.mapPoint2,
      });
      dispatch(updateMode('select'));
      return;
    }
    const pathArray = currentPins.map((pin) => ({
      lat: pin.position.lat(),
      lng: pin.position.lng(),
    }));

    const newObstacle = attachHandlers(
      generateObstacle(mode, pathArray),
      (obj) => {
        setSelectedObstacle(obj);
        dispatch(setElevationModal(true));
      }
    );

    newObstacle.setMap(mapRef);
    setObstacleOverlays([...obstacleOverlays, newObstacle]);
    const packagedObstacles = packObstacles([...obstacleOverlays, newObstacle]);
    dispatch(updateSiteDetails({ packagedObstacles }));
    dispatch(saveData());
    dispatch(updateMode('select'));
    if (livePolyline) {
      clearLivePolyline();
      setLivePolyline(null);
    }
    if (currentPolylines.length > 0) {
      currentPolylines.forEach((polyline) => polyline.line.setMap(null));
      setCurrentPolylines([]);
    }
  };

  useEffect(() => {
    if (footprint?.setCircle) {
      if (footprint.setCircle.getRadius() !== craneDetails.setRadius * 0.3048) {
        footprint.setCircle.setRadius(craneDetails.setRadius * 0.3048);
        if (craneOverlay.setLine) {
          if (craneOverlay.setLine && siteDetails.pinType !== 'Crane') {
            craneOverlay.set.setMap(null);
            craneOverlay.set = null;
            craneOverlay.setLine.label.marker.setMap(null);
            craneOverlay.setLine.line.setMap(null);
            craneOverlay.setLine = null;
            if (craneOverlay.tailswing) craneOverlay.tailswing.setMap(null);
            dispatch(updateMode('pins'));
            dispatch(updateSiteDetails({ pinType: 'Set' }));
            setInitialSetup(false);
          }
        }
      }
    }
    if (footprint?.pickCircle) {
      if (
        footprint.pickCircle.getRadius() !==
        craneDetails.pickRadius * 0.3048
      ) {
        footprint.pickCircle.setRadius(craneDetails.pickRadius * 0.3048);
        if (craneOverlay.pickLine) {
          if (craneOverlay.pickLine && siteDetails.pinType !== 'Crane') {
            craneOverlay.pick.setMap(null);
            craneOverlay.pick = null;
            craneOverlay.pickLine.label.marker.setMap(null);
            craneOverlay.pickLine.line.setMap(null);
            craneOverlay.pickLine = null;
            if (craneOverlay.tailswing) craneOverlay.tailswing.setMap(null);
            dispatch(updateMode('pins'));
            dispatch(updateSiteDetails({ pinType: 'Pick' }));
            setInitialSetup(false);
          }
        }
      }
    }
  }, [craneDetails.setRadius, craneDetails.pickRadius]);

  useEffect(() => {
    if (selectedCrane === 'default' || !mapRef) return;
    if (!mapRef.getBounds()) return;
    if (footprint) {
      if (footprint.setCircle) footprint.setCircle.setMap(null);
      if (footprint.pickCircle) footprint.pickCircle.setMap(null);
      footprint.setMap(null);
      footprint.rigObstacle.setMap(null);
      for (let i = 0; i < 4; i += 1) {
        footprint.padObstacleArray[i].setMap(null);
      }
    }
    const newObstacle = generateObstacle(
      'polygon',
      createFootprintPath(
        mapRef,
        craneDetails.footprintDimensions || defaultDimensions,
        siteDetails.craneLocation
      ),
      'footprint'
    );
    newObstacle.setMap(mapRef);
    if (newObstacle.pickCircle) {
      newObstacle.pickCircle.setMap(mapRef);
      newObstacle.pickCircle.setCenter(siteDetails.craneLocation);
    }
    if (newObstacle.setCircle) {
      newObstacle.setCircle.setMap(mapRef);
      newObstacle.setCircle.setCenter(siteDetails.craneLocation);
    }
    newObstacle.rigObstacle.setMap(mapRef);
    for (let i = 0; i < 4; i += 1) {
      newObstacle.padObstacleArray[i].setMap(mapRef);
    }
    if (siteDetails.angle) {
      rotateCrane(newObstacle, siteDetails.angle, siteDetails.craneLocation);
    }
    if (mode !== 'measurements') {
      // currently disabled so on intial placement it doesn't boot you out of pin mode
      // dispatch(updateMode('select'));
    }
    if (
      craneOverlay.set &&
      craneOverlay.pick &&
      craneDetails.selectedCrane.dimensions
    ) {
      if (craneOverlay.tailswing) craneOverlay.tailswing.setMap(null);
      const tailswing = generateObstacle(
        'polygon',
        drawArc(
          siteDetails.craneLocation,
          google.maps.geometry.spherical.computeHeading(
            siteDetails.craneLocation,
            siteDetails.pickLocation
          ) + 180,
          google.maps.geometry.spherical.computeHeading(
            siteDetails.craneLocation,
            siteDetails.setLocation
          ) + 180,
          craneDetails.selectedCrane.dimensions.tailSwing / 1000
        ),
        'swing'
      );
      tailswing.setMap(mapRef);
      setCraneOverlay({
        ...craneOverlay,
        tailswing,
      });
    }
    setFootprint(newObstacle);
  }, [selectedCrane]);

  const handlePlacePin = (pinType) => {
    if (livePreview) {
      livePreview.setMap(null);
      setLivePreview(null);
    }
    const text =
      pinType === 'Obstacle' ||
      pinType === 'Route' ||
      pinType === 'Set' ||
      pinType === 'Pick'
        ? pinType
        : null;
    const newPin = placePin(mapRef, mapRef.getCenter(), text);
    let pickLine = null;
    let setLine = null;
    let tailswing = null;
    const pinLocation = {
      lat: mapRef.getCenter().lat(),
      lng: mapRef.getCenter().lng(),
    };
    if (pinType === 'Crane') {
      if (craneOverlay.crane) craneOverlay.crane.setMap(null);
      if (footprint) {
        if (footprint.setCircle) footprint.setCircle.setMap(null);
        if (footprint.pickCircle) footprint.pickCircle.setMap(null);
        footprint.setMap(null);
        footprint.rigObstacle.setMap(null);
        for (let i = 0; i < 4; i += 1) {
          footprint.padObstacleArray[i].setMap(null);
        }
      }
      if (craneOverlay.tailswing) craneOverlay.tailswing.setMap(null);

      const generateFootprint = createFootprintPath(
        mapRef,
        craneDetails?.footprintDimensions?.height
          ? craneDetails.footprintDimensions
          : defaultDimensions,
        pinLocation
      );
      generateFootprint.setRadius = craneDetails.setRadius || 5;
      generateFootprint.pickRadius = craneDetails.pickRadius || 5;

      const newObstacle = generateObstacle(
        'polygon',
        generateFootprint,
        'footprint'
      );
      if (siteDetails.angle) {
        moveObstacle(
          newObstacle,
          mapRef,
          siteDetails.angle,
          craneDetails?.footprintDimensions?.height
            ? craneDetails.footprintDimensions
            : defaultDimensions
        );
      }
      if (newObstacle.pickCircle) {
        newObstacle.pickCircle.setMap(mapRef);
        newObstacle.pickCircle.setCenter(mapRef.getCenter());
      }
      if (newObstacle.setCircle) {
        newObstacle.setCircle.setMap(mapRef);
        newObstacle.setCircle.setCenter(mapRef.getCenter());
      }
      newObstacle.rigObstacle.setMap(mapRef);
      for (let i = 0; i < 4; i += 1) {
        newObstacle.padObstacleArray[i].setMap(mapRef);
      }
      newObstacle.setMap(mapRef);

      if (craneOverlay.set) {
        craneOverlay.setLine.label.setMap(null);
        craneOverlay.setLine.line.marker.setMap(null);
        setLine = generateLine(pinLocation, siteDetails.setLocation, mapRef);
      }
      if (craneOverlay.pick) {
        craneOverlay.pickLine.label.setMap(null);
        craneOverlay.pickLine.line.marker.setMap(null);
        pickLine = generateLine(pinLocation, siteDetails.pickLocation, mapRef);
      }
      if (
        craneOverlay.set &&
        craneOverlay.pick &&
        craneDetails.selectedCrane.dimensions
      ) {
        tailswing = generateObstacle(
          'polygon',
          drawArc(
            pinLocation,
            google.maps.geometry.spherical.computeHeading(
              pinLocation,
              siteDetails.pickLocation
            ) + 180,
            google.maps.geometry.spherical.computeHeading(
              pinLocation,
              siteDetails.setLocation
            ) + 180,
            craneDetails.selectedCrane.dimensions.tailSwing / 1000
          ),
          'swing'
        );
        tailswing.setMap(mapRef);
        dispatch(
          updateCraneDetails({
            setAngle: google.maps.geometry.spherical.computeHeading(
              pinLocation,
              siteDetails.setLocation
            ),
            pickAngle: google.maps.geometry.spherical.computeHeading(
              pinLocation,
              siteDetails.pickLocation
            ),
          })
        );
      }
      if (!siteDetails.pickLocation || !siteDetails.setLocation) {
        dispatch(updateMode('pins'));
        dispatch(
          updateSiteDetails({
            craneLocation: pinLocation,
            pinType: 'Pick',
          })
        );
      } else {
        dispatch(updateMode('select'));
        dispatch(
          updateSiteDetails({
            craneLocation: pinLocation,
          })
        );
      }
      if (setLine?.label?.lengthInFoot) {
        dispatch(
          updateCraneDetails({
            setRadius: setLine?.label?.lengthInFoot || null,
            pickRadius: pickLine?.label?.lengthInFoot || null,
            craneSavePending: true,
          })
        );
      }
      setFootprint(newObstacle);
      setCraneOverlay({
        ...craneOverlay,
        crane: newPin,
        pickLine,
        setLine,
        tailswing,
      });
      dispatch(
        updateSiteDetails({
          footprintPath: generateFootprint,
          rigprintPath: generateFootprint.rigPath,
          padPathArray: generateFootprint.padArray,
        })
      );
      dispatch(saveData());
    }
    if (pinType === 'Set') {
      let setLine = null;
      let tailswing = null;
      if (craneOverlay.set) craneOverlay.set.setMap(null);
      if (craneOverlay.tailswing) craneOverlay.tailswing.setMap(null);
      if (craneOverlay.setLine) {
        craneOverlay.setLine.line.marker.setMap(null);
        craneOverlay.setLine.label.setMap(null);
      }
      const heading = google.maps.geometry.spherical.computeHeading(
        siteDetails.craneLocation,
        pinLocation
      );
      const newLocation = google.maps.geometry.spherical.computeOffset(
        siteDetails.craneLocation,
        craneDetails.setRadius * 0.3048,
        heading
      );
      const newerLocation = { lat: newLocation.lat(), lng: newLocation.lng() };
      newPin.setPosition(newerLocation);
      dispatch(updateMode('select'));
      dispatch(
        updateSiteDetails({
          setLocation: newerLocation,
        })
      );
      if (craneOverlay.pick && craneDetails.selectedCrane.dimensions) {
        tailswing = generateObstacle(
          'polygon',
          drawArc(
            craneOverlay.crane.position,
            google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              craneOverlay.pick.position
            ) + 180,
            google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              newerLocation
            ) + 180,
            craneDetails.selectedCrane.dimensions.tailSwing / 1000
          ),
          'swing'
        );
        tailswing.setMap(mapRef);
        dispatch(
          updateCraneDetails({
            setAngle: google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              newerLocation
            ),
            pickAngle: google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              craneOverlay.pick.position
            ),
          })
        );
      }
      setInitialSetup(true);
      if (craneOverlay.crane) {
        setLine = generateLine(
          siteDetails.craneLocation,
          newerLocation,
          mapRef
        );
      }
      setCraneOverlay({ ...craneOverlay, set: newPin, setLine, tailswing });
      dispatch(saveData());
    }
    if (pinType === 'Pick') {
      let pickLine = null;
      let tailswing = null;
      if (craneOverlay.tailswing) craneOverlay.tailswing.setMap(null);
      if (craneOverlay.pick) craneOverlay.pick.setMap(null);
      if (craneOverlay.pickLine) {
        craneOverlay.pickLine.line.marker.setMap(null);
        craneOverlay.pickLine.label.setMap(null);
      }
      const heading = google.maps.geometry.spherical.computeHeading(
        siteDetails.craneLocation,
        pinLocation
      );
      const newLocation = google.maps.geometry.spherical.computeOffset(
        siteDetails.craneLocation,
        craneDetails.pickRadius * 0.3048,
        heading
      );
      const newerLocation = { lat: newLocation.lat(), lng: newLocation.lng() };
      newPin.setPosition(newerLocation);
      dispatch(updateMode('select'));
      dispatch(
        updateSiteDetails({
          pickLocation: newerLocation,
        })
      );
      if (craneOverlay.crane) {
        pickLine = generateLine(
          siteDetails.craneLocation,
          newerLocation,
          mapRef
        );
      }
      if (craneOverlay.set && craneDetails.selectedCrane.dimensions) {
        tailswing = generateObstacle(
          'polygon',
          drawArc(
            craneOverlay.crane.position,
            google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              newerLocation
            ) + 180,
            google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              craneOverlay.set.position
            ) + 180,
            craneDetails.selectedCrane.dimensions.tailSwing / 1000
          ),
          'swing'
        );
        tailswing.setMap(mapRef);
        dispatch(
          updateCraneDetails({
            pickAngle: google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              newerLocation
            ),
            setAngle: google.maps.geometry.spherical.computeHeading(
              craneOverlay.crane.position,
              craneOverlay.set.position
            ),
          })
        );
      }
      setCraneOverlay({ ...craneOverlay, pick: newPin, pickLine, tailswing });
      if (!craneOverlay.set) {
        dispatch(updateMode('pins'));
        dispatch(updateSiteDetails({ pinType: 'Set' }));
      } else {
        setInitialSetup(true);
      }
      dispatch(saveData());
    }
    if (
      pinType === 'Obstacle' ||
      pinType === 'Route' ||
      pinType === 'Overlay'
    ) {
      if (pinType === 'Overlay') {
        dispatch(setShowOverlay(false));
      }
      if (!livePolyline) {
        const newPolyline = new google.maps.Polyline(newPolylineOptions);
        newPolyline.setPath([mapRef.getCenter(), mapRef.getCenter()]);
        newPolyline.setMap(mapRef);
        newPolyline.marker = null;
        setCurrentPins([...currentPins, newPin]);
        setLivePolyline(newPolyline);
        return;
      }
      const currentPolyline = generateLine(
        livePolyline.getPath().getAt(0),
        mapRef.getCenter(),
        mapRef
      );
      currentPolyline.label.marker.setMap(null);
      livePolyline.setPath([
        livePolyline.getPath().getAt(1),
        mapRef.getCenter(),
      ]);
      setCurrentPolylines([...currentPolylines, currentPolyline]);
      setCurrentPins([...currentPins, newPin]);
    }
    if (mode === 'measurements') {
      setCurrentPins([...currentPins, newPin]);
      if (currentPins.length === 0) {
        newPin.setIcon(Pin1);
        const newPolyline = new google.maps.Polyline(newPolylineOptions);
        newPolyline.setPath([mapRef.getCenter(), mapRef.getCenter()]);
        newPolyline.setMap(mapRef);
        newPolyline.marker = null;
        setLivePolyline(newPolyline);
      } else if (currentPins.length === 1) {
        newPin.setIcon(Pin2);
        const measurementPoints = [
          currentPins[0].getPosition().toJSON(),
          newPin.getPosition().toJSON(),
        ];
        clearLivePolyline();
        dispatch(setMapPoints(measurementPoints));
      }
      dispatch(saveData());
    }
  };

  const setOverlay = (data) => {
    if (data === null) {
      dispatch(setOverlayDefault());
      return;
    }
    const { boundaries, degreeDifference } = generateDegreesAndBoundaries(
      mapRef,
      data
    );
    dispatch(setShowOverlay(true));
    dispatch(
      setOverlayData({
        overlayBounds: boundaries.toJSON(),
        deg: degreeDifference,
        rotationPoint: {
          x: data.point1.x,
          y: data.point1.y,
        },
      })
    );
    dispatch(
      setImage({
        imageLink: data.imageLink,
      })
    );
  };

  return (
    <SitePlanContainer>
      {!loaded ? (
        <LoaderBackground>
          <Loader style={{ position: 'absolute' }} />
        </LoaderBackground>
      ) : null}
      {radiusModal ? <Radius done={initialSetup} /> : null}
      <DeductionsModal />
      {diagramModal ? (
        <Diagram
          boomPinHeight={craneDetails.boomPinHeight}
          distances={Math.max(craneDetails.pickRadius, craneDetails.setRadius)}
          selectedAttachment={craneDetails.selectedAttachment}
          customObstacles={craneDetails.customObstacles}
          updateCraneDetails={(obstacles) =>
            dispatch(updateCraneDetails(obstacles))
          }
        />
      ) : (
        <Crosshairdiv />
      )}
      <Elevation
        mapRef={mapRef}
        selectedObstacle={selectedObstacle}
        obstacleOverlays={obstacleOverlays}
      />
      <InfoModal />
      <FilterModal />
      <ResetModal
        footprint={footprint}
        setFootprint={setFootprint}
        obstacleOverlays={obstacleOverlays}
        setObstacleOverlays={setObstacleOverlays}
        setCurrentPins={setCurrentPins}
        craneOverlay={craneOverlay}
        setCraneOverlay={setCraneOverlay}
        notesOverlays={notesOverlays}
        setNotesOverlays={setNotesOverlays}
        setInitialSetup={setInitialSetup}
      />
      <Overlay
        overlays={overlays}
        setOverlay={setOverlay}
        blank={blank}
        setBlank={setBlank}
      />
      <AddNote
        mapRef={mapRef}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
        notesOverlays={notesOverlays}
        setNotesOverlays={setNotesOverlays}
        note={note}
        setNote={setNote}
      />
      <CapacityToggle
        calculateDeductions={() =>
          calculateDeductions(
            grossCap,
            selectedBoomLength,
            true,
            (e) => dispatch(updateCraneDetails(e)),
            () => dispatch(saveData()),
            craneDetails,
            loadDetails,
            craneDetails.selectedCrane
          )
        }
        setInfoModal={() => dispatch(setInfoModal(true))}
        percentOfCap={percentOfCap}
        lowPercent={lowPercent}
        highPercent={highPercent}
      />
      <DiagramToggle
        diagramModal={diagramModal}
        selectedBoomLength={selectedBoomLength}
        setDiagramModal={(action) => dispatch(setDiagramModal(action))}
      />
      {!diagramModal && (
        <ControlsContainer>
          {defaultDimensions != null ? (
            <SitePlanControls
              done={initialSetup}
              handlePlacePin={handlePlacePin}
              currentPins={currentPins}
              confirmObstacle={confirmObstacle}
              sendImageData={sendImageData}
              mode={mode}
              pinType={siteDetails.pinType}
              angle={siteDetails.angle}
              setlocation={craneOverlay.set}
              setRadius={craneDetails.setRadius}
              pickRadius={craneDetails.pickRadius}
              radiusModal={craneDetails.radiusModal}
            />
          ) : null}
        </ControlsContainer>
      )}
      {siteDetails.mode === 'select' && !diagramModal && (
        <TheReset
          onClick={() => {
            dispatch(setResetModal(true));
          }}
        >
          <img src={ResetSVG} alt="Reset" />
          Reset
        </TheReset>
      )}
      <SiteplanMap
        siteOverlays={siteOverlays}
        basicInfo={basicInfo}
        mapRef={mapRef}
        setMapRef={setMapRef}
        footprint={footprint}
        setFootprint={setFootprint}
        setObstacleOverlays={setObstacleOverlays}
        setCraneOverlay={setCraneOverlay}
        setNotesOverlays={setNotesOverlays}
        livePolyline={livePolyline}
        setLivePolyline={setLivePolyline}
        livePreview={livePreview}
        setLivePreview={setLivePreview}
        setNote={setNote}
        setSelectedNote={setSelectedNote}
        match={match}
        setInitialSetup={setInitialSetup}
        blank={blank}
        setSelectedObstacle={setSelectedObstacle}
      />
    </SitePlanContainer>
  );
}
