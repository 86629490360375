import React from 'react';
import styled, { useTheme } from 'styled-components';
import LoginCrane from '../LoginCrane/LoginCrane';

const BannerTitle = styled.div`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 5px;
  margin-top: 100px;
  h2 {
    font-family: 'Roboto-Medium';
    font-size: 52px;
  }
  p {
    color: #f3b301;
    font-size: 32px;
  }
`;

const LoginBanner = styled.h1`
  background-color: ${({ theme }) => theme.colors.grey.dark};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const LoginBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const CraneSVG = styled.div`
  svg {
    width: 400px;
    height: auto;
  }
`;

const BannerTitleContainer = styled.div`
  padding-inline: 40px;
  padding-top: 10%;
`;

export default function LoginCraneBanner() {
  return (
    <LoginBannerContainer className="login-banner-container">
      <LoginBanner>
        <BannerTitleContainer className="banner-title">
          <BannerTitle>
            <h2>Welcome to Lift-Pro</h2>
            <p>
              Bringing safety, speed and simplicity to your crane operations.
            </p>
          </BannerTitle>
        </BannerTitleContainer>
        <CraneSVG className="crane-img-svg">
          <LoginCrane />
        </CraneSVG>
      </LoginBanner>
    </LoginBannerContainer>
  );
}
