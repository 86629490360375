import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import App from './App';
import reportWebVitals from './reportWebVitals';

const STYTCH_PUBLIC_TOKEN = process.env.REACT_APP_STYTCH_PUBLIC_TOKEN;

const stytch = new StytchUIClient(STYTCH_PUBLIC_TOKEN);

const root = createRoot(document.getElementById('root'));
root.render(
  <StytchProvider stytch={stytch}>
    <App />
  </StytchProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
