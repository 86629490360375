function validateEmail(email) {
  const emailRegX =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegX.test(email);
}

function validatePhoneNumber(number) {
  const phoneRegX = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/g;
  return phoneRegX.test(number);
}

// for checking positive valid number Ex: 123, 123.12
function isValidNumber(number) {
  if (number === '') {
    return true;
  }
  return /([0-9]+\.?[0-9]*|\.[0-9]+)/.test(number);
}

function isAlphaNumeric(string){
  return /^[a-zA-Z0-9_-]*$/.test(string)
}

export { validateEmail, validatePhoneNumber, isValidNumber, isAlphaNumeric };
