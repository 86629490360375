import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import Button from '../../reusables/Button';
import { useDispatch } from 'react-redux';
import { setFilterModal } from '../../context/liftpage';
import { updateCraneDetails } from '../../context/siteplan';
import Modal from '../../reusables/Modal/Modal';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 0px 24px;
`;

const Label = styled.p`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 124px;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
  border: 1px solid #3b4046;
  border-radius: 8px;
  font-size: 16px;
  width: 152px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

export default function FilterModal() {
  const [localLowPercent, setLocalLowPercent] = useState(0);
  const [localHighPercent, setLocalHighPercent] = useState(0);
  const { lowPercent, highPercent } = useSelector(
    (state) => state.site.craneDetails
  );
  const { filterModal } = useSelector((state) => state.page);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalLowPercent(lowPercent);
    setLocalHighPercent(highPercent);
  }, [lowPercent, highPercent]);

  const clampPercent = (num) => {
    if (num < 0) {
      return 0;
    } else if (num > 100) {
      return 100;
    }
    return num;
  };

  return (
    <Modal
      show={filterModal}
      modalStyles={theme.modals.info}
      handleClose={() => dispatch(setFilterModal(false))}
      title="Filter Charts by % Capacity"
    >
      <FilterContainer>
        <Row>
          <Label>Max Capacity (%)</Label>
          <Input
            id="highPercent"
            type="number"
            value={localHighPercent}
            min={0}
            max={100}
            onChange={(e) => setLocalHighPercent(clampPercent(e.target.value))}
          />
        </Row>
        <Row>
          <Label>Min Capacity (%)</Label>
          <Input
            id="lowPercent"
            type="number"
            value={localLowPercent}
            min={0}
            max={100}
            onChange={(e) => setLocalLowPercent(clampPercent(e.target.value))}
          />
        </Row>
        <ButtonContainer>
          <Button
            theme={theme.buttons.editJobWalk}
            onClick={() => dispatch(setFilterModal(false))}
          >
            Cancel
          </Button>
          <Button
            theme={theme.buttons.overlay}
            onClick={() => {
              dispatch(
                updateCraneDetails({
                  highPercent: localHighPercent,
                  lowPercent: localLowPercent,
                })
              );
              dispatch(setFilterModal(false));
            }}
          >
            Confirm
          </Button>
        </ButtonContainer>
      </FilterContainer>
    </Modal>
  );
}
