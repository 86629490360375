import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { updateSiteDetails } from '../../context/siteplan';
import { setElevationModal } from '../../context/liftpage';
import { saveData } from '../../helpers/saveData';
import Modal from '../../reusables/Modal/Modal';
import { generateElevation, packObstacles } from './sitePlanFunctions';
import Button from '../../reusables/Button';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Textarea = styled.textarea`
  border-radius: 8px;
  width: 100%;
  border: 1px solid #cfd4db;
  background: linear-gradient(180deg, #fff 0%, #f8fafc 100%);
  font-family: 'Roboto';
  font-size: 16px;
  padding: 10px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(17, 17, 17, 0.32);
    font-family: 'Roboto';
  }
`;

export default function Elevation({
  mapRef,
  selectedObstacle,
  obstacleOverlays,
}) {
  const [elevation, setElevation] = useState('');
  const { elevationModal } = useSelector((state) => state.page);

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleDeleteOverlay = () => {
    selectedObstacle.setMap(null);
    if (selectedObstacle.marker) {
      selectedObstacle.marker.setMap(null);
    }
    dispatch(setElevationModal(false));
    const remainingObstacles = obstacleOverlays.filter((obstacle) =>
      obstacle.map !== null ? obstacle : null
    );
    const packagedObstacles = packObstacles([...remainingObstacles]);
    dispatch(updateSiteDetails({ packagedObstacles }));
    dispatch(saveData());
    setElevation(null);
  };

  const handleSubmitElevation = () => {
    const generatedElevation = generateElevation(
      selectedObstacle,
      elevation,
      mapRef
    );
    const packagedObstacles = packObstacles(obstacleOverlays);
    dispatch(updateSiteDetails({ packagedObstacles }));
    dispatch(setElevationModal(false));
    dispatch(saveData());
  };

  return (
    <Modal
      show={elevationModal}
      modalStyles={theme.modals.elevation}
      handleClose={() => dispatch(setElevationModal(false))}
      title="Add Label"
    >
      <FlexRow>
        <Textarea
          value={elevation}
          onChange={(e) => setElevation(e.target.value)}
        />
      </FlexRow>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          theme={theme.buttons.resetJobWalk}
          onClick={() => handleDeleteOverlay()}
        >
          Delete
        </Button>
        <Button
          theme={theme.buttons.overlay}
          onClick={() => handleSubmitElevation()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
