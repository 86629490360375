import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useStytch } from '@stytch/react';
import LogoutIcon from '../../assets/images/Logout.svg';
import Slider from '../../reusables/Slider';
import DropdownSelect from '../../reusables/InputDropdown';
import Pie from '../../assets/images/pie chart.svg';
import Button from '../../reusables/Button';
import { saveData } from '../../helpers/saveData';
import { updateCraneDetails, setOverlayDefault } from '../../context/siteplan';
import calculateDeductions from '../../reusables/DeductionCalc';
import {
  setDiagramModal,
  setDeductionModal,
  setFilterModal,
  setNotification,
} from '../../context/liftpage';
import FilledPlus from '../../assets/images/FilledPlus.svg';
import Filter from '../../assets/images/Filter.svg';

const Side = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => (1 ? theme.colors.grey.dark : 'white')};
  min-width: 200px;
  width: 200px;
  position: static;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.53);
  height: 100%;
`;

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  select {
    width: 168px;
    margin-bottom: 10px;
  }
  p {
    color: ${({ theme }) => theme.colors.yellow.base};
  }
`;

const Check = styled.p`
  position: absolute;
  font-size: 24px;
  top: 29px;
  right: 24px;
`;

const SmallContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CompanyName = styled.div`
  width: 100%;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.grey.xdark};
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-size: 16px;
  p {
    color: white;
    margin-bottom: 8px;
    font-size: 16px;
  }
  p:nth-child(2) {
    color: ${({ theme }) => theme.colors.yellow.base};
    font-size: 12px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  margin-top: 12px;
  width: 100%;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.grey.xdark};
  border-left: 4px solid
    ${({ theme, step }) =>
      !step ? theme.colors.yellow.base : theme.colors.grey.xdark};
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    img {
      margin-left: 24px;
      height: 16px;
      width: 16px;
    }
    p {
      margin-left: 8px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.yellow.base};
    }
  }
`;

const Logout = styled.button`
  margin-top: auto;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey.dark};
  border: none;
  border-top: 1px solid black;
  img {
    margin-left: 24px;
  }
  p {
    margin-left: 8px;
    font-size: 16px;
    color: white;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

export default function SideBar() {
  const { companyName, step } = useSelector((state) => state.user);
  const {
    selectedOffset,
    allowableOffsets,
    min,
    selectedCounterWeight,
    boomsAndCapacities,
    attachments,
    selectedCrane,
    selectedBoomLength,
    selectedAttachment,
    lowPercent,
    highPercent,
    pickRadius,
    setRadius,
  } = useSelector((state) => state.site.craneDetails);
  const { craneList } = useSelector((state) => state.site);
  const { loadDetails, craneDetails } = useSelector((state) => state.site);
  const [allCounterweights, setAllCounterweights] = useState([]);
  const [sliderValue, setSliderValue] = useState(selectedOffset);

  // The next couple useStates are for the checkmarks
  // The Shown booleans are toggled when the user clicks on the dropdown
  // The arrays are used to store the good values that get checks
  const [craneShown, setCraneShown] = useState(false);
  const [goodCranes, setGoodCranes] = useState([]);
  const [attachmentShown, setAttachmentShown] = useState(false);
  const [goodAttachments, setGoodAttachments] = useState([]);
  const [counterweightShown, setCounterweightShown] = useState(false);
  const [goodCounterweights, setGoodCounterweights] = useState([]);
  const [boomShown, setBoomShown] = useState(false);
  const [goodBooms, setGoodBooms] = useState([false]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const stytch = useStytch();

  function loadChartIsCompatible(chart, toDispatch) {
    if (typeof chart === 'undefined') return false;
    // get max operating radius
    const newDistances = [pickRadius, setRadius].map((d) => Number(d));
    const maxOperatingRad = Math.round(Math.max(...newDistances));
    // get minimum operating radius
    const minOperatingRad = Math.round(Math.min(...newDistances));
    // get all possible radii in this load chart
    let radii = Object.keys(chart);
    radii = radii.map((r) => Number(r));
    radii = radii.filter((r) => !Number.isNaN(r));
    // detect case where crane can't reach load that is too far from it
    const maxCraneReach = Math.max(...radii);
    if (maxCraneReach < maxOperatingRad) {
      if (toDispatch)
        dispatch(
          setNotification({
            text: 'This boom configuration cannot pick at the maximum operating radius, please try a different configuration',
            type: 'error',
          })
        );
      return false;
    }
    // detect case where crane config can't reach load that is too close to it
    const minCraneReach = Math.min(...radii);
    if (minCraneReach > minOperatingRad) {
      if (toDispatch)
        dispatch(
          setNotification({
            text: 'This boom configuration cannot pick at the minimum operating radius, please try a different boom configuration',
            type: 'error',
          })
        );
      return false;
    }
    return true;
  }

  function getRowFromChart(chart) {
    // find the row in chart corresponding to the max operating radius
    const newDistances = [pickRadius, setRadius].map((d) => Number(d));
    let maxRadRounded = Math.round(Math.max(...newDistances));
    while (!(maxRadRounded in chart)) {
      if (maxRadRounded > 600) {
        console.log('too long');
        break;
      }
      maxRadRounded += 1;
    }
    const chartRow = chart[maxRadRounded];
    return {
      maxOperatingRadius: maxRadRounded,
      boomsAndCapacities: chartRow,
    };
  }

  const getLoadCharts = (counterweight = null, offset = null) => {
    // This is used mostly by counterweight to get the correct loadchart data to load
    // into Redux
    let target = null;
    let theOffset = selectedOffset;
    if (offset !== null) {
      theOffset = offset;
    }
    const range = allowableOffsets.map((offset) =>
      offset.split('-').map((offsetRange) => parseInt(offsetRange))
    );
    // Filter the angle offset based on the loadchart
    if (theOffset !== 0) {
      target = range.filter((offset) => {
        if (offset.length === 0) {
          return [];
        }
        if (offset[0] <= theOffset && offset[1] >= theOffset) {
          return offset;
        }
        if (offset[0] === theOffset) {
          return offset;
        }
      });
    } else {
      target = [theOffset];
    }
    while (Array.isArray(target[0])) {
      target = target[0];
    }
    // Make sure the loadcharts match the offset and counterweight
    const theChart = selectedCrane.loadCharts.filter((chart) => {
      let isMatching = false;
      let chartOffset = chart.attachmentOffset.split('-');
      if (target.length !== chartOffset.length) return null;
      if (target.length > 1) {
        if (
          parseInt(chartOffset[0]) === target[0] &&
          parseInt(chartOffset[1]) === target[1]
        ) {
          isMatching = true;
        }
      } else {
        if (parseInt(chartOffset[0]) === target[0]) {
          isMatching = true;
        }
        chartOffset = [parseInt(chartOffset[0])];
      }
      if (counterweight === null) {
        counterweight = selectedCounterWeight;
      }
      const counterweightToTest = Array.isArray(chart.counterweight)
        ? chart.counterweight[0]
        : chart.counterweight;
      if (
        chart.attachment.name === selectedAttachment.name &&
        isMatching === true &&
        counterweightToTest === counterweight
      ) {
        return chart;
      }
    });
    if (theChart.length > 1) {
      console.error('found more than one chart for this configuration');
    }
    if (theChart.length === 0) {
      console.error('no loadchart found');
      return null;
    }
    // set chosen load chart
    if (loadChartIsCompatible(theChart[0], true)) {
      dispatch(
        updateCraneDetails({
          selectedLoadChart: theChart[0],
          ...getRowFromChart(theChart[0]),
        })
      );
      dispatch(saveData());
      if (
        selectedBoomLength !== 'default' &&
        selectedBoomLength !== null &&
        selectedBoomLength !== undefined
      ) {
        const BAC = getRowFromChart(theChart[0]).boomsAndCapacities;
        const theBoomLength = Object.keys(BAC).find(
          (op) => op === selectedBoomLength
        );
        const grossCap =
          BAC[Object.keys(BAC).find((op) => op === theBoomLength)];
        dispatch(
          updateCraneDetails({
            selectedBoomLength: theBoomLength,
            grossCap,
          })
        );
        calculateDeductions(
          grossCap,
          theBoomLength,
          true,
          (e) => dispatch(updateCraneDetails(e)),
          () => dispatch(saveData()),
          craneDetails,
          loadDetails,
          selectedCrane
        );
      }
      return {
        selectedLoadChart: theChart[0],
        ...getRowFromChart(theChart[0]),
      };
    }
    return null;
  };

  useEffect(() => {
    // The first useEffect that gets run when you open a job walk
    // First we make sure there is a craneList before moving on to the calcs
    if (craneList) {
      const cra = [];
      craneList.map((craneInfo) => {
        // We check every crane to see if there is at least one good loadchart
        let found = null;
        for (let i = 0; i < craneInfo.loadCharts.length; i += 1) {
          // Check if the loadchart is compatible with the job walk
          if (loadChartIsCompatible(craneInfo.loadCharts[i])) {
            // isWithinRange returns true if there is a good loadchart
            found = isWithinRange(craneInfo.loadCharts[i], craneInfo);
            if (found) {
              break;
            }
          }
        }
        // If a good loadchart is found, add it to the good cranes array
        if (found) {
          cra.push(craneInfo.id);
        }
      });
      setGoodCranes(cra);
    }
    // This runs whenever site critical values are changed, craneList is here to run
    // before anything else and when new data is fetched
  }, [craneList, lowPercent, highPercent, setRadius, pickRadius]);

  useEffect(() => {
    // Second dropdown useEffect
    const att = [];
    attachments.map((attachment) => {
      // Checking every attachment to see if there is at least one good loadchart
      let found = null;
      for (let i = 0; i < selectedCrane.loadCharts.length; i += 1) {
        if (loadChartIsCompatible(selectedCrane.loadCharts[i])) {
          // Make sure the attachment is part of the crane loadchart
          if (
            selectedCrane.loadCharts[i].attachment.name === attachment.name &&
            !found
          ) {
            found = isWithinRange(selectedCrane.loadCharts[i], selectedCrane);
          }
        }
      }
      // If a good loadchart is found, add it to the good attachments array
      if (found) {
        att.push(attachment.name);
      }
    });
    setGoodAttachments(att);
    // This runs whenever you change the selectedCrane, or the range of capacity
  }, [attachments, selectedCrane, lowPercent, highPercent]);

  useEffect(() => {
    // Counterweights are more complicated because they depend on the current offset
    const theObj = {};
    let availableCharts = [];
    const counts = [];
    const allcounters = [];
    if (selectedCrane?.loadCharts) {
      // First we create the availableCharts that filters out charts that are out of
      // the current offset range
      availableCharts = selectedCrane.loadCharts.filter((chart) => {
        // Offset is a string with a ° in it so we have to parse that
        const offsets = chart.attachmentOffset
          .split('-')
          .map((offsetRange) => parseInt(offsetRange));
        // Making sure the chart is within the offset
        if (
          chart.attachment.name === selectedAttachment.name &&
          !theObj[chart.counterweight] &&
          (offsets.length === 1
            ? selectedOffset === offsets[0]
            : selectedOffset >= offsets[0] && selectedOffset <= offsets[1])
        ) {
          theObj[chart.counterweight] = true;
          return chart;
        }
      });
    }
    // Now we take that array and loop through the counterweights
    availableCharts.map((counterweight) => {
      const found = isWithinRange(counterweight, selectedCrane);
      // If a counterweight is within the percent range, add it to the good array
      if (found) {
        counts.push(counterweight.counterweight);
      }
      // Even if it isn't good, add that to the general counterweight array
      // This lets us filter by offset
      allcounters.push(counterweight.counterweight);
    });
    setGoodCounterweights(counts);
    setAllCounterweights(allcounters);
    // After selecting attachment, this is run
  }, [selectedOffset, selectedAttachment, lowPercent, highPercent]);

  useEffect(() => {
    // Booms are the last dropdown useEffect to be run
    const bs = [];
    Object.keys(boomsAndCapacities).map((booms) => {
      const grossCap = boomsAndCapacities[booms];
      // Since booms are the last, we don't need to use the isWithinRange function
      // and can just call calculateDeductions directly to get the result
      const percCap = calculateDeductions(
        grossCap,
        booms,
        false,
        (e) => dispatch(updateCraneDetails(e)),
        () => dispatch(saveData()),
        craneDetails,
        loadDetails,
        craneDetails.selectedCrane
      );
      // If it is within the capacity range, add it to the good array
      if (
        parseFloat(percCap) <= highPercent &&
        parseFloat(percCap) >= lowPercent
      ) {
        bs.push(booms);
      }
    });
    setGoodBooms(bs);
    // This is run whenever you change the counterweight or mess with the offset slider
  }, [selectedOffset, selectedCounterWeight, lowPercent, highPercent]);

  const handleCraneDropdown = (e) => {
    // This function is run whenever you select a new crane from the dropdown
    setCraneShown(false);
    // If there is no crane selected, reset to default values
    // This should never be possible by the user and only when resetting a siteplan
    if (e.target.value === 'default') {
      dispatch(
        updateCraneDetails({
          selectedCrane: 'default',
          attachments: [],
          counterWeights: [],
          boomsAndCapacities: [],
          selectedAttachment: 'default',
          selectedCounterWeight: 'default',
          selectedBoomLength: null,
        })
      );
      dispatch(saveData());
    } else {
      // If the user selected a good crane, parse all the data from the craneList
      const theSelectedCrane = craneList.find(
        (item) => item.id === e.target.value
      );
      const { dimensions } = theSelectedCrane;
      const width = dimensions.bodyWidth;
      const { boomPinHeight } = dimensions;
      const frontRig = dimensions.frontToFrontOutrig;
      const height = dimensions.length;
      const { fullWidth } = dimensions;
      const centerPin = dimensions.frontToCenterpin;
      const backRig = dimensions.frontToRearOutrig;
      const { padLength, padWidth } = theSelectedCrane;
      const { parentId } = theSelectedCrane;
      const { hookBlocks } = theSelectedCrane;
      const theAttachments = theSelectedCrane.attachments;
      const footprintDimensions = {
        height,
        width,
        centerPin,
        frontRig,
        backRig,
        fullWidth,
        parent: parentId,
        padLength,
        padWidth,
        pickRadius,
        setRadius,
      };
      dispatch(setDiagramModal(false));
      // Update Redux with the selected crane data
      dispatch(
        updateCraneDetails({
          attachmentStatus: 'absent',
          attachmentExtension: 'default',
          selectedCrane: theSelectedCrane,
          percentOfCap: null,
          selectedAttachment: 'default',
          selectedCounterWeight: 'default',
          boomsAndCapacities: {},
          boomPinHeight,
          selectedBoomLength: 'default',
          attachments: theAttachments,
          units: theSelectedCrane.units,
          hookBlocks,
          footprintDimensions,
          rotation: '360',
          counterweights: theSelectedCrane.counterweights,
        })
      );
      dispatch(saveData());
    }
  };

  const isWithinRange = (loadChart, crane) => {
    // This function is run in so many places but most notably to filter dropdowns for
    // displying checkmarks
    // This checks if you sent a good loadchart before proceeding
    if (!loadChartIsCompatible(loadChart)) return false;
    const booms = getRowFromChart(loadChart);
    if (!booms.boomsAndCapacities) {
      return false;
    }
    // Creates an array of the boom lengths and the capacities for each
    const theArray = Object.keys(booms.boomsAndCapacities);
    for (let i = 0; i < theArray.length; i += 1) {
      const grossCap = booms.boomsAndCapacities[theArray[i]];
      // Runs the calculateDeductions function and returns true if it is within the
      // percent range
      const percCap = calculateDeductions(
        grossCap,
        theArray[i],
        false,
        (e) => dispatch(updateCraneDetails(e)),
        () => dispatch(saveData()),
        craneDetails,
        loadDetails,
        crane
      );
      if (
        parseFloat(percCap) <= highPercent &&
        parseFloat(percCap) >= lowPercent
      ) {
        return true;
      }
    }
    return false;
  };

  const counterweightOffsetCheck = (offset) => {
    // This function is run whenever you change the offset to display the checkmark
    // for the angle the user chooses
    let availableCharts = [];
    let found = false;
    if (selectedCrane?.loadCharts) {
      // Checks the selected crane's loadcharts and filter any that are not in the offset
      // range
      availableCharts = selectedCrane.loadCharts.filter((chart) => {
        const offsets = chart.attachmentOffset
          .split('-')
          .map((offsetRange) => parseInt(offsetRange));
        if (
          chart.attachment.name === selectedAttachment.name &&
          (offsets.length === 1
            ? offset === offsets[0]
            : offset >= offsets[0] && offset <= offsets[1])
        ) {
          return chart;
        }
      });
    }
    // Checks those filtered loadcharts to see if there is at least one good chart
    for (const chart of availableCharts) {
      if (isWithinRange(chart, selectedCrane) && !found) {
        found = true;
        break;
      }
    }
    return found;
  };

  const populateCraneList = () =>
    craneList.map((craneInfo) => {
      // Small function to create the crane dropdown list from craneList
      if (goodCranes.includes(craneInfo.id)) {
        return (
          <option key={craneInfo.id} value={`${craneInfo.id}`}>
            {craneInfo.model} {craneShown && '✅'}
          </option>
        );
      }
      return (
        <option key={craneInfo.id} value={`${craneInfo.id}`}>
          {craneInfo.model}
        </option>
      );
    });

  const handleAttachmentsDropdown = (e) => {
    setAttachmentShown(false);
    const attachment = attachments.filter(
      (attachment) => attachment.name === e.target.value
    );
    // Function to save the selected attachment data
    dispatch(
      updateCraneDetails({
        attachmentStatus: 'absent',
        attachmentExtension: 'default',
        selectedAttachment: attachment[0],
        mainLinePresent: attachment[0]?.name === 'Main Boom' || 0,
        auxLinePresent: attachment[0]?.name !== 'Main Boom' || 0,
        selectedCounterWeight: 'default',
        selectedBoomLength: 'default',
        percentOfCap: null,
        boomsAndCapacities: [],
        selectedOffset: parseInt(attachment[0].allowableOffsets[0]),
        min: parseInt(attachment[0].allowableOffsets[0]),
        allowableOffsets: attachment[0].allowableOffsets,
      })
    );
    dispatch(saveData());
    dispatch(setDiagramModal(false));
  };

  const handleSlider = (e) => {
    // Very annoying - Enrico
    // The slider value is not the same as the selected offset value because
    // sometimes the loadcharts have angle ranges that we have to deal with
    // This is for slider control and creates the variables used in the rounding
    setSliderValue(parseInt(e.target.value));
    const temprange = allowableOffsets.map((offset) => offset.split('-'));
    let value = parseInt(e.target.value);

    // I'm like 99% sure I copied this from stack overflow
    // This takes the current value and rounds it to the closest range or acceptable
    // offset value
    if (!temprange[temprange.length - 1][1]) {
      const indexArr = temprange.map((k) =>
        Math.abs(parseInt(k) - parseInt(e.target.value))
      );
      const min = Math.min.apply(Math, indexArr);
      value = parseInt(temprange[indexArr.indexOf(min)]);
    }

    // Save that rounded value to redux and update the loadcharts
    dispatch(
      updateCraneDetails({
        selectedOffset: value,
      })
    );
    if (
      selectedCounterWeight !== 'default' &&
      selectedBoomLength !== 'default' &&
      value !== selectedOffset
    ) {
      getLoadCharts(null, value);
    }
  };

  const createOffsetDropdown = () => {
    // This creates the slider and makes sure the max and min stay within range
    // of the allowableOffsets per attachment
    if (selectedAttachment?.name === 'Main Boom') {
      return null;
    }
    let max = 0;
    // Creates max and min ranges
    if (allowableOffsets) {
      const temprange = allowableOffsets.map((offset) => offset.split('-'));
      if (temprange.length > 1) {
        max = parseInt(temprange[temprange.length - 1][1]);
        if (!max) {
          max = parseInt(temprange[temprange.length - 1]);
        }
      }
    }
    // Renders the slider with a ✅ if the loadchart is good
    return (
      <>
        <p>Offset</p>
        <SliderContainer>
          {`${selectedOffset} degrees ${
            counterweightOffsetCheck(selectedOffset) ? '✅' : ''
          }`}
          <Slider
            sliderValue={sliderValue}
            min={min}
            max={max}
            step={1}
            handleSlider={handleSlider}
          />
        </SliderContainer>
      </>
    );
  };

  const handleCounterWeightDropdown = (e) => {
    // Function that runs whenever counterweight is changed
    setCounterweightShown(false);
    const theCounterWeight = e.target.value;
    const result = getLoadCharts(e.target.value);
    dispatch(setDiagramModal(false));
    // If there is a loadchart with the counterweight, save all that data and reset boom
    if (result) {
      dispatch(
        updateCraneDetails({
          selectedCounterWeight: theCounterWeight,
          selectedBoomLength: 'default',
          percentOfCap: null,
          ...result,
        })
      );
    } else {
      dispatch(
        updateCraneDetails({
          selectedCounterWeight: theCounterWeight,
          percentOfCap: null,
          boomsAndCapacities: [],
          selectedBoomLength: 'default',
        })
      );
    }
    dispatch(saveData());
  };

  const handleBoomDropdown = (e) => {
    // Runs whenever boom is changed
    setBoomShown(false);
    if (e.target.value === 'default') {
      dispatch(
        updateCraneDetails({
          selectedBoomLength: null,
        })
      );
      dispatch(saveData());
      return;
    }
    // This takes the boom length and calculated a grossCap which is then used to save the
    // percent of capacity for the first time
    const theBoomLength = Object.keys(boomsAndCapacities).find(
      (op) => op === e.target.value
    );
    const grossCap =
      boomsAndCapacities[
        Object.keys(boomsAndCapacities).find((op) => op === e.target.value)
      ];
    dispatch(
      updateCraneDetails({
        selectedBoomLength: theBoomLength,
        grossCap,
      })
    );
    calculateDeductions(
      grossCap,
      theBoomLength,
      true,
      (e) => dispatch(updateCraneDetails(e)),
      () => dispatch(saveData()),
      craneDetails,
      loadDetails,
      craneDetails.selectedCrane
    );
  };

  const renderDropdowns = (
    <DropdownContainer>
      <Button
        className="deductions-button"
        theme={theme.buttons.addDeductions}
        style={{ marginBottom: '10px', marginTop: '10px' }}
        onClick={() => {
          dispatch(setFilterModal(true));
        }}
      >
        <img src={Filter} /> &nbsp; Filter Capacity
      </Button>
      {pickRadius && setRadius ? (
        <SmallContainer>
          <p>Crane</p>
          <DropdownSelect
            onClick={() => setCraneShown(true)}
            onFocus={() => setCraneShown(true)}
            onBlur={() => setCraneShown(false)}
            onTouchStart={() => setCraneShown(true)}
            onChange={(e) => {
              document.activeElement.blur();
              handleCraneDropdown(e);
              dispatch(setDiagramModal(false));
            }}
            value={selectedCrane?.id || 'default'}
          >
            <option value="default" disabled hidden>
              Select Crane
            </option>
            {populateCraneList()}
          </DropdownSelect>
          {goodCranes.includes(selectedCrane.id) && <Check>✅</Check>}
        </SmallContainer>
      ) : null}
      {selectedCrane !== 'default' ? (
        <SmallContainer>
          <p>Attachments</p>
          <DropdownSelect
            onClick={() => setAttachmentShown(true)}
            onTouchStart={() => setAttachmentShown(true)}
            onFocus={() => setAttachmentShown(true)}
            onBlur={() => setAttachmentShown(false)}
            onChange={(e) => {
              document.activeElement.blur();
              handleAttachmentsDropdown(e);
              dispatch(setDiagramModal(false));
            }}
            value={selectedAttachment?.name || 'default'}
          >
            <option value="default" disabled hidden>
              Select Attachment
            </option>
            {attachments.map((attachment) => {
              if (goodAttachments.includes(attachment.name)) {
                return (
                  <option key={attachment.name} value={attachment.name}>
                    {attachment.name} {attachmentShown && '✅'}
                  </option>
                );
              }
              return (
                <option key={attachment.name} value={attachment.name}>
                  {attachment.name}
                </option>
              );
            })}
          </DropdownSelect>
          {goodAttachments.includes(selectedAttachment.name) && (
            <Check>✅</Check>
          )}
        </SmallContainer>
      ) : null}
      {selectedAttachment !== 'default' ? createOffsetDropdown() : null}
      {selectedAttachment !== 'default' ? (
        <SmallContainer>
          <p>Counterweight</p>
          <DropdownSelect
            onClick={() => setCounterweightShown(true)}
            onBlur={() => setCounterweightShown(false)}
            onFocus={() => setCounterweightShown(true)}
            onTouchStart={() => setCounterweightShown(true)}
            onChange={(e) => {
              document.activeElement.blur();
              handleCounterWeightDropdown(e);
              dispatch(setDiagramModal(false));
            }}
            value={selectedCounterWeight || 'default'}
          >
            <option value="default" disabled hidden>
              Select Counterweight
            </option>
            {allCounterweights.map((counterweight) => {
              if (goodCounterweights.includes(counterweight)) {
                return (
                  <option value={counterweight} key={counterweight}>
                    {counterweight} {counterweightShown && '✅'}
                  </option>
                );
              }
              return (
                <option value={counterweight} key={counterweight}>
                  {counterweight}
                </option>
              );
            })}
          </DropdownSelect>
          {goodCounterweights.includes(selectedCounterWeight) && (
            <Check>✅</Check>
          )}
        </SmallContainer>
      ) : null}
      {selectedCounterWeight !== 'default' &&
      Object.keys(boomsAndCapacities).length &&
      setRadius ? (
        <SmallContainer>
          <p>Boom Length</p>
          <DropdownSelect
            onClick={() => setBoomShown(true)}
            onBlur={() => setBoomShown(false)}
            onFocus={() => setBoomShown(true)}
            onTouchStart={() => setBoomShown(true)}
            onChange={(e) => {
              document.activeElement.blur();
              handleBoomDropdown(e);
            }}
            value={selectedBoomLength || 'default'}
          >
            <option value="default" disabled hidden>
              Boom Length
            </option>
            {Object.keys(boomsAndCapacities).map((booms) => {
              if (goodBooms.includes(booms)) {
                return (
                  <option value={booms} key={booms}>
                    {booms} {boomShown && '✅'}
                  </option>
                );
              }
              return (
                <option value={booms} key={booms}>
                  {booms}
                </option>
              );
            })}
          </DropdownSelect>
          {goodBooms.includes(selectedBoomLength) && <Check>✅</Check>}
        </SmallContainer>
      ) : null}
      {selectedBoomLength !== 'default' ? (
        <Button
          className="deductions-button"
          theme={theme.buttons.addDeductions}
          style={{ marginBottom: '10px', marginTop: '10px' }}
          onClick={() => {
            dispatch(setDeductionModal(true));
          }}
        >
          <img src={FilledPlus} /> &nbsp; Add Deductions
        </Button>
      ) : null}
    </DropdownContainer>
  );

  // Render buttons when not on siteplan
  return (
    <Side>
      {step !== 3 ? (
        <CompanyName>
          <p>{companyName}</p>
          <p>by Sarus Lifting</p>
        </CompanyName>
      ) : null}
      <MenuItem step={step}>
        <Link
          to="/home"
          onClick={() => {
            dispatch(setDiagramModal(false));
            dispatch(setOverlayDefault());
          }}
        >
          <img alt="pie" src={Pie} />
          <p>Dashboard</p>
        </Link>
      </MenuItem>
      {step === 3 ? renderDropdowns : null}
      <Logout onClick={() => stytch.session.revoke()}>
        <img alt="credit" src={LogoutIcon} />
        <p>Logout</p>
      </Logout>
    </Side>
  );
}
