import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useStytch } from '@stytch/react';
import styled, { useTheme } from 'styled-components/macro';
import { API_URL } from '../../services/api';
import Button from '../../reusables/Button';
import { validateEmail, validatePhoneNumber } from '../../helpers/validate';
import Loader from '../../reusables/Loader/Loader';
import Input from '../../reusables/InputBar';

const innerHeight = window.innerHeight;

const Title = styled.h1`
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: Roboto-Medium;
  font-size: 30px;
`;

const SignupContainer = styled.div`
  font-size: 12px;
  a {
    margin-left: 5px;
    font-weight: bolder;
    text-decoration: none;
  }
  margin-bottom: 20px;
`;

const SignupFormContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  width: 100vw;
  height: calc(${innerHeight}px - 64px);
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 100px;
    overflow: auto;
    padding-right: 100px;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  p {
    margin-bottom: 8px;
    font-size: 12px;
    align-self: start;
  }
  button {
    position: absolute;
    top: 40px;
    right: 10px;
    border: none;
    background: none;
  }
`;

const Banner = styled.div`
  width: 100%;
  position: absolute;
  height: 67px;
  text-align: center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.5s;
  color: #b50614;
  background-color: #f2d5d7;
`;

function SignUpForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [company, setCompany] = useState('');
  const theme = useTheme();
  const stytchClient = useStytch();
  const [isDisplayed, setIsDisplayed] = useState(1);
  const [notifText, setNotifText] = useState('');

  const notification = (text) => {
    setNotifText(text);
    setIsDisplayed(0);
    setTimeout(() => {
      setIsDisplayed(1);
    }, 5000);
  };

  const callSignupService = () => {
    // console.log(name, email, password, company, phoneNo, countryCode);
    if (validateSignUpForm()) {
      setIsLoading(true);
      try {
        stytchClient.passwords
          .create({
            email,
            password,
            session_duration_minutes: 60,
          })
          .then(async (result) => {
            axios
              .post(`${API_URL}/user/signup`, {
                _id: result.user_id,
                username: name,
                email,
                company,
                phoneNo,
                countryCode,
              })
              .then(() => {
                props.history.push('/');
              });
          });
      } catch (err) {
        console.log('Error occured while creating account: ', err);
      }
      setIsLoading(false);
    }
  };

  const validateSignUpForm = () => {
    let flag = true;
    // console.log("======validatePhoneNumber(phoneNo)======", validatePhoneNumber(phoneNo))

    if (name.length === 0) {
      notification('Name should not be empty');
      flag = false;
    } else if (!validateEmail(email)) {
      notification('Please enter a valid email');
      flag = false;
    } else if (company.length === 0) {
      notification('Company name cannot be empty');
      flag = false;
    } else if (password.length === 0) {
      notification('Password cannot be empty');
      flag = false;
    } else if (confirmPassword.length === 0) {
      notification('Confirm password cannot be empty');
      flag = false;
    } else if (password !== confirmPassword) {
      notification("Password doesn't match!");
      flag = false;
    } else if (!validatePhoneNumber(phoneNo)) {
      notification(
        'Please enter a valid phone number in the given format Ex: 123-456-7890'
      );
      flag = false;
    }

    return flag;
  };

  return (
    <SignupFormContainer>
      <Banner
        style={{
          top: isDisplayed * -67,
        }}
      >
        {notifText}
        <i
          className="fas fa-times"
          onClick={() => setIsDisplayed(1)}
          style={{
            cursor: 'pointer',
            fontSize: 25,
            color: 'black',
            position: 'absolute',
            right: 30,
          }}
        />
      </Banner>
      <Title>Create an Account</Title>
      <InputContainer>
        <p>Full Name</p>
        <Input
          placeholder="Enter full name"
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <p>Email Address</p>
        <Input
          placeholder="Enter email address"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
      </InputContainer>
      <InputContainer>
        <p>Company Name</p>
        <Input
          placeholder="Enter Company Name"
          type="text"
          name="company_name"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <p>Phone Number</p>
        <Input
          placeholder="123-456-7890"
          id="phone-number-field"
          name="phone_number"
          value={phoneNo}
          onChange={(e) => setPhoneNo(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <p>Set a password</p>
        <Input
          placeholder="Set Password"
          type={isPasswordVisible ? 'text' : 'password'}
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <p>Confirm password</p>
        <Input
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </InputContainer>
      <div>
        <Button
          theme={theme.buttons.login}
          style={{ width: '256px', marginBottom: '20px' }}
          disabled={isLoading}
          onClick={() => callSignupService()}
        >
          {isLoading ? <Loader /> : 'Create an account'}
        </Button>
      </div>
      <SignupContainer>
        Already have an account?{' '}
        <Link
          style={{ color: theme.light.colors.highlight.primary }}
          to="/login"
        >
          Login
        </Link>
      </SignupContainer>
    </SignupFormContainer>
  );
}

export default SignUpForm;
