import React from 'react';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import Routes from './routes';
import theme from './theme';
import GlobalStyles from './theme/global.style';
import { DEVELOPMENT } from './services/api';

if (DEVELOPMENT === 'false') {
  Sentry.init({
    dsn: 'https://1015fa07f3719a2b0f93ecd370b2479c@o4504062719557632.ingest.sentry.io/4505880632426496',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
