import styled from 'styled-components/macro';

const Input = styled.input`
  width: 100%;
  font-size: 12px;
  border: 1px solid lightgray;
  padding: 10px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  height: 56px;
  border-radius: 5px;
`;

export default Input;
