import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Button from '../../reusables/Button';
import InputContainer from '../../reusables/Input';
import { API_URL } from '../../services/api';
import authHeader from '../../services/auth-header';
import Company from './Company';

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
`;

export default function Admin() {
  const [newCompany, setNewCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  // load all the companies
  useEffect(() => {
    axios
      .get(`${API_URL}/company/all`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCompanies(response.data.companies);
      });
  }, []);

  const handleOnClick = (company) => {
    setSelectedCompany(company);
  };

  return (
    <AdminContainer>
      {companies.length
        ? companies.map((company) => (
            <Company
              company={company}
              selectedCompany={selectedCompany}
              onClick={handleOnClick}
              setCompanies={setCompanies}
            />
          ))
        : null}
      <InputContainer>
        <input
          style={{ marginTop: '10px', marginBottom: '20px' }}
          placeholder="Add New Company"
          type="text"
          value={newCompany}
          onChange={(e) => {
            setNewCompany(e.target.value);
          }}
        />
      </InputContainer>
      <Button
        style={{ margin: '20px auto' }}
        onClick={() => {
          axios.post(
            `${API_URL}/company/create`,
            { name: newCompany },
            {
              headers: authHeader(),
            }
          );
          setCompanies((current) => [...current, newCompany]);
        }}
      >
        Add New Company
      </Button>
    </AdminContainer>
  );
}
