import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { removeLeadingZeroes } from '../../helpers/utils';
import InputContainer from '../../reusables/Input';
import Down from '../../assets/images/arrow-ios-downward.svg';
import {
  siteplanValue,
  updateLoadDetails,
  updateCraneDetails,
} from '../../context/siteplan';

const LoadDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 27px;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 53px;
    padding-right: 53px;
  }
`;
const Req = styled.span`
  color: #b50614;
`;

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 3;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  @media (min-width: 768px) {
    select {
      height: 56px;
    }
  }
`;

const DimensionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: normal;
`;

const InputGrouping = styled.div`
  width: 48%;
`;

const Title = styled.h1`
  font-size: 32px;
  flex-grow: 1;
  line-height: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px;
`;

export default function LoadDetails() {
  const { loadDetails } = useSelector((state) => state.site);
  const dispatch = useDispatch();

  return (
    <LoadDetailsContainer>
      <TitleContainer>
        <Title>Load Details</Title>
      </TitleContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <InputGrouping>
          <InputContainer>
            <p>
              Load Description<Req>&nbsp;*</Req>
            </p>
            <input
              placeholder="Enter load description"
              type="text"
              value={loadDetails.loadDescription || ''}
              onChange={(e) =>
                dispatch(updateLoadDetails({ loadDescription: e.target.value }))
              }
            />
          </InputContainer>
        </InputGrouping>
        <InputGrouping>
          <InputContainer>
            <p>
              Load Weight (lbs)<Req>&nbsp;*</Req>
            </p>
            <input
              placeholder="Enter weight"
              type="number"
              value={loadDetails.loadWeight || 0}
              onChange={(e) => {
                if (
                  !Number.isNaN(e.target.value) &&
                  e.target.value.length !== 0
                ) {
                  const weight = removeLeadingZeroes(e.target.value);
                  dispatch(updateLoadDetails({ loadWeight: weight.trim() }));
                  dispatch(
                    siteplanValue({
                      loadWeight: weight.trim(),
                    })
                  );
                } else if (e.target.value.length === 0) {
                  dispatch(updateLoadDetails({ loadWeight: 0 }));
                  dispatch(
                    siteplanValue({
                      loadWeight: 0,
                    })
                  );
                }
              }}
            />
          </InputContainer>
        </InputGrouping>
      </div>
      <InputContainer>
        <p>
          Load Shape<Req>&nbsp;*</Req>
        </p>
        <SelectContainer>
          <img src={Down} alt="down" />
          <select
            id="load_selection_dropdown"
            onChange={(e) => {
              dispatch(updateLoadDetails({ selectedLoad: e.target.value }));
            }}
            value={loadDetails.selectedLoad || 'selected'}
          >
            <option disabled value="selected">
              Select shape
            </option>
            <option value="Rectangle">Rectangle</option>
            <option value="Cylinder">Cylinder</option>
            <option value="Irregular">Irregular</option>
          </select>
        </SelectContainer>
      </InputContainer>
      {!loadDetails?.selectedLoad ? null : (
        <InputContainer>
          <p>Load dimensions (ft)</p>
          <DimensionsContainer>
            {(loadDetails?.selectedLoad === 'Rectangle' ||
              loadDetails?.selectedLoad === 'Irregular') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <p style={{ fontSize: '14px' }}>
                  Height<Req>&nbsp;*</Req>
                </p>
                <input
                  type="number"
                  placeholder="Height"
                  value={loadDetails.loadHeight || 0}
                  onChange={(e) => {
                    if (
                      !Number.isNaN(e.target.value) &&
                      e.target.value.length !== 0
                    ) {
                      const height = removeLeadingZeroes(e.target.value);
                      dispatch(updateLoadDetails({ loadHeight: height }));
                    } else if (e.target.value.length === 0) {
                      dispatch(updateLoadDetails({ loadHeight: 0 }));
                    }
                  }}
                />
              </div>
            )}
            {(loadDetails?.selectedLoad === 'Rectangle' ||
              loadDetails?.selectedLoad === 'Irregular') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  margin: '0 2%',
                }}
              >
                <p style={{ fontSize: '14px' }}>
                  Width<Req>&nbsp;*</Req>
                </p>
                <input
                  type="number"
                  placeholder="Width"
                  value={loadDetails.loadWidth || 0}
                  onChange={(e) => {
                    if (
                      !Number.isNaN(e.target.value) &&
                      e.target.value.length !== 0
                    ) {
                      const width = removeLeadingZeroes(e.target.value);
                      dispatch(updateLoadDetails({ loadWidth: width }));
                    } else if (e.target.value.length === 0) {
                      dispatch(updateLoadDetails({ loadWidth: 0 }));
                    }
                  }}
                />
              </div>
            )}
            {loadDetails?.selectedLoad === 'Cylinder' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginRight: '4%',
                }}
              >
                <p style={{ fontSize: '14px' }}>
                  Diameter<Req>&nbsp;*</Req>
                </p>
                <input
                  type="number"
                  placeholder="Diameter"
                  value={loadDetails.loadDiameter || 0}
                  onChange={(e) => {
                    if (
                      !Number.isNaN(e.target.value) &&
                      e.target.value.length !== 0
                    ) {
                      const diameter = removeLeadingZeroes(e.target.value);
                      dispatch(updateLoadDetails({ loadDiameter: diameter }));
                    } else if (e.target.value.length === 0) {
                      dispatch(updateLoadDetails({ loadDiameter: 0 }));
                    }
                  }}
                />
              </div>
            )}
            <div
              style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            >
              <p style={{ fontSize: '14px' }}>
                Length<Req>&nbsp;*</Req>
              </p>
              <input
                type="number"
                placeholder="Length"
                value={loadDetails.loadLength || 0}
                onChange={(e) => {
                  if (
                    !Number.isNaN(e.target.value) &&
                    e.target.value.length !== 0
                  ) {
                    const length = removeLeadingZeroes(e.target.value);
                    dispatch(updateLoadDetails({ loadLength: length }));
                  } else if (e.target.value.length === 0) {
                    dispatch(updateLoadDetails({ loadLength: 0 }));
                  }
                }}
              />
            </div>
          </DimensionsContainer>
        </InputContainer>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <InputGrouping>
          <InputContainer>
            <p>How was the weight determined</p>
            <SelectContainer>
              <img src={Down} alt="down" />
              <select
                id="weight_determined_dropdown"
                onChange={(e) => {
                  dispatch(
                    updateLoadDetails({ weightDeterminedBy: e.target.value })
                  );
                }}
                value={loadDetails.weightDeterminedBy || 'default'}
              >
                <option disabled value="default">
                  Select source
                </option>
                <option value="Manufacturer">Manufacturer</option>
                <option value="Customer">Customer</option>
                <option value="Calculated">Calculated</option>
              </select>
            </SelectContainer>
          </InputContainer>
        </InputGrouping>
        <InputGrouping>
          <InputContainer>
            <p>Estimated Total Deductions (lbs)</p>
            <input
              placeholder="Enter weight"
              type="number"
              value={loadDetails.estimatedDeductions || 0}
              onChange={(e) => {
                if (
                  !Number.isNaN(e.target.value) &&
                  e.target.value.length !== 0
                ) {
                  const deductions = removeLeadingZeroes(e.target.value);
                  dispatch(
                    updateLoadDetails({
                      estimatedDeductions: deductions.trim(),
                    })
                  );
                  dispatch(
                    updateCraneDetails({
                      totalDeductions: deductions.trim(),
                    })
                  );
                } else if (e.target.value.length === 0) {
                  dispatch(updateLoadDetails({ estimatedDeductions: 0 }));
                  dispatch(
                    updateCraneDetails({
                      totalDeductions: 0,
                    })
                  );
                }
              }}
            />
          </InputContainer>
        </InputGrouping>
      </div>
    </LoadDetailsContainer>
  );
}
