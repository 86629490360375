import React from 'react';

export default function LoginCrane() {
  return (
    <svg
      width="611"
      height="462"
      viewBox="0 0 611 462"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M183.947 14.0779L178.922 19.4856L252.615 87.9657L257.64 82.558L183.947 14.0779Z"
        fill="#4D4D4D"
      />
      <path
        d="M183.97 82.5935L178.938 87.995L252.465 156.488L257.497 151.087L183.97 82.5935Z"
        fill="#4D4D4D"
      />
      <path
        d="M183.935 151.095L178.893 156.487L252.178 225.018L257.22 219.626L183.935 151.095Z"
        fill="#4D4D4D"
      />
      <path
        d="M183.868 219.629L178.819 225.014L251.911 293.532L256.96 288.146L183.868 219.629Z"
        fill="#4D4D4D"
      />
      <path
        d="M183.797 288.096L178.742 293.476L251.64 361.979L256.695 356.6L183.797 288.096Z"
        fill="#4D4D4D"
      />
      <path
        d="M176.901 12.6173H259.046V372.38H176.901V12.6173ZM250.835 20.8656H185.15V364.169H250.835V20.8656Z"
        fill="#757575"
      />
      <path
        d="M254.978 12.6173H181.044V20.828H254.978V12.6173Z"
        fill="#757575"
      />
      <path
        d="M254.979 81.165H181.271V89.3756H254.979V81.165Z"
        fill="#757575"
      />
      <path
        d="M255.055 149.675H181.536V157.886H255.055V149.675Z"
        fill="#757575"
      />
      <path
        d="M255.091 218.185H181.76V226.396H255.091V218.185Z"
        fill="#757575"
      />
      <path
        d="M255.167 286.695H182.024V294.906H255.167V286.695Z"
        fill="#757575"
      />
      <path
        d="M255.205 355.243H182.251V363.453H255.205V355.243Z"
        fill="#757575"
      />
      <path
        d="M252.145 13.7863L178.476 82.2921L184.067 88.3048L257.736 19.799L252.145 13.7863Z"
        fill="#757575"
      />
      <path
        d="M252.182 82.2375L178.691 150.769L184.291 156.774L257.782 88.2424L252.182 82.2375Z"
        fill="#757575"
      />
      <path
        d="M252.256 150.825L178.959 219.343L184.566 225.341L257.863 156.823L252.256 150.825Z"
        fill="#757575"
      />
      <path
        d="M252.308 219.35L179.216 287.868L184.832 293.858L257.923 225.34L252.308 219.35Z"
        fill="#757575"
      />
      <path
        d="M252.347 287.819L179.461 356.336L185.085 362.318L257.971 293.802L252.347 287.819Z"
        fill="#757575"
      />
      <path
        d="M390.426 81.2652L322.018 154.703L327.407 159.722L395.815 86.285L390.426 81.2652Z"
        fill="#4D4D4D"
      />
      <path
        d="M322.689 80.3363L254.158 153.827L259.557 158.862L328.088 85.3708L322.689 80.3363Z"
        fill="#4D4D4D"
      />
      <path
        d="M185.659 80.19L117.142 153.282L122.527 158.33L191.045 85.2387L185.659 80.19Z"
        fill="#4D4D4D"
      />
      <path
        d="M117.117 80.1669L48.6006 153.053L53.9792 158.109L122.496 85.2231L117.117 80.1669Z"
        fill="#4D4D4D"
      />
      <path
        d="M51.2947 160.447H397.988V78.3402H48.0933V86.5509H389.777V152.236H51.2947V160.447Z"
        fill="#757575"
      />
      <path
        d="M397.987 82.4455H389.776V156.379H397.987V82.4455Z"
        fill="#757575"
      />
      <path
        d="M329.477 82.6715H321.266V156.379H329.477V82.6715Z"
        fill="#757575"
      />
      <path
        d="M192.42 83.1611H184.209V156.492H192.42V83.1611Z"
        fill="#757575"
      />
      <path
        d="M123.908 83.4248H115.698V156.567H123.908V83.4248Z"
        fill="#757575"
      />
      <path
        d="M328.356 79.9131L322.345 85.5064L390.877 159.152L396.887 153.559L328.356 79.9131Z"
        fill="#757575"
      />
      <path
        d="M259.825 80.1316L253.821 85.7323L322.366 159.211L328.37 153.611L259.825 80.1316Z"
        fill="#757575"
      />
      <path
        d="M122.82 80.5937L116.829 86.208L185.334 159.312L191.325 153.697L122.82 80.5937Z"
        fill="#757575"
      />
      <path
        d="M54.2734 80.8532L48.292 86.478L116.821 159.352L122.803 153.727L54.2734 80.8532Z"
        fill="#757575"
      />
      <path
        d="M527.412 80.187L458.894 153.279L464.28 158.327L532.797 85.2357L527.412 80.187Z"
        fill="#4D4D4D"
      />
      <path
        d="M458.903 80.1618L390.386 153.048L395.765 158.104L464.281 85.218L458.903 80.1618Z"
        fill="#4D4D4D"
      />
      <path
        d="M391.171 160.447H530.526C531.615 160.447 532.659 160.014 533.429 159.244C534.199 158.474 534.632 157.43 534.632 156.341V82.4455C534.632 81.3567 534.199 80.3125 533.429 79.5426C532.659 78.7727 531.615 78.3402 530.526 78.3402H389.89C388.802 78.3402 387.757 78.7727 386.988 79.5426C386.218 80.3125 385.785 81.3567 385.785 82.4455C385.785 83.5343 386.218 84.5785 386.988 85.3484C387.757 86.1183 388.802 86.5509 389.89 86.5509H526.496V152.236H391.171C390.082 152.236 389.038 152.669 388.268 153.439C387.498 154.208 387.066 155.253 387.066 156.341C387.066 157.43 387.498 158.474 388.268 159.244C389.038 160.014 390.082 160.447 391.171 160.447Z"
        fill="#757575"
      />
      <path
        d="M465.705 83.4248H457.495V156.567H465.705V83.4248Z"
        fill="#757575"
      />
      <path
        d="M532.52 160.522H610.484L535.534 80.148C535.17 79.7362 534.727 79.4015 534.232 79.1637C533.737 78.926 533.199 78.7902 532.65 78.7642C532.101 78.7382 531.553 78.8226 531.037 79.0124C530.522 79.2023 530.05 79.4937 529.649 79.8693C529.248 80.245 528.926 80.6973 528.704 81.1995C528.481 81.7016 528.361 82.2434 528.351 82.7927C528.342 83.342 528.442 83.8877 528.647 84.3973C528.852 84.907 529.158 85.3704 529.545 85.7599L591.615 152.311H532.52C531.432 152.311 530.388 152.744 529.618 153.514C528.848 154.284 528.415 155.328 528.415 156.417C528.415 157.506 528.848 158.55 529.618 159.32C530.388 160.09 531.432 160.522 532.52 160.522Z"
        fill="#757575"
      />
      <path
        d="M464.549 80.5692L458.558 86.1835L527.062 159.287L533.054 153.673L464.549 80.5692Z"
        fill="#757575"
      />
      <path
        d="M396.033 80.8577L390.051 86.4824L458.58 159.356L464.562 153.732L396.033 80.8577Z"
        fill="#757575"
      />
      <path
        d="M202.099 0H94.4941C85.4248 0 78.0728 7.35207 78.0728 16.4213V193.026C78.0728 202.095 85.4248 209.447 94.4941 209.447H202.099C211.168 209.447 218.52 202.095 218.52 193.026V16.4213C218.52 7.35207 211.168 0 202.099 0Z"
        fill="#F4B301"
      />
      <path
        d="M95.3227 9.45357H200.479C201.243 9.45357 201.976 9.15001 202.517 8.60967C203.057 8.06933 203.361 7.33647 203.361 6.57231C203.361 5.80815 203.057 5.07529 202.517 4.53495C201.976 3.99461 201.243 3.69105 200.479 3.69105H95.3227C94.5585 3.69105 93.8257 3.99461 93.2853 4.53495C92.745 5.07529 92.4414 5.80815 92.4414 6.57231C92.4414 7.33647 92.745 8.06933 93.2853 8.60967C93.8257 9.15001 94.5585 9.45357 95.3227 9.45357Z"
        fill="#FED154"
      />
      <path
        d="M97.8085 134.497H123.231C124.32 134.497 125.364 134.064 126.134 133.294C126.904 132.524 127.337 131.48 127.337 130.391C127.337 129.302 126.904 128.258 126.134 127.488C125.364 126.718 124.32 126.286 123.231 126.286H97.8085C96.7197 126.286 95.6755 126.718 94.9056 127.488C94.1357 128.258 93.7031 129.302 93.7031 130.391C93.7031 131.48 94.1357 132.524 94.9056 133.294C95.6755 134.064 96.7197 134.497 97.8085 134.497Z"
        fill="#757575"
      />
      <path
        d="M95.322 114.987H130.349L165.376 152.65H187.974C191.241 152.65 194.373 151.353 196.683 149.043C198.993 146.733 200.29 143.601 200.29 140.334V25.4606C200.29 23.283 199.425 21.1946 197.885 19.6548C196.346 18.115 194.257 17.2499 192.08 17.2499H103.42C101.242 17.2499 99.1536 18.115 97.6138 19.6548C96.074 21.1946 95.209 23.283 95.209 25.4606L95.322 114.987Z"
        fill="#C6DEC3"
      />
      <path
        d="M103.42 17.2499C101.242 17.2499 99.1536 18.115 97.6138 19.6548C96.074 21.1946 95.209 23.283 95.209 25.4606V55.9304L163.681 131.483C165.539 132.642 167.672 133.284 169.861 133.344C172.049 133.404 174.214 132.879 176.133 131.824C178.051 130.769 179.654 129.221 180.775 127.341C181.897 125.461 182.496 123.315 182.513 121.126V17.2499H103.42Z"
        fill="#E2EFE1"
      />
      <path
        d="M192.23 17.2499C194.408 17.2499 196.496 18.1149 198.036 19.6547C199.576 21.1945 200.441 23.283 200.441 25.4606V140.447C200.441 142.065 200.123 143.666 199.504 145.16C198.885 146.655 197.977 148.012 196.834 149.156C195.69 150.3 194.332 151.207 192.838 151.826C191.344 152.445 189.742 152.763 188.125 152.763H165.527L130.5 115.1H95.4728V25.4606C95.4717 23.3279 96.3005 21.2784 97.7837 19.746C99.2669 18.2136 101.288 17.3184 103.42 17.2499H192.23ZM91.2168 25.4606V114.987C91.2168 116.076 91.6493 117.12 92.4192 117.89C93.1891 118.66 94.2333 119.092 95.3221 119.092H128.579L162.476 155.55C162.86 155.966 163.326 156.298 163.845 156.524C164.364 156.751 164.923 156.869 165.489 156.869H188.087C192.44 156.859 196.611 155.125 199.688 152.048C202.766 148.971 204.499 144.8 204.509 140.447V25.4606C204.509 22.1942 203.211 19.0615 200.902 16.7518C198.592 14.4421 195.459 13.1446 192.193 13.1446H103.42C100.173 13.1744 97.0693 14.4851 94.784 16.7915C92.4987 19.098 91.2167 22.2136 91.2168 25.4606Z"
        fill="#757575"
      />
      <path
        d="M-51 168.846H56.6048V66.5138H-26.368L-51 91.1458V168.846Z"
        fill="#757575"
      />
      <path
        d="M56.6048 81.5793V91.9368H-51V91.1458L-41.4335 81.5793H56.6048Z"
        fill="#4D4D4D"
      />
      <path d="M56.6048 157.019H-51V167.377H56.6048V157.019Z" fill="#4D4D4D" />
      <path
        d="M-26.3676 66.5138H56.6052V76.8713H-36.7251L-26.3676 66.5138Z"
        fill="#4D4D4D"
      />
      <path d="M56.6048 141.916H-51V152.274H56.6048V141.916Z" fill="#4D4D4D" />
      <path d="M56.6048 96.6823H-51V107.04H56.6048V96.6823Z" fill="#4D4D4D" />
      <path d="M56.6048 111.748H-51V122.105H56.6048V111.748Z" fill="#4D4D4D" />
      <path d="M56.6048 126.851H-51V137.208H56.6048V126.851Z" fill="#4D4D4D" />
      <path
        d="M437.27 388.688H-1.32227V423.188H437.27V388.688Z"
        fill="#4D4D4D"
      />
      <path
        d="M363.789 429.139C363.789 437.854 367.251 446.212 373.413 452.375C379.576 458.538 387.935 462 396.65 462C405.365 462 413.724 458.538 419.887 452.375C426.049 446.212 429.511 437.854 429.511 429.139C429.511 428.913 429.511 428.687 429.511 428.461C429.511 419.75 426.051 411.397 419.892 405.237C413.733 399.078 405.379 395.618 396.669 395.618C387.958 395.618 379.605 399.078 373.446 405.237C367.286 411.397 363.826 419.75 363.826 428.461C363.789 428.724 363.789 428.913 363.789 429.139Z"
        fill="#757575"
      />
      <path
        d="M377.459 429.139C377.459 434.223 379.479 439.099 383.074 442.694C386.67 446.29 391.546 448.309 396.63 448.309C401.715 448.309 406.591 446.29 410.186 442.694C413.781 439.099 415.801 434.223 415.801 429.139C415.801 429.139 415.801 428.913 415.801 428.8C415.582 423.867 413.469 419.209 409.901 415.796C406.333 412.383 401.586 410.478 396.649 410.478C391.712 410.478 386.965 412.383 383.397 415.796C379.829 419.209 377.716 423.867 377.497 428.8C377.497 428.8 377.459 429.026 377.459 429.139Z"
        fill="#CCCCCC"
      />
      <path
        d="M396.631 437.914C401.478 437.914 405.407 433.985 405.407 429.139C405.407 424.292 401.478 420.363 396.631 420.363C391.784 420.363 387.855 424.292 387.855 429.139C387.855 433.985 391.784 437.914 396.631 437.914Z"
        fill="#757575"
      />
      <path
        d="M377.459 428.8C377.761 421.719 386.197 414.939 396.63 414.939C407.063 414.939 415.462 421.756 415.801 428.837C415.582 423.905 413.469 419.247 409.901 415.834C406.333 412.421 401.586 410.516 396.649 410.516C391.712 410.516 386.965 412.421 383.397 415.834C379.829 419.247 377.716 423.905 377.497 428.837L377.459 428.8Z"
        fill="#999999"
      />
      <path
        d="M292.303 429.139C292.303 437.854 295.765 446.212 301.928 452.375C308.09 458.538 316.449 462 325.164 462C333.88 462 342.238 458.538 348.401 452.375C354.563 446.212 358.026 437.854 358.026 429.139C358.026 428.913 358.026 428.687 358.026 428.461C358.026 419.75 354.565 411.397 348.406 405.237C342.247 399.078 333.893 395.618 325.183 395.618C316.473 395.618 308.119 399.078 301.96 405.237C295.801 411.397 292.34 419.75 292.34 428.461C292.34 428.724 292.303 428.913 292.303 429.139Z"
        fill="#757575"
      />
      <path
        d="M306.013 429.139C306.013 434.223 308.032 439.099 311.628 442.694C315.223 446.29 320.099 448.309 325.183 448.309C330.268 448.309 335.144 446.29 338.739 442.694C342.334 439.099 344.354 434.223 344.354 429.139C344.354 429.139 344.354 428.913 344.354 428.8C344.135 423.867 342.022 419.209 338.454 415.796C334.887 412.383 330.14 410.478 325.202 410.478C320.265 410.478 315.518 412.383 311.95 415.796C308.383 419.209 306.269 423.867 306.05 428.8C306.05 428.8 306.013 429.026 306.013 429.139Z"
        fill="#CCCCCC"
      />
      <path
        d="M325.183 437.914C330.029 437.914 333.958 433.985 333.958 429.139C333.958 424.292 330.029 420.363 325.183 420.363C320.336 420.363 316.407 424.292 316.407 429.139C316.407 433.985 320.336 437.914 325.183 437.914Z"
        fill="#757575"
      />
      <path
        d="M306.013 428.8C306.314 421.719 314.751 414.939 325.183 414.939C335.616 414.939 344.015 421.756 344.354 428.837C344.135 423.905 342.022 419.247 338.454 415.834C334.887 412.421 330.14 410.516 325.202 410.516C320.265 410.516 315.518 412.421 311.95 415.834C308.383 419.247 306.269 423.905 306.05 428.837L306.013 428.8Z"
        fill="#999999"
      />
      <path
        d="M220.856 429.139C220.856 437.854 224.318 446.212 230.481 452.375C236.644 458.538 245.002 462 253.717 462C262.433 462 270.791 458.538 276.954 452.375C283.117 446.212 286.579 437.854 286.579 429.139C286.579 428.913 286.579 428.687 286.579 428.461C286.579 419.75 283.119 411.397 276.959 405.237C270.8 399.078 262.447 395.618 253.736 395.618C245.026 395.618 236.672 399.078 230.513 405.237C224.354 411.397 220.894 419.75 220.894 428.461C220.856 428.724 220.856 428.913 220.856 429.139Z"
        fill="#757575"
      />
      <path
        d="M234.564 429.139C234.564 434.223 236.584 439.099 240.179 442.694C243.775 446.29 248.651 448.309 253.735 448.309C258.82 448.309 263.696 446.29 267.291 442.694C270.886 439.099 272.906 434.223 272.906 429.139C272.906 429.139 272.906 428.913 272.906 428.8C272.687 423.867 270.574 419.209 267.006 415.796C263.438 412.383 258.691 410.478 253.754 410.478C248.817 410.478 244.07 412.383 240.502 415.796C236.934 419.209 234.821 423.867 234.602 428.8C234.602 428.8 234.564 429.026 234.564 429.139Z"
        fill="#CCCCCC"
      />
      <path
        d="M253.697 437.914C258.544 437.914 262.473 433.985 262.473 429.139C262.473 424.292 258.544 420.363 253.697 420.363C248.851 420.363 244.922 424.292 244.922 429.139C244.922 433.985 248.851 437.914 253.697 437.914Z"
        fill="#757575"
      />
      <path
        d="M234.564 428.8C234.866 421.719 243.302 414.939 253.735 414.939C264.168 414.939 272.567 421.756 272.906 428.837C272.687 423.905 270.574 419.247 267.006 415.834C263.438 412.421 258.691 410.516 253.754 410.516C248.817 410.516 244.07 412.421 240.502 415.834C236.934 419.247 234.821 423.905 234.602 428.837L234.564 428.8Z"
        fill="#999999"
      />
      <path
        d="M149.407 429.139C149.407 437.854 152.869 446.212 159.032 452.375C165.195 458.538 173.553 462 182.269 462C190.984 462 199.343 458.538 205.505 452.375C211.668 446.212 215.13 437.854 215.13 429.139C215.13 428.913 215.13 428.687 215.13 428.461C215.13 419.75 211.67 411.397 205.511 405.237C199.352 399.078 190.998 395.618 182.288 395.618C173.577 395.618 165.223 399.078 159.064 405.237C152.905 411.397 149.445 419.75 149.445 428.461C149.407 428.724 149.407 428.913 149.407 429.139Z"
        fill="#757575"
      />
      <path
        d="M163.079 429.139C163.079 434.223 165.099 439.099 168.694 442.694C172.289 446.29 177.165 448.309 182.25 448.309C187.334 448.309 192.21 446.29 195.806 442.694C199.401 439.099 201.421 434.223 201.421 429.139C201.421 429.139 201.421 428.913 201.421 428.8C201.202 423.867 199.088 419.209 195.521 415.796C191.953 412.383 187.206 410.478 182.269 410.478C177.331 410.478 172.584 412.383 169.017 415.796C165.449 419.209 163.336 423.867 163.117 428.8C163.117 428.8 163.079 429.026 163.079 429.139Z"
        fill="#CCCCCC"
      />
      <path
        d="M182.251 437.914C187.097 437.914 191.026 433.985 191.026 429.139C191.026 424.292 187.097 420.363 182.251 420.363C177.404 420.363 173.475 424.292 173.475 429.139C173.475 433.985 177.404 437.914 182.251 437.914Z"
        fill="#757575"
      />
      <path
        d="M163.118 428.8C163.419 421.719 171.856 414.939 182.288 414.939C192.721 414.939 201.12 421.756 201.459 428.837C201.24 423.905 199.127 419.247 195.559 415.834C191.992 412.421 187.245 410.516 182.307 410.516C177.37 410.516 172.623 412.421 169.055 415.834C165.488 419.247 163.374 423.905 163.155 428.837L163.118 428.8Z"
        fill="#999999"
      />
      <path
        d="M77.9609 429.139C77.9609 437.854 81.4231 446.212 87.5858 452.375C93.7485 458.538 102.107 462 110.822 462C119.538 462 127.896 458.538 134.059 452.375C140.222 446.212 143.684 437.854 143.684 429.139C143.684 428.913 143.684 428.687 143.684 428.461C143.684 419.75 140.224 411.397 134.064 405.237C127.905 399.078 119.552 395.618 110.841 395.618C102.131 395.618 93.7772 399.078 87.618 405.237C81.4588 411.397 77.9986 419.75 77.9986 428.461C77.9609 428.724 77.9609 428.913 77.9609 429.139Z"
        fill="#757575"
      />
      <path
        d="M91.6323 429.139C91.6323 434.223 93.6521 439.099 97.2473 442.694C100.843 446.29 105.719 448.309 110.803 448.309C115.887 448.309 120.764 446.29 124.359 442.694C127.954 439.099 129.974 434.223 129.974 429.139C129.974 429.139 129.974 428.913 129.974 428.8C129.755 423.867 127.642 419.209 124.074 415.796C120.506 412.383 115.759 410.478 110.822 410.478C105.885 410.478 101.138 412.383 97.5699 415.796C94.0022 419.209 91.8888 423.867 91.67 428.8C91.67 428.8 91.6323 429.026 91.6323 429.139Z"
        fill="#CCCCCC"
      />
      <path
        d="M110.802 437.914C115.649 437.914 119.578 433.985 119.578 429.139C119.578 424.292 115.649 420.363 110.802 420.363C105.956 420.363 102.027 424.292 102.027 429.139C102.027 433.985 105.956 437.914 110.802 437.914Z"
        fill="#757575"
      />
      <path
        d="M91.6321 428.8C91.9334 421.719 100.37 414.939 110.803 414.939C121.236 414.939 129.785 421.832 129.785 428.8C129.566 423.867 127.453 419.209 123.885 415.796C120.318 412.383 115.571 410.478 110.633 410.478C105.696 410.478 100.949 412.383 97.3813 415.796C93.8136 419.209 91.7002 423.867 91.4814 428.8H91.6321Z"
        fill="#999999"
      />
      <path
        d="M6.47363 429.139C6.47363 433.454 7.32362 437.727 8.97506 441.714C10.6265 445.701 13.0471 449.324 16.0985 452.375C19.15 455.427 22.7726 457.847 26.7596 459.499C30.7465 461.15 35.0197 462 39.3351 462C43.6505 462 47.9237 461.15 51.9106 459.499C55.8976 457.847 59.5202 455.427 62.5716 452.375C65.6231 449.324 68.0437 445.701 69.6951 441.714C71.3465 437.727 72.1965 433.454 72.1965 429.139C72.1965 428.913 72.1965 428.687 72.1965 428.461C72.1965 419.75 68.7363 411.397 62.5772 405.237C56.418 399.078 48.0643 395.618 39.3539 395.618C30.6435 395.618 22.2899 399.078 16.1307 405.237C9.97149 411.397 6.5113 419.75 6.5113 428.461C6.5113 428.724 6.47363 428.913 6.47363 429.139Z"
        fill="#757575"
      />
      <path
        d="M20.1841 429.139C20.1841 434.223 22.2039 439.099 25.7991 442.694C29.3943 446.29 34.2704 448.309 39.3548 448.309C44.4392 448.309 49.3154 446.29 52.9106 442.694C56.5058 439.099 58.5256 434.223 58.5256 429.139C58.5256 429.139 58.5256 428.913 58.5256 428.8C58.3068 423.867 56.1934 419.209 52.6257 415.796C49.058 412.383 44.311 410.478 39.3737 410.478C34.4363 410.478 29.6894 412.383 26.1217 415.796C22.5539 419.209 20.4405 423.867 20.2217 428.8C20.2217 428.8 20.1841 429.026 20.1841 429.139Z"
        fill="#CCCCCC"
      />
      <path
        d="M39.3552 437.914C44.2018 437.914 48.1308 433.985 48.1308 429.139C48.1308 424.292 44.2018 420.363 39.3552 420.363C34.5086 420.363 30.5796 424.292 30.5796 429.139C30.5796 433.985 34.5086 437.914 39.3552 437.914Z"
        fill="#757575"
      />
      <path
        d="M20.1841 428.8C20.4854 421.719 28.922 414.939 39.3548 414.939C49.7876 414.939 58.1866 421.756 58.5256 428.837C58.3068 423.905 56.1934 419.247 52.6257 415.834C49.058 412.421 44.311 410.516 39.3737 410.516C34.4363 410.516 29.6894 412.421 26.1217 415.834C22.5539 419.247 20.4405 423.905 20.2217 428.837L20.1841 428.8Z"
        fill="#999999"
      />
      <path
        d="M460.282 353.585H-24.2974V388.085H460.282V353.585Z"
        fill="#F4B301"
      />
      <path
        d="M-4.33608 368.274H445.743C446.832 368.274 447.876 367.842 448.646 367.072C449.416 366.302 449.849 365.258 449.849 364.169C449.849 363.08 449.416 362.036 448.646 361.266C447.876 360.496 446.832 360.064 445.743 360.064H-4.33608C-5.42488 360.064 -6.46909 360.496 -7.23899 361.266C-8.00888 362.036 -8.44141 363.08 -8.44141 364.169C-8.44141 365.258 -8.00888 366.302 -7.23899 367.072C-6.46909 367.842 -5.42488 368.274 -4.33608 368.274Z"
        fill="#FED154"
      />
      <path
        d="M575.834 158.413V306.242H546.269V158.413H575.834ZM544.611 307.9H577.454V156.756H544.611V307.9Z"
        fill="#757575"
      />
      <path
        d="M561.07 173.177C570.139 173.177 577.491 165.825 577.491 156.756C577.491 147.686 570.139 140.334 561.07 140.334C552.001 140.334 544.649 147.686 544.649 156.756C544.649 165.825 552.001 173.177 561.07 173.177Z"
        fill="#757575"
      />
      <path
        d="M561.071 164.966C565.605 164.966 569.281 161.29 569.281 156.756C569.281 152.221 565.605 148.545 561.071 148.545C556.536 148.545 552.86 152.221 552.86 156.756C552.86 161.29 556.536 164.966 561.071 164.966Z"
        fill="#999999"
      />
      <path
        d="M564.723 334.98C564.723 338.143 568.979 341.458 572.482 345.638C575.463 348.644 577.136 352.705 577.136 356.937C577.136 361.17 575.463 365.231 572.482 368.237C566.644 374.074 556.777 374.225 552.219 369.705C546.193 363.679 547.097 355.469 547.097 349.329C552.822 352.305 553.952 357.992 556.513 360.629C560.919 365.035 569.657 357.917 564.046 350.572C561.841 348.661 560.069 346.302 558.848 343.652C557.627 341.003 556.985 338.123 556.965 335.206L564.723 334.98Z"
        fill="#757575"
      />
      <path
        d="M538.51 307.9C538.369 299.801 540.51 291.826 544.687 284.887H577.417C581.594 291.826 583.735 299.801 583.594 307.9C583.82 313.187 582.96 318.464 581.069 323.406C579.177 328.349 576.294 332.851 572.596 336.637H549.546C545.841 332.855 542.95 328.353 541.052 323.411C539.154 318.468 538.289 313.189 538.51 307.9Z"
        fill="#757575"
      />
      <path
        d="M543.444 307.9C543.444 309.94 543.582 311.979 543.858 314.001C544.217 316.564 544.823 319.087 545.666 321.534C546.912 325.305 548.94 328.77 551.617 331.703H570.449C570.901 331.251 571.315 330.724 571.729 330.234C572.615 329.107 573.409 327.911 574.102 326.656C574.809 325.37 575.426 324.036 575.948 322.664C576.505 321.187 576.97 319.678 577.341 318.144C577.739 316.531 578.04 314.896 578.245 313.248C578.466 311.474 578.579 309.688 578.584 307.9H543.444Z"
        fill="white"
      />
      <path
        d="M567.171 307.9L577.454 318.144C577.851 316.531 578.153 314.896 578.357 313.248L573.009 307.9H567.171Z"
        fill="#E73400"
      />
      <path
        d="M555.383 307.9L574.215 326.731C574.922 325.445 575.539 324.111 576.061 322.739L561.259 307.937L555.383 307.9Z"
        fill="#E73400"
      />
      <path
        d="M543.707 307.9L567.511 331.703H570.448C570.9 331.251 571.315 330.724 571.729 330.234L549.395 307.9H543.707Z"
        fill="#E73400"
      />
      <path
        d="M543.858 314.001C544.217 316.564 544.823 319.087 545.666 321.534L555.76 331.628H561.56L543.858 314.001Z"
        fill="#E73400"
      />
      <path
        d="M571.842 297.203C573.652 297.203 575.119 295.736 575.119 293.926C575.119 292.117 573.652 290.65 571.842 290.65C570.032 290.65 568.565 292.117 568.565 293.926C568.565 295.736 570.032 297.203 571.842 297.203Z"
        fill="#999999"
      />
      <path
        d="M550.261 297.203C552.07 297.203 553.537 295.736 553.537 293.926C553.537 292.117 552.07 290.65 550.261 290.65C548.451 290.65 546.984 292.117 546.984 293.926C546.984 295.736 548.451 297.203 550.261 297.203Z"
        fill="#999999"
      />
      <path
        d="M561.862 329.933C563.671 329.933 565.138 328.466 565.138 326.656C565.138 324.846 563.671 323.379 561.862 323.379C560.052 323.379 558.585 324.846 558.585 326.656C558.585 328.466 560.052 329.933 561.862 329.933Z"
        fill="#757575"
      />
    </svg>
  );
}
