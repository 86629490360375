import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: null,
  mobile: null,
  touch: null,
  width: null,
  addUserModal: false,
  content: null,
  deductionModal: false,
  deleteModal: false,
  infoModal: false,
  diagramModal: false,
  noteModal: false,
  resetModal: false,
  radiusModal: false,
  riggingModal: false,
  riggingInfoModal: false,
  pointsModal: false,
  additionalNoteModal: false,
  filterModal: false,
  refresh: false,
  elevationModal: false,
  displayNotification: 1,
  type: 'success',
  text: '',
  bold: '',
  showOverlay: false,
  loaded: false,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.page = action.payload;
    },
    setScreen: (state, action) => ({
      ...state,
      width: action.payload.width,
      height: action.payload.height,
    }),
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setDeductionModal: (state, action) => {
      state.deductionModal = action.payload;
    },
    setInfoModal: (state, action) => {
      state.infoModal = action.payload;
    },
    setDiagramModal: (state, action) => {
      state.diagramModal = action.payload;
    },
    setNoteModal: (state, action) => {
      state.noteModal = action.payload;
    },
    setDeleteModal: (state, action) => {
      state.deleteModal = action.payload.show;
      state.content = action.payload.content;
    },
    setResetModal: (state, action) => {
      state.resetModal = action.payload;
    },
    setRadiusModal: (state, action) => {
      state.radiusModal = action.payload;
    },
    setRiggingModal: (state, action) => {
      state.riggingModal = action.payload;
    },
    setPointsModal: (state, action) => {
      state.pointsModal = action.payload;
    },
    setAdditionalNoteModal: (state, action) => {
      state.additionalNoteModal = action.payload;
    },
    setFilterModal: (state, action) => {
      state.filterModal = action.payload;
    },
    setRiggingInfoModal: (state, action) => {
      state.riggingInfoModal = action.payload;
    },
    setElevationModal: (state, action) => {
      state.elevationModal = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    submitNote: (state) => ({ ...state, noteModal: false }),
    logout: () => initialState,
    hideNotification: (state) => {
      state.displayNotification = 1;
      state.refresh = false;
    },
    setNotification: (state, action) => ({
      ...state,
      displayNotification: 0,
      type: action.payload.type,
      text: action.payload.text,
      bold: action.payload.bold,
    }),
    setDefault: () => ({ ...initialState }),
    setShowOverlay: (state, action) => {
      state.showOverlay = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
  },
});

export const {
  setDeleteModal,
  setScreen,
  setView,
  setNoteModal,
  setDiagramModal,
  setDeductionModal,
  setInfoModal,
  setRiggingModal,
  setPointsModal,
  setResetModal,
  setRadiusModal,
  setAdditionalNoteModal,
  submitNote,
  logout,
  setFilterModal,
  setRiggingInfoModal,
  setDefault,
  hideNotification,
  setNotification,
  setElevationModal,
  setShowOverlay,
  setLoaded,
  setRefresh,
} = pageSlice.actions;

export default pageSlice.reducer;
