import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { setPointsModal } from '../../context/liftpage';
import Modal from '../../reusables/Modal/Modal';
import styled from 'styled-components';
import paper from 'paper';
import { setBridlePoints } from '../../context/siteplan';

export default function PointsModal({ width, length, lines, bridlePositions }) {
  const { pointsModal } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const { modals, buttons } = useTheme();

  useEffect(() => {
    const canvas = document.getElementById('canvas');
    paper.setup(canvas);
    let rectHeight = width;
    let rectLength = length;

    rectLength = paper.view.size.width * 0.9;
    rectHeight = (width / length) * rectLength;
    if (rectHeight > paper.view.size.height * 0.9) {
      rectHeight = paper.view.size.height * 0.9;
      rectLength = (length / width) * rectHeight;
    }

    const lift = new paper.Rectangle(
      new paper.Point(
        (paper.view.size.width - rectLength) / 2,
        (paper.view.size.height - rectHeight) / 2
      ),
      new paper.Point(
        (paper.view.size.width + rectLength) / 2,
        (paper.view.size.height + rectHeight) / 2
      )
    );
    const p = new paper.Path.Rectangle(lift);
    p.fillColor = '#888';

    const objCenter = paper.view.center;

    let center = new paper.Point(
      (bridlePositions[4].x / (length / 2)) * (rectLength / 2) + objCenter.x,
      (bridlePositions[4].y / (width / 2)) * (rectHeight / 2) + objCenter.y
    );
    const centerCircle = new paper.Path.Circle(center, 25);
    centerCircle.fillColor = '#000099';
    const points = [];
    const circles = [];

    if (lines > 1) {
      for (let i = 0; i < lines; i++) {
        const pick = new paper.Point(
          (bridlePositions[i].x / (length / 2)) * (rectLength / 2) +
            objCenter.x,
          (bridlePositions[i].y / (width / 2)) * (rectHeight / 2) + objCenter.y
        );
        const C = new paper.Path.Circle(pick, 25);
        C.fillColor = '#990000';
        points.push(pick);
        circles.push(C);
      }
    }

    let x = paper.Path.Line(center, center);
    let y = paper.Path.Line(center, center);
    x.strokeColor = 'orange';
    y.strokeColor = 'orange';
    x.strokeWidth = 3;
    y.strokeWidth = 3;
    let xDist = new paper.PointText(center);
    xDist.fillColor = 'orange';
    xDist.fontFamily = 'Roboto';
    xDist.fontSize = 20;
    xDist.content = '';
    let yDist = new paper.PointText(center);
    yDist.fillColor = 'orange';
    yDist.fontFamily = 'Roboto';
    yDist.fontSize = 20;
    yDist.content = '';

    let selected = -1;

    paper.view.onMouseMove = (event) => {
      let target = event.point;
      let found = false;
      if (lines > 1) {
        for (let i = 0; i < lines; i++) {
          const vec = new paper.Point(
            event.point.x - points[i].x,
            event.point.y - points[i].y
          );
          if (vec.length < 25 && !found) {
            circles[i].fillColor = 'red';
            target = points[i];
            selected = i;
            found = true;
          } else if (found) {
            circles[i].fillColor = '#990000';
          } else {
            circles[i].fillColor = '#990000';
            selected = -1;
          }
        }
      }
      const vecCenter = new paper.Point(
        event.point.x - center.x,
        event.point.y - center.y
      );
      if (vecCenter.length < 25 && !found) {
        centerCircle.fillColor = 'blue';
        selected = 4;
        found = true;
      } else {
        centerCircle.fillColor = '#000099';
        if (!found) {
          selected = -1;
        }
      }
      x.removeSegments();
      x.addSegments([center, new paper.Point(target.x, center.y)]);
      y.removeSegments();
      y.addSegments([new paper.Point(target.x, center.y), target]);
      xDist.position = new paper.Point(
        (target.x - center.x) / 2 + center.x,
        center.y - 16
      );
      xDist.content = `${(
        (Math.abs(target.x - center.x) / (rectLength / 2)) *
        (length * 6)
      ).toFixed(2)} Inches `;
      yDist.position = new paper.Point(
        target.x +
          ((target.x - center.x) / Math.abs(target.x - center.x) || 0.001) *
            -65,
        (target.y - center.y) / 2 + center.y
      );
      yDist.content = `${(
        (Math.abs(target.y - center.y) / (rectHeight / 2)) *
        (width * 6)
      ).toFixed(2)} Inches `;
    };

    // 3 | 2
    // -----
    // 0 | 1

    paper.view.onMouseDrag = (event) => {
      let target = event.point;
      if (selected > -1 && selected < 4 && lines > 1) {
        points[selected] = event.point;
        if (lines === 4) {
          if (selected === 0) {
            points[1].x = -(event.point.x - center.x) + center.x;
            points[1].y = event.point.y;
            points[3].x = event.point.x;
            points[3].y = -(event.point.y - center.y) + center.y;
            points[2].x = -(event.point.x - center.x) + center.x;
            points[2].y = -(event.point.y - center.y) + center.y;
          }
          if (selected === 1) {
            points[0].x = -(event.point.x - center.x) + center.x;
            points[0].y = event.point.y;
            points[2].x = event.point.x;
            points[2].y = -(event.point.y - center.y) + center.y;
            points[3].x = -(event.point.x - center.x) + center.x;
            points[3].y = -(event.point.y - center.y) + center.y;
          }
          if (selected === 2) {
            points[3].x = -(event.point.x - center.x) + center.x;
            points[3].y = event.point.y;
            points[1].x = event.point.x;
            points[1].y = -(event.point.y - center.y) + center.y;
            points[0].x = -(event.point.x - center.x) + center.x;
            points[0].y = -(event.point.y - center.y) + center.y;
          }
          if (selected === 3) {
            points[2].x = -(event.point.x - center.x) + center.x;
            points[2].y = event.point.y;
            points[0].x = event.point.x;
            points[0].y = -(event.point.y - center.y) + center.y;
            points[1].x = -(event.point.x - center.x) + center.x;
            points[1].y = -(event.point.y - center.y) + center.y;
          }
        }
        if (lines === 2) {
          if (selected === 0) {
            points[1].x = -(event.point.x - center.x) + center.x;
            points[1].y = -(event.point.y - center.y) + center.y;
          }
          if (selected === 1) {
            points[0].x = -(event.point.x - center.x) + center.x;
            points[0].y = -(event.point.y - center.y) + center.y;
          }
        }
        for (let i = 0; i < lines; i++) {
          circles[i].position = points[i];
        }
      }
      if (selected === 4) {
        center = new paper.Point(
          (paper.view.size.width - rectLength) / 2,
          (paper.view.size.height + rectHeight) / 2
        );
        centerCircle.position = event.point;
        if (lines > 1) {
          for (let i = 0; i < lines; i++) {
            points[i].x += event.delta.x;
            points[i].y += event.delta.y;
            circles[i].position.x += event.delta.x;
            circles[i].position.y += event.delta.y;
          }
        }
      }

      x.removeSegments();
      x.addSegments([center, new paper.Point(target.x, center.y)]);
      y.removeSegments();
      y.addSegments([new paper.Point(target.x, center.y), target]);
      xDist.position = new paper.Point(
        (target.x - center.x) / 2 + center.x,
        center.y - 16
      );
      xDist.content = `${(
        (Math.abs(target.x - center.x) / (rectLength / 2)) *
        (length * 6)
      ).toFixed(2)} Inches `;
      yDist.position = new paper.Point(
        target.x +
          ((target.x - center.x) / Math.abs(target.x - center.x) || 0.001) *
            -65,
        (target.y - center.y) / 2 + center.y
      );
      yDist.content = `${(
        (Math.abs(target.y - center.y) / (rectHeight / 2)) *
        (width * 6)
      ).toFixed(2)} Inches `;
    };

    paper.view.onMouseUp = (event) => {
      if (selected === 4) {
        center = event.point;
        const x =
          ((event.point.x - objCenter.x) / (rectLength / 2)) * (length / 2);
        const y =
          ((event.point.y - objCenter.y) / (rectHeight / 2)) * (width / 2);
        dispatch(setBridlePoints({ index: 4, point: { x: x, y: y } }));
      }
      if (lines > 1) {
        for (let i = 0; i < lines; i++) {
          const x =
            ((points[i].x - objCenter.x) / (rectLength / 2)) * (length / 2);
          const y =
            ((points[i].y - objCenter.y) / (rectHeight / 2)) * (width / 2);
          dispatch(setBridlePoints({ index: i, point: { x: x, y: y } }));
        }
      }
      selected = -1;
    };
  }, [pointsModal]);

  return (
    <Modal
      show={pointsModal}
      modalStyles={modals.pick}
      handleClose={() => {
        dispatch(setPointsModal(false));
      }}
      title={'Anchor Points'}
    >
      {pointsModal && (
        <canvas
          id="canvas"
          style={{
            height: '100%',
            width: '100%',
            resize: 'true',
            border: '1px solid black',
          }}
        />
      )}
    </Modal>
  );
}
