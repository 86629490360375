import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import CraneDiagram from './CraneDiagram';
import Collapsible from '../../reusables/CollapsibleSection';
import Slider from '../../reusables/Slider';

const SliderContainer = styled.div`
  display: flex;
  height: 30px;
  width: 80%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Tick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  bottom: 10px;
  z-index: 1;
`;

const TickBox = styled.div`
  height: 10px;
  width: 4px;
  z-index: 2;
`;

const TickText = styled.div`
  color: #000;
  text-align: center;
  font-size: 12px;
`;

const GroundPressureContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-left: 27px;
  width: 100%;
  align-items: center;
  padding-right: 27px;
  @media (min-width: 768px) {
    padding-left: 53px;
    padding-right: 53px;
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 12px;
`;

const DataRow = styled.div`
  display: flex;
  width: 100%;
`;

const DataTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #2a2f35;
  width: 50%;
`;

const DataContent = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #2a2f35;
  width: 50%;
`;

const Title = styled.h1`
  font-size: 32px;
  flex-grow: 1;
  line-height: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
`;

export default function GroundPressure() {
  const { craneDetails } = useSelector((state) => state.site);
  const { loadWeight } = useSelector((state) => state.site.loadDetails);
  const { angle } = useSelector((state) => state.site.siteDetails);
  const [craneComplete, setCraneComplete] = useState(true);
  const [sliderValue, setSliderValue] = useState(0);

  const convertAngle = (angle, offset) => {
    offset = offset || 0;
    return (Math.abs(angle - 180) + offset) % 360;
  };

  const truncateNumber = (value) => {
    if (value === null) {
      return null;
    }
    value = parseFloat(value);
    return parseFloat(value.toFixed(2));
  };

  const checkComplete = () => {
    if (craneComplete) {
      setCraneComplete(false);
    }
    return 'N/A';
  };

  const handleSlider = (e) => {
    setSliderValue(parseInt(e.target.value));
  };

  return (
    <GroundPressureContainer>
      <TitleContainer>
        <Title>Ground Bearing Pressure</Title>
      </TitleContainer>
      <Collapsible
        title="Crane Selection Summary"
        completed={craneComplete}
        isEditable={false}
      >
        {craneDetails.selectedCrane !== 'default' && (
          <DataContainer>
            <DataRow>
              <DataTitle>Crane:</DataTitle>
              {craneDetails.selectedCrane ? (
                <DataContent>{`${craneDetails.selectedCrane.manufacturer} ${craneDetails.selectedCrane.model}`}</DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Load Weight:</DataTitle>
              {loadWeight ? (
                <DataContent>{`${loadWeight} (lbs)`}</DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Boom Length:</DataTitle>
              {craneDetails.selectedBoomLength ? (
                <DataContent>
                  {`${craneDetails.selectedBoomLength} (ft)`}
                </DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Boom Configuration:</DataTitle>
              {craneDetails.selectedAttachment ? (
                <DataContent>
                  {craneDetails.selectedAttachment.name}
                </DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Counterweight:</DataTitle>
              {craneDetails.selectedCounterWeight ? (
                <DataContent>
                  {`${craneDetails.selectedCounterWeight} (lbs)`}
                </DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Gross Capacity:</DataTitle>
              {craneDetails.grossCap ? (
                <DataContent>
                  {`${truncateNumber(craneDetails.grossCap)} (lbs)`}
                </DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Total Deductions:</DataTitle>
              {craneDetails.totalDeductions !== null &&
              craneDetails.totalDeductions !== undefined &&
              craneDetails.totalDeductions !== 'default' ? (
                <DataContent>
                  {`${truncateNumber(craneDetails.totalDeductions)} (lbs)`}
                </DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Net Capacity:</DataTitle>
              {craneDetails.netCapacity ? (
                <DataContent>
                  {`${truncateNumber(craneDetails.netCapacity)} (lbs)`}
                </DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Percent of Chart Utilized:</DataTitle>
              {craneDetails.percentOfCap ? (
                <DataContent>{`${craneDetails.percentOfCap}%`}</DataContent>
              ) : (
                <DataContent>{checkComplete()}</DataContent>
              )}
            </DataRow>
            <DataRow>
              <DataTitle>Default Pad Size:</DataTitle>
              <DataContent>4' x 6'</DataContent>
            </DataRow>
          </DataContainer>
        )}
      </Collapsible>
      {craneDetails.selectedCrane !== 'default' && (
        <>
          <SliderContainer>
            <Slider
              min={0}
              max={360}
              step={1}
              sliderValue={sliderValue}
              handleSlider={handleSlider}
            />

            <Tick
              style={{
                left: `${
                  (convertAngle(craneDetails.pickAngle, angle) * 100) / 370
                }%`,
              }}
            >
              <TickText>Pick</TickText>
              <TickBox style={{ background: '#1865B8' }} />
            </Tick>
            <Tick
              style={{
                left: `${
                  (convertAngle(craneDetails.setAngle, angle) * 100) / 370
                }%`,
              }}
            >
              <TickText>Set</TickText>
              <TickBox style={{ background: '#B50614' }} />
            </Tick>
          </SliderContainer>
          <CraneDiagram
            angle={sliderValue}
            loadWeight={loadWeight}
            craneDetails={craneDetails}
            craneAngle={angle}
          />
        </>
      )}
    </GroundPressureContainer>
  );
}
