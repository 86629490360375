import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  role: null,
  verfied: null,
  username: null,
  companyName: null,
  email: null,
  mobile: null,
  height: null,
  ref: null,
  step: null,
  socketId: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => ({ ...state, ...action.payload }),
    pdf: (state, action) => {
      state.ref = action.payload;
    },
    clearPdf: (state) => {
      state.ref = null;
    },
    setScreen: (state, action) => ({
      ...state,
      mobile: action.payload <= 768,
      width: action.payload,
    }),
    setSocketId: (state, action) => ({
      ...state,
      socketId: action.payload,
    }),
    logout: () => initialState,
    setSteps: (state, action) => {
      state.step = action.payload;
    },
  },
});

export const {
  login,
  pdf,
  setScreen,
  logout,
  setSteps,
  clearPdf,
  setSocketId,
} = userSlice.actions;

export default userSlice.reducer;
