import colors from './colors';

export default {
  default: {
    color: '#2A2F35',
    backgroundColor: colors.yellow.base,
    width: '160px',
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  defaultRed: {
    color: '#2A2F35',
    backgroundColor: colors.red.base,
    width: '160px',
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  defaultGray: {
    color: '#2A2F35',
    backgroundColor: 'lightGRay',
    width: '160px',
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  addDeductions: {
    color: '#2A2F35',
    width: '168px',
    fontFamily: 'Roboto',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    border: '1px solid #CFD4DB',
    fontSize: '16px',
    borderRadus: '15px',
  },
  deleteJobWalk: {
    backgroundColor: '#B50614',
    color: 'white',
    width: '94px',
    borderRadius: '8px',
  },
  resetJobWalk: {
    backgroundColor: '#B50614',
    color: 'white',
    width: '120px',
    borderRadius: '8px',
  },
  login: {
    color: 'black',
    backgroundColor: '#CFD4DB',
    width: '168px',
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
  loginNotReady: {
    color: 'white',
    backgroundColor: '#2A2F35',
    width: '168px',
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
  share: {
    color: 'black',
    backgroundColor: '#F5A302',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    border: '1.5px solid black',
    padding: '12px 16px',
  },
  create: {
    color: 'white',
    backgroundColor: '#0F7637',
    width: '144px',
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
  resumeAdditionalConsiderations: {
    color: 'black',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    border: '1px solid #CFD4DB',
    width: '81px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    padding: '12px 16px',
  },
  deleteAdditionalConsiderations: {
    color: '#B50614',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    border: '1px solid #CFD4DB',
    width: '81px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    padding: '12px 16px',
  },
  editJobWalk: {
    color: 'black',
    fontStyle: 'normal',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    border: '1px solid #CFD4DB',
    width: '120px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    padding: '12px 16px',
  },
  editJobWalkDisabled: {
    color: 'rgba(101, 109, 118, 1)',
    fontStyle: 'italic',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    border: '1px solid #CFD4DB',
    fontFamily: 'Roboto',
    fontSize: '16px',
    width: '120px',
    padding: '12px 16px',
  },
  viewJobWalk: {
    color: '',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    width: 'auto',
    padding: '12px 16px',
    border: '1px solid #CFD4DB',
    fontFamily: 'Roboto',
    fontSize: '16px',
  },
  elevation: {
    width: '100px',
    backgroundColor: '#F4B301',
    fontFamily: 'Roboto',
    fontSize: '10px',
  },
  myCranes: {
    color: '#2A2F35',
    backgroundColor: '#D9D9D9',
    width: '160px',
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  craneSelection: {
    color: 'black',
    backgrond: '#D9D9D9',
    width: '157px',
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  overlay: {
    color: 'white',
    fontStyle: 'normal',
    backgroundColor: '#0458B2',
    border: '1px solid #CFD4DB',
    width: '120px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    padding: '12px 16px',
  },
  overlayDisabled: {
    color: 'white',
    fontStyle: 'italic',
    backgroundColor: '#C0D5EB',
    border: '1px solid #CFD4DB',
    fontFamily: 'Roboto',
    fontSize: '16px',
    width: '120px',
    padding: '12px 16px',
  },
  percentOfChart: {
    color: 'white',
    backgroundColor: 'black',
    width: '170px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    padding: '12px 16px',
  },
  siteplanControl: {
    color: 'black',
    fontStyle: 'normal',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFC 100%)',
    border: '1px solid #CFD4DB',
    width: '151px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    padding: '12px 16px',
  },
};
