import styled from 'styled-components/macro';
import Crosshair from './crosshair.svg';

const PictureLink = styled.button`
  background: none !important;
  color: blue;
  border: none;
`;

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 3;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  @media (min-width: 768px) {
    select {
      height: 56px;
    }
  }
`;

const LoaderBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GrayBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(1, 1, 1, 0.5);
`;

const ThePicture = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
`;

const UploadButton = styled.label`
  border: 1px solid #ccc;
  padding: 6px 12px;
  height: 31px;
  background: #f4b301;
  size: 10px;
  input {
    display: none;
  }
`;

const Crosshairdiv = styled.div`
  width: 100px;
  height: 100px;
  display: block;
  z-index: 8;
  content: ' ';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(${Crosshair});
  background-size: 100px 100px;
  pointer-events: none;
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
`;

const SitePlanContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  .mapContainer {
    position: relative;
    height: 100%;
  }

  .marker-position {
    top: 25px;
    left: 0;
    font-weight: bold;
    position: relative;
    padding: 2px;
    z-index: 2;
    border-radius: 3px;
    text-shadow: 1px 2px 2px black;
  }
  .lengthMarker {
    background-color: black;
    box-shadow: 1px 1px 1px black;
    padding: 4px;
    border-radius: 3px;
    border: 1px solid white;
    transform: rotate(var(--angle));
  }
  .elevation-label {
    background-color: white;
    box-shadow: 1px 1px 1px black;
    padding: 1px;
    border-radius: 2px;
    border: 1px solid white;
  }
  @media (min-width: 768px) {
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  bottom: 0px;
  height: 100px;
  z-index: 10;
  @media (min-width: 768px) {
    left: 0px;
    top: 50%;
    transform: translate(0%, -50%);
    height: auto;
    width: auto;
    flex-direction: column;
  }
`;

const Input = styled.div`
  width: 100%;
  padding-left: 25px;
  padding-right: 15px;
  input {
    width: 50%;
    border: 1px solid black;
    height: 31px;
    border-radius: 4px;
  }
  textarea {
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    border-radius: 5px;
    font-family: 'Roboto';
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(17, 17, 17, 0.32);
      font-family: 'Roboto';
    }
  }
  p {
    color: black;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  button {
    background-color: #f4b301;
    font-family: Lato;
    cursor: pointer;
    font-size: 15px;
    width: 70px;
    height: 31px;
    border: none;
    border-radius: 4px;
    font-weight: bolder;
  }
`;

const CancelButton = styled.button`
  cursor: pointer;
  position: absolute;
  height: 48px;
  border: none;
  width: 138px;
  top: 10px;
  left: 10px;
  color: black;
  background-color: red;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  z-index: 20;
  @media (min-width: 768px) {
    position: static;
    width: 160px;
    cursor: pointer;
    height: 41px;
    background-color: red;
    color: black;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    margin-top: 1px;
  }
`;

const PercentContainer = styled.div`
  position: absolute;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: space-around;
  top: 17px;
  left: 14px;
  z-index: 10;
`;

const DiagramContainer = styled.div`
  position: absolute;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: space-around;
  top: 17px;
  right: 14px;
  height: 100px;
  z-index: 10;
`;

const TheReset = styled.button`
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: 64px;
  height: 64px;
  z-index: 10;
  background: #cfd4db;
  border: 2px solid #2a2f35;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #2a2f35;
  font-size: 16px;
  img {
    margin-bottom: 5px;
  }
  align-items: center;
`;

export {
  CancelButton,
  PictureLink,
  GrayBackground,
  ThePicture,
  UploadButton,
  SitePlanContainer,
  ControlsContainer,
  Input,
  SelectContainer,
  PercentContainer,
  DiagramContainer,
  Crosshairdiv,
  TheReset,
  LoaderBackground,
};
