import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { setRiggingModal } from '../../context/liftpage';
import Modal from '../../reusables/Modal/Modal';
import styled from 'styled-components';
import InputContainer from '../../reusables/Input';
import Down from '../../assets/images/arrow-ios-downward.svg';
import Button from '../../reusables/Button';
import Search from '../../assets/images/search.svg';
import { updateRiggingPlan, resetRigging } from '../../context/siteplan';

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 3;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  @media (min-width: 768px) {
    select {
      height: 56px;
    }
  }
`;

const RiggingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  background: white;
  padding: 20px;
  border: 1px solid #cfd4db;
`;

const ResultTitle = styled.p`
  color: #2a2f35;
  font-size: 18px;
  font-weight: 600;
`;

const ResultDataContainer = styled.div`
  color: black;
  font-size: 14px;
  display: flex;
  flex-direction: row;
`;

const ResultColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 0px;
`;

const YellowColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  background: #fed154;
  border-radius: 5px;
`;

const ResultRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Bold = styled.p`
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Link = styled.a`
  color: #0458b2;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`;

export default function RiggingModal({ setIsEditing, rigging }) {
  const { riggingModal } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const { modals, buttons } = useTheme();
  const [title, setTitle] = useState('Search Rigging Inventory');
  const [result, setResult] = useState(false);
  const [type, setType] = useState('Any');
  const [length, setLength] = useState('Any');
  const [width, setWidth] = useState('Any');
  const [ply, setPly] = useState('Any');
  const [dropdowns, setDropdowns] = useState({
    types: ['Any'],
    widths: [],
    lengths: [],
    plys: [],
    typeobjs: [{ name: 'Any', width: true, plys: true }],
  });
  const [widthDisabled, setWidthDisabled] = useState(false);
  const [plyDisabled, setPlyDisabled] = useState(false);

  const filterData = (data) => {
    let t = type === 'Any' || data.category === type;
    let l = length === 'Any' || data.length === parseInt(length);
    let w = width === 'Any' || data.width === width;
    let p = ply === 'Any' || data.ply === parseInt(ply);
    return t && l && w && p;
  };

  const dropdownOptions = (rigging) => {
    let types = ['Any'];
    let widths = [];
    let lengths = [];
    let plys = [];
    let typeobjs = [{ name: 'Any', width: true, plys: true }];
    for (const data of rigging) {
      if (!types.includes(data.category)) {
        let typeobj = {
          name: data.category,
          width: data.width !== null,
          plys: data.ply !== null,
        };
        typeobjs.push(typeobj);
        types.push(data.category);
      }
      if (data.width !== null && !widths.includes(data.width)) {
        widths.push(data.width);
      }
      if (data.length !== null && !lengths.includes(data.length)) {
        lengths.push(data.length);
      }
      if (data.ply !== null && !plys.includes(data.ply)) {
        plys.push(data.ply);
      }
    }
    widths.sort((a, b) => {
      return eval(a) - eval(b);
    });
    lengths.sort((a, b) => {
      return eval(a) - eval(b);
    });
    plys.sort((a, b) => {
      return eval(a) - eval(b);
    });
    return {
      types: types,
      widths: widths,
      lengths: lengths,
      plys: plys,
      typeobjs: typeobjs,
    };
  };

  useEffect(() => {
    setDropdowns(dropdownOptions(rigging));
  }, [rigging]);

  return (
    <Modal
      show={riggingModal}
      modalStyles={modals.rigging}
      handleClose={() => {
        dispatch(setRiggingModal(false));
        setResult(false);
        setTitle('Search Rigging Inventory');
      }}
      title={title}
    >
      <RiggingContainer>
        {!result ? (
          <>
            <InputContainer style={{ marginBottom: '6px' }}>
              <p>Type of Rigging</p>
              <SelectContainer>
                <img src={Down} alt="down" />
                <select
                  onChange={(e) => {
                    setType(e.target.value);
                    const t = dropdowns.typeobjs.filter((data) => {
                      return data.name === e.target.value;
                    });
                    setWidthDisabled(!t[0].width);
                    if (!t[0].width) setWidth('Any');
                    setPlyDisabled(!t[0].plys);
                    if (!t[0].plys) setPly('Any');
                    setLength('Any');
                  }}
                  value={type}
                >
                  {dropdowns.types.map((obj) => {
                    return (
                      <option key={obj} value={obj}>
                        {obj}
                      </option>
                    );
                  })}
                </select>
              </SelectContainer>
            </InputContainer>
            <InputContainer style={{ marginBottom: '6px' }}>
              <p>Length of Rigging</p>
              <SelectContainer>
                <img src={Down} alt="down" />
                <select
                  onChange={(e) => {
                    setLength(e.target.value);
                  }}
                  value={length}
                >
                  <option value="Any">Any</option>
                  {dropdowns.lengths.map((obj) => {
                    return (
                      <option key={obj} value={obj}>{`${obj} (ft)`}</option>
                    );
                  })}
                </select>
              </SelectContainer>
            </InputContainer>
            <InputContainer style={{ marginBottom: '6px' }}>
              <p>Width of Rigging</p>
              <SelectContainer>
                <img src={Down} alt="down" />
                <select
                  onChange={(e) => {
                    setWidth(e.target.value);
                  }}
                  value={width}
                >
                  <option value="Any">Any</option>
                  {dropdowns.widths.map((obj) => {
                    return (
                      <option
                        key={obj}
                        value={obj}
                        disabled={widthDisabled}
                      >{`${obj} (in)`}</option>
                    );
                  })}
                </select>
              </SelectContainer>
            </InputContainer>
            <InputContainer style={{ marginBottom: '6px' }}>
              <p>Ply</p>
              <SelectContainer>
                <img src={Down} alt="down" />
                <select
                  onChange={(e) => {
                    setPly(e.target.value);
                  }}
                  value={ply}
                >
                  <option value="Any">Any</option>
                  {dropdowns.plys.map((obj) => {
                    return (
                      <option
                        key={obj}
                        value={obj}
                        disabled={plyDisabled}
                      >{`${obj}-ply`}</option>
                    );
                  })}
                </select>
              </SelectContainer>
            </InputContainer>
            <Button
              theme={buttons.editJobWalk}
              style={{ width: '150px' }}
              onClick={() => {
                setTitle('Available Rigging');
                setResult(true);
              }}
            >
              <img src={Search} /> &nbsp; Search (
              {rigging.filter((d) => filterData(d)).length})
            </Button>
          </>
        ) : (
          <>
            {rigging
              .filter((d) => filterData(d))
              .map((data) => (
                <ResultContainer>
                  <ResultTitle>{`${data.manufacturer} ${data.category}`}</ResultTitle>
                  <ResultDataContainer>
                    <ResultColumn>
                      <ResultRow>
                        Length:&nbsp;<Bold>{data.length} (ft)</Bold>
                      </ResultRow>
                      <ResultRow>
                        Width:&nbsp;
                        <Bold>{data.width ? `${data.width} (in)` : 'N/A'}</Bold>
                      </ResultRow>
                      <ResultRow>
                        Ply:&nbsp;
                        <Bold>{data.ply ? `${data.ply}-ply` : 'N/A'}</Bold>
                      </ResultRow>
                    </ResultColumn>
                    <YellowColumn>
                      <ResultRow>
                        Vertical Capacity:&nbsp;
                        <Bold>
                          {data.capacities.vertical
                            ? `${data.capacities.vertical} (lbs)`
                            : 'N/A'}
                        </Bold>
                      </ResultRow>
                      <ResultRow>
                        Choker Capacity:&nbsp;
                        <Bold>
                          {data.capacities.choker
                            ? `${data.capacities.choker} (lbs)`
                            : 'N/A'}
                        </Bold>
                      </ResultRow>
                      <ResultRow>
                        Basket Capacity:&nbsp;
                        <Bold>
                          {data.capacities.basket
                            ? `${data.capacities.basket} (lbs)`
                            : 'N/A'}
                        </Bold>
                      </ResultRow>
                    </YellowColumn>
                  </ResultDataContainer>
                  <ButtonContainer>
                    <Link href={data.files[0]} target="_blank">
                      View Tag
                    </Link>
                    <Button
                      onClick={() => {
                        dispatch(resetRigging());
                        dispatch(updateRiggingPlan({ currentSling: data }));
                        dispatch(setRiggingModal(false));
                        setIsEditing(false);
                        setResult(false);
                        setTitle('Search Rigging Inventory');
                      }}
                      theme={buttons.editJobWalk}
                    >
                      Select
                    </Button>
                  </ButtonContainer>
                </ResultContainer>
              ))}
          </>
        )}
      </RiggingContainer>
    </Modal>
  );
}
