import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import axios from 'axios';
import { useStytch } from '@stytch/react';
import { API_URL } from '../../services/api';
import authHeader from '../../services/auth-header';
import InputContainer from '../../reusables/Input';
import Input from '../../reusables/InputBar';
import Button from '../../reusables/Button';
import Modal from '../../reusables/Modal/Modal';
import Loader from '../../reusables/Loader/Loader';
import { setNotification } from '../../context/liftpage';
import Down from '../../assets/images/arrow-ios-downward.svg';
import { useDispatch } from 'react-redux';

const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  p {
    font-size: 30px;
  }
`;

const MainContainer = styled.div`
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  flex-direction: column;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow};
  align-items: center;
`;

const ExtraInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 0 5px;
`;

const HookblockContainer = styled(InputContainer)`
  flex-direction: row;
`;

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 3;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  i {
    position: absolute;
    bottom: 17px;
    right: 10px;
  }
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  @media (min-width: 768px) {
    select {
      height: 56px;
    }
  }
`;

const boxShadow = { boxShadow: '0px 3px 4px #373737' };

export default function Company({ company, selectedCompany, onClick }) {
  const [users, setUsers] = useState([]);
  const [craneAssets, setCraneAssets] = useState([]);
  const [riggingAssets, setRiggingAssets] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [selected, setSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCraneModal, setShowCraneModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showRiggingModal, setShowRiggingModal] = useState(false);
  const theme = useTheme();

  /* data for new crane assets */
  const [manufacturers, setCraneMfrs] = useState([]);
  const [selectedMfrAddNew, setSelectedMfrAddNew] = useState('');
  const [modelNumbers, setCraneModelNumbers] = useState([]);
  const [selectedModelAddNew, setSelectedModelAddNew] = useState('');
  const [craneInspectionReport, setCraneInspectionReport] = useState();
  const fileCategories = ['Annual Crane Inspection Report'];
  const [lineWt, setLineWt] = useState();
  const [hookBlockSheaves, setHookBlockSheaves] = useState();
  const [hookBlockWeight, setHookBlockWeight] = useState();
  const [hookBlockHeight, setHookBlockHeight] = useState();
  const [hookBlockCapacity, setHookBlockCapacity] = useState();
  const [allHookBlocks, setAllBlocks] = useState([]);
  const [defaultPadWidth, setDefaultPadWidth] = useState();
  const [defaultPadLength, setDefaultPadLength] = useState();
  const [hourlyRate, setHourlyRate] = useState();
  const [hourlyMin, setHourlyMin] = useState();

  // rigging
  const [riggingObj, setRiggingObj] = useState({
    manufacturer: '',
    category: '',
    length: 0,
    width: '',
    color: null,
    ply: null,
    weight: 0,
    capacities: {
      vertical: null,
      choker: null,
      basket: null,
    },
  });

  const dispatch = useDispatch();

  // called when new user is to be created
  const callSignupService = () => {
    // console.log(name, email, password, company, phoneNo, countryCode);
    setIsLoading(true);
    if (confirmPassword !== password) {
      dispatch(
        setNotification({ text: 'Passwords do not match', type: 'error' })
      );
      return;
    }
    try {
      axios
        .post(`${API_URL}/user/signup`, {
          username: name,
          email,
          password,
          linkedCompanyName: selectedCompany,
          phoneNo,
          countryCode,
        })
        .then(async (resp) => {
          setUsers((current) => [...current, resp.data.data.user]);
        });
    } catch (err) {
      console.log('Error occured while creating account: ', err);
    }
    setIsLoading(false);
  };

  // get all users of a company
  const getUsers = async () => {
    axios
      .get(`${API_URL}/user/company`, {
        params: {
          linkedCompanyName: selectedCompany,
        },
        headers: authHeader(),
      })
      .then((response) => {
        console.log('all users:', response);
        setUsers(response.data.users);
      });
  };

  // get all crane assets of a company
  const getCranes = async () => {
    axios
      .get(`${API_URL}/crane/company/list`, {
        params: {
          linkedCompanyName: selectedCompany,
        },
        headers: authHeader(),
      })
      .then((response) => {
        console.log('cranes', response);
        setCraneAssets(response.data.data.myCranes);
      });
  };

  // get all rigging assets of a company
  const getRigging = async () => {
    axios
      .get(`${API_URL}/rigging/company/list`, {
        params: {
          linkedCompanyName: selectedCompany,
        },
        headers: authHeader(),
      })
      .then((response) => {
        console.log('rigging', response);
        setRiggingAssets(response.data.data.rigging);
      });
  };

  const addRigging = async (riggingObj) => {
    axios
      .post(
        `${API_URL}/rigging/add`,
        { ...riggingObj, linkedCompany: selectedCompany },
        {
          headers: authHeader(),
        }
      )
      .then((resp) => {
        setIsLoading(false);
        dispatch(
          setNotification({
            text: 'Rigging added successfully',
            type: 'success',
          })
        );

        setShowRiggingModal(false);
        setRiggingObj({
          manufacturer: '',
          category: '',
          length: 0,
          width: '0',
          color: null,
          ply: null,
          weight: 0,
          capacities: {
            vertical: null,
            choker: null,
            basket: null,
          },
        });
        setRiggingAssets([...riggingAssets, riggingObj]);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };

  // get all crane manufacturers from global database
  const getCraneMfrs = async () => {
    axios
      .get(`${API_URL}/crane/manufacturer/list`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCraneMfrs(response.data.data.mfrs);
      })
      .catch((err) => {
        throw err;
      });
  };

  // get all models of selected manufacturer
  const getAllCraneModelsByMfr = async () => {
    axios
      .get(`${API_URL}/crane/models/list`, {
        params: {
          mfr: selectedMfrAddNew,
        },
        headers: authHeader(),
      })
      .then((response) => {
        setCraneModelNumbers(response.data.data.modelNumbers);
      })
      .catch((err) => {
        throw err;
      });
  };

  // save crane asset to account
  const saveCrane = () => {
    let allFiles = [craneInspectionReport];

    const formData = new FormData();
    allFiles = allFiles.filter((file) => file);
    allFiles.forEach((file) => {
      formData.append(file.category, file);
    });
    formData.append('allHookBlocks', JSON.stringify(allHookBlocks));
    formData.append('manufacturer', selectedMfrAddNew);
    formData.append('modelNumber', selectedModelAddNew);
    formData.append('fileCategories', fileCategories);
    formData.append('lineWt', lineWt);
    formData.append('defaultPadLength', defaultPadLength);
    formData.append('defaultPadWidth', defaultPadWidth);
    formData.append('hourlyRate', hourlyRate);
    formData.append('hourlyMinimum', hourlyMin);
    formData.append('companyName', selectedCompany);
    setIsLoading(true);

    axios
      .post(`${API_URL}/crane/create`, formData, {
        headers: authHeader(),
      })
      .then((resp) => {
        setIsLoading(false);
        dispatch(
          setNotification({ text: 'Crane added successfully', type: 'success' })
        );

        setShowCraneModal(false);
        setCraneInspectionReport();
        setCraneAssets((current) => [...current, resp.data.data.craneAsset]);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };

  // populate dropdown
  useEffect(() => {
    getCraneMfrs();
  }, []);

  // populate next dropdown
  useEffect(() => {
    getAllCraneModelsByMfr();
  }, [selectedMfrAddNew]);

  // expand company container and load details when company selected
  useEffect(() => {
    if (selectedCompany === company.name) {
      setSelected(true);
    } else {
      setSelected(false);
    }
    getUsers();
    getCranes();
    getRigging();
    console.log('craneasset', craneAssets);
  }, [selectedCompany]);

  const extraInformation = (
    <ExtraInformationContainer>
      <h3>Users</h3>
      {users.length
        ? users.map((user) => (
            <div>
              <p>
                Username:
                {user.username}
              </p>
              <p>
                Email:
                {user.email}
              </p>
              <p>
                Phone:
                {user.phoneNumber}
              </p>
              <p>
                Role:
                {user.role}
              </p>
              <hr className="solid" />
            </div>
          ))
        : null}
      <h3>Crane Assets</h3>
      {craneAssets.length
        ? craneAssets.map((craneAsset) => (
            <div>
              <p>{`Crane Asset: ${craneAsset.manufacturer} ${craneAsset.model}`}</p>
              <hr className="solid" />
            </div>
          ))
        : null}
      <h3>Rigging Assets</h3>
      {riggingAssets.length
        ? riggingAssets.map((rigging) => (
            <div>
              <p>{`${rigging.manufacturer}, ${rigging.category} ${rigging.length}ft`}</p>
              <hr className="solid" />
            </div>
          ))
        : null}
    </ExtraInformationContainer>
  );

  return (
    <MainContainer
      theme={selected ? boxShadow : null}
      onClick={() => onClick(company.name)}
    >
      <CompanyContainer>
        <p>{company.name}</p>
        {selected && (
          <div>
            <Button
              style={{ margin: '20px auto', padding: '5px' }}
              onClick={() => {
                setShowUserModal(true);
              }}
            >
              Add New User
            </Button>
            <Button
              style={{ margin: '20px auto', padding: '5px' }}
              onClick={() => {
                setShowCraneModal(true);
              }}
            >
              Add New Crane Asset
            </Button>
            <Button
              style={{ margin: '20px auto', padding: '5px' }}
              onClick={() => {
                setShowRiggingModal(true);
              }}
            >
              Add New Rigging Asset
            </Button>
          </div>
        )}
      </CompanyContainer>
      {selected ? extraInformation : null}
      <Modal
        show={showUserModal}
        closeButtonStyle={{
          padding: 10,
          cursor: 'pointer',
        }}
        modalStyles={{
          width: '50%',
          paddingLeft: '27px',
          paddingRight: '27px',
          overflow: 'scroll',
        }}
        handleClose={() => {
          setShowUserModal(false);
        }}
      >
        <h1>
          Add new user to
          {selectedCompany}
        </h1>
        <p>Full Name</p>
        <InputContainer>
          <Input
            placeholder="Enter full name"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Email Address</p>
          <Input
            placeholder="Enter email address"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
        </InputContainer>
        <InputContainer>
          <p>Phone Number</p>
          <Input
            placeholder="123-456-7890"
            id="phone-number-field"
            name="phone_number"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Set a password</p>
          <Input
            placeholder="Set Password"
            type={isPasswordVisible ? 'text' : 'password'}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Confirm password</p>
          <Input
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputContainer>
        <Button
          style={{ width: '256px', marginBottom: '20px' }}
          disabled={isLoading}
          onClick={() => {
            callSignupService();
            setShowUserModal(false);
          }}
        >
          {isLoading ? <Loader /> : 'Create user'}
        </Button>
      </Modal>
      <Modal
        show={showCraneModal}
        closeButtonStyle={{
          padding: 10,
          cursor: 'pointer',
        }}
        modalStyles={{
          paddingLeft: '27px',
          paddingRight: '27px',
          overflowY: 'scroll',
        }}
        handleClose={() => {
          setShowCraneModal(false);
        }}
      >
        <h1>Add new Crane Asset</h1>
        <SelectContainer>
          <p>Manufacturer</p>
          <select
            onChange={(e) => {
              const manufacturer = manufacturers.find(
                (op) => op === e.target.value
              );
              setSelectedMfrAddNew(manufacturer);
            }}
            value={selectedMfrAddNew || 'default'}
          >
            <option disabled value="default">
              Select Crane
            </option>
            {manufacturers.map((manufacturer) => (
              <option key={manufacturer} value={manufacturer}>
                {`${manufacturer}`}
              </option>
            ))}
          </select>
        </SelectContainer>
        <SelectContainer>
          <p>Select Model*</p>
          <div className="select">
            <select
              onChange={(e) => {
                const model = modelNumbers.find((op) => op === e.target.value);
                setSelectedModelAddNew(model);
              }}
              value={selectedModelAddNew || 'default'}
            >
              <option disabled value="default">
                Select Model Number
              </option>
              {modelNumbers.map((modelNumber) => (
                <option key={modelNumber} value={modelNumber}>
                  {`${modelNumber}`}
                </option>
              ))}
            </select>
          </div>
        </SelectContainer>
        <SelectContainer>
          <p>Upload Annual Crane Inspection Report*</p>
          <i className="fa fa-paperclip medium upload-icon" />
          <input
            className="file-upload-field"
            id="crane-inspection-report"
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              file.category = 'Annual Crane Inspection Report';
              setCraneInspectionReport(file);
            }}
          />
        </SelectContainer>
        <HookblockContainer>
          <p>Hook Blocks</p>
          <div>
            {allHookBlocks.map(({ sheaves, weight, capacity }) => (
              <p key={sheaves}>
                {sheaves}
                Sheave;
                {weight}
                lbs;
                {capacity}
                lb capacity
              </p>
            ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              placeholder="# of Sheaves"
              value={hookBlockSheaves}
              onChange={(e) => {
                setHookBlockSheaves(e.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              placeholder="Weight"
              value={hookBlockWeight}
              onChange={(e) => {
                setHookBlockWeight(e.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              placeholder="Capacity"
              value={hookBlockCapacity}
              onChange={(e) => {
                setHookBlockCapacity(e.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              placeholder="Height"
              value={hookBlockHeight}
              onChange={(e) => {
                setHookBlockHeight(e.target.value);
              }}
            />
          </div>
        </HookblockContainer>
        <Button
          onClick={() => {
            const newBlock = {
              sheaves: hookBlockSheaves,
              weight: hookBlockWeight,
              capacity: hookBlockCapacity,
              height: hookBlockHeight,
            };
            setAllBlocks((current) => [...current, newBlock]);
          }}
        >
          Save Hookblock
        </Button>
        <hr className="solid" />
        <HookblockContainer>
          <p>Default Outrigger Pads</p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              placeholder="Length"
              value={defaultPadLength}
              onChange={(e) => {
                setDefaultPadLength(e.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              placeholder="Width"
              value={defaultPadWidth}
              onChange={(e) => {
                setDefaultPadWidth(e.target.value);
              }}
            />
          </div>
        </HookblockContainer>
        <InputContainer>
          <p>Hourly Rate</p>
          <input
            placeholder="Hours"
            type="text"
            value={hourlyRate}
            onChange={(e) => {
              setHourlyRate(e.target.value);
            }}
          />
        </InputContainer>
        <InputContainer>
          <p>Hourly Minimum</p>
          <input
            placeholder="Hours"
            type="text"
            value={hourlyMin}
            onChange={(e) => {
              setHourlyMin(e.target.value);
            }}
          />
        </InputContainer>
        <InputContainer>
          <p>Line Weight</p>
          <input
            placeholder="lbs/foot"
            type="text"
            value={lineWt}
            onChange={(e) => {
              setLineWt(e.target.value);
            }}
          />
        </InputContainer>
        <Button
          style={{ margin: '40px auto' }}
          theme={theme.buttons.default}
          disabled={isLoading}
          onClick={() => saveCrane()}
        >
          {isLoading ? <Loader /> : 'Save'}
        </Button>
      </Modal>
      <Modal
        show={showRiggingModal}
        closeButtonStyle={{
          padding: 10,
          cursor: 'pointer',
        }}
        modalStyles={{
          width: '50%',
          paddingLeft: '27px',
          paddingRight: '27px',
          overflow: 'scroll',
        }}
        handleClose={() => {
          setShowRiggingModal(false);
        }}
      >
        <h1>
          Add Rigging to
          {selectedCompany}
        </h1>
        <InputContainer>
          <p>Manufacturer</p>
          <Input
            placeholder="Enter manufacturer"
            type="text"
            name="manu"
            value={riggingObj.manufacturer}
            onChange={(e) =>
              setRiggingObj({ ...riggingObj, manufacturer: e.target.value })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Category</p>
          <SelectContainer>
            <img src={Down} alt="down" />
            <select
              onChange={(e) => {
                setRiggingObj({ ...riggingObj, category: e.target.value });
              }}
              value={riggingObj.category}
            >
              <option value={''} disabled={true}>
                Select Category
              </option>
              <option value={'Endless Polyester Websling'}>
                Endless Polyester Websling
              </option>
              <option value={'Endless Nylon Websling'}>
                Endless Nylon Websling
              </option>
              <option value={'Endless Polyester Roundsling'}>
                Endless Polyester Roundsling
              </option>
              <option value={'Endless Nylon Roundsling'}>
                Endless Nylon Roundsling
              </option>
              <option value={'Eye & Eye Polyester Websling'}>
                Eye & Eye Polyester Websling
              </option>
              <option value={'Eye & Eye Nylon Websling'}>
                Eye & Eye Nylon Websling
              </option>
              <option value={'Wire Rope Sling'}>Wire Rope Sling</option>
              <option value={'Chain Sling'}>Chain Sling</option>
            </select>
          </SelectContainer>
        </InputContainer>
        <InputContainer>
          <p>Length (ft)</p>
          <Input
            placeholder="Enter length (ft)"
            type="number"
            name="length"
            value={riggingObj.length}
            onChange={(e) =>
              setRiggingObj({
                ...riggingObj,
                length: parseFloat(e.target.value),
              })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Width (in)</p>
          <Input
            placeholder="Enter width (in)"
            type="text"
            name="width"
            value={riggingObj.width}
            onChange={(e) =>
              setRiggingObj({ ...riggingObj, width: e.target.value })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Color</p>
          <SelectContainer>
            <img src={Down} alt="down" />
            <select
              onChange={(e) => {
                setRiggingObj({ ...riggingObj, color: e.target.value });
              }}
              value={riggingObj.color}
            >
              <option value={null}>N/A</option>
              <option value={'Purple'}>Purple</option>
              <option value={'Green'}>Green</option>
              <option value={'Yellow'}>Yellow</option>
              <option value={'Tan'}>Tan</option>
              <option value={'Red'}>Red</option>
              <option value={'White'}>White</option>
              <option value={'Blue'}>Blue</option>
              <option value={'Orange'}>Orange</option>
              <option value={'Gray'}>Gray</option>
              <option value={'Brown'}>Brown</option>
              <option value={'Olive'}>Olive</option>
              <option value={'Black'}>Black</option>
            </select>
          </SelectContainer>
        </InputContainer>
        <InputContainer>
          <p>Weight (lbs)</p>
          <Input
            placeholder="Enter weight (lbs)"
            type="number"
            name="weight"
            value={riggingObj.weight}
            onChange={(e) =>
              setRiggingObj({
                ...riggingObj,
                weight: parseFloat(e.target.value),
              })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Ply</p>
          <Input
            placeholder="Enter ply"
            type="number"
            name="ply"
            value={riggingObj.ply}
            onChange={(e) =>
              setRiggingObj({
                ...riggingObj,
                ply: parseFloat(e.target.value),
              })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Vertical Capacity (lbs)</p>
          <Input
            placeholder="Enter vertical capacity (lbs)"
            type="number"
            name="vertical"
            value={riggingObj.capacities.vertical}
            onChange={(e) =>
              setRiggingObj({
                ...riggingObj,
                capacities: {
                  ...riggingObj.capacities,
                  vertical:
                    e.target.value === 0 ||
                    e.target.value === null ||
                    e.target.value === undefined
                      ? null
                      : parseFloat(e.target.value),
                },
              })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Choker Capacity (lbs)</p>
          <Input
            placeholder="Enter choker capacity (lbs)"
            type="number"
            name="choker"
            value={riggingObj.capacities.choker}
            onChange={(e) =>
              setRiggingObj({
                ...riggingObj,
                capacities: {
                  ...riggingObj.capacities,
                  choker:
                    e.target.value === 0 ||
                    e.target.value === null ||
                    e.target.value === undefined
                      ? null
                      : parseFloat(e.target.value),
                },
              })
            }
          />
        </InputContainer>
        <InputContainer>
          <p>Basket Capacity (lbs)</p>
          <Input
            placeholder="Enter basket capacity (lbs)"
            type="number"
            name="basket"
            value={riggingObj.capacities.basket}
            onChange={(e) =>
              setRiggingObj({
                ...riggingObj,
                capacities: {
                  ...riggingObj.capacities,
                  basket:
                    e.target.value === 0 ||
                    e.target.value === null ||
                    e.target.value === undefined
                      ? null
                      : parseFloat(e.target.value),
                },
              })
            }
          />
        </InputContainer>
        <Button
          style={{ width: '256px', marginBottom: '20px' }}
          disabled={isLoading}
          onClick={() => {
            console.log(riggingObj);
            addRigging(riggingObj);
          }}
        >
          {isLoading ? <Loader /> : 'Create Rigging Asset'}
        </Button>
      </Modal>
    </MainContainer>
  );
}
