import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceDot,
  Tooltip,
  Legend,
} from 'recharts';
import { setNotification } from '../../context/liftpage';
import Button from '../../reusables/Button';
import { useTheme } from 'styled-components/macro';

let screenHeight = window.innerHeight;
let screenWidth = window.innerWidth;

const DiagramContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  user-select: 'none';
`;

export default function Diagram({
  distances,
  selectedAttachment,
  boomPinHeight,
  customObstacles,
  updateCraneDetails,
}) {
  const { selectedBoomLength, selectedOffset } = useSelector(
    (state) => state.site.craneDetails
  );
  const { width, height } = useSelector((state) => state.page);
  const { loadHeight, loadWidth, selectedLoad, loadDiameter } = useSelector(
    (state) => state.site.loadDetails
  );
  const [chartHeight, setChartHeight] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const [tempObstacle, setTempObstacle] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [downPoint, setDownPoint] = useState(null);
  const [customObstacleData, setCustomObstacleData] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currObstacle, setCurrObstacle] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [hoveredSide, setHoveredSide] = useState([]);
  const radiusTotal = parseFloat(distances);
  const dispatch = useDispatch();
  const xaxis = useRef();
  const theme = useTheme();

  let tipHeight = null;
  let knuckleHeight = null;
  let knuckleRadius = null;
  if (!selectedBoomLength) {
    dispatch(
      setNotification({
        text: 'Configuration Error, please change options',
        type: 'error',
      })
    );
    return null;
  }
  let targetWidth = null;
  const feetBoomPinHeight = boomPinHeight / 304.8; // millimeters to feet

  // calculate start and end location of boom
  if (selectedAttachment.name === 'Main Boom') {
    targetWidth = radiusTotal;
    tipHeight = Math.sqrt(
      selectedBoomLength ** 2 - targetWidth ** 2 + feetBoomPinHeight
    );
  } else {
    // with attachment
    // got numbers
    let attachmentLength = selectedAttachment.length;
    let adjustedBoomLength = selectedBoomLength;
    if (selectedAttachment.hingePoint) {
      attachmentLength += selectedAttachment.hingePoint;
      adjustedBoomLength -= selectedAttachment.hingePoint;
    }

    const reach = Math.sqrt(
      attachmentLength ** 2 +
        adjustedBoomLength ** 2 -
        2 *
          attachmentLength *
          adjustedBoomLength *
          Math.cos((180 - selectedOffset) * (Math.PI / 180))
    );

    tipHeight = Math.sqrt(reach ** 2 - radiusTotal ** 2 + 3);
    // DEGREE ONE
    const deg1 = Math.atan2(tipHeight, radiusTotal);
    // DEGREE TWO
    const deg2 = Math.acos(
      (adjustedBoomLength ** 2 + reach ** 2 - attachmentLength ** 2) /
        (2 * adjustedBoomLength * reach)
    );
    if (
      (adjustedBoomLength ** 2 + reach ** 2 - attachmentLength ** 2) /
        (2 * adjustedBoomLength * reach) <
      0.9999997
    ) {
      knuckleHeight = adjustedBoomLength * Math.sin(deg1 + deg2);
    }
    knuckleRadius = Math.sqrt(adjustedBoomLength ** 2 - knuckleHeight ** 2);
  }

  const twoDecimalOrNull = (x) => {
    return parseFloat((x || 0).toFixed(2)) || null;
  };

  const displayObstacleHeights = (obstacles) => {
    const dots = [];
    for (let i = 0; i < obstacles.length; i += 5) {
      if (
        obstacles[i + 2].CobstacleHeight !== obstacles[i + 3].CobstacleHeight
      ) {
        dots.push(
          <ReferenceDot
            x={obstacles[i + 2].distance}
            y={obstacles[i + 2].CobstacleHeight + 5}
            r={0}
            label={`${obstacles[i + 2].CobstacleHeight}ft`}
            stroke="none"
          />
        );
        dots.push(
          <ReferenceDot
            x={obstacles[i + 3].distance}
            y={obstacles[i + 3].CobstacleHeight + 5}
            r={0}
            label={`${obstacles[i + 3].CobstacleHeight}ft`}
            stroke="none"
          />
        );
      } else {
        dots.push(
          <ReferenceDot
            x={(obstacles[i + 2].distance + obstacles[i + 3].distance) / 2}
            y={obstacles[i + 3].CobstacleHeight + 5}
            r={0}
            label={`${obstacles[i + 3].CobstacleHeight}ft`}
            stroke="none"
          />
        );
      }
    }
    return dots;
  };

  const getLoadHeight = (obstacles) => {
    let max = 0;
    let width =
      selectedLoad === 'Cylinder'
        ? parseFloat(loadDiameter) / 2
        : parseFloat(loadWidth) / 2;
    for (let i = 0; i < obstacles.length; i++) {
      if (obstacles[i].CobstacleHeight > 0) {
        if (
          obstacles[i].CobstacleHeight === obstacles[i + 1].CobstacleHeight &&
          ((obstacles[i].distance >= pickData[0].distance - width &&
            obstacles[i].distance <= pickData[0].distance + width) ||
            (obstacles[i + 1].distance >= pickData[0].distance - width &&
              obstacles[i + 1].distance <= pickData[0].distance + width) ||
            (obstacles[i].distance <= pickData[0].distance - width &&
              obstacles[i + 1].distance >= pickData[0].distance + width) ||
            (obstacles[i + 1].distance <= pickData[0].distance - width &&
              obstacles[i].distance >= pickData[0].distance + width))
        ) {
          if (obstacles[i].CobstacleHeight > max) {
            max = obstacles[i].CobstacleHeight;
          }
        } else if (
          obstacles[i].distance >= pickData[0].distance - width &&
          obstacles[i].distance <= pickData[0].distance + width
        ) {
          if (obstacles[i].CobstacleHeight > max) {
            max = obstacles[i].CobstacleHeight;
          }
        } else if (
          obstacles[i + 1].distance >= pickData[0].distance - width &&
          obstacles[i + 1].distance <= pickData[0].distance + width
        ) {
          if (obstacles[i + 1].CobstacleHeight > max) {
            max = obstacles[i + 1].CobstacleHeight;
          }
        }
      }
    }
    return max;
  };

  const getObstacleDistance = (obstacles) => {
    let min = null;
    for (let i = 0; i < obstacles.length; i++) {
      if (obstacles[i].distance > 0) {
        if (min === null) {
          min = obstacles[i].distance;
        } else if (obstacles[i].distance < min) {
          min = obstacles[i].distance;
        }
      }
    }
    return min;
  };

  const getBoomClearance = (obstacles, boomData) => {
    let min = 9999999;
    let dist = null;
    let boomPoint = null;
    let obstaclePoint = null;
    if (boomData.length === 2) {
      let slope =
        (boomData[1].craneHeight - boomData[0].craneHeight) /
        (boomData[1].distance - boomData[0].distance);
      let intercept = boomData[0].craneHeight;
      for (let i = 0; i < obstacles.length; i++) {
        if (obstacles[i].distance > 0) {
          let tempBoom = slope * obstacles[i].distance + intercept;
          let tempdist = Math.abs(tempBoom - obstacles[i].CobstacleHeight);
          if (tempdist < min) {
            min = tempdist;
            dist = obstacles[i].distance;
            boomPoint = tempBoom;
            obstaclePoint = obstacles[i].CobstacleHeight;
          }
        }
      }
    }
    if (boomData.length === 3) {
      let slope1 =
        (boomData[1].craneHeight - boomData[0].craneHeight) /
        (boomData[1].distance - boomData[0].distance);
      let intercept1 = boomData[0].craneHeight;
      let slope2 =
        (boomData[2].craneHeight - boomData[1].craneHeight) /
        (boomData[2].distance - boomData[1].distance);
      let intercept2 = boomData[2].craneHeight - slope2 * boomData[2].distance;
      for (let i = 0; i < obstacles.length; i++) {
        if (obstacles[i].distance > 0) {
          let tempBoom = 0;
          if (
            obstacles[i].distance > 0 &&
            obstacles[i].distance < boomData[1].distance
          ) {
            tempBoom = slope1 * obstacles[i].distance + intercept1;
          } else if (obstacles[i].distance > boomData[1].distance) {
            tempBoom = slope2 * obstacles[i].distance + intercept2;
          }
          let tempdist = Math.abs(tempBoom - obstacles[i].CobstacleHeight);
          if (tempdist < min) {
            min = tempdist;
            dist = obstacles[i].distance;
            boomPoint = tempBoom;
            obstaclePoint = obstacles[i].CobstacleHeight;
          }
        }
      }
    }
    return [
      { distance: dist, height: boomPoint },
      { distance: dist, height: obstaclePoint },
    ];
  };

  const boomData = [
    { distance: 0, craneHeight: feetBoomPinHeight },
    {
      distance: twoDecimalOrNull(radiusTotal),
      craneHeight: twoDecimalOrNull(tipHeight),
    },
  ];
  const newBoomData = [
    { distance: 0, craneHeight: feetBoomPinHeight },
    {
      distance: twoDecimalOrNull(knuckleRadius),
      craneHeight: twoDecimalOrNull(knuckleHeight),
    },
    {
      distance: twoDecimalOrNull(radiusTotal),
      craneHeight: twoDecimalOrNull(tipHeight),
    },
  ];
  const pickData = [
    {
      distance: twoDecimalOrNull(radiusTotal),
      pickLocation: twoDecimalOrNull(0),
    },
  ];
  const loadData =
    selectedLoad === 'Cylinder'
      ? [
          {
            distance: pickData[0].distance,
            Load:
              tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData),
          },
          {
            distance:
              pickData[0].distance -
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) +
              parseFloat(loadDiameter) / 2 -
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
          },
          {
            distance: pickData[0].distance - parseFloat(loadDiameter) / 2,
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) +
              parseFloat(loadDiameter) / 2,
          },
          {
            distance:
              pickData[0].distance -
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) +
              parseFloat(loadDiameter) / 2 +
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
          },
          {
            distance: pickData[0].distance,
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) + parseFloat(loadDiameter),
          },
          {
            distance:
              pickData[0].distance +
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) +
              parseFloat(loadDiameter) / 2 +
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
          },
          {
            distance: pickData[0].distance + parseFloat(loadDiameter) / 2,
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) +
              parseFloat(loadDiameter) / 2,
          },
          {
            distance:
              pickData[0].distance +
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
            Load:
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)) +
              parseFloat(loadDiameter) / 2 -
              (parseFloat(loadDiameter) / 2) * (Math.sqrt(2) / 2),
          },
          {
            distance: pickData[0].distance,
            Load:
              tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData),
          },
        ]
      : [
          {
            distance: pickData[0].distance - parseFloat(loadWidth) / 2,
            Load:
              tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData),
          },
          {
            distance: pickData[0].distance - parseFloat(loadWidth) / 2,
            Load:
              parseFloat(loadHeight) +
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)),
          },
          {
            distance: pickData[0].distance + parseFloat(loadWidth) / 2,
            Load:
              parseFloat(loadHeight) +
              (tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData)),
          },
          {
            distance: pickData[0].distance + parseFloat(loadWidth) / 2,
            Load:
              tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData),
          },
          {
            distance: pickData[0].distance - parseFloat(loadWidth) / 2,
            Load:
              tempObstacle.length > 0
                ? getLoadHeight([...customObstacleData, ...tempObstacle])
                : getLoadHeight(customObstacleData),
          },
        ];
  const lineData = [
    {
      distance: pickData[0].distance,
      Load: tipHeight,
    },
    {
      distance: pickData[0].distance,
      Load:
        selectedLoad === 'Cylinder'
          ? parseFloat(loadDiameter) +
            (tempObstacle.length > 0
              ? getLoadHeight([...customObstacleData, ...tempObstacle])
              : getLoadHeight(customObstacleData))
          : parseFloat(loadHeight) +
            (tempObstacle.length > 0
              ? getLoadHeight([...customObstacleData, ...tempObstacle])
              : getLoadHeight(customObstacleData)),
    },
  ];
  const selectedBoomData = knuckleHeight ? newBoomData : boomData;
  useEffect(() => {
    screenHeight = window.innerHeight;
    screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      screenWidth -= 200;
    }
    let heightDifference = (screenHeight - 300) / tipHeight;
    let widthDifference = (screenWidth - 200) / radiusTotal;
    if (heightDifference > widthDifference) {
      setChartHeight(tipHeight * widthDifference);
      setChartWidth(radiusTotal * widthDifference);
    } else {
      setChartHeight(tipHeight * heightDifference);
      setChartWidth(
        Math.min(radiusTotal * heightDifference * 2, screenWidth - 200)
      );
    }
  }, [width, height]);

  const generateXTicks = (maxDistance) => {
    let xTicks = [];
    for (let i = 0; i <= Math.ceil(maxDistance / 5) * 5 + 20; i = i + 5) {
      xTicks.push(i);
    }
    return xTicks;
  };

  const interval = (maxDistance) => {
    if (maxDistance + 20 > 150) {
      return 3;
    } else if (maxDistance + 20 > 75) {
      return 1;
    }
    return 0;
  };

  const thingy = (e) => {
    if (isEditing) {
      const X =
        Math.round(
          ((e.chartX - 65) / (chartWidth + 100 - 65)) *
            (pickData[0].distance + 20 + (pickData[0].distance + 20) * 0.023) *
            2
        ) / 2;

      const Y = Math.round(
        tipHeight -
          ((e.chartY - 5) /
            (chartHeight -
              document
                .getElementsByClassName('xAxis')[0]
                .getBoundingClientRect().height)) *
            tipHeight
      );
      let c = false;
      let bigg = [...customObstacleData, ...tempObstacle];
      for (let i = 0; i < bigg.length; i += 5) {
        let diff = (bigg[i + 3].distance - bigg[i + 2].distance) * 0.2;
        if (
          X <= bigg[i + 3].distance + diff &&
          X >= bigg[i + 3].distance - diff &&
          Y <= bigg[i + 3].CobstacleHeight - diff
        ) {
          c = true;
          setCurrObstacle(i);
          setHoveredSide([
            { distance: bigg[i + 3].distance, CobstacleHeight: 0 },
            {
              distance: bigg[i + 3].distance,
              CobstacleHeight: bigg[i + 3].CobstacleHeight,
            },
          ]);
          break;
        } else if (
          X <= bigg[i + 2].distance + diff &&
          X >= bigg[i + 2].distance - diff &&
          Y <= bigg[i + 2].CobstacleHeight - diff
        ) {
          c = true;
          setCurrObstacle(i);
          setHoveredSide([
            { distance: bigg[i + 2].distance, CobstacleHeight: 0 },
            {
              distance: bigg[i + 2].distance,
              CobstacleHeight: bigg[i + 2].CobstacleHeight,
            },
          ]);
          break;
        }

        if (bigg[i + 2].CobstacleHeight === bigg[i + 3].CobstacleHeight) {
          if (
            X <= bigg[i + 3].distance + diff &&
            X >= bigg[i + 2].distance - diff &&
            Y <= bigg[i + 3].CobstacleHeight + diff &&
            Y >= bigg[i + 3].CobstacleHeight - diff
          ) {
            c = true;
            setCurrObstacle(i);
            setHoveredSide([
              {
                distance: bigg[i + 2].distance,
                CobstacleHeight: bigg[i + 2].CobstacleHeight,
              },
              {
                distance: bigg[i + 3].distance,
                CobstacleHeight: bigg[i + 3].CobstacleHeight,
              },
            ]);
            break;
          } else if (
            X <= bigg[i + 3].distance - diff &&
            X >= bigg[i + 2].distance + diff &&
            Y <= bigg[i + 3].CobstacleHeight
          ) {
            c = true;
            setCurrObstacle(i);
          }
        } else {
          let slope =
            (bigg[i + 3].CobstacleHeight - bigg[i + 2].CobstacleHeight) /
            (bigg[i + 3].distance - bigg[i + 2].distance);
          if (
            X <= bigg[i + 3].distance - diff &&
            X >= bigg[i + 1].distance + diff &&
            Y <=
              slope * X +
                (bigg[i + 2].CobstacleHeight - slope * bigg[i + 2].distance)
          ) {
            c = true;
            setCurrObstacle(i);
          }
        }
        setHoveredSide([]);
      }

      if (currObstacle !== null && !isSelected) {
        let a = [...customObstacleData];
        let b = a.splice(0 + currObstacle, 5 + currObstacle);
        setTempObstacle(b);
        setCustomObstacleData(a);
        setIsSelected(true);
      } else if (!c && hoveredSide.length < 1) {
        setCurrObstacle(null);
        setCustomObstacleData([...customObstacleData, ...tempObstacle]);
        setTempObstacle([]);
        setIsSelected(false);
      }
    }
  };

  useEffect(() => {
    if (customObstacles) {
      setCustomObstacleData(customObstacles);
    }
  }, [customObstacles]);

  const handleMouseMove = (e) => {
    if (!isDrawing && !isEditing) {
      return;
    }
    if (!e) {
      setIsMouseDown(false);
      setCustomObstacleData([...customObstacleData, ...tempObstacle]);
      setTempObstacle([]);
      setHoveredSide([]);
      setCurrObstacle(null);
      setIsSelected(false);
      return;
    }
    const X =
      Math.round(
        ((e.chartX - 65) / (chartWidth + 100 - 65)) *
          (pickData[0].distance + 20 + (pickData[0].distance + 20) * 0.023) *
          2
      ) / 2;

    const Y = Math.round(
      tipHeight -
        ((e.chartY - 5) /
          (chartHeight -
            document.getElementsByClassName('xAxis')[0].getBoundingClientRect()
              .height)) *
          tipHeight
    );
    if (isMouseDown) {
      if (isDrawing) {
        const temp = [
          {
            name: 'tempObstacle',
            distance: 0,
            CobstacleHeight: 0,
          },
        ];

        if (X > downPoint.X) {
          temp.push({
            name: 'tempObstacle',
            distance: downPoint.X,
            CobstacleHeight: 0,
          });
          temp.push({
            name: 'tempObstacle',
            distance: downPoint.X,
            CobstacleHeight: downPoint.Y,
          });
          if (Y > downPoint.Y + 5 || Y < downPoint.Y - 5) {
            temp.push({
              name: 'tempObstacle',
              distance: X,
              CobstacleHeight: Y,
            });
          } else {
            temp.push({
              name: 'tempObstacle',
              distance: X,
              CobstacleHeight: downPoint.Y,
            });
          }
          temp.push({
            name: 'tempObstacle',
            distance: X,
            CobstacleHeight: 0,
          });
        } else {
          temp.push({
            name: 'tempObstacle',
            distance: X,
            CobstacleHeight: 0,
          });
          if (Y > downPoint.Y + 5 || Y < downPoint.Y - 5) {
            temp.push({
              name: 'tempObstacle',
              distance: X,
              CobstacleHeight: Y,
            });
          } else {
            temp.push({
              name: 'tempObstacle',
              distance: X,
              CobstacleHeight: downPoint.Y,
            });
          }
          temp.push({
            name: 'tempObstacle',
            distance: downPoint.X,
            CobstacleHeight: downPoint.Y,
          });
          temp.push({
            name: 'tempObstacle',
            distance: downPoint.X,
            CobstacleHeight: 0,
          });
        }
        setTempObstacle(temp);
      }
      if (isEditing) {
        thingy(e);
        if (currObstacle !== null) {
          if (
            (Math.abs(downPoint.Y - Y) >= 1 ||
              Math.abs(downPoint.X - X) >= 0.5) &&
            tempObstacle.length > 0
          ) {
            let temp = [...tempObstacle];
            if (hoveredSide.length > 0) {
              if (hoveredSide[0].distance === hoveredSide[1].distance) {
                if (temp[1].distance === hoveredSide[0].distance) {
                  temp[1] = {
                    name: 'tempObstacle',
                    distance: temp[1].distance + X - downPoint.X,
                    CobstacleHeight: temp[1].CobstacleHeight,
                  };
                  temp[2] = {
                    name: 'tempObstacle',
                    distance: temp[2].distance + X - downPoint.X,
                    CobstacleHeight: temp[2].CobstacleHeight + Y - downPoint.Y,
                  };
                } else if (temp[3].distance === hoveredSide[0].distance) {
                  temp[3] = {
                    name: 'tempObstacle',
                    distance: temp[3].distance + X - downPoint.X,
                    CobstacleHeight: temp[3].CobstacleHeight + Y - downPoint.Y,
                  };
                  temp[4] = {
                    name: 'tempObstacle',
                    distance: temp[4].distance + X - downPoint.X,
                    CobstacleHeight: temp[4].CobstacleHeight,
                  };
                }
              }
              if (
                hoveredSide[0].CobstacleHeight ===
                hoveredSide[1].CobstacleHeight
              ) {
                temp[2] = {
                  name: 'tempObstacle',
                  distance: temp[2].distance,
                  CobstacleHeight: temp[2].CobstacleHeight + Y - downPoint.Y,
                };
                temp[3] = {
                  name: 'tempObstacle',
                  distance: temp[3].distance,
                  CobstacleHeight: temp[3].CobstacleHeight + Y - downPoint.Y,
                };
              }
            } else {
              temp[1] = {
                name: 'tempObstacle',
                distance: temp[1].distance + X - downPoint.X,
                CobstacleHeight: temp[1].CobstacleHeight,
              };
              temp[2] = {
                name: 'tempObstacle',
                distance: temp[2].distance + X - downPoint.X,
                CobstacleHeight: temp[2].CobstacleHeight + Y - downPoint.Y,
              };
              temp[3] = {
                name: 'tempObstacle',
                distance: temp[3].distance + X - downPoint.X,
                CobstacleHeight: temp[3].CobstacleHeight + Y - downPoint.Y,
              };
              temp[4] = {
                name: 'tempObstacle',
                distance: temp[4].distance + X - downPoint.X,
                CobstacleHeight: temp[4].CobstacleHeight,
              };
            }
            setTempObstacle(temp);
            setDownPoint({
              X: X,
              Y: Y,
            });
          }
        }
      }
    }
    thingy(e);
  };

  const handleMouseDown = (e) => {
    if (!isDrawing && !isEditing) {
      return;
    }
    if (!isMouseDown) {
      const X =
        Math.round(
          ((e.chartX - 65) / (chartWidth + 100 - 65)) *
            (pickData[0].distance + 20 + (pickData[0].distance + 20) * 0.023) *
            2
        ) / 2;

      const Y = Math.round(
        tipHeight -
          ((e.chartY - 5) /
            (chartHeight -
              document
                .getElementsByClassName('xAxis')[0]
                .getBoundingClientRect().height)) *
            tipHeight
      );
      setDownPoint({
        X: X,
        Y: Y,
      });
      setIsMouseDown(true);
    }
  };

  const handleMouseUp = () => {
    if (!isDrawing && !isEditing) {
      return;
    }
    setIsMouseDown(false);
    setCustomObstacleData([...customObstacleData, ...tempObstacle]);
    setTempObstacle([]);
    setCurrObstacle(null);
    setIsSelected(false);
  };

  const renderLineChart = () => {
    return (
      <DiagramContainer style={{ zIndex: 9 }}>
        <ComposedChart
          width={chartWidth + 100}
          height={chartHeight}
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={() => handleMouseUp()}
          onMouseMove={(e) => handleMouseMove(e)}
          onTouchStart={(e) => handleMouseDown(e)}
          onTouchEnd={() => handleMouseUp()}
          onTouchMove={(e) => handleMouseMove(e)}
          style={{ userSelect: 'none' }}
        >
          <CartesianGrid strokeDasharray="10" style={{ userSelect: 'none' }} />
          <YAxis
            label={{ value: '(ft)', position: 'insideLeft', offset: 0 }}
            dataKey="craneHeight"
            type="number"
            scale="linear"
            interval={0}
            tickCount={20}
            style={{ userSelect: 'none' }}
          />
          <XAxis
            label={{ value: '(ft)', position: 'insideBottom', offset: -5 }}
            ticks={generateXTicks(pickData[0].distance)}
            tick={true}
            tickLine={true}
            padding={{ right: 10 }}
            allowDecimals={false}
            dataKey="distance"
            scale="linear"
            type="number"
            interval={interval(pickData[0].distance)}
            ref={xaxis}
            style={{ userSelect: 'none' }}
          />
          <Line
            data={pickData}
            dataKey="pickLocation"
            name="Pick Point"
            type="stepAfter"
            fill="green"
            stroke="green"
            isAnimationActive={false}
          />
          <Line
            data={lineData}
            dataKey="Load"
            name="Line"
            type="stepAfter"
            fill="black"
            legendType="none"
            tooltipType="none"
            stroke="black"
            isAnimationActive={false}
          />
          <Line
            data={selectedBoomData}
            dataKey="craneHeight"
            name="Boom Elevation"
            type="linear"
            fill="yellow"
            stroke="orange"
            isAnimationActive={false}
          />
          <Line
            data={
              tempObstacle.length > 0
                ? getBoomClearance(
                    [...customObstacleData, ...tempObstacle],
                    selectedBoomData
                  )
                : getBoomClearance(customObstacleData, selectedBoomData)
            }
            dataKey="height"
            name="Boom Elevation"
            type="linear"
            stroke="purple"
            fill="purple"
            isAnimationActive={false}
          />
          <Area
            data={tempObstacle}
            dot={false}
            dataKey="CobstacleHeight"
            name="Temp Obstacle Height"
            legendType="none"
            tooltipType="none"
            type="linear"
            fill="blue"
            stroke="blue"
            isAnimationActive={false}
          />
          <Area
            data={customObstacleData}
            dot={false}
            dataKey="CobstacleHeight"
            name="Obstacle Height"
            type="linear"
            fill="red"
            stroke="red"
            isAnimationActive={false}
          />
          <Area
            data={loadData}
            dot={false}
            dataKey="Load"
            name="Load"
            legendType="none"
            tooltipType="none"
            type="linear"
            fill="green"
            stroke="green"
            isAnimationActive={false}
          />
          <Line
            data={hoveredSide}
            dataKey="CobstacleHeight"
            name="Hover"
            type="linear"
            stroke="red"
            fill="red"
            strokeWidth={3}
            isAnimationActive={false}
          />
          {displayObstacleHeights(customObstacleData)}
        </ComposedChart>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            gap: '24px',
          }}
        >
          <p>{`Boom tip height: ${Math.round(tipHeight)}ft`}</p>
          <p>
            {`Obstacle Distance: ${
              twoDecimalOrNull(
                tempObstacle.length > 0
                  ? getObstacleDistance([
                      ...customObstacleData,
                      ...tempObstacle,
                    ])
                  : getObstacleDistance(customObstacleData)
              ) || 0
            }ft`}
          </p>
          <p>
            {`Load Height: ${
              twoDecimalOrNull(
                tempObstacle.length > 0
                  ? getLoadHeight([...customObstacleData, ...tempObstacle])
                  : getLoadHeight(customObstacleData)
              ) || 0
            }ft`}
          </p>
          <p>
            {`Boom Clearance: ${
              twoDecimalOrNull(
                tempObstacle.length > 0
                  ? getBoomClearance(
                      [...customObstacleData, ...tempObstacle],
                      selectedBoomData
                    )[0].height -
                      getBoomClearance(
                        [...customObstacleData, ...tempObstacle],
                        selectedBoomData
                      )[1].height
                  : getBoomClearance(customObstacleData, selectedBoomData)[0]
                      .height -
                      getBoomClearance(customObstacleData, selectedBoomData)[1]
                        .height
              ) || 0
            }ft`}
          </p>
        </div>
        <div style={{ marginTop: '20px', display: 'flex', gap: '24px' }}>
          {isDrawing && (
            <>
              <Button
                theme={theme.buttons.editJobWalk}
                onClick={() => setCustomObstacleData([])}
              >
                Reset
              </Button>
              <Button
                theme={theme.buttons.editJobWalk}
                onClick={() => {
                  updateCraneDetails({ customObstacles: customObstacleData });
                  setIsDrawing(false);
                }}
              >
                Save
              </Button>
            </>
          )}{' '}
          {!isDrawing && !isEditing && (
            <>
              <Button
                theme={theme.buttons.editJobWalk}
                onClick={() => setIsDrawing(true)}
              >
                Draw
              </Button>
              <Button
                theme={theme.buttons.editJobWalk}
                style={{ width: 'auto' }}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Edit Obstacles
              </Button>
            </>
          )}{' '}
          {isEditing && (
            <>
              <Button
                theme={theme.buttons.editJobWalk}
                onClick={() => {
                  updateCraneDetails({
                    customObstacles: [...customObstacleData, ...tempObstacle],
                  });
                  setIsEditing(false);
                }}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </DiagramContainer>
    );
  };

  return renderLineChart();
}
