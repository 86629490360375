import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useStytchSession, useStytch } from '@stytch/react';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { io } from 'socket.io-client';
import userReducer, { setSocketId } from '../context/user.jsx';
import pageReducer from '../context/liftpage';
import siteReducer from '../context/siteplan';
import MainWrapper from '../wrappers/MainWrapper/MainWrapper';

import { API_URL } from '../services/api';
import Home from '../pages/Home/Home';
import Admin from '../pages/Admin/Admin';
import FormWrapper from '../wrappers/FormWrapper/FormWrapper';
import socketMiddleware from '../helpers/websocketMiddleware';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

function PrivateRoute({ path, ...rest }) {
  const stytchClient = useStytch();
  const { session } = useStytchSession();
  const [store, setStore] = useState(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!session) {
      console.log('Session logged out');
    }
  }, [session]);

  useEffect(() => {
    if (!socket) return;
    socket.on('siteplanUpdated', (data) => {
      // from here you can dispatch actions and stuff depending on emit type
      store.dispatch({
        type: 'page/setRefresh',
        payload: true,
      });
    });
    socket.on('alone', (data) => {
      store.dispatch({
        type: 'page/setRefresh',
        payload: false,
      });
    });
  }, [socket]);

  useEffect(() => {
    console.log('Redux Refreshed');
    let storeLink = null;
    const socketInstance = io(API_URL);
    socketInstance.on('connect', () => {
      if (storeLink) {
        storeLink.dispatch(setSocketId(socketInstance.id));
        return;
      }
      setSocket(socketInstance);
      storeLink = configureStore({
        reducer: {
          user: userReducer,
          page: pageReducer,
          site: siteReducer,
        },
        middleware: [
          ...getDefaultMiddleware(),
          socketMiddleware(socketInstance),
        ],
        preloadedState: {
          user: {
            socketId: socketInstance.id,
          },
        },
        enhancers: [sentryReduxEnhancer],
      });
      setStore(storeLink);
    });
  }, []);

  useEffect(() => {
    const refresh = () => {
      console.log('Refresh function called');
      if (stytchClient.session.getSync()) {
        const time =
          (new Date(stytchClient.session.getSync().expires_at) - new Date()) /
          1000 /
          60;
        console.log(`Time remaining: ${Math.round(time * 10) / 10} minutes`);
        if (time < 10) {
          console.log(
            `Session refreshed with ${Math.round(time * 10) / 10} minutes left`
          );
          stytchClient.session.authenticate({
            session_duration_minutes: Math.round(60 + time),
          });
        } else {
          console.log(
            `Next check should be at ${Math.round(time * 10) / 10 - 5}`
          );
        }
      }
    };
    const interval = setInterval(refresh, 300000);
    console.log('Starting session timer');
    refresh();
    return () => clearInterval(interval);
  }, [stytchClient]);

  if (!session) {
    return <Redirect to="/login" />;
  }
  return (
    store && (
      <Provider store={store}>
        <MainWrapper {...rest}>
          <Switch>
            <Route
              exact
              path={['/', '/home']}
              render={(props) => <Home {...props} />}
            />
            <Route
              exact
              path="/admin"
              render={(props) => <Admin {...props} />}
            />
            <Route
              exact
              path="/liftplan/:id"
              render={(props) => <FormWrapper {...props} upgraded />}
            />
            <Route
              exact
              path="/jobwalk/:id"
              render={(props) => <FormWrapper {...props} upgraded={false} />}
            />
          </Switch>
        </MainWrapper>
      </Provider>
    )
  );
}

export default PrivateRoute;
