import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../reusables/Modal/Modal';
import Input from '../../reusables/InputBar';
import { isValidNumber } from '../../helpers/validate';
import { removeLeadingZeroes } from '../../helpers/utils';
import Button from '../../reusables/Button';
import { SelectContainer } from './siteplan_components';
import { setDeductionModal } from '../../context/liftpage';
import { updateCraneDetails, updateLoadDetails } from '../../context/siteplan';
import calculateDeductions from '../../reusables/DeductionCalc';
import { saveData } from '../../helpers/saveData';
import FilledPlus from '../../assets/images/FilledPlus.svg';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  gap: 12px;
  min-height: 0;
  overflow-y: scroll;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    font-size: 16px;
    color: #2a2f35;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 12px;
`;

const LoadedLine = styled.div`
  border-radius: 8px;
  background: #f0f2f5;
  padding: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

const ExtraLine = styled.div`
  border-radius: 8px;
  border: 1px dashed #000;
  padding: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export default function DeductionsModal() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    attachmentExtension,
    sheaves,
    partsOfLine,
    auxsheaves,
    auxpartsOfLine,
    selectedCrane,
    otherDeductions,
    riggingDeductions,
    hookBlocks,
    attachmentStatus,
    auxLinePresent,
    hangingHeight,
    mainLinePresent,
    grossCap,
    selectedBoomLength,
    selectedAttachment,
  } = useSelector((state) => state.site.craneDetails);
  const { deductionModal } = useSelector((state) => state.page);
  const { loadDetails, craneDetails } = useSelector((state) => state.site);
  if (!deductionModal) return null;

  const generateHookblocksDropdown = () =>
    hookBlocks.map((aHook) => (
      <option
        value={aHook.sheaves}
        key={aHook.sheaves}
      >{`${aHook.sheaves} Sheaves`}</option>
    ));

  const handleHookblockSelection = (e) => {
    const theHookblock = hookBlocks.filter(
      (hook) => hook.sheaves === parseInt(e.target.value)
    )[0];
    const information = e.target.value.split('-');
    dispatch(
      updateCraneDetails({
        sheaves: parseInt(information[0]),
        partsOfLine: 'default',
        selectedHookblock: {
          sheaves: parseInt(information[0]),
          capacity: theHookblock.capacity,
          weight: theHookblock.weight,
        },
      })
    );
  };

  const handleAUXHookblockSelection = (e) => {
    const theHookblock = hookBlocks.filter(
      (hook) => hook.sheaves === parseInt(e.target.value)
    )[0];
    const information = e.target.value.split('-');
    dispatch(
      updateCraneDetails({
        auxsheaves: parseInt(information[0]),
        auxPartsOfLine: 'default',
        auxselectedHookblock: {
          sheaves: parseInt(information[0]),
          capacity: theHookblock.capacity,
          weight: theHookblock.weight,
        },
      })
    );
  };

  const generatePartsOfLine = () => {
    const optionList = [];
    if (!sheaves) {
      optionList.push(<option key={1}>{1}</option>);
      return optionList;
    }
    for (let i = 1; i <= sheaves * 2 + 1; i += 1) {
      optionList.push(<option key={i}>{i}</option>);
    }
    return optionList;
  };

  const generateAUXPartsOfLine = () => {
    const optionList = [];
    if (!auxsheaves) {
      optionList.push(<option key={1}>{1}</option>);
      return optionList;
    }
    for (let i = 1; i <= auxsheaves * 2 + 1; i += 1) {
      optionList.push(<option key={i}>{i}</option>);
    }
    return optionList;
  };

  const handleAttachmentExtension = (e) => {
    if (e.target.value === 'default') {
      dispatch(
        updateCraneDetails({
          attachmentStatus: 'absent',
          attachmentExtension: 'default',
        })
      );
    } else {
      const attachment = selectedCrane.attachments.filter(
        (attachment) => attachment.name === e.target.value
      )[0];
      dispatch(
        updateCraneDetails({
          attachmentStatus: 'stowed',
          attachmentExtension: attachment,
        })
      );
      dispatch(
        updateLoadDetails({
          estimatedDeductions: 0,
        })
      );
    }
  };

  const generateAttachmentExtensionDropdown = () => {
    const list = [];
    for (let i = 1; i < selectedCrane.attachments.length; i += 1) {
      list.push(
        <option
          value={selectedCrane.attachments[i].name}
          key={selectedCrane.attachments[i].name}
        >
          {selectedCrane.attachments[i].name}
        </option>
      );
    }
    return list;
  };

  return (
    <Modal
      show={deductionModal}
      modalStyles={theme.modals.info}
      title="Add/Edit Deductions"
      handleClose={() => {
        dispatch(setDeductionModal(false));
      }}
    >
      <ModalWrapper>
        {selectedAttachment.name === 'Main Boom' ? (
          <InputWrapper>
            <p>Select Stowed Jib/Extension</p>
            <SelectContainer>
              <select
                value={attachmentExtension.name || 'default'}
                onChange={(e) => handleAttachmentExtension(e)}
              >
                <option value="default">N/A</option>
                {generateAttachmentExtensionDropdown()}
              </select>
            </SelectContainer>
          </InputWrapper>
        ) : null}

        <p>Loaded Line</p>
        <LoadedLine>
          <InputWrapper>
            <p>Hookblock</p>
            <SelectContainer>
              <select
                value={sheaves || sheaves === 0 ? sheaves : 'default'}
                onChange={(e) => handleHookblockSelection(e)}
              >
                <option value="default" disabled hidden>
                  Select Sheaves
                </option>
                {generateHookblocksDropdown()}
              </select>
            </SelectContainer>
          </InputWrapper>
          <InputWrapper>
            <p>Parts Of Line</p>
            <SelectContainer>
              <select
                value={partsOfLine || 'default'}
                onChange={(e) => {
                  dispatch(
                    updateCraneDetails({
                      partsOfLine: parseInt(e.target.value),
                    })
                  );
                  dispatch(
                    updateLoadDetails({
                      estimatedDeductions: 0,
                    })
                  );
                }}
              >
                <option disabled value="default" hidden>
                  Select Parts Of Line
                </option>
                {generatePartsOfLine()}
              </select>
            </SelectContainer>
          </InputWrapper>
        </LoadedLine>
        {auxLinePresent ? (
          <>
            <p>Extra Line Deduction (Unloaded)</p>
            <ExtraLine>
              <InputWrapper>
                <p>Line Hanging Height (ft)</p>
                <Input
                  style={{ fontSize: '16px' }}
                  type="number"
                  value={hangingHeight || 'default'}
                  onChange={(e) => {
                    dispatch(
                      updateCraneDetails({
                        hangingHeight: e.target.value,
                      })
                    );
                    dispatch(
                      updateLoadDetails({
                        estimatedDeductions: 0,
                      })
                    );
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <p>Hookblock</p>
                <SelectContainer>
                  <select
                    value={
                      auxsheaves || auxsheaves === 0 ? auxsheaves : 'default'
                    }
                    onChange={(e) => handleAUXHookblockSelection(e)}
                  >
                    <option value="default" disabled hidden>
                      Select Sheaves
                    </option>
                    {generateHookblocksDropdown()}
                  </select>
                </SelectContainer>
              </InputWrapper>
              <InputWrapper>
                <p>Parts Of Line</p>
                <SelectContainer>
                  <select
                    value={auxpartsOfLine || 'default'}
                    onChange={(e) => {
                      dispatch(
                        updateCraneDetails({
                          auxpartsOfLine: parseInt(e.target.value),
                        })
                      );
                      dispatch(
                        updateLoadDetails({
                          estimatedDeductions: 0,
                        })
                      );
                    }}
                  >
                    <option disabled value="default" hidden>
                      Select Parts Of Line
                    </option>
                    {generateAUXPartsOfLine()}
                  </select>
                </SelectContainer>
              </InputWrapper>
              <Button
                theme={theme.buttons.resetJobWalk}
                onClick={() => {
                  dispatch(
                    updateCraneDetails({
                      auxLinePresent: 0,
                    })
                  );
                }}
              >
                Remove Line
              </Button>
            </ExtraLine>
          </>
        ) : (
          <Button
            theme={theme.buttons.addDeductions}
            onClick={() => {
              dispatch(
                updateCraneDetails({
                  auxLinePresent: 1,
                })
              );
            }}
          >
            <img src={FilledPlus} /> &nbsp;Deduct extra lines
          </Button>
        )}
        <InputWrapper>
          <p>Rigging Deductions (lbs)</p>
          <Input
            style={{ fontSize: '16px' }}
            placeholder="Deduct weight for wire rope, stowed extensions, etc."
            type="number"
            value={riggingDeductions || 0}
            onChange={(e) => {
              if (isValidNumber(e.target.value)) {
                const deductions = removeLeadingZeroes(e.target.value);
                if (deductions === '') {
                  dispatch(
                    updateCraneDetails({
                      riggingDeductions: 0,
                    })
                  );
                } else {
                  dispatch(
                    updateCraneDetails({
                      riggingDeductions: deductions.trim(),
                    })
                  );
                  dispatch(
                    updateLoadDetails({
                      estimatedDeductions: 0,
                    })
                  );
                }
              }
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <p>Other Deductions (lbs)</p>
          <Input
            style={{ fontSize: '16px' }}
            placeholder="Misc deductions"
            type="number"
            value={otherDeductions || 0}
            onChange={(e) => {
              if (isValidNumber(e.target.value)) {
                const deductions = removeLeadingZeroes(e.target.value);
                if (deductions === '') {
                  dispatch(
                    updateCraneDetails({
                      otherDeductions: 0,
                    })
                  );
                } else {
                  dispatch(
                    updateCraneDetails({
                      otherDeductions: deductions.trim(),
                    })
                  );
                  dispatch(
                    updateLoadDetails({
                      estimatedDeductions: 0,
                    })
                  );
                }
              }
            }}
          />
        </InputWrapper>
      </ModalWrapper>
      <ButtonContainer>
        <div style={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            calculateDeductions(
              grossCap,
              selectedBoomLength,
              true,
              (e) => dispatch(updateCraneDetails(e)),
              () => dispatch(saveData()),
              craneDetails,
              loadDetails,
              craneDetails.selectedCrane
            );
            dispatch(setDeductionModal(false));
          }}
          theme={theme.buttons.editJobWalk}
        >
          Confirm
        </Button>
      </ButtonContainer>
    </Modal>
  );
}
