import styled from 'styled-components/macro';

const InputDropdown = styled.select`
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  color: #000;
  height: 50px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  .check {
    display: none;
  }
`;

export default InputDropdown;
