import React, { useEffect, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import styled, { useTheme } from 'styled-components/macro';
import Loader from '../../reusables/Loader/Loader';
import {
  findPlace,
  placeNotes,
  placeObstacles,
  placeCraneOverlay,
  handleOnTilesLoaded,
} from '../Siteplan/sitePlanFunctions';
import MapOverlay from '../Siteplan/siteplan_overlay';
import CollapsibleSection from '../../reusables/CollapsibleSection';
import Button from '../../reusables/Button';
import Modal from '../../reusables/Modal/Modal';
import EmailModal from './EmailModal';
import CraneDiagram from '../GroundPressure/CraneDiagram';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceDot,
} from 'recharts';

const PreviewContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-left: 27px;
  height: 100%;
  padding-right: 27px;
  padding-bottom: 55px;
  @media (min-width: 768px) {
    padding-left: 53px;
    padding-right: 53px;
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 12px;
`;

const GBPContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 12px;
`;

const GBPDivider = styled.div`
  margin: 0 16px;
  width: 100%;
  border-top: 1px solid #e3e6ea;
`;

const DataRow = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
`;

const DataTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #2a2f35;
  width: 50%;
`;

const DataContent = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #2a2f35;
  width: 50%;
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 24px 0;
`;

const NoteRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const NoteContent = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const NoteText = styled.p`
  flex-grow: 1;
  font-size: 16px;
  padding: 0 16px;
`;

const NoteImg = styled.img`
  width: 50%;
`;

const NoteNum = styled.h1`
  background: #2a2f35;
  width: 60px;
  height: 56px;
  font-weight: 700;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5a302;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const NoteDivider = styled.div`
  margin: 0 16px;
  flex-grow: 1;
  border-top: 1px solid #e3e6ea;
`;

const MapContainer = styled.div`
  .marker-position {
    top: 25px;
    left: 0;
    font-weight: bold;
    position: relative;
    padding: 2px;
    z-index: 2;
    border-radius: 3px;
    text-shadow: 1px 2px 2px black;
  }
  .lengthMarker {
    background-color: black;
    box-shadow: 1px 1px 1px black;
    padding: 4px;
    border-radius: 3px;
    border: 1px solid white;
    transform: rotate(var(--angle));
  }
  .elevation-label {
    background-color: white;
    box-shadow: 1px 1px 1px black;
    padding: 1px;
    border-radius: 2px;
    border: 1px solid white;
  }
  width: 100%;
  height: calc(100vh - 88px);
  @media (min-width: 768px) {
    width: cal(100vw - 256);
    height: 600px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  flex-grow: 1;
  line-height: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  min-height: 48px;
  gap: 24px;
`;

export default function LiftPlanPreview(props) {
  const { basicInfo } = props.liftplanState;
  const { loadDetails } = props.liftplanState;
  const [center, setCenter] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { craneDetails } = props.liftplanState;
  const { hazards } = props.liftplanState;
  const { siteDetails } = props.liftplanState;
  const { liftplanState } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [basicInfoComplete, setBasicInfoComplete] = useState(true);
  const [loadDetailsComplete, setLoadDetailsComplete] = useState(true);
  const [craneComplete, setCraneComplete] = useState(true);
  const [emailModal, setEmailModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedBoomData, setSelectedBoomData] = useState([]);
  const [loadData, setLoadData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [pickData, setPickData] = useState([]);
  const [tipHeight, setTipHeight] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    if (basicInfo?.projectName) {
      setIsLoading(false);
      if (craneDetails?.selectedBoomLength) {
        const radiusTotal = parseFloat(
          Math.max(craneDetails.pickRadius, craneDetails.setRadius)
        );
        let tipHeight = null;
        let knuckleHeight = null;
        let knuckleRadius = null;
        let targetWidth = null;
        const feetBoomPinHeight = craneDetails.boomPinHeight / 304.8; // millimeters to feet

        // calculate start and end location of boom
        if (craneDetails.selectedAttachment.name === 'Main Boom') {
          targetWidth = radiusTotal;
          tipHeight = Math.sqrt(
            craneDetails.selectedBoomLength ** 2 -
              targetWidth ** 2 +
              feetBoomPinHeight
          );
        } else {
          // with attachment
          // got numbers
          let attachmentLength = craneDetails.selectedAttachment.length;
          let adjustedBoomLength = craneDetails.selectedBoomLength;
          if (craneDetails.selectedAttachment.hingePoint) {
            attachmentLength += craneDetails.selectedAttachment.hingePoint;
            adjustedBoomLength -= craneDetails.selectedAttachment.hingePoint;
          }

          const reach = Math.sqrt(
            attachmentLength ** 2 +
              adjustedBoomLength ** 2 -
              2 *
                attachmentLength *
                adjustedBoomLength *
                Math.cos((180 - craneDetails.selectedOffset) * (Math.PI / 180))
          );

          tipHeight = Math.sqrt(reach ** 2 - radiusTotal ** 2 + 3);
          // DEGREE ONE
          const deg1 = Math.atan2(tipHeight, radiusTotal);
          // DEGREE TWO
          const deg2 = Math.acos(
            (adjustedBoomLength ** 2 + reach ** 2 - attachmentLength ** 2) /
              (2 * adjustedBoomLength * reach)
          );
          if (
            (adjustedBoomLength ** 2 + reach ** 2 - attachmentLength ** 2) /
              (2 * adjustedBoomLength * reach) <
            0.9999997
          ) {
            knuckleHeight = adjustedBoomLength * Math.sin(deg1 + deg2);
          }
          knuckleRadius = Math.sqrt(
            adjustedBoomLength ** 2 - knuckleHeight ** 2
          );
        }

        const boomData = [
          { distance: 0, craneHeight: feetBoomPinHeight },
          {
            distance: twoDecimalOrNull(radiusTotal),
            craneHeight: twoDecimalOrNull(tipHeight),
          },
        ];
        const newBoomData = [
          { distance: 0, craneHeight: feetBoomPinHeight },
          {
            distance: twoDecimalOrNull(knuckleRadius),
            craneHeight: twoDecimalOrNull(knuckleHeight),
          },
          {
            distance: twoDecimalOrNull(radiusTotal),
            craneHeight: twoDecimalOrNull(tipHeight),
          },
        ];
        const pickData = [
          {
            distance: twoDecimalOrNull(radiusTotal),
            pickLocation: twoDecimalOrNull(0),
          },
        ];
        const loadData =
          loadDetails.selectedLoad === 'Cylinder'
            ? [
                {
                  distance: pickData[0].distance,
                  Load: getLoadHeight(craneDetails.customObstacles, pickData),
                },
                {
                  distance:
                    pickData[0].distance -
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter) / 2 -
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                },
                {
                  distance:
                    pickData[0].distance -
                    parseFloat(loadDetails.loadDiameter) / 2,
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter) / 2,
                },
                {
                  distance:
                    pickData[0].distance -
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter) / 2 +
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                },
                {
                  distance: pickData[0].distance,
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter),
                },
                {
                  distance:
                    pickData[0].distance +
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter) / 2 +
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                },
                {
                  distance:
                    pickData[0].distance +
                    parseFloat(loadDetails.loadDiameter) / 2,
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter) / 2,
                },
                {
                  distance:
                    pickData[0].distance +
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                  Load:
                    getLoadHeight(craneDetails.customObstacles, pickData) +
                    parseFloat(loadDetails.loadDiameter) / 2 -
                    (parseFloat(loadDetails.loadDiameter) / 2) *
                      (Math.sqrt(2) / 2),
                },
                {
                  distance: pickData[0].distance,
                  Load: getLoadHeight(craneDetails.customObstacles, pickData),
                },
              ]
            : [
                {
                  distance:
                    pickData[0].distance -
                    parseFloat(loadDetails.loadWidth) / 2,
                  Load: getLoadHeight(craneDetails.customObstacles, pickData),
                },
                {
                  distance:
                    pickData[0].distance -
                    parseFloat(loadDetails.loadWidth) / 2,
                  Load:
                    parseFloat(loadDetails.loadHeight) +
                    getLoadHeight(craneDetails.customObstacles, pickData),
                },
                {
                  distance:
                    pickData[0].distance +
                    parseFloat(loadDetails.loadWidth) / 2,
                  Load:
                    parseFloat(loadDetails.loadHeight) +
                    getLoadHeight(craneDetails.customObstacles, pickData),
                },
                {
                  distance:
                    pickData[0].distance +
                    parseFloat(loadDetails.loadWidth) / 2,
                  Load: getLoadHeight(craneDetails.customObstacles, pickData),
                },
                {
                  distance:
                    pickData[0].distance -
                    parseFloat(loadDetails.loadWidth) / 2,
                  Load: getLoadHeight(craneDetails.customObstacles, pickData),
                },
              ];
        const lineData = [
          {
            distance: pickData[0].distance,
            Load: tipHeight,
          },
          {
            distance: pickData[0].distance,
            Load:
              loadDetails.selectedLoad === 'Cylinder'
                ? parseFloat(loadDetails.loadDiameter) +
                  getLoadHeight(craneDetails.customObstacles, pickData)
                : parseFloat(loadDetails.loadHeight) +
                  getLoadHeight(craneDetails.customObstacles, pickData),
          },
        ];
        setPickData(pickData);
        setLoadData(loadData);
        setLineData(lineData);
        setTipHeight(tipHeight);
        setSelectedBoomData(knuckleHeight ? newBoomData : boomData);
      }
    }
  }, [basicInfo]);

  const onMapLoad = (e) => {
    let possibleLocation = null;
    if (siteDetails?.craneLocation) {
      const { craneLocation, setLocation, pickLocation } = siteDetails;
      placeCraneOverlay(
        e,
        craneLocation,
        setLocation,
        pickLocation,
        craneDetails
      );
      possibleLocation = { ...craneLocation };
    }

    if (siteDetails?.packagedObstacles) {
      placeObstacles(e, siteDetails.packagedObstacles, false, null, null);
    }

    if (siteDetails?.packagedNotes) {
      placeNotes(e, siteDetails.packagedNotes, false, null, null, null);
    }

    findPlace(e, basicInfo, possibleLocation, setCenter, null);

    setMapRef(e);
  };

  const formatTime = (hhmm) => {
    const now = new Date();
    const parts = hhmm.split(':');
    const hour = parts[0];
    const minute = parts[1];
    now.setHours(hour);
    now.setMinutes(minute);
    return now.toLocaleTimeString('en-US', {
      timeStyle: 'short',
    });
  };

  const editLiftPlan = (key) => {
    props.updateStep('back', key);
  };

  const truncateNumber = (value) => {
    if (value === null) {
      return null;
    }
    value = parseFloat(value);
    return parseFloat(value.toFixed(2));
  };

  const checkComplete = (section) => {
    if (section === 'basic' && basicInfoComplete) {
      setBasicInfoComplete(false);
    }
    if (section === 'load' && loadDetailsComplete) {
      setLoadDetailsComplete(false);
    }
    if (section === 'crane' && craneComplete) {
      setCraneComplete(false);
    }
    return 'N/A';
  };

  const twoDecimalOrNull = (x) => {
    return parseFloat((x || 0).toFixed(2)) || null;
  };

  const getLoadHeight = (obstacles, pickData) => {
    let max = 0;
    let width =
      loadDetails.selectedLoad === 'Cylinder'
        ? parseFloat(loadDetails.loadDiameter) / 2
        : parseFloat(loadDetails.loadWidth) / 2;
    for (let i = 0; i < obstacles.length; i++) {
      if (obstacles[i].CobstacleHeight > 0) {
        if (
          obstacles[i].CobstacleHeight === obstacles[i + 1].CobstacleHeight &&
          ((obstacles[i].distance >= pickData[0].distance - width &&
            obstacles[i].distance <= pickData[0].distance + width) ||
            (obstacles[i + 1].distance >= pickData[0].distance - width &&
              obstacles[i + 1].distance <= pickData[0].distance + width) ||
            (obstacles[i].distance <= pickData[0].distance - width &&
              obstacles[i + 1].distance >= pickData[0].distance + width) ||
            (obstacles[i + 1].distance <= pickData[0].distance - width &&
              obstacles[i].distance >= pickData[0].distance + width))
        ) {
          if (obstacles[i].CobstacleHeight > max) {
            max = obstacles[i].CobstacleHeight;
          }
        } else if (
          obstacles[i].distance >= pickData[0].distance - width &&
          obstacles[i].distance <= pickData[0].distance + width
        ) {
          if (obstacles[i].CobstacleHeight > max) {
            max = obstacles[i].CobstacleHeight;
          }
        } else if (
          obstacles[i + 1].distance >= pickData[0].distance - width &&
          obstacles[i + 1].distance <= pickData[0].distance + width
        ) {
          if (obstacles[i + 1].CobstacleHeight > max) {
            max = obstacles[i + 1].CobstacleHeight;
          }
        }
      }
    }
    return max;
  };

  const displayObstacleHeights = (obstacles) => {
    const dots = [];
    for (let i = 0; i < obstacles.length; i += 5) {
      if (
        obstacles[i + 2].CobstacleHeight !== obstacles[i + 3].CobstacleHeight
      ) {
        dots.push(
          <ReferenceDot
            x={obstacles[i + 2].distance}
            y={obstacles[i + 2].CobstacleHeight + 5}
            r={0}
            label={`${obstacles[i + 2].CobstacleHeight}ft`}
            stroke="none"
          />
        );
        dots.push(
          <ReferenceDot
            x={obstacles[i + 3].distance}
            y={obstacles[i + 3].CobstacleHeight + 5}
            r={0}
            label={`${obstacles[i + 3].CobstacleHeight}ft`}
            stroke="none"
          />
        );
      } else {
        dots.push(
          <ReferenceDot
            x={(obstacles[i + 2].distance + obstacles[i + 3].distance) / 2}
            y={obstacles[i + 3].CobstacleHeight + 5}
            r={0}
            label={`${obstacles[i + 3].CobstacleHeight}ft`}
            stroke="none"
          />
        );
      }
    }
    return dots;
  };

  const getBoomClearance = (obstacles, boomData) => {
    let min = 9999999;
    let dist = null;
    let boomPoint = null;
    let obstaclePoint = null;
    if (boomData.length === 2) {
      let slope =
        (boomData[1].craneHeight - boomData[0].craneHeight) /
        (boomData[1].distance - boomData[0].distance);
      let intercept = boomData[0].craneHeight;
      for (let i = 0; i < obstacles.length; i++) {
        if (obstacles[i].distance > 0) {
          let tempBoom = slope * obstacles[i].distance + intercept;
          let tempdist = Math.abs(tempBoom - obstacles[i].CobstacleHeight);
          if (tempdist < min) {
            min = tempdist;
            dist = obstacles[i].distance;
            boomPoint = tempBoom;
            obstaclePoint = obstacles[i].CobstacleHeight;
          }
        }
      }
    }
    if (boomData.length === 3) {
      let slope1 =
        (boomData[1].craneHeight - boomData[0].craneHeight) /
        (boomData[1].distance - boomData[0].distance);
      let intercept1 = boomData[0].craneHeight;
      let slope2 =
        (boomData[2].craneHeight - boomData[1].craneHeight) /
        (boomData[2].distance - boomData[1].distance);
      let intercept2 = boomData[2].craneHeight - slope2 * boomData[2].distance;
      for (let i = 0; i < obstacles.length; i++) {
        if (obstacles[i].distance > 0) {
          let tempBoom = 0;
          if (
            obstacles[i].distance > 0 &&
            obstacles[i].distance < boomData[1].distance
          ) {
            tempBoom = slope1 * obstacles[i].distance + intercept1;
          } else if (obstacles[i].distance > boomData[1].distance) {
            tempBoom = slope2 * obstacles[i].distance + intercept2;
          }
          let tempdist = Math.abs(tempBoom - obstacles[i].CobstacleHeight);
          if (tempdist < min) {
            min = tempdist;
            dist = obstacles[i].distance;
            boomPoint = tempBoom;
            obstaclePoint = obstacles[i].CobstacleHeight;
          }
        }
      }
    }
    return [
      { distance: dist, height: boomPoint },
      { distance: dist, height: obstaclePoint },
    ];
  };

  const generateXTicks = (maxDistance) => {
    let xTicks = [];
    for (let i = 0; i <= Math.ceil(maxDistance / 5) * 5 + 20; i = i + 5) {
      xTicks.push(i);
    }
    return xTicks;
  };

  const interval = (maxDistance) => {
    if (maxDistance + 20 > 150) {
      return 3;
    } else if (maxDistance + 20 > 75) {
      return 1;
    }
    return 0;
  };

  const getObstacleDistance = (obstacles) => {
    let min = null;
    for (let i = 0; i < obstacles.length; i++) {
      if (obstacles[i].distance > 0) {
        if (min === null) {
          min = obstacles[i].distance;
        } else if (obstacles[i].distance < min) {
          min = obstacles[i].distance;
        }
      }
    }
    return min;
  };

  return (
    <>
      <Modal
        show={emailModal}
        title="Send as Email"
        modalStyles={{
          width: '50%',
          height: '100%',
          padding: '20px',
        }}
        handleClose={() => {
          setEmailModal(false);
        }}
      >
        <EmailModal
          show={emailModal}
          theId={props.match?.params?.id}
          setEmailModal={setEmailModal}
        />
      </Modal>
      <PreviewContainer id="liftPreview">
        <TitleContainer>
          <Title>Job Walk Report</Title>
          {props.isEditable && !emailModal && (
            <>
              {/* {!props.upgraded && (
                <Button
                  onClick={() => {
                    props.createLiftPlan(() => {
                      props.history.push(`/liftplan/${props.match.params.id}`);
                    });
                  }}
                  style={{ width: 'auto' }}
                  theme={theme.buttons.share}
                >
                  Upgrade to Lift Plan
                </Button>
              )} */}
              <Button
                onClick={() => setEmailModal(true)}
                style={{ width: 'auto' }}
                theme={theme.buttons.editJobWalk}
              >
                Send as Email
              </Button>
            </>
          )}
        </TitleContainer>
        {props.liftplanState.basicInfo ? (
          <CollapsibleSection
            title="Basic Info"
            completed={basicInfoComplete}
            editFunction={editLiftPlan}
            id="basicInfo"
            isEditable={props.isEditable}
            sectionKey={1}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <DataContainer>
                <DataRow>
                  <DataTitle>Created By:</DataTitle>
                  {basicInfo.createdBy ? (
                    <DataContent>{basicInfo.createdBy}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Job Walk Date:</DataTitle>
                  {basicInfo.jobWalkDate ? (
                    <DataContent>{basicInfo.jobWalkDate}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Job Start Date:</DataTitle>
                  {basicInfo.jobStartDate ? (
                    <DataContent>{basicInfo.jobStartDate}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Project Name:</DataTitle>
                  {basicInfo.projectName ? (
                    <DataContent>{basicInfo.projectName}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Customer Name:</DataTitle>
                  {basicInfo.customerName ? (
                    <DataContent>{basicInfo.customerName}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Customer Point of Contact:</DataTitle>
                  {basicInfo.customerPOC ? (
                    <DataContent>{basicInfo.customerPOC}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Point of Contact Phone Number:</DataTitle>
                  {basicInfo.customerPhone ? (
                    <DataContent>{basicInfo.customerPhone}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Point of Contact Email:</DataTitle>
                  {basicInfo.customerEmail ? (
                    <DataContent>{basicInfo.customerEmail}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Location:</DataTitle>
                  {basicInfo.location ? (
                    <DataContent>{basicInfo.location}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Arrival Time:</DataTitle>
                  {basicInfo.arrivalTime ? (
                    <DataContent>
                      {formatTime(basicInfo.arrivalTime)}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Duration:</DataTitle>
                  {basicInfo.duration ? (
                    <DataContent>{`${basicInfo.duration} ${basicInfo.durationType}`}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Description:</DataTitle>
                  {basicInfo.description ? (
                    <DataContent>{basicInfo.description}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('basic')}</DataContent>
                  )}
                </DataRow>
              </DataContainer>
            )}
          </CollapsibleSection>
        ) : (
          <CollapsibleSection
            title="Basic Info"
            completed={false}
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={1}
          />
        )}

        {props.liftplanState.loadDetails ? (
          <CollapsibleSection
            title="Load Details"
            completed={loadDetailsComplete}
            editFunction={editLiftPlan}
            id="loadDetails"
            isEditable={props.isEditable}
            sectionKey={2}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <DataContainer>
                <DataRow>
                  <DataTitle>Load Description:</DataTitle>
                  {loadDetails.loadDescription ? (
                    <DataContent>{loadDetails.loadDescription}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('load')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Load Weight:</DataTitle>
                  {loadDetails.loadWeight >= 0 ? (
                    <DataContent>{`${truncateNumber(
                      loadDetails.loadWeight
                    )} (lbs)`}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('load')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Load Shape:</DataTitle>
                  {loadDetails.selectedLoad ? (
                    <DataContent>{loadDetails.selectedLoad}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('load')}</DataContent>
                  )}
                </DataRow>
                {loadDetails.selectedLoad === 'Cylinder' ? (
                  <>
                    <DataRow>
                      <DataTitle>Load Diameter:</DataTitle>
                      {loadDetails.loadDiameter ? (
                        <DataContent>{`${loadDetails.loadDiameter} (ft)`}</DataContent>
                      ) : (
                        <DataContent>{checkComplete('load')}</DataContent>
                      )}
                    </DataRow>
                    <DataRow>
                      <DataTitle>Load Length:</DataTitle>
                      {loadDetails.loadLength ? (
                        <DataContent>{`${loadDetails.loadLength} (ft)`}</DataContent>
                      ) : (
                        <DataContent>{checkComplete('load')}</DataContent>
                      )}
                    </DataRow>
                  </>
                ) : (
                  <>
                    <DataRow>
                      <DataTitle>Load Height:</DataTitle>
                      {loadDetails.loadHeight ? (
                        <DataContent>{`${loadDetails.loadHeight} (ft)`}</DataContent>
                      ) : (
                        <DataContent>{checkComplete('load')}</DataContent>
                      )}
                    </DataRow>
                    <DataRow>
                      <DataTitle>Load Width:</DataTitle>
                      {loadDetails.loadWidth ? (
                        <DataContent>{`${loadDetails.loadWidth} (ft)`}</DataContent>
                      ) : (
                        <DataContent>{checkComplete('load')}</DataContent>
                      )}
                    </DataRow>
                    <DataRow>
                      <DataTitle>Load Length:</DataTitle>
                      {loadDetails.loadLength ? (
                        <DataContent>{`${loadDetails.loadLength} (ft)`}</DataContent>
                      ) : (
                        <DataContent>{checkComplete('load')}</DataContent>
                      )}
                    </DataRow>
                  </>
                )}
                <DataRow>
                  <DataTitle>Weight Determined By:</DataTitle>
                  {loadDetails.weightDeterminedBy ? (
                    <DataContent>{loadDetails.weightDeterminedBy}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('load')}</DataContent>
                  )}
                </DataRow>
              </DataContainer>
            )}
          </CollapsibleSection>
        ) : (
          <CollapsibleSection
            title="Load Details"
            completed={false}
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={2}
          />
        )}
        {props.liftplanState.craneDetails ? (
          <CollapsibleSection
            title="Crane Selection"
            completed={craneComplete}
            id="craneSelection"
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={3}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <DataContainer>
                <DataRow>
                  <DataTitle>Crane:</DataTitle>
                  {craneDetails.selectedCrane &&
                  craneDetails.selectedCrane !== 'default' ? (
                    <DataContent>{`${craneDetails.selectedCrane.manufacturer} ${craneDetails.selectedCrane.model}`}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Boom Length:</DataTitle>
                  {craneDetails.selectedBoomLength &&
                  craneDetails.selectedBoomLength !== 'default' ? (
                    <DataContent>
                      {`${craneDetails.selectedBoomLength} (ft)`}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Boom Configuration:</DataTitle>
                  {craneDetails.selectedAttachment &&
                  craneDetails.selectedAttachment !== 'default' ? (
                    <DataContent>
                      {craneDetails.selectedAttachment.name}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Counterweight:</DataTitle>
                  {craneDetails.selectedCounterWeight &&
                  craneDetails.selectedCounterWeight !== 'default' ? (
                    <DataContent>
                      {`${craneDetails.selectedCounterWeight} (lbs)`}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Gross Capacity:</DataTitle>
                  {craneDetails.grossCap ? (
                    <DataContent>
                      {`${truncateNumber(craneDetails.grossCap)} (lbs)`}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Total Deductions:</DataTitle>
                  {craneDetails.totalDeductions !== null &&
                  craneDetails.totalDeductions !== undefined &&
                  craneDetails.totalDeductions !== 'default' ? (
                    <DataContent>
                      {`${truncateNumber(craneDetails.totalDeductions)} (lbs)`}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Net Capacity:</DataTitle>
                  {craneDetails.netCapacity ? (
                    <DataContent>
                      {`${truncateNumber(craneDetails.netCapacity)} (lbs)`}
                    </DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
                <DataRow>
                  <DataTitle>Percent of Chart Utilized:</DataTitle>
                  {craneDetails.percentOfCap ? (
                    <DataContent>{`${craneDetails.percentOfCap}%`}</DataContent>
                  ) : (
                    <DataContent>{checkComplete('crane')}</DataContent>
                  )}
                </DataRow>
              </DataContainer>
            )}
          </CollapsibleSection>
        ) : (
          <CollapsibleSection
            title="Crane Selection"
            completed={false}
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={3}
          />
        )}
        {props.liftplanState.siteDetails ? (
          <CollapsibleSection
            title="Site Layout"
            completed
            id="siteLayout"
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={3}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <MapContainer>
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    id="map-container-main"
                    zoom={20}
                    center={center}
                    onLoad={(e) => onMapLoad(e)}
                    onTilesLoaded={() =>
                      handleOnTilesLoaded(
                        mapRef,
                        loaded,
                        center,
                        setLoaded,
                        null,
                        siteDetails,
                        craneDetails,
                        (result) => setShowOverlay(result),
                        (value) => value
                      )
                    }
                    tilt={0}
                    options={{
                      rotateControl: false,
                      mapTypeId: 'satellite',
                      disableDefaultUI: true,
                      gestureHandling: 'cooperative',
                    }}
                  >
                    {liftplanState.overlays &&
                    liftplanState.overlays.imageLink !== '' &&
                    loaded ? (
                      <MapOverlay
                        image={liftplanState.overlays.imageLink}
                        bounds={
                          new google.maps.LatLngBounds(
                            liftplanState.overlays.overlayBounds
                          )
                        }
                        deg={liftplanState.overlays.deg}
                        z={2}
                      />
                    ) : null}
                  </GoogleMap>
                </MapContainer>
                <NoteContainer>
                  {Array.isArray(siteDetails?.packagedNotes) &&
                    siteDetails?.packagedNotes.map((note, index) => (
                      <>
                        {index > 0 && <NoteDivider key={index} />}
                        <NoteRow key={note.note}>
                          <NoteNum>{index + 1}</NoteNum>
                          <NoteContent>
                            <NoteText>{note.note}</NoteText>
                            {note.url && <NoteImg src={note.url} />}
                          </NoteContent>
                        </NoteRow>
                      </>
                    ))}
                </NoteContainer>
              </div>
            )}
          </CollapsibleSection>
        ) : (
          <CollapsibleSection
            title="Site Layout"
            completed={false}
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={3}
          />
        )}

        {craneDetails &&
          craneDetails.selectedAttachment &&
          craneDetails.selectedBoomLength &&
          craneDetails.pickRadius && (
            <CollapsibleSection
              title="Range Diagram"
              id="rangeDiagram"
              completed={true}
              editFunction={editLiftPlan}
              isEditable={props.isEditable}
              sectionKey={3}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <ComposedChart
                    height={
                      document.getElementById('rangeDiagram').offsetWidth *
                      (9 / 16)
                    }
                    width={document.getElementById('rangeDiagram').offsetWidth}
                    style={{ userSelect: 'none' }}
                  >
                    <CartesianGrid
                      strokeDasharray="10"
                      style={{ userSelect: 'none' }}
                    />
                    <YAxis
                      label={{
                        value: '(ft)',
                        position: 'insideLeft',
                        offset: 0,
                      }}
                      dataKey="craneHeight"
                      type="number"
                      scale="linear"
                      interval={0}
                      tickCount={20}
                      style={{ userSelect: 'none' }}
                    />
                    <XAxis
                      label={{
                        value: '(ft)',
                        position: 'insideBottom',
                        offset: -5,
                      }}
                      ticks={generateXTicks(pickData[0].distance)}
                      tick={true}
                      tickLine={true}
                      padding={{ right: 10 }}
                      allowDecimals={false}
                      dataKey="distance"
                      scale="linear"
                      type="number"
                      interval={interval(pickData[0].distance)}
                      style={{ userSelect: 'none' }}
                    />
                    <Line
                      data={pickData}
                      dataKey="pickLocation"
                      name="Pick Point"
                      type="stepAfter"
                      fill="green"
                      stroke="green"
                      isAnimationActive={false}
                    />
                    <Line
                      data={lineData}
                      dataKey="Load"
                      name="Line"
                      type="stepAfter"
                      fill="black"
                      legendType="none"
                      tooltipType="none"
                      stroke="black"
                      isAnimationActive={false}
                    />
                    <Line
                      data={selectedBoomData}
                      dataKey="craneHeight"
                      name="Boom Elevation"
                      type="linear"
                      fill="yellow"
                      stroke="orange"
                      isAnimationActive={false}
                    />
                    <Line
                      data={getBoomClearance(
                        craneDetails.customObstacles,
                        selectedBoomData
                      )}
                      dataKey="height"
                      name="Boom Elevation"
                      type="linear"
                      stroke="purple"
                      fill="purple"
                      isAnimationActive={false}
                    />
                    <Area
                      data={craneDetails.customObstacles}
                      dot={false}
                      dataKey="CobstacleHeight"
                      name="Obstacle Height"
                      type="linear"
                      fill="red"
                      stroke="red"
                      isAnimationActive={false}
                    />
                    <Area
                      data={loadData}
                      dot={false}
                      dataKey="Load"
                      name="Load"
                      legendType="none"
                      tooltipType="none"
                      type="linear"
                      fill="green"
                      stroke="green"
                      isAnimationActive={false}
                    />
                    {displayObstacleHeights(craneDetails.customObstacles)}
                  </ComposedChart>
                  <div
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      display: 'flex',
                      gap: '24px',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <p>{`Boom tip height: ${Math.round(tipHeight)}ft`}</p>
                    <p>
                      {`Obstacle Distance: ${
                        twoDecimalOrNull(
                          getObstacleDistance(craneDetails.customObstacles)
                        ) || 0
                      }ft`}
                    </p>
                    <p>
                      {`Load Height: ${
                        twoDecimalOrNull(
                          getLoadHeight(craneDetails.customObstacles, pickData)
                        ) || 0
                      }ft`}
                    </p>
                    <p>
                      {`Boom Clearance: ${
                        twoDecimalOrNull(
                          getBoomClearance(
                            craneDetails.customObstacles,
                            selectedBoomData
                          )[0].height -
                            getBoomClearance(
                              craneDetails.customObstacles,
                              selectedBoomData
                            )[1].height
                        ) || 0
                      }ft`}
                    </p>
                  </div>
                </>
              )}
            </CollapsibleSection>
          )}
        {Array.isArray(hazards) && hazards.length > 0 && (
          <CollapsibleSection
            title="Additional Information"
            id="additionalInformation"
            completed
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={4}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <DataContainer>
                {hazards.map((data) => (
                  <DataRow key={data.hazardName}>
                    <DataTitle>{data.hazardName}</DataTitle>
                    {data.mitigationPlanDesc ? (
                      <DataContent>{data.mitigationPlanDesc}</DataContent>
                    ) : (
                      <DataContent>N/A</DataContent>
                    )}
                  </DataRow>
                ))}
              </DataContainer>
            )}
          </CollapsibleSection>
        )}
        {props.upgraded && (
          <CollapsibleSection
            title="Ground Bearing Pressure"
            id="groundPressure"
            completed
            editFunction={editLiftPlan}
            isEditable={props.isEditable}
            sectionKey={1}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <DataContainer>
                <GBPContainer>
                  <CraneDiagram
                    angle={0}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={45}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={90}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={135}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={180}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={225}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={270}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                  <GBPDivider />
                  <CraneDiagram
                    angle={315}
                    loadWeight={loadDetails.loadWeight}
                    craneDetails={craneDetails}
                    craneAngle={siteDetails.angle}
                  />
                </GBPContainer>
              </DataContainer>
            )}
          </CollapsibleSection>
        )}
      </PreviewContainer>
    </>
  );
}
