import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import Button from '../../reusables/Button';
import { useDispatch } from 'react-redux';
import { setRiggingInfoModal } from '../../context/liftpage';
import Modal from '../../reusables/Modal/Modal';

const CapacityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeductionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 12px;
`;

const DataContainer = styled.div`
  background: #f0f2f5;
  border-radius: 8px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  flex-grow: 1;
  color: #2a2f35;
`;

const TitleData = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #2a2f35;
`;

const BoldText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #3b4046;
  flex-grow: 1;
`;

const BoldData = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #3b4046;
`;

const RowText = styled.p`
  font-size: 16px;
  color: #3b4046;
  flex-grow: 1;
`;

const Data = styled.p`
  font-size: 16px;
  color: #3b4046;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
`;

const DashedDivider = styled.div`
  border-bottom: 1px dashed #cfd4db;
  margin: 0px 12px;
  flex-grow: 1;
`;

const SolidDivider = styled.div`
  border-bottom: 1px solid #cfd4db;
  margin: 0px 12px;
  flex-grow: 1;
`;

const Percent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 12px 0px 12px;
`;

const Report = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #0458b2;
  flex-grow: 1;
`;

export default function RiggingInfoModal({
  config,
  currentSling,
  currentShackle,
  type,
  loadWeight,
  lines,
  slingWeights,
  angle,
  percent,
  setPercent,
  setColor2,
}) {
  const [color, setColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [shackles, setShackles] = useState(2);
  const [bearingShackles, setBearingShackles] = useState(2);
  const [capacity, setCapacity] = useState(0);
  const { riggingInfoModal } = useSelector((state) => state.page);
  const theme = useTheme();
  const dispatch = useDispatch();

  const truncateNumber = (value) => {
    if (value === null) {
      return null;
    }
    value = parseFloat(value);
    return parseFloat(value.toFixed(2));
  };
  const reducedWeight = truncateNumber(slingWeights[0]);

  useEffect(() => {
    let b = lines;
    let s = lines;
    if (type === 'Bridle') {
      s = Math.max(lines, 2);
      b = 2;
      if (lines === 1) {
        b = 1;
      }
    }
    if (type === 'Basket') {
      s = 0;
      b = 1;
    }
    const c = Math.min(
      reducedWeight * (lines > 1 ? 2 : 1),
      (currentShackle.capacity || 99999999999) * b
    );
    const p =
      100 *
      (loadWeight /
        (c - currentSling.weight * lines - (currentShackle.weight || 0) * s));
    if (p >= 100 || p < 0) {
      setColor('#B50614');
      setColor2('#C43A45');
      setBackgroundColor('#F2D5D7');
    } else if (p >= 0 && p <= 70) {
      setColor('#0F7637');
      setColor2('#549D70');
      setBackgroundColor('#D1EDDC');
    } else {
      setColor('#774F01');
      setColor2('#F5A302');
      setBackgroundColor('#FFF5DB');
    }
    setCapacity(c);
    setPercent(truncateNumber(p));
    setShackles(s);
    setBearingShackles(b);
  }, [slingWeights, currentShackle, currentSling]);

  return (
    <Modal
      show={riggingInfoModal}
      modalStyles={theme.modals.info}
      handleClose={() => dispatch(setRiggingInfoModal(false))}
      title="Rigging Capacity Details"
    >
      <CapacityContainer>
        <Row>
          <Title>{config}</Title>
        </Row>
        <DeductionsContainer>
          <Row>
            <BoldText>Capacities</BoldText>
          </Row>
          <DataContainer>
            <Row>
              <RowText>
                {currentSling.length}ft & {currentSling.category} ({lines})
              </RowText>
            </Row>
            <Row>
              <RowText>&nbsp;&nbsp;&nbsp;&nbsp;Gross {type} Capacity:</RowText>
              {type === 'Basket' && (
                <Data>{currentSling.capacities.basket} (lbs)</Data>
              )}
              {type === 'Choker' && (
                <Data>{currentSling.capacities.choker} (lbs)</Data>
              )}
              {type === 'Bridle' && (
                <Data>{currentSling.capacities.vertical} (lbs)</Data>
              )}
            </Row>
            <Row>
              <RowText>
                &nbsp;&nbsp;&nbsp;&nbsp;Reduced Capacity at{' '}
                {truncateNumber(angle * (180 / Math.PI))}°:
              </RowText>
              <Data>{reducedWeight} (lbs)</Data>
            </Row>
            {currentShackle.size !== 'default' && (
              <Row>
                <RowText>
                  {`${currentShackle.manufacturer} ${currentShackle.size} (${shackles}):`}
                </RowText>
                <Data>{truncateNumber(currentShackle.capacity)} (lbs)</Data>
              </Row>
            )}
          </DataContainer>
          <Row>
            <BoldText>Total Combined Capacity:</BoldText>
            <BoldData>{`${capacity} (lbs)`}</BoldData>
          </Row>
          {lines > 1 && (
            <Row>
              <BoldText style={{ fontSize: '12px' }}>
                (Load carried by 2 slings max)
              </BoldText>
            </Row>
          )}
          <DashedDivider />
          <Row>
            <BoldText>Weights</BoldText>
          </Row>
          <DataContainer>
            <Row>
              <RowText>
                {currentSling.length}ft & {currentSling.category} ({lines}):
              </RowText>
              <Data>{currentSling.weight} (lbs)</Data>
            </Row>
            {currentShackle.size !== 'default' && (
              <Row>
                <RowText>
                  {`${currentShackle.manufacturer} ${currentShackle.size} (${shackles}):`}
                </RowText>
                <Data>{truncateNumber(currentShackle.weight)} (lbs)</Data>
              </Row>
            )}
          </DataContainer>
          <Row>
            <BoldText>Total Combined Weight:</BoldText>
            <BoldData style={{ color: '#B50614' }}>
              {`${
                currentSling.weight * lines +
                (currentShackle.weight || 0) * shackles
              } (lbs)`}
            </BoldData>
          </Row>
          <DashedDivider />
          <Row>
            <BoldText>Net Capacity:</BoldText>
            <BoldData>
              {`${
                capacity -
                currentSling.weight * lines -
                (currentShackle.weight || 0) * shackles
              }
              (lbs)`}
            </BoldData>
          </Row>
        </DeductionsContainer>
        <SolidDivider />
        <Row>
          <BoldText>Load Weight:</BoldText>
          <BoldData>{truncateNumber(loadWeight)} (lbs)</BoldData>
        </Row>
        <DashedDivider />
        <Row>
          <BoldText>Percent of Rigging Capacity:</BoldText>
          <Percent style={{ backgroundColor: backgroundColor, color: color }}>
            {percent || 0}%
          </Percent>
        </Row>
        <ButtonContainer>
          <Report>{/* Report Calculation Errors */}</Report>
        </ButtonContainer>
      </CapacityContainer>
    </Modal>
  );
}
