import React from 'react';
import styled from 'styled-components/macro';
import LoginCraneBanner from '../../reusables/LoginCraneBannner/LoginCraneBanner';
import SignUpForm from './SignUpForm';

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  height: calc(100% - 64px);
  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const Banner = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    width: 45%;
    float: left;
    background-color: theme.light.colors.highlight.tertiary;
  }
`;

const FormDiv = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    float: left;
    background-color: theme.light.colors.body.primary;
  }
  @media (min-width: 768px) {
    width: 55%;
    float: left;
    background-color: theme.light.colors.body.primary;
  }
`;

export default function SignUp(props) {
  return (
    <Container>
      <Banner>
        <LoginCraneBanner />
      </Banner>
      <FormDiv>
        <SignUpForm {...props} />
      </FormDiv>
    </Container>
  );
}
