import styled from 'styled-components/macro';

const Input = styled.div`
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  input {
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    height: 56px;
    border-radius: 5px;
  }
  p {
    margin-bottom: 8px;
    font-size: 16px;
    align-self: start;
  }
  button {
    position: absolute;
    top: 40px;
    right: 10px;
    border: none;
    background: none;
  }
  label {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &:focus {
    outline: none;
  }
  textarea {
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    border-radius: 5px;
    font-family: 'Roboto';
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(17, 17, 17, 0.32);
      font-family: 'Roboto';
    }
  }
  .auto-container {
    width: 100%;
    position: relative;
    i {
      top: -10px;
      position: absolute;
    }
  }
  .dimensions-container {
    input {
      width: 95%;
    }
  }
  i {
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
`;

export default Input;
