import React from 'react';
import styled from 'styled-components';
import XSVG from '../../assets/images/X.svg';
import LittleButton from '../LittleButton';

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const ModalMain = styled.section`
  background: white;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 100%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 601px) {
    width: 40%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

const ModalTitle = styled.h1`
  font-size: 2rem;
  font-family: 'Roboto';
  font-weight: 600;
  flex-grow: 1;
  color: #2a2f35;
`;

function Modal({
  handleClose,
  show,
  children,
  closeButtonStyle = {},
  modalStyles = {},
  title = '',
}) {
  if (!show) {
    return null;
  }

  return (
    <ModalContainer>
      <ModalMain
        className="modal-main"
        style={{ ...modalStyles, display: show ? 'flex' : 'none' }}
      >
        <ModalHeader className="modal-header" style={{ ...closeButtonStyle }}>
          <ModalTitle className="modal-title">{title}</ModalTitle>
          <LittleButton onClick={handleClose}>
            <img src={XSVG} />
          </LittleButton>
        </ModalHeader>
        {children}
      </ModalMain>
    </ModalContainer>
  );
}

export default Modal;
