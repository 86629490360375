import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete } from '@react-google-maps/api';
import DatePicker from 'react-datepicker';
import styled from 'styled-components/macro';
import 'react-datepicker/dist/react-datepicker.css';
import { convertToMMDDYY } from '../../helpers/utils';
import { setNotification } from '../../context/liftpage';
import InputContainer from '../../reusables/Input';
import Location from '../../assets/images/Location.svg';
import Calendar from '../../assets/images/Calendar.svg';
import Down from '../../assets/images/arrow-ios-downward.svg';
import { updateBasicInfo } from '../../context/siteplan';

const TextArea = styled.textarea`
  width: 100%;
  min-height: 166px;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 16px 16px;
`;

const InputGrouping = styled.div`
  width: 48%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Req = styled.span`
  color: #b50614;
`;

const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  padding-right: 27px;
  @media (min-width: 768px) {
    padding-left: 53px;
    padding-right: 53px;
  }
`;

const InputIconLabel = styled.label`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 16px;
    top: 48%;
    bottom: 0;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    z-index: 10;
  }
  input {
    padding: 10px 30px;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  line-height: 3;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    color: #000;
    height: 50px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  @media (min-width: 768px) {
    select {
      height: 56px;
    }
  }
`;

const Title = styled.h1`
  font-size: 32px;
  flex-grow: 1;
  line-height: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px;
`;

const DropdownItem = styled.div`
  position: relative;
  width: 100%;
  font-size: 16px;
  border: 1px solid lightgray;
  padding: 10px;
  z-index: 20;
  background-color: white;
  cursor: pointer;
`;

export default function BasicInfo({ getCustomers }) {
  const { basicInfo } = useSelector((state) => state.site);
  const dispatch = useDispatch();

  const [autoComplete, setAutoComplete] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [recentCustomers, setRecentCustomers] = useState([]);
  const [matchedCustomers, setMatchedCustomers] = useState([]);

  const handleCustomerChange = (value) => {
    dispatch(updateBasicInfo({ customerName: value }));
    setIsFocused(true);
    if (value !== '') {
      if (recentCustomers.length > 0) {
        setMatchedCustomers(
          recentCustomers
            .filter(
              (customer) =>
                customer !== null &&
                customer !== '' &&
                customer.toLowerCase().includes(value.toLowerCase()) &&
                customer.toLowerCase() !== value.toLowerCase()
            )
            .slice(0, 5)
        );
      }
    } else {
      if (recentCustomers.length > 0) {
        setMatchedCustomers(
          recentCustomers
            .filter((customer) => customer !== null && customer !== '')
            .slice(0, 5)
        );
      }
    }
  };

  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
    getCustomers((customers) => setRecentCustomers(customers));
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const selectedPlace = document.getElementById(
        'autocomplete_text_box'
      ).value;
      dispatch(updateBasicInfo({ location: selectedPlace }));
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const handleOnJobStartChanged = (val) => {
    if (basicInfo?.jobWalkDate) {
      if (Date.parse(basicInfo?.jobWalkDate) <= Date.parse(val)) {
        dispatch(updateBasicInfo({ jobStartDate: convertToMMDDYY(val) }));
      } else {
        dispatch(
          setNotification({
            type: 'error',
            text: 'Job walk date cannot be earlier than job start date',
            bold: 'Error',
          })
        );
      }
    } else {
      dispatch(updateBasicInfo({ jobStartDate: convertToMMDDYY(val) }));
    }
  };

  const handleOnJobWalkChanged = (val) => {
    if (basicInfo?.jobStartDate) {
      if (Date.parse(basicInfo?.jobStartDate) >= Date.parse(val)) {
        dispatch(updateBasicInfo({ jobWalkDate: convertToMMDDYY(val) }));
      } else {
        dispatch(
          setNotification({
            type: 'error',
            text: 'Job start date cannot be later than job walk date',
            bold: 'Error',
          })
        );
      }
    } else {
      dispatch(updateBasicInfo({ jobWalkDate: convertToMMDDYY(val) }));
    }
  };

  return (
    <BasicInfoContainer>
      <TitleContainer>
        <Title>Basic Information</Title>
      </TitleContainer>
      <InfoContainer>
        <InputGrouping>
          <InputContainer>
            <p>
              Project Name<Req>&nbsp;*</Req>
            </p>
            <input
              id="projectName"
              placeholder="Enter project name"
              type="text"
              value={basicInfo.projectName || ''}
              onChange={(e) => {
                dispatch(updateBasicInfo({ projectName: e.target.value }));
              }}
            />
          </InputContainer>
          <InputContainer>
            <p>Customer Name</p>
            <input
              id="customerName"
              placeholder="Enter customer name"
              type="text"
              value={basicInfo.customerName || ''}
              onFocus={() => handleCustomerChange('')}
              onBlur={() =>
                setTimeout(() => {
                  setIsFocused(false);
                }, 200)
              }
              onChange={(e) => handleCustomerChange(e.target.value)}
            />
            <div>
              <div style={{ position: 'absolute', width: '100%' }}>
                {isFocused &&
                  matchedCustomers.map((customer) => (
                    <DropdownItem
                      key={customer}
                      onClick={() => {
                        dispatch(updateBasicInfo({ customerName: customer }));
                        setIsFocused(false);
                      }}
                    >
                      {customer}
                    </DropdownItem>
                  ))}
              </div>
            </div>
          </InputContainer>
          <InputContainer>
            <p>Customer Point of Contact</p>
            <input
              id="customerName"
              placeholder="Enter POC name"
              type="text"
              value={basicInfo.customerPOC || ''}
              onChange={(e) =>
                dispatch(updateBasicInfo({ customerPOC: e.target.value }))
              }
            />
          </InputContainer>
          <InputContainer>
            <p>Job Walk Date</p>
            <InputIconLabel image={Calendar}>
              <DatePicker
                style={{ padding: '10px 30px' }}
                dateFormat="MM/dd/yyyy"
                selected={
                  basicInfo.jobWalkDate
                    ? new Date(basicInfo.jobWalkDate)
                    : new Date()
                }
                onChange={(val) => handleOnJobWalkChanged(val)}
              />
            </InputIconLabel>
          </InputContainer>
          <InputContainer>
            <p>Lift Description</p>
            <TextArea
              id="description"
              style={{ resize: 'none' }}
              placeholder="Enter brief summary"
              value={basicInfo.description || ''}
              onChange={(e) =>
                dispatch(updateBasicInfo({ description: e.target.value }))
              }
            />
          </InputContainer>
        </InputGrouping>
        <InputGrouping>
          <InputContainer>
            <p>
              Location<Req>&nbsp;*</Req>
            </p>
            <InputIconLabel image={Location}>
              <Autocomplete
                className="auto-container"
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  type="text"
                  id="autocomplete_text_box"
                  placeholder="Enter jobsite location"
                  value={basicInfo?.location || ''}
                  onChange={(e) =>
                    dispatch(updateBasicInfo({ location: e.target.value }))
                  }
                />
              </Autocomplete>
            </InputIconLabel>
          </InputContainer>
          <InputContainer>
            <p>Point of Contact Phone</p>
            <input
              id="customerPhone"
              placeholder="Enter POC Phone #"
              type="tel"
              value={basicInfo.customerPhone || ''}
              onChange={(e) =>
                dispatch(updateBasicInfo({ customerPhone: e.target.value }))
              }
            />
          </InputContainer>
          <InputContainer>
            <p>Point of Contact Email</p>
            <input
              id="customerEmail"
              placeholder="Enter POC email"
              type="email"
              value={basicInfo.customerEmail || ''}
              onChange={(e) =>
                dispatch(updateBasicInfo({ customerEmail: e.target.value }))
              }
            />
          </InputContainer>
          <InputContainer>
            <p>Job Start Date</p>
            <InputIconLabel image={Calendar}>
              <DatePicker
                style={{ padding: '10px 30px' }}
                dateFormat="MM/dd/yyyy"
                selected={
                  basicInfo.jobStartDate
                    ? new Date(basicInfo.jobStartDate)
                    : new Date()
                }
                onChange={(val) => handleOnJobStartChanged(val)}
              />
            </InputIconLabel>
          </InputContainer>
          <InputContainer>
            <p>Arrival time</p>
            <input
              id="arrivalTime"
              type="time"
              value={basicInfo.arrivalTime || ''}
              onChange={(e) =>
                dispatch(updateBasicInfo({ arrivalTime: e.target.value }))
              }
            />
          </InputContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputGrouping>
              <InputContainer>
                <p>Duration</p>
                <input
                  id="duration"
                  type="number"
                  placeholder="Duration of Job"
                  value={basicInfo.duration || ''}
                  onChange={(e) => {
                    dispatch(updateBasicInfo({ duration: e.target.value }));
                  }}
                />
              </InputContainer>
            </InputGrouping>
            <InputGrouping>
              <InputContainer>
                <p>&nbsp;</p>
                <SelectContainer>
                  <img src={Down} alt="down" />
                  <select
                    id="durationType"
                    onChange={(e) => {
                      dispatch(
                        updateBasicInfo({ durationType: e.target.value })
                      );
                    }}
                    value={basicInfo.durationType || 'Hours'}
                  >
                    <option value="Hours">Hours</option>
                    <option value="Days">Days</option>
                  </select>
                </SelectContainer>
              </InputContainer>
            </InputGrouping>
          </div>
        </InputGrouping>
      </InfoContainer>
    </BasicInfoContainer>
  );
}
