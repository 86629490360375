import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Button from '../../reusables/Button';
import Elipses from '../../assets/images/Ellipsis.svg';
import Popup from './Popup';

const TheCard = styled.div`
  display: flex;
  margin: 10px;
  flex-direction: column;
  min-height: 269px;
  width: 360px;
  padding: 19px;
  justify-content: space-between;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
`;

const EllipsisContainer = styled.div`
  margin-right: 0px !important;
  position: relative;
  button {
    cursor: pointer;
    border: none;
    background-color: white;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;

const Section = styled.div`
  width: 100%;
`;

const SectionText = styled.p`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.grey.base};
  span {
    font-weight: 600;
    margin-left: 4px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: space-between;
`;

const CardDescription = styled.p`
  margin-top: 16px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey.base};
`;

function formatDate(value) {
  const date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${day} ${month}, ${year}`;
}
// ellipsis for future use
// <img src={Elipses} alt="yolo" />
export default function Card({ draft, handleViewReport }) {
  const theme = useTheme();
  const [showBubble, setShowBubble] = useState(false);
  const [description, setDescription] = useState('');

  const handleEllipsesClick = () => {
    if (showBubble) {
      setShowBubble(false);
    } else {
      setShowBubble(true);
    }
  };

  useEffect(() => {
    if (draft.draftInfo?.basicInfo?.description) {
      if (draft.draftInfo?.basicInfo?.description.length < 150) {
        setDescription(draft.draftInfo?.basicInfo?.description);
      } else {
        setDescription(
          draft.draftInfo?.basicInfo?.description.substring(0, 150) + '...'
        );
      }
    }
  }, []);

  return (
    <TheCard>
      <Section>
        <TitleContainer>
          <h3>{draft.draftInfo?.basicInfo?.projectName}</h3>
          <EllipsisContainer>
            <button type="button" onClick={() => handleEllipsesClick()}>
              <img src={Elipses} alt="yolo" />
            </button>
            {showBubble ? (
              <Popup content={draft} setShowBubble={setShowBubble} />
            ) : null}
          </EllipsisContainer>
        </TitleContainer>
        <CardDescription>{description}</CardDescription>
      </Section>
      <Section>
        <SectionText>
          Job Walk Date:
          <span>
            {formatDate(
              draft.draftInfo?.basicInfo.jobWalkDate ||
                draft.draftInfo?.basicInfo.date
            )}
          </span>
        </SectionText>
        <SectionText>
          Customer:
          <span>{draft.draftInfo?.basicInfo.customerName}</span>
        </SectionText>
        <ButtonContainer>
          <Button
            theme={theme.buttons.viewJobWalk}
            onClick={() => handleViewReport(draft.liftplanId, 'jobwalk', 1)}
          >
            Edit Job Walk
          </Button>
          <Button
            theme={theme.buttons.viewJobWalk}
            onClick={() => handleViewReport(draft.liftplanId, 'jobwalk', 5)}
          >
            View Report
          </Button>
          {/* {draft.linkedLiftplan && (
            <Button
              theme={theme.buttons.share}
              onClick={() => handleViewReport(draft.liftplanId, 'liftplan')}
            >
              Edit Lift Plan
            </Button>
          )} */}
        </ButtonContainer>
      </Section>
    </TheCard>
  );
}
