import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import styled from 'styled-components/macro';
import UploadIMG from '../../assets/images/UploadPlaceholder.png';
import {
  resetCanvas,
  updateMode,
  setUploadModal,
  setCanvas,
  setImage,
  setConfiguration,
  setOverlayDefault,
} from '../../context/siteplan';
import Modal from '../../reusables/Modal/Modal';
import { uploadFile } from './sitePlanFunctions';
import Button from '../../reusables/Button';
import Pagination from '../../reusables/Pagination';

const TheImage = styled.label`
  height: 85px;
  width: 115px;
  border-radius: 8px;
  background-size: 115px 85px;
  img {
    border-radius: 8px;
    height: 85px;
    width: 115px;
  }
  input {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
`;

export default function Overlay({ overlays, setOverlay, blank, setBlank }) {
  const [tempOverlay, setTempOverlay] = useState(null);
  const canvasState = useSelector((state) => state.site.overlays);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [uploadPage, setUploadPage] = useState(1);

  const checkUploadPage = (num) => {
    let n = num;
    if (n < 1) {
      n = 1;
    }
    if (n > Math.ceil((overlays.length + 1) / 8)) {
      n = Math.ceil((overlays.length + 1) / 8);
    }
    setUploadPage(n);
  };

  return (
    <Modal
      show={canvasState.uploadModal}
      modalStyles={theme.modals.info}
      handleClose={() => {
        dispatch(setUploadModal(false));
        setTempOverlay(null);
      }}
      title="Select Image"
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
          height: '186px',
          paddingLeft: '10px',
        }}
      >
        {overlays &&
          overlays.slice((uploadPage - 1) * 8, uploadPage * 8).map((data) => (
            <TheImage
              key={data.imageLink}
              onClick={() => {
                setTempOverlay(data);
              }}
            >
              <img
                src={data.imageLink}
                style={{
                  border:
                    tempOverlay && tempOverlay.imageLink === data.imageLink
                      ? '3px solid #0458B2'
                      : 'none',
                }}
              />
            </TheImage>
          ))}
        {uploadPage === Math.ceil((overlays.length + 1) / 8) && (
          <TheImage style={{ backgroundImage: `url(${UploadIMG})` }}>
            <input
              type="file"
              onChange={(e) =>
                uploadFile(
                  e.target.files[0],
                  (url) => {
                    dispatch(setImage({ imageLink: url }));
                    dispatch(updateMode('photo'));
                  },
                  () => {
                    dispatch(resetCanvas());
                    dispatch(setCanvas(true));
                    dispatch(setUploadModal(false));
                    setTempOverlay(null);
                    dispatch(updateMode('loading'));
                  }
                )
              }
              accept="image/*"
            />
          </TheImage>
        )}
      </div>
      {Math.ceil((overlays.length + 1) / 8) > 1 && (
        <Pagination
          current={uploadPage}
          numPages={Math.ceil((overlays.length + 1) / 8)}
          handlePage={(n) => checkUploadPage(n)}
          handleLeft={() => checkUploadPage(uploadPage - 1)}
          handleRight={() => checkUploadPage(uploadPage + 1)}
          disabled={[0, Math.ceil((overlays.length + 1) / 8) + 1]}
        />
      )}
      <ButtonContainer>
        <Button
          theme={theme.buttons.editJobWalk}
          style={{ width: 'auto' }}
          onClick={() => {
            dispatch(setOverlayDefault());
            setTempOverlay(null);
            setBlank(false);
            setOverlay(null);
          }}
        >
          Reset Overlay
        </Button>

        <CheckContainer>
          <input
            type="checkbox"
            name="blank"
            checked={blank}
            onChange={() => setBlank(!blank)}
          />
          <label htmlFor="blank">&nbsp; Remove satellite imagery</label>
        </CheckContainer>

        <Button
          theme={
            tempOverlay !== null
              ? theme.buttons.overlay
              : theme.buttons.overlayDisabled
          }
          onClick={() => {
            dispatch(setConfiguration(tempOverlay));
            dispatch(setUploadModal(false));
            if (!tempOverlay.mapPoint1) {
              dispatch(updateMode('measurements'));
              return;
            }
            setOverlay(tempOverlay);
            setTempOverlay(null);
          }}
        >
          Submit
        </Button>
      </ButtonContainer>
    </Modal>
  );
}
