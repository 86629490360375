import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleMap } from '@react-google-maps/api';
import paper from 'paper';
import Image from './circle-outline.svg';
import { updateMode, setDimensions, setTemp } from '../../context/siteplan';
import {
  setNoteModal,
  setRadiusModal,
  setElevationModal,
  setLoaded,
  setShowOverlay,
  setNotification,
} from '../../context/liftpage';
import MapOverlay from './siteplan_overlay';
import {
  generateObstacle,
  createFootprintPath,
  moveObstacle,
  attachPolygonInfoWindow,
  placeCraneOverlay,
  placeObstacles,
  placeNotes,
  findPlace,
  handleOnTilesLoaded,
} from './sitePlanFunctions';
import Pin1 from '../../assets/images/Pin1.png';
import Pin2 from '../../assets/images/Pin2.png';
import BlankPNG from '../../assets/images/blank.png';

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};

let handler = null;

export default function SiteplanMap({
  mapRef,
  setMapRef,
  footprint,
  setFootprint,
  setObstacleOverlays,
  setCraneOverlay,
  setNotesOverlays,
  livePolyline,
  setLivePolyline,
  livePreview,
  setLivePreview,
  setNote,
  setSelectedNote,
  setInitialSetup,
  blank,
  basicInfo,
  setSelectedObstacle,
}) {
  const [center, setCenter] = useState(null);
  const { siteDetails, craneDetails, craneList, mode } = useSelector(
    (state) => state.site
  );
  const { showOverlay, loaded } = useSelector((state) => state.page);
  const canvasState = useSelector((state) => state.site.overlays);
  const [rasterPos, setRasterPos] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (canvasState.imageLink !== '' && canvasState.isShown) {
      if (canvasState.point1.x === 0) {
        const canvas = document.getElementById('canvas');
        paper.setup(canvas);
      }
      paper.project.clear();
      const raster = new paper.Raster({
        source: canvasState.imageLink,
        position: rasterPos,
      });
      let scale = 1;
      const pin1 = new paper.Raster({
        source: Pin1,
      });

      const pin2 = new paper.Raster({
        source: Pin2,
      });

      pin1.visible = false;
      pin2.visible = false;
      raster.onLoad = () => {
        if (rasterPos === undefined) {
          raster.position = paper.view.center;
        }
        dispatch(
          setDimensions({
            height: raster.size.height,
            width: raster.size.width,
          })
        );
        if (
          raster.size.height < paper.view.size.height ||
          raster.size.height < paper.view.size.height
        ) {
          raster.size.height = raster.size.height * 2;
          raster.size.width = raster.size.width * 2;
          scale = 2;
        }
        dispatch(
          setTemp({
            tempPoint: {
              x:
                (raster.size.width / 2 -
                  raster.position.x +
                  paper.view.center.x) /
                scale,
              y:
                (raster.size.height / 2 -
                  raster.position.y +
                  paper.view.center.y) /
                scale,
            },
          })
        );
        if (canvasState.point1.x !== 0) {
          pin1.visible = true;
          pin1.position.x =
            raster.position.x -
            raster.size.width / 2 +
            canvasState.point1.x * scale;
          pin1.position.y =
            raster.position.y -
            raster.size.height / 2 +
            canvasState.point1.y * scale -
            32;
        }
        if (canvasState.point2.x !== 0) {
          pin2.visible = true;
          pin2.position.x =
            raster.position.x -
            raster.size.width / 2 +
            canvasState.point2.x * scale;
          pin2.position.y =
            raster.position.y -
            raster.size.height / 2 +
            canvasState.point2.y * scale -
            32;
        }
      };

      paper.view.onMouseUp = () => {
        setRasterPos(raster.position);
        dispatch(
          setTemp({
            tempPoint: {
              x:
                (raster.size.width / 2 -
                  raster.position.x +
                  paper.view.center.x) /
                scale,
              y:
                (raster.size.height / 2 -
                  raster.position.y +
                  paper.view.center.y) /
                scale,
            },
          })
        );
      };

      paper.view.onMouseDrag = (event) => {
        raster.position.x += event.delta.x;
        raster.position.y += event.delta.y;
        pin1.position.x =
          raster.position.x -
          raster.size.width / 2 +
          canvasState.point1.x * scale;
        pin1.position.y =
          raster.position.y -
          raster.size.height / 2 +
          canvasState.point1.y * scale -
          32;
        pin2.position.x =
          raster.position.x -
          raster.size.width / 2 +
          canvasState.point2.x * scale;
        pin2.position.y =
          raster.position.y -
          raster.size.height / 2 +
          canvasState.point2.y * scale -
          32;
      };
    }
  }, [
    canvasState.imageLink,
    canvasState.point1,
    canvasState.point2,
    canvasState.isShown,
  ]);

  const onMapLoad = (e) => {
    if (loaded) dispatch(setLoaded(false));
    let possibleLocation = null;
    if (!siteDetails.craneLocation) {
      dispatch(setRadiusModal(true));
    } else {
      setInitialSetup(true);
      if (siteDetails.pinType !== 'Overlay') dispatch(updateMode('select'));
    }
    if (siteDetails?.craneLocation) {
      const { craneLocation, setLocation, pickLocation } = siteDetails;
      setCraneOverlay(
        placeCraneOverlay(
          e,
          craneLocation,
          setLocation,
          pickLocation,
          craneDetails
        )
      );
      possibleLocation = { ...craneLocation };
    }

    if (siteDetails?.packagedObstacles) {
      setObstacleOverlays(
        placeObstacles(
          e,
          siteDetails.packagedObstacles,
          true,
          () => dispatch(setElevationModal(true)),
          setSelectedObstacle
        )
      );
    }

    if (siteDetails?.packagedNotes) {
      setNotesOverlays(
        placeNotes(
          e,
          siteDetails.packagedNotes,
          true,
          setNote,
          setSelectedNote,
          () => dispatch(setNoteModal(true))
        )
      );
    }

    findPlace(e, basicInfo, possibleLocation, setCenter, (e) =>
      dispatch(setNotification(e))
    );

    setMapRef(e);
  };

  const dragPin = (
    type,
    livePreview,
    siteDetails,
    craneDetails,
    mapRef,
    livePolyline
  ) => {
    const heading = google.maps.geometry.spherical.computeHeading(
      siteDetails.craneLocation,
      mapRef.getCenter()
    );

    const newLocation = google.maps.geometry.spherical.computeOffset(
      siteDetails.craneLocation,
      (type === 'Set' ? craneDetails.setRadius : craneDetails.pickRadius) *
        0.3048,
      heading
    );
    const newerLocation = {
      lat: newLocation.lat(),
      lng: newLocation.lng(),
    };
    if (livePolyline) {
      livePolyline.setPath([livePolyline.getPath().getAt(0), newerLocation]);
    }
    if (!livePolyline) {
      const newPolyline = new google.maps.Polyline({
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: '0',
            repeat: '20px',
          },
        ],
      });
      newPolyline.setPath([siteDetails.craneLocation, newerLocation]);
      newPolyline.setMap(mapRef);
      newPolyline.marker = null;
      setLivePolyline(newPolyline);
    }
    const newPreview = new google.maps.Marker({
      label: {
        text: `${type} location`,
        className: 'marker-position',
        color: 'white',
      },
      icon: {
        url: Image,
        scaledSize: { width: 20, height: 20 },
        anchor: { x: 10, y: 10 },
      },
    });
    if (livePreview) {
      livePreview.setMap(null);
    }
    newPreview.setMap(mapRef);
    newPreview.setPosition(newerLocation);
    setLivePreview(newPreview);
  };

  useEffect(() => {
    if (mode === 'crane' && !footprint) {
      const footprintPath = createFootprintPath(
        mapRef,
        craneDetails.footprintDimensions
      );
      footprintPath.setRadius = craneDetails.setRadius || 5;
      footprintPath.pickRadius = craneDetails.pickRadius || 5;
      const theFootprint = generateObstacle(
        'polygon',
        footprintPath,
        'footprint'
      );
      theFootprint.setMap(mapRef);
      setFootprint(theFootprint);
    }
  }, [mode]);

  const handleOnDrag = () => {
    if (!mapRef) return;
    if (mode === 'crane') {
      if (!footprint) {
        return;
      }
      moveObstacle(
        footprint,
        mapRef,
        siteDetails.angle || 0,
        craneDetails.footprintDimensions
      );
    }
    if (mode === 'pins') {
      if (
        siteDetails.craneLocation &&
        (footprint.setCircle || footprint.pickCircle)
      ) {
        if (handler) {
          clearTimeout(handler);
        }
        handler = setTimeout(
          () =>
            dragPin(
              siteDetails.pinType,
              livePreview,
              siteDetails,
              craneDetails,
              mapRef,
              livePolyline
            ),
          50
        );
      }
    }
    if (
      livePolyline &&
      (mode === 'polygon' ||
        mode === 'line' ||
        mode === 'arrow' ||
        mode === 'measurements')
    ) {
      if (livePolyline.marker) {
        livePolyline.marker.setMap(null);
      }
      attachPolygonInfoWindow(livePolyline, mapRef);
      livePolyline.setPath([
        livePolyline.getPath().getAt(0),
        mapRef.getCenter(),
      ]);
    }
  };

  return canvasState.isShown ? (
    <canvas
      id="canvas"
      style={{ height: '100%', width: '100%', resize: 'true' }}
    />
  ) : (
    <GoogleMap
      mapContainerStyle={{ width: '100%' }}
      id="map-container-main"
      mapContainerClassName="mapContainer"
      zoom={20}
      center={center}
      onLoad={(e) => onMapLoad(e)}
      onTilesLoaded={() =>
        handleOnTilesLoaded(
          mapRef,
          loaded,
          center,
          setLoaded,
          setFootprint,
          siteDetails,
          craneDetails,
          dispatch,
          setShowOverlay
        )
      }
      tilt={0}
      onDrag={() => handleOnDrag()}
      onZoomChanged={() => handleOnDrag()}
      options={{
        rotateControl: false,
        mapTypeId: 'satellite',
        disableDefaultUI: true,
      }}
    >
      {blank && showOverlay ? (
        <MapOverlay
          image={BlankPNG}
          bounds={
            new google.maps.LatLngBounds({
              north: canvasState.overlayBounds.north + 1,
              south: canvasState.overlayBounds.south - 1,
              east: canvasState.overlayBounds.east + 1,
              west: canvasState.overlayBounds.west - 1,
            })
          }
          z={1}
        />
      ) : null}
      {showOverlay && loaded ? (
        <MapOverlay
          image={canvasState.imageLink}
          bounds={new google.maps.LatLngBounds(canvasState.overlayBounds)}
          deg={canvasState.deg}
          z={2}
        />
      ) : null}
    </GoogleMap>
  );
}
